export const copyTextToClipboard = (text: string) => {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(text);
    }

    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return true;
}