import React, { useMemo, useRef } from 'react';

import toast from 'toasted-notes';

import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import Toast from '../toast';
import { copyTextToClipboard } from '../../../infrastructure/utils/clipboard';

interface IProps {
    value: string;
    id: string;
    label: string;
    disabled?: boolean;
}

const CopyToClipboardField = (props: IProps) => {
    const { id, label, value: valueProps, disabled: disabledProps } = props;

    const inputRef = useRef<any>();

    const value = useMemo(() => valueProps, [valueProps]);
    const disabled = useMemo(() => disabledProps === undefined ? true : disabledProps, [disabledProps]);

    const handleClick = () => {
        if (!inputRef.current) {
            return;
        }

        copyTextToClipboard(value);

        toast.notify(
            <Toast
                text="Copié dans le presse papier."
            />,
            {
                duration: 10000,
                position: "top"
            }
        );
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                inputRef={(ref) => inputRef.current = ref}
                id={id}
                type="text"
                readOnly={disabled}
                fullWidth
                value={value}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="copy to clipboard"
                            onClick={handleClick}
                        >
                            <CopyIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default CopyToClipboardField;