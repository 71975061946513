import React from 'react';

import { IPage } from '../../../../infrastructure/services/pages/types';
import { getHumanizedDate } from '../../../../infrastructure/utils/dates';

import DJCFooter from '../../../components/footer';
import Markdown from '../../../components/markdown';

interface IRenderProps {
    article: IPage;
}

const Render = (props: IRenderProps) => {
    const { article } = props;
    
    const articleUrl = `https://dojo.codes${window.location.pathname}`;
    const fbUrl = `https://www.facebook.com/dialog/feed?app_id=1322248731483455&display=popup&link=${articleUrl}&description=${article.title}`;
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${articleUrl}&title=${article.title}&summary=${article.title}&source=${articleUrl}`;

    return (
        <>
            <div className="djc--view-article">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row article-title">
                            <h1>{ article.title }</h1>
                        </div>
                        <div className="row article-meta">
                            <div className="col-lg-6 col-xs-12 order-md-2 order-2 order-lg-1">
                                <div className="row align-items-center">
                                    <span>
                                        <b>Auteur: </b>{ article.author } • <b>Crée le:</b> {getHumanizedDate(article.created_at)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12 order-md-1 order-1 order-lg-2">
                                <div className="ml-1 mr-1 mb-8 row justify-content-lg-end justify-content-start">
                                    <a
                                        href={`https://twitter.com/intent/tweet?text=${article.title} ${articleUrl}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-social-icon btn-xs btn-twitter mr-2"
                                        data-show-count="false"
                                    >
                                        <i className="fa fa-twitter" />
                                    </a>

                                    <a
                                        href={fbUrl}
                                        target="popup"
                                        onClick={() => {
                                            window.open(fbUrl,'popup','width=600,height=600');
                                            return false;
                                        }}
                                        className="btn btn-social-icon btn-xs btn-facebook mr-2"
                                    >
                                        <span className="fa fa-facebook"></span>
                                    </a>

                                    <a
                                        href={linkedInUrl}
                                        target="popup"
                                        onClick={() => {
                                            window.open(linkedInUrl,'popup','width=600,height=600');
                                            return false;
                                        }}
                                        className="btn btn-social-icon btn-xs btn-linkedin"
                                        data-show-count="false"
                                    >
                                        <span className="fa fa-linkedin"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        {
                            /*
                            article.meta.article_image && (
                                <div className="article-image">
                                    <div className="img-container">
                                        <img
                                            src={article.meta.article_image.src}
                                            alt="article presentation"
                                        />
                                    </div>
                                    <span className="article-image-meta">
                                        Image by {article.meta.article_image.credit.author} from <a href={article.meta.article_image.credit.href}>{article.meta.article_image.credit.link_txt}</a>
                                    </span>
                                </div>
                            )
                            */
                        }

                        <article className="article">
                            <Markdown
                                children={article.content}
                            />
                        </article>
                    </div>
                </div>
            </div>

            <DJCFooter />
        </>
    )
};

export default Render;
