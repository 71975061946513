import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import Toast from '../../../../../components/toast';
import { useMutationGetUserByUsername, useMutationUpdateUserInfos } from '../../../../../../infrastructure/services/users';
import { IUser } from '../../../../../../infrastructure/services/users/types';

import MuiPhoneNumber from 'material-ui-phone-number';
import { schoolsMocked } from '../../../../../mock/schools';
import PasswordField from '../../../../../components/password_field';

type Inputs = {
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
    guild: string;
    experience: string;
    password_confirm: string;
}

interface IProps {
    slug: string;
}

const AdminUserEditView = (props: IProps) => {
    const { slug } = props;

    const [fetchUserByUsername] = useMutationGetUserByUsername();
    const [fetchUpdate] = useMutationUpdateUserInfos();

    const [baseUserInfos, setBaseUserInfos] = useState<Partial<IUser>>({});

    const history = useHistory();

    const generateError = (errors: IError<Inputs>, field: string, condition: boolean, errorMessage: string) => {
        if (!condition) {
            return errors;
        }

        return {
            ...errors,
            [field]: { error: true, message: errorMessage }
        };
    }

    const handleValidate = (v: Inputs) => {
        let errors: IError<Inputs> = {};

        if (v.password.length) {
            errors = generateError(errors, "password", v.password !== v.password_confirm, "Mot de passe différents");
            errors = generateError(errors, "password_confirm", v.password !== v.password_confirm, "Mot de passe différents");
        }

        return errors;
    }

    const {
        values,
        handleBlur,
        handleChange,
        handleChangeSingleAutocomplete,
        handleChangeSpecificFieldWithValue,
        updateValues,
        errors,
        containsError,
        fulfilled
    } = useForm<Inputs>({
        defaultValues: {
            first_name: baseUserInfos.first_name || "",
            guild: baseUserInfos.guild || "",
            experience: baseUserInfos.experience || "",
            last_name: baseUserInfos.last_name || "",
            password: "",
            phone: baseUserInfos.phone || "",
            password_confirm: ""
        },
        validate: handleValidate,
    });

    const init = async () => {
        const resp = await fetchUserByUsername(slug);

        if (resp.data) {
            setBaseUserInfos(resp.data);
            updateValues({
                first_name: resp.data.first_name,
                guild: resp.data.guild || undefined,
                experience: resp.data.experience || undefined,
                last_name: resp.data.last_name,
                phone: resp.data.phone || undefined,
            });
        } else {
            toast.notify(
                <Toast
                    text={`Error while fetching user informations. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );

            history.push('/admin/users')
        }
    };

    useEffect(() => {
        init();
    }, []);

    const handleCancel = () => {
        history.push("/admin/users");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchUpdate({
            username: slug,
            body: {
                first_name: values.first_name.length > 0 ? values.first_name : undefined,
                last_name: values.last_name.length > 0 ? values.last_name : undefined,
                guild: values.guild.length > 0 ? values.guild : undefined,
                experience: values.experience.length > 0 ? values.experience : undefined,
                password: values.password.length > 0 ? values.password : undefined,
                phone: values.phone.length > 0 ? values.phone : undefined,
            }
        })
        if (resp.status === "fulfilled") {
            toast.notify(
                <Toast text={"Modifications sauvegardées."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/users');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-cogs" />
                    <h1>Modifier Utilisateur: {slug}</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>Personnal Informations</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-first-name"
                                    name="first_name"
                                    label="Prénom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.first_name}
                                    variant="outlined"
                                    required
                                    error={errors.first_name !== undefined}
                                    helperText={errors.first_name?.message}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-last-name"
                                    name="last_name"
                                    label="Nom de Famille"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.last_name}
                                    variant="outlined"
                                    required
                                    error={errors.last_name !== undefined}
                                    helperText={errors.last_name?.message}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-experience"
                                    name="experience"
                                    label="Expérience professionnelle (nombre d'années)"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.experience}
                                    variant="outlined"
                                    error={errors.experience !== undefined}
                                    helperText={errors.experience?.message}
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <MuiPhoneNumber
                                    defaultCountry={'fr'}
                                    variant="outlined"
                                    fullWidth
                                    id="input-phone"
                                    name={"phone"}
                                    label={"Numéro de téléphone"}
                                    regions="europe"
                                    onChange={handleChangeSpecificFieldWithValue("phone")}
                                    value={values.phone}
                                    error={errors.phone}
                                    helperText={
                                        errors.phone !== undefined
                                            ? errors['phone']
                                            : ""
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <Autocomplete
                                    id="input-guild"
                                    fullWidth
                                    onChange={handleChangeSingleAutocomplete("guild")}
                                    freeSolo
                                    value={values.guild}
                                    options={schoolsMocked.map<string>((school) => {
                                        if (school.label && school.short_name) {
                                            return `${school.label} (${school.short_name})`;
                                        }

                                        if (school.label) {
                                            return school.label;
                                        }

                                        if (school.short_name) {
                                            return school.short_name;
                                        }

                                        return school.short_name;
                                    })}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name="guild"
                                            label="Guild"
                                            variant="outlined"
                                            fullWidth
                                            error={errors.guild}
                                            helperText={
                                                errors.guild !== undefined
                                                    ? errors.guild
                                                    : ""
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid section">
                    <h4>Password</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <PasswordField
                                    id="input-password"
                                    label="Mot de passe"
                                    name="password"
                                    inputProps={{
                                        onBlur: handleBlur,
                                        onChange: handleChange,
                                        required: true,
                                        error: errors.password !== undefined
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <PasswordField
                                    id="input-password-confirm"
                                    label="Mot de passe (confirmation)"
                                    name="password_confirm"
                                    inputProps={{
                                        onBlur: handleBlur,
                                        onChange: handleChange,
                                        required: true,
                                        error: errors.password_confirm !== undefined
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminUserEditView;
