import { api } from "..";

const mailExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        verifyMailToken: build.mutation<boolean, string>({
            query: (token: string) => ({
                url: `/authentication/verify_mail/${token}`,
                method: 'GET'
            })
        })
    }),
    overrideExisting: false
});

const {
    verifyMailToken: {
        useMutation: useMutationVerifyMailToken
    }
} = mailExtendedApi.endpoints;

export { mailExtendedApi };
export {
    useMutationVerifyMailToken
};

