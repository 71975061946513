import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Render from './render';
import { isLoggedDrawerOpenSelector } from '../../../infrastructure/stores/global/selectors';
import { toggleLoggedDrawer } from '../../../infrastructure/stores/global/actions';
import { clearCurrentUser, selectCurrentUser } from '../../../infrastructure/services/users/slice';
import { clearUserToken } from '../../../infrastructure/services/authentication/slice';
import { cleanAuthOnLocalStorage } from '../../../infrastructure/services/authentication/utils';
import { Action, ISection } from './const';
import { SETTINGS_ROUTE } from '../../../infrastructure/globals/routes';

const items: ISection[] = [
    {
        label: "Mon Compte",
        adminRequired: false,
        items: [
            {
                label: "Mon Compte",
                action: 'GOTO_MY_ACCOUNT',
                icon: AccountCircleIcon,
                disabled: false
            }
        ]
    },
    {
        label: "Administration",
        adminRequired: true,
        items: [
            {
                label: "Admin Dashboard",
                action: 'GOTO_ADMIN',
                icon: AccountCircleIcon,
                disabled: false
            },
        ]
    },
];

const LoggedDrawer = () => {
    const isloggedDrawerOpen = useSelector(isLoggedDrawerOpenSelector);
    const currentUser = useSelector(selectCurrentUser);

    const isAdmin = useMemo<boolean>(() => {
        if (!currentUser) {
            return false;
        }

        return ["Admin", "Owner", "Moderator"].includes(currentUser.role);
    }, [currentUser]);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = () => {
        dispatch(toggleLoggedDrawer());
    };

    const handleLogout = () => {
        handleClose();

        dispatch(clearCurrentUser());
        dispatch(clearUserToken());
        cleanAuthOnLocalStorage();
    };

    const redirectTo = (url: string) => {
        history.push(url);
    };

    const handleAction = (action: Action) => () => {
        handleClose();

        switch (action) {
            case 'GOTO_MY_ACCOUNT':
                redirectTo(SETTINGS_ROUTE + "/account");
                break;
            case 'GOTO_ADMIN':
                redirectTo('/admin');
                break;
            default:
                return;
        }
    };

    return (
        <Render
            open={isloggedDrawerOpen}
            handleClose={handleClose}
            handleLogout={handleLogout}
            handleAction={handleAction}
            isUserAdmin={isAdmin}
            items={items}
        />
    );
};

export default LoggedDrawer;
