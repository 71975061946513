import { api } from "..";
import { IAction } from "./types";

const actionExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getActionsList: build.mutation<{ actions: Array<IAction> }, void>({
            query: () => ({
                url: '/actions',
                method: "GET"
            })
        }),
        postActionCreate: build.mutation<IAction, IAction>({
            query: (args) => ({
                url: '/actions',
                method: 'POST',
                body: args
            })
        }),
        getActionById: build.mutation<IAction, string>({
            query: (arg) => ({
                url: `/actions/${arg}`,
                method: 'GET'
            })
        }),
        deleteActionById: build.mutation<void, string>({
            query: (arg) => ({
                url: `/actions/${arg}`,
                method: 'DELETE',
                validateStatus: (status) => status === 204
            })
        })
    }),
    overrideExisting: false
});

const {
    getActionsList: {
        useMutation: useMutationGetActionsList
    },
    postActionCreate: {
        useMutation: useMutationPostActionCreate
    },
    getActionById: {
        useMutation: useMutationGetActionById
    },
    deleteActionById: {
        useMutation: useMutationDeleteActionById
    }
} = actionExtendedApi.endpoints;

export { actionExtendedApi };
export {
    useMutationDeleteActionById,
    useMutationPostActionCreate,
    useMutationGetActionsList,
    useMutationGetActionById
};
