import React from 'react';
import ReactDOM from 'react-dom';
import EditorJS, { ToolConstructable, ToolSettings } from '@editorjs/editorjs';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import SimpleImage from '@editorjs/simple-image';

import { fromBlockToMd, fromMdToBlock } from './utils';

type AvailableTools = (
    "header"
    | "list"
    | "image"
)

interface IWYSIWYGEditorProps {
    editorJSConfig?: EditorJS.EditorConfig;
    tools?: Array<AvailableTools>;
    defaultValue?: string;
}

class WYSIWYGEditor extends React.Component<IWYSIWYGEditorProps> {
    editor: EditorJS | null = null;
    editorJSConfig: EditorJS.EditorConfig = {};

    constructor (props: IWYSIWYGEditorProps) {
        super(props);

        if (this.props.editorJSConfig) {
            this.editorJSConfig = { ...this.props.editorJSConfig };
        }

        this.state = {};
    }

    componentDidMount() {
        const dom = ReactDOM.findDOMNode(this) as HTMLElement;

        const {
            editorJSConfig: { holder, ...editorJS }
        } = this;
        
        const editorProps: EditorJS.EditorConfig = {
            holder: dom,
            ...editorJS
        };

        if (this.props.defaultValue) {
            editorProps.data = fromMdToBlock(this.props.defaultValue);
        }

        if (this.props.tools) {
            const tools: {
                [toolName: string]: ToolConstructable|ToolSettings
            } = {};

            for (let tool of this.props.tools) {
                switch (tool) {
                    case "header": {
                        tools["header"] = {
                            class: Header,
                            config: {
                                placeholder: null,
                                levels: [2, 3],
                                defaultLevel: 2
                            }
                        }
                        break;
                    }
                    case "list": {
                        tools["list"] = {
                            class: List,
                            inlineToolbar: true,
                        }
                        break;
                    }
                    case "image": {
                        tools["image"] = {
                            class: SimpleImage
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            editorProps.tools = tools;
        }

        this.editor = new EditorJS(editorProps);
    }

    async fromModelToMd(): Promise<string | null> {
        if (!this.editor) {
            return null;
        }
        const savedData = await this.editor.saver.save();

        if (!savedData) {
            return null;
        }

        const { blocks } = savedData;

        const generatedMdBlocks = blocks.map(fromBlockToMd);

        return generatedMdBlocks.join('\n');
    }

    render () {
        const { editorJSConfig, ...props } = this.props;

        return React.createElement("div", props);
    }
};

export default WYSIWYGEditor;
