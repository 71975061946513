export const AUTH_SCOPES = {
    challenges: {
        READ: "challenge_read",
        WRITE: "challenge_write",
        CHECK: "challenge_check",
        CREATE: "challenge_create",
        DELETE: "challenge_delete",
        ADMIN: "challenge_admin"
    },
    campaigns: {
        READ: "campaign_read",
        WRITE: "campaign_write",
        DELETE: "campaign_delete",
        ADMIN: "campaign_admin",
    },
    users: {
        READ: "users_read",
        WRITE: "users_write",
        DELETE: "users_delete",
        ADMIN: "users_admin",
        CREATE: "users_create",
    },
    code: {
        EXECUTE: "code_execute",
    },
    roles: {
        READ: "roles_read",
    },
    tags: {
        READ: "tags_read",
        ADMIN: "tags_admin",
    },
    actions: {
        READ: "actions_read",
        ADMIN: "actions_admin",
    },
    pages: {
        READ: "page_read",
        WRITE: "page_write",
        ADMIN: "page_admin"
    },
    environments: {
        READ: "environment_read",
        WRITE: "environment_write",
        ADMIN: "environment_admin",
    },
    scores: {
        READ: "score_read",
        WRITE: "score_write",
        ADMIN: "score_admin",
    }
};

export const getScopesAsArray = (): string[] => {
    return Object.values(AUTH_SCOPES).map(v => Object.values(v)).flat();
};
