import React from 'react';

import Render from './render';

interface IDJCFooterProps {
    dark?: boolean;
}

const DJCFooter = (props: IDJCFooterProps) => {
    const { dark } = props;

    return (
        <Render
            dark={dark || false}
        />
    );
};

export default DJCFooter;
