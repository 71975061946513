import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { renderers } from '../renderers';

interface IPanelProps {
    src: string;
};

const Panel = (props: IPanelProps) => {
    const { src } = props;

    const title = React.useMemo<string>(() => {
        const results = src.split('\n')[0]
        return  results;
    }, [src]);

    const values = React.useMemo<string[]>(() => {
        return src.split('line: ').slice(1).map(v => v.replaceAll('\n', '\n'));
    }, [src]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h3>{title}</h3>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
                {
                    values.map(subtext => (
                        subtext.trim().length === 0 ? (
                            <br />
                        ) : (
                            <React.Fragment key={subtext}>
                                <div className="markdown">
                                    <ReactMarkdown
                                        plugins={[gfm]}
                                        renderers={renderers}
                                        children={subtext}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    ))
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default Panel;
