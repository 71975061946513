import React, { KeyboardEvent } from 'react';

import Render from './render';
import toast from 'toasted-notes';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutationPostResetPasswordRequest } from '../../../infrastructure/services/authentication';
import Toast from '../../components/toast';

export interface IFormInputs {
    email: string;
};

const rules = {
    email: {
        minLength: 3,
        required: true
    },
};

const ResetPasswordRequestView = () => {
    const {
        control,
        errors,
        setError,
        handleSubmit
    } = useForm<IFormInputs>({
        mode: "all",
        defaultValues: {
            email: ""
        }
    });

    const [resetPasswordRequest] = useMutationPostResetPasswordRequest();

    const [isResetPasswordRequestSent, setIsResetPasswordRequestSent] = React.useState<boolean>(false);
    const [pending, setPending] = React.useState<boolean>(false);

    const onSubmit = async (data: IFormInputs) => {
        if (!data.email || !data.email.length) {
            return;
        }

        const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
        if (!emailReg.test(data.email)) {
            setError("email", {
                message: "Mauvais email entré.",
                shouldFocus: true
            });

            return;
        }

        setPending(true);
        const response = await resetPasswordRequest({
            email: data.email
        });

        if (response.data !== undefined) {
            if (response.data) {
                setIsResetPasswordRequestSent(true);
            } else {
                toast.notify(
                    <Toast
                        text="Erreur durant la réinitalisation. Vérifiez votre token ou réessayer plus tard."
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
            }
        } else if (response.error !== undefined) {
            const error = (response.error as any);

            toast.notify(
                <Toast
                    text={error.data.detail.message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }

        
        setPending(false);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        }
    };

    return (
        <Render
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            rules={rules}
            errors={errors}
            pending={pending}
            isResetPasswordRequestSent={isResetPasswordRequestSent}
            handleKeyDown={handleKeyDown}
        />
    );
};

export default ResetPasswordRequestView;
