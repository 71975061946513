import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';
import { updateDocumentTileForCampaign, updateDocumentTitleToDefault } from '../../../../infrastructure/logic/document';
import { useGetCampaignFromRoute, useMutationCampaignIsDone, useMutationGetCampaignParticipantStatus } from '../../../../infrastructure/services/campaign';
import { ICampaignSummary, ICampaignParticipantStatusResults } from '../../../../infrastructure/services/campaign/types';
import { useMutationGetScoreboardResultsById, useMutationGetScoreboardSingleResults } from '../../../../infrastructure/services/scoring';
import { IScoreboardResults, IScoreboardSingleResults } from '../../../../infrastructure/services/scoring/types';
import { selectCurrentUser } from '../../../../infrastructure/services/users/slice';
import { IUser } from '../../../../infrastructure/services/users/types';
import DJCerror from '../../../components/error';

import Render from './render';

interface ICampaignsDoneViewProps {
    slug: string;
};

const CampaignsDoneView = (props: ICampaignsDoneViewProps) => {
    const { slug } = props;

    const history = useHistory();

    const [isReadyToRender, setReadyToRender] = useState<boolean>(false);
    const [campaignParticipantStatus, setCampaignParticipantStatus] = useState<ICampaignParticipantStatusResults | null>(null);
    const [completionMessage, setCompletionMessage] = useState<string | null>(null);
    const [globalResults, setGlobalResults] = useState<IScoreboardResults | null>(null);
    const [userResults, setUserResults] = useState<IScoreboardSingleResults | null>(null);

    const currentUser = useSelector(selectCurrentUser);
    const [fetchIsCampaignFinished] = useMutationCampaignIsDone();
    const [fetchCampaignParticipantStatus] = useMutationGetCampaignParticipantStatus();
    const [fetchScoreboardById] = useMutationGetScoreboardResultsById();
    const [fetchScoreboardSingleResults] = useMutationGetScoreboardSingleResults();

    const {
        data: campaign,
        isUninitialized,
        isLoading,
        isError,
        error
    } = useGetCampaignFromRoute(slug);

    const redirectTo = (url: string) => {
        history.push(url);
    };

    const retreiveScoreboardInformations = async (scoreboardId: string, username: string) => {
        const scoreboardResults = await fetchScoreboardById(scoreboardId);
        const singleResults = await fetchScoreboardSingleResults({
            id: scoreboardId,
            username: username
        });

        if (scoreboardResults.data) {
            setGlobalResults(scoreboardResults.data);
        }
        if (singleResults.data) {
            setUserResults(singleResults.data);
        }
    };

    const init = useCallback(async (camp: ICampaignSummary, user: IUser) => {
        const campaignDoneResp = await fetchIsCampaignFinished(camp.id);

        if (campaignDoneResp.data !== undefined) {
            if (campaignDoneResp.data.completed === false) {
                redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
                return;
            }

            if (campaignDoneResp.data.details) {

                setCompletionMessage(campaignDoneResp.data.details.description);

                if (campaignDoneResp.data.details.associated_scoreboard) {
                    const scoreboardId = campaignDoneResp.data.details.associated_scoreboard;
                    await retreiveScoreboardInformations(scoreboardId, user.username);
                }
            }
        } else if (campaignDoneResp.error) {
            redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
            return;
        }

        const campaignParticipantStatusResp = await fetchCampaignParticipantStatus({
            id: camp.id,
            username: user.username
        });
        if (campaignParticipantStatusResp.data) {
            setCampaignParticipantStatus(campaignParticipantStatusResp.data);
        } else if (campaignParticipantStatusResp.error) {
            redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
            return;
        }

        setReadyToRender(true);
    }, [fetchIsCampaignFinished, slug]);

    useEffect(() => {
        if (!currentUser) {
            redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
            return;
        }

        if (campaign) {
            updateDocumentTileForCampaign(campaign);
            init(campaign, currentUser);
        }

        return () => {
            updateDocumentTitleToDefault();
        };
    }, [campaign, init]);

    if (isError && error) {
        const err = (error as any);
        let errorMsg = "Internal Error";

        if (err.data && err.data.detail) {
            errorMsg = err.data.detail.message;
        }

        return (
            <DJCerror
                status={err.status}
                errorMessage={errorMsg}
            />
        )
    }

    if (isUninitialized || isLoading || !isReadyToRender) {
        return null;
    }

    if (!campaign || !currentUser || !campaignParticipantStatus) {
        return (
            <DJCerror
                status={404}
                errorMessage="Service Temporarily Unavailable"
            />
        )
    }

    return (
        <Render
            campaign={campaign}
            completionMessage={completionMessage}
            userResults={userResults}
            scoreboard={globalResults}
            campaignParticipation={campaignParticipantStatus}
            currentUser={currentUser}
        />
    );
};

export default CampaignsDoneView;
