import { api } from "..";
import {
    IDeletePageByIdParams,
    IGetPageByIdParams,
    IGetPageByIdResults,
    IGetPageBySlugParams,
    IGetPagesResults,
    IPatchPageByIdParams,
    IPatchPageByIdResults,
    IPostCreatePageParams,
    IPostCreatePageResults
} from "./types";

const pageExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPages: build.mutation<IGetPagesResults, "news" | "events" | void>({
            query: (filtersOn?: "news" | "events") => ({
                url: filtersOn === undefined
                    ? '/pages'
                    : `/pages?filter="${filtersOn}"[in]tags`,
                method: 'GET'
            })
        }),
        postCreatePage: build.mutation<IPostCreatePageResults, IPostCreatePageParams>({
            query: (args) => ({
                url: "/pages",
                method: "POST",
                body: args
            })
        }),
        getPageById: build.mutation<IGetPageByIdResults, IGetPageByIdParams>({
            query: (args) => ({
                url: `/pages/${args.id}`,
                method: 'GET'
            })
        }),
        deletePageById: build.mutation<void, IDeletePageByIdParams>({
            query: (args) => ({
                url: `/pages/${args.id}`,
                method: "DELETE"
            })
        }),
        patchPageEdit: build.mutation<IPatchPageByIdResults, IPatchPageByIdParams>({
            query: (args) => ({
                url: `/pages/${args.id}`,
                method: "PATCH",
                body: args.body
            })
        }),
        getPageBySlug: build.mutation<IGetPageByIdResults, IGetPageBySlugParams>({
            query: (args) => ({
                url: `/route/page/${args.slug}`,
                method: 'GET'
            })
        })
    }),
    overrideExisting: false
});

const {
    getPages: {
        useMutation: useMutationGetPages
    },
    postCreatePage: {
        useMutation: useMutationPostCreatePage
    },
    getPageById: {
        useMutation: useMutationGetPageById
    },
    deletePageById: {
        useMutation: useMutationDeletePageById
    },
    patchPageEdit: {
        useMutation: useMutationPatchPageEdit
    },
    getPageBySlug: {
        useMutation: useMutationGetPageBySlug
    }
} = pageExtendedApi.endpoints;

export { pageExtendedApi };
export {
    useMutationDeletePageById,
    useMutationGetPageById,
    useMutationGetPages,
    useMutationPatchPageEdit,
    useMutationPostCreatePage,
    useMutationGetPageBySlug
};
