export const TOGGLE_SIGN_IN_DIALOG = 'TOGGLE_SIGN_IN_DIALOG';
export const TOGGLE_LOGGED_DRAWER = 'TOGGLE_LOGGED_DRAWER';

interface ToggleSignInDialogAction {
    type: typeof TOGGLE_SIGN_IN_DIALOG;
    payload: null;
};

interface ToggleLoggedDrawerAction {
    type: typeof TOGGLE_LOGGED_DRAWER;
    payload: null;
}

export interface GlobalState {
    isSignInDialogOpen: boolean;
    isLoggedDrawerOpen: boolean;
};

export type GlobalActionsTypes = (
    ToggleSignInDialogAction
    | ToggleLoggedDrawerAction
);