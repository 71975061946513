import { api } from "..";
import { ICreatePATInputs, ILoginWithPATInputs, ILoginWithPATOutputs, IPrivatePAT, IPublicPAT } from "./types";

const PATExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPATList: build.mutation<{ access_tokens: IPublicPAT[] }, void>({
            query: () => ({
                url: '/authentication/personal_access_token',
                method: "GET"
            })
        }),
        getPATById: build.mutation<IPublicPAT, string>({
            query: (arg) => ({
                url: `/authentication/personal_access_token/${arg}`,
                method: "GET"
            })
        }),
        postCreatePAT: build.mutation<IPrivatePAT, ICreatePATInputs>({
            query: (args) => ({
                url: '/authentication/personal_access_token',
                method: "POST",
                body: args
            })
        }),
        deletePATById: build.mutation<void, string>({
            query: (args) => ({
                url: `/authentication/personal_access_token/${args}`,
                method: "DELETE",
                validateStatus: (status) => status === 204
            })
        }),
        postLoginWithPAT: build.mutation<ILoginWithPATOutputs, ILoginWithPATInputs>({
            query: (args) => ({
                url: `/authentication/personal_access_token_login`,
                method: "POST",
                body: args
            })
        }),
    }),
    overrideExisting: false
});

const {
    endpoints: {
        getPATById: {
            useMutation: useMutationGetPATById
        },
        getPATList: {
            useMutation: useMutationGetPATList
        },
        postCreatePAT: {
            useMutation: useMutationPostCreatePAT
        },
        deletePATById: {
            useMutation: useMutationDeletePATById
        },
        postLoginWithPAT: {
            useMutation: useMutationPostLoginWithPAT
        }
    }
} = PATExtendedApi;

export { PATExtendedApi };
export {
    useMutationGetPATById,
    useMutationGetPATList,
    useMutationPostCreatePAT,
    useMutationDeletePATById,
    useMutationPostLoginWithPAT
}
