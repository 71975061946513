import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { SETTINGS_ROUTE } from '../../../infrastructure/globals/routes';
import { selectCurrentUser } from '../../../infrastructure/services/users/slice';
import { INavLink } from '../../components/nav';

import AccountNav from './nav/account';

import Render from './render';

interface SettingsViewProps {
    option?: string;
};

const NAV_OPTIONS: Record<string, INavLink> = {
    'account': {
        label: 'Account',
        link: '/settings/account',
    }
};

const COMPONENTS: Record<string, (a: any) => JSX.Element> = {
    'account': AccountNav
} 

const SettingsView = (props: SettingsViewProps) => {
    const { option } = props;

    const history = useHistory();
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (!option) {
            history.push(`${SETTINGS_ROUTE}/${NAV_OPTIONS.account.link}`);
            return;
        }

        if (!NAV_OPTIONS[option]) {
            history.push(`${SETTINGS_ROUTE}/${NAV_OPTIONS.account.link}`);
        }
    }, [option, history]);

    const OptionComponent = useMemo(() => {
        if (!option) {
            return COMPONENTS.account;
        }

        if (!COMPONENTS[option]) {
            return COMPONENTS.account;
        }

        return COMPONENTS[option];
    }, [option]);

    if (!user) {
        return <Redirect to="/" />;
    }

    return (
        <Render
            options={NAV_OPTIONS}
            currentOption={history.location.pathname}
        >
            <OptionComponent
                user={user}
            />
        </Render>
    );
};

export default SettingsView;
