import React, { KeyboardEvent } from 'react';

import { TextField } from '@material-ui/core';
import { IFormInputs } from '.';
import { FieldError, Control, DeepMap, Controller } from 'react-hook-form';
import DJCFooter from '../../components/footer';
import { DojoCodesBrandLogo } from '../../../assets/assets';

interface IRenderProps {
    handleSubmit: (_: (data: IFormInputs) => void) => any;
    isResetPasswordRequestSent: boolean;
    pending: boolean;
    onSubmit: (data: IFormInputs) => void;
    control: Control;
    rules: Record<string, Record<string, any>>;
    errors: DeepMap<IFormInputs, FieldError>;
    handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
};

const Render = (props: IRenderProps) => {
    const {
        handleSubmit,
        onSubmit,
        handleKeyDown,
        control,
        pending,
        rules,
        errors,
        isResetPasswordRequestSent
    } = props;

    if (isResetPasswordRequestSent) {
        return (
            <>
                <div className="djc--view-reset-password-content">
                    <section className="djc--view-reset-password-topbar">
                        <div className="djc--header-logo">
                            <img src={DojoCodesBrandLogo} alt="brand logo" />
                            <h1>
                                <a href="/">
                                    <span className="dark">Dojo</span><span className="primary">.Codes</span>
                                </a>
                            </h1>
                        </div>
                    </section>
                    <section className="djc--view-reset-password-form">
                        <h1>Réinitialiser le mot de passe</h1>
                        <span style={{ textAlign: "center" }}>Un message a été envoyé sur votre email avec les instructions.</span>
                    </section>
                </div>

                
                <DJCFooter />
            </>
        )
    }

    return (
        <>
            <div className="djc--view-reset-password-content">
                <section className="djc--view-reset-password-topbar">
                    <div className="djc--header-logo">
                        <img src={DojoCodesBrandLogo} alt="brand logo" />
                        <h1>
                            <a href="/">
                                <span className="dark">Dojo</span><span className="primary">.Codes</span>
                            </a>
                        </h1>
                    </div>
                </section>
                <section className="djc--view-reset-password-form">
                    <h1>Réinitialiser le mot de passe</h1>
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            as={TextField}
                            name="email"
                            control={control}
                            id="field--email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            type="email"
                            className="input"
                            onKeyDown={handleKeyDown}
                            rules={{ ...rules.email }}
                            error={!!errors.email && !pending}
                            helperText={
                                !pending && errors.email ?
                                    errors.email.message ?
                                        errors.email.message
                                            : "Mauvais email entré."
                                        : ""
                                }
                        />
                    </form>

                    <div className="djc--view-reset-password-form-actions">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            disabled={pending}
                        >
                            Réinitialiser
                        </button>
                    </div>
                </section>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
