import React, { KeyboardEvent } from 'react';

import { TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { IFormInputs } from '.';
import { FieldError, Control, DeepMap, Controller } from 'react-hook-form';
import DJCFooter from '../../components/footer';
import { DojoCodesBrandLogo } from '../../../assets/assets';
import { CGU_ROUTE, RESET_PASSWORD_ROUTE } from '../../../infrastructure/globals/routes';

interface IRenderProps {
    handleSubmit: (_: (data: IFormInputs) => void) => any;
    handleSignUp: () => void;
    onSubmit: (data: IFormInputs) => void;
    control: Control;
    rules: Record<string, Record<string, any>>;
    errors: DeepMap<IFormInputs, FieldError>;
    isLoading: boolean;
    isLoginError: boolean;
    handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
    customErrors: Record<string, string>;
};

const Render = (props: IRenderProps) => {
    const {
        handleSubmit,
        handleSignUp,
        onSubmit,
        control,
        rules,
        errors,
        isLoading,
        isLoginError,
        handleKeyDown,
        customErrors
    } = props;

    return (
        <>
            <div className="djc--view-login-content">
                <section className="djc--view-login-topbar">
                    <div className="djc--header-logo">
                        <img src={DojoCodesBrandLogo} alt="brand logo" />
                        <h1>
                            <a href="/">
                                <span className="dark">Dojo</span><span className="primary">.Codes</span>
                            </a>
                        </h1>
                    </div>
                </section>
                <section className="djc--view-login-form">
                    <h1>Connexion</h1>

                    {
                        isLoginError && (
                            <span className="invalid-credentials">Invalid credentials please verify them and retry</span>
                        )
                    }
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            as={TextField}
                            name="username"
                            control={control}
                            id="field--username"
                            label="Nom d'utilisateur"
                            variant="outlined"
                            fullWidth
                            className="input"
                            onKeyDown={handleKeyDown}
                            rules={{ ...rules.username }}
                            error={!!errors.username || isLoginError || !!customErrors["username"]}
                            helperText={!!customErrors["username"] ? customErrors["username"] : errors.username}
                        />
                        <Controller
                            as={TextField}
                            name="password"
                            control={control}
                            id="field--password"
                            label="Mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            className="input"
                            onKeyDown={handleKeyDown}
                            rules={{ ...rules.password }}
                            error={!!errors.password || isLoginError}
                            helperText={
                                errors.password ?
                                    errors.password.message ?
                                        errors.password.message
                                            : "Invalid password, 1 uppercase, 1 number and 1 special char minimum."
                                        : ""
                                }
                        />
                    </form>

                    <div className="djc--view-login-form-actions">
                        <Link to={RESET_PASSWORD_ROUTE}>Mot de passe oublié ?</Link>
                        <button
                            disabled={isLoading}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Connexion
                        </button>
                    </div>

                    <div className="divider" />

                    <section className="djc--view-login-form-signup">
                        <h3>Vous n'avez pas de compte ?</h3>
                        <button
                            onClick={handleSignUp}
                        >
                            S'enregistrer
                        </button>
                    </section>

                    <div className="divider" />

                    <section className="djc--view-login-form-legals">
                        <p>
                            En cliquant sur “S'inscrire”, vous acceptez les <Link to={CGU_ROUTE}>Conditions Générales d'Utilisation</Link> ainsi que 
                            la <Link to={CGU_ROUTE}>Politique de Confidentialité</Link> de Dojo.Codes.
                        </p>
                    </section>
                </section>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
