import { ICampaignSummary } from "../services/campaign/types";

export const updateDocumentTileForCampaign = (campaign: ICampaignSummary) => {
    document.title = `DojoCodes | Campaign ${campaign.name}`
};

export const updateDocumentTitleForParticipatingTo = (campaign: ICampaignSummary) => {
    document.title = `DojoCodes | Participating on ${campaign.name}`
};

export const updateDocumentTitleToDefault = () => {
    document.title = "DojoCodes | Dojo.Codes"
};

export const updateDocumentTitleForAdmin = () => {
    document.title = "DojoCodes | Admin"
};
