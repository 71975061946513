import React, { KeyboardEvent } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Render from './render';
import { toggleSignInDialog } from '../../../infrastructure/stores/global/actions';
import { SIGN_UP_ROUTE } from '../../../infrastructure/globals/routes';
import { useForm } from 'react-hook-form';
import { useGetSelf } from '../../../infrastructure/services/users';
import { usePostAutoToken } from '../../../infrastructure/services/authentication';
import { setAccessToken, setScopes } from '../../../infrastructure/services/authentication/slice';
import { cleanAuthOnLocalStorage, saveCredentialsOnLocalStorage, saveScopesOnLocalStorage } from '../../../infrastructure/services/authentication/utils';
import { setCurrentUser } from '../../../infrastructure/services/users/slice';

interface LoginModalProps {
    open: boolean;
};

export interface IFormInputs {
    username: string | null;
    password: string | null;
};

const rules = {
    username: {
        minLength: 4,
        required: true
    },
    password: {
        minLegth: 3,
        required: true,
    }
};

const LoginModal = (props: LoginModalProps) => {
    const { open } = props;

    const {
        control,
        errors,
        handleSubmit,
    } = useForm<IFormInputs>({
        mode: "onTouched",
        defaultValues: {
            username: "",
            password: ""
        }
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const [customErrors, setCustomErrors] = React.useState<Record<string, string>>({});

    const [
        login, {
            isLoading: isLogging,
            isError: isLoginError
        }
    ] = usePostAutoToken();
    const [
        getSelf, {
            isLoading: isLoadingSelf,
            isError: isSelfError
        }
    ] = useGetSelf();

    const handleClose = () => {
        dispatch(toggleSignInDialog());
    };

    const redirect = (to: string) => {
        return () => {
            handleClose();
            history.push(to);
        };
    }

    const handleSignUp = () => {
        handleClose();
        redirect(SIGN_UP_ROUTE)();
    };

    const onSubmit = async (data: IFormInputs) => {
        if (!data.username || !data.password) {
            return;
        }

        const usernamePattern = /^([A-Za-z0-9_-]+)$/g;
        if (data.username.match(usernamePattern) === null) {
            setCustomErrors({
                ...customErrors,
                "username": "Le nom d'utilisateur contient des caractères interdits."
            });

            return;
        }

        try {
            const result = await login({
                username: data.username,
                password: data.password
            });

            if (result.data) {
                dispatch(setAccessToken({
                    access_token: result.data.access_token
                }));
                dispatch(setScopes({
                    scopes: result.data.scopes
                }));

                saveCredentialsOnLocalStorage(result.data, data.username);
                saveScopesOnLocalStorage(result.data.scopes);
            }

            const selfResult = await getSelf();
            if (selfResult.data) {
                dispatch(setCurrentUser(selfResult.data));
            }

            if (result.data && selfResult.data) {
                handleClose();
            }
        } catch (e) {
            cleanAuthOnLocalStorage();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        }
    };

    return (
        <Render
            open={open}
            handleClose={handleClose}
            handleSignUp={handleSignUp}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            rules={rules}
            errors={errors}
            isLoading={isLoadingSelf || isLogging}
            isLoginError={isLoginError || Object.keys(customErrors).length > 0}
            handleKeyDown={handleKeyDown}
            customErrors={customErrors}
        />
    );
};

export default LoginModal;
