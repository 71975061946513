import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../stores";

interface IInitialState {
    accessToken: string | null;
    isConnecting: boolean;
    scopes: string[];
};

const initialState: IInitialState = {
    accessToken: null,
    isConnecting: false,
    scopes: []
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAccessToken: (
            state,
            { payload }: PayloadAction<{ access_token: string }>
        ) => {
            state.accessToken = payload.access_token;
        },
        clearUserToken: (
            state,
            { payload }: PayloadAction<void>
        ) => {
            state.accessToken = null;
        },
        setIsConnecting: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isConnecting = payload;
        },
        setScopes: (
            state,
            { payload }: PayloadAction<{ scopes: string[] }>
        ) => {
            state.scopes = [...payload.scopes];
        }
    },
    extraReducers: (builder) => {}
});

export default authSlice.reducer;
export const {
    setAccessToken,
    clearUserToken,
    setIsConnecting,
    setScopes
} = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken || null;
export const selectIsConnecting = (state: RootState) => state.auth.isConnecting;
export const selectScopes = (state: RootState) => state.auth.scopes;
export const selectIsScopeIncluded = (state: RootState) => (scope: string) => {
    return state.auth.scopes.includes(scope);
}
export const selectIsOneScopeIncluded = (state: RootState) => (...scopes: string[]) => {
    for (let scope of scopes) {
        if (state.auth.scopes.includes(scope)) {
            return true;
        }
    }

    return false;
}
