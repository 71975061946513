import { api } from "..";
import {
    IGetRuleListResults,
    IGetScoreboardListResults,
    IGetScoreboardSingleResultsInputs,
    IPostRuleCreateResults,
    IRule,
    IScoreboard,
    IScoreboardResults,
    IScoreboardSingleResults
} from "./types";

const scoringExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRuleList: build.mutation<IGetRuleListResults, void>({
            query: () => ({
                url: '/scoring/rule',
                method: "GET"
            })
        }),
        postRuleCreate: build.mutation<IPostRuleCreateResults, IRule>({
            query: (args) => ({
                url: '/scoring/rule',
                method: "POST",
                body: args
            })
        }),
        getRuleById: build.mutation<IRule, string>({
            query: (id) => ({
                url: `/scoring/rule/${id}`,
                method: "GET"
            })
        }),
        deleteRuleById: build.mutation<void, string>({
            query: (id) => ({
                url: `/scoring/rule/${id}`,
                method: "DELETE",
                validateStatus: (status: number) => status === 204
            })
        }),
        getScoreboardList: build.mutation<IGetScoreboardListResults, void>({
            query: () => ({
                url: '/scoring/scoreboard',
                method: "GET"
            })
        }),
        postScoreboardCreate: build.mutation<IScoreboard, IScoreboard>({
            query: (args) => ({
                url: '/scoring/scoreboard',
                method: "POST",
                body: args,
            })
        }),
        getScoreboardDetailsById: build.mutation<IScoreboard, string>({
            query: (id: string) => ({
                url: `/scoring/scoreboard/${id}`,
                method: "GET"
            })
        }),
        deleteScoreboardById: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/scoring/scoreboard/${id}`,
                method: "DELETE",
                validateStatus: (status) => status === 204
            })
        }),
        getScoreboardResultsById: build.mutation<IScoreboardResults, string>({
            query: (id: string) => ({
                url: `/scoring/scoreboard/${id}/results`,
                method: "GET"
            })
        }),
        getScoreboardSingleResults: build.mutation<IScoreboardSingleResults, IGetScoreboardSingleResultsInputs>({
            query: (args) => ({
                url: `/scoring/scoreboard/${args.id}/results/${args.username}`,
                method: "GET"
            })
        })
    }),
    overrideExisting: false
});

const {
    endpoints: {
        getRuleList: {
            useMutation: useMutationGetRuleList
        },
        deleteRuleById: {
            useMutation: useMutationDeleteRuleById
        },
        deleteScoreboardById: {
            useMutation: useMutationDeleteScoreboardById
        },
        getRuleById: {
            useMutation: useMutationGetRuleById
        },
        getScoreboardDetailsById: {
            useMutation: useMutationGetScoreboardDetailsById
        },
        getScoreboardList: {
            useMutation: useMutationGetScoreboardList
        },
        getScoreboardResultsById: {
            useMutation: useMutationGetScoreboardResultsById
        },
        getScoreboardSingleResults: {
            useMutation: useMutationGetScoreboardSingleResults
        },
        postRuleCreate: {
            useMutation: useMutationPostRuleCreate
        },
        postScoreboardCreate: {
            useMutation: useMutationPostScoreboardCreate
        }
    }
} = scoringExtendedApi;

export { scoringExtendedApi };
export {
    useMutationDeleteRuleById,
    useMutationPostScoreboardCreate,
    useMutationPostRuleCreate,
    useMutationGetScoreboardDetailsById,
    useMutationGetScoreboardList,
    useMutationGetScoreboardSingleResults,
    useMutationGetRuleById,
    useMutationDeleteScoreboardById,
    useMutationGetRuleList,
    useMutationGetScoreboardResultsById
}
