import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import Toast from '../../../../../components/toast';
import { useMutationGetEnvironmentById, useMutationPatchUpdateEnvironment } from '../../../../../../infrastructure/services/environments';
import { IWorkerFile } from '../../../../../../infrastructure/services/environments/types';

type AllowedInjectionMethods = "File" | "Stdin" | "Parameters" | "String";

type Inputs = {
    id: string;
    name: string;
    description: string;
    image: string;
    interpret_as: string;
    placeholder: string;
    command: string;
    visible: boolean;
    files: Array<IWorkerFile>;
}

interface IProps {
    slug: string;
}

const AdminEnvironmentEditView = (props: IProps) => {
    const { slug } = props;

    const [fetchEnv] = useMutationGetEnvironmentById();
    const [fetchUpdate] = useMutationPatchUpdateEnvironment();

    const history = useHistory();

    const generateError = (errors: IError<Inputs>, field: string, condition: boolean, errorMessage: string) => {
        if (!condition) {
            return errors;
        }

        return {
            ...errors,
            [field]: { error: true, message: errorMessage }
        };
    }

    const handleValidate = (v: Inputs) => {
        let errors: IError<Inputs> = {};

        errors = generateError(errors, "id", v.id.length === 0, "Le champ \"ID\" est obligatoire.");
        errors = generateError(errors, "name", v.name.length === 0, "Le champ \"Nom\" est obligatoire.");
        errors = generateError(errors, "image", v.interpret_as.length === 0, "Le champ \"Image\" est obligatoire.");
        errors = generateError(errors, "interpret_as", v.interpret_as.length === 0, "Le champ \"Intérpréter comme\" est obligatoire.");

        return errors;
    }

    const {
        values,
        handleBlur,
        handleChange,
        updateValues,
        errors,
        containsError,
        fulfilled
    } = useForm<Inputs>({
        defaultValues: {
            id: "",
            name: "",
            description: "",
            image: "",
            interpret_as: "",
            placeholder: "",
            command: "",
            visible: true,
            files: []
        },
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/environments");
    };

    const raise = (message: string) => {
        toast.notify(
            <Toast
                text={message}
            />,
            {
                duration: 10000,
                position: "top",
                type: "error"
            }
        );

        handleCancel();
    }

    const init = async () => {
        const resp = await fetchEnv(slug);

        if (resp.data) {
            updateValues({
                id: resp.data.id,
                name: resp.data.name,
                interpret_as: resp.data.interpret_as,
                description: resp.data.description || "",
                files: []
            })
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            raise(message);
        }
    };

    useEffect(() => {
        init();
    }, [])

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchUpdate({
            id: slug,
            body: {
                id: values.id,
                name: values.name,
                description: values.description,
                image: values.image,
                interpret_as: values.interpret_as,
                placeholder: values.placeholder,
                command: values.command,
                visible: values.visible,
                files: values.files
            }
        });

        if (resp.data) {
            toast.notify(
                <Toast text={"Environnement créé avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/environments');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            raise(message);
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-cogs" />
                    <h1>Créer Environnement</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-12 col-lg-4 mb-16">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="Id"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={values.id}
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 mb-16">
                                <TextField
                                    id="input-name"
                                    name="name"
                                    label="Nom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={values.name}
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 mb-16">
                                <TextField
                                    id="input-interpret-as"
                                    name="interpret_as"
                                    label="Intérpréter comme"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={values.interpret_as}
                                    error={errors.interpret_as !== undefined}
                                    helperText={errors.interpret_as?.message}
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 mb-16">
                                <TextField
                                    id="input-description"
                                    name="description"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={values.description}
                                    error={errors.description !== undefined}
                                    helperText={errors.description?.message}
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 mb-16">
                                <TextField
                                    id="input-code"
                                    name="code"
                                    label="Code par défaut"
                                    multiline
                                    rowsMax={20}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.files}
                                    fullWidth
                                    variant="outlined"
                                    error={errors.files !== undefined}
                                    helperText={errors.files?.message}
                                />
                            </div>
                        </div>


                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-6 col-lg-6 mb-16">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.visible}
                                            onChange={handleChange}
                                            name="visible"
                                            color="primary"
                                        />
                                    }
                                    label="Visible ?"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminEnvironmentEditView;
