import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SIGN_UP_ROUTE } from '../../../infrastructure/globals/routes';
import Render from './render';
import { HeroButtonAction } from './types';
import moment from 'moment-timezone';

const HomepageView = () => {

    const history = useHistory();

    const [targetDate] = useState(Date.parse("11 Jan 2023 18:30:00 GMT+2"));

    const checkIfCountdownIsOver = useCallback(() => {
        const end = moment.tz(targetDate, "Europe/Paris");
        const now = moment(new Date());
        const duration = moment.duration(end.diff(now)).asSeconds();

        return duration <= 1;
    }, [targetDate]);

    const [isCountdownFinished, setIsCountdownFinished] = useState(checkIfCountdownIsOver());

    const redirectTo = (url: string) => {
        history.push(url);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const isOver = checkIfCountdownIsOver();
            if (isCountdownFinished !== isOver) {
                setIsCountdownFinished(isOver);
            }
        }, 1000);

        if (isCountdownFinished === true) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        }
    }, [checkIfCountdownIsOver, isCountdownFinished]);

    const handleOnHeroButtonClicked = (action: HeroButtonAction) => {
        return () => {
            switch (action) {
                case "more-info":
                    redirectTo("/articles/orness-battle-dev-2022");
                    break;
                case "register":
                    redirectTo(SIGN_UP_ROUTE);
                    break;
                default:
                    break;
            }
        }
    }


    return (
        <Render
            handleOnHeroButtonClicked={handleOnHeroButtonClicked}
            isCountdownFinished={isCountdownFinished}
            targetDate={targetDate}
        />
    );
};

export default HomepageView;