import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import DJCFooter from '../../../components/footer';
import WYSIWYGEditor from '../../../components/wysiwyg_editor';
import { IFormInputs } from './types';

interface IRenderProps {
    handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    handleSubmit: (_: (data: IFormInputs) => void) => any;
    onSubmit: (data: IFormInputs) => void;
    control: Control;
    errors: DeepMap<IFormInputs, FieldError>;
    savedValues: IFormInputs;
    isCreatingPage: boolean;
    globalError: string | null;
}

const Render = React.forwardRef<WYSIWYGEditor, IRenderProps>((props, ref) => {
    const {
        handleBlur,
        handleSubmit,
        onSubmit,
        control,
        errors,
        savedValues,
        isCreatingPage,
        globalError
    } = props;

    return (
        <>
            <div className="djc--view-articles-create">
                <div className="container">
                    <h2>{savedValues.title.length > 0 ? savedValues.title : "My New Page..."}</h2>
                    <div className="container-fluid bg-blank pd-16">
                        <div className="container-fluid section">
                            <h4>General</h4>
                            <div className="row">
                                <div className="col-md-6 input">
                                    <Controller
                                        as={TextField}
                                        control={control}
                                        error={!!errors.title}
                                        id="input-title"
                                        name="title"
                                        label="Title"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        inputProps={{
                                            onBlur: handleBlur
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 input">
                                    <Controller
                                        inputProps={{
                                            onBlur: handleBlur
                                        }}
                                        as={TextField}
                                        control={control}
                                        error={!!errors.route_name}
                                        id="input-uri"
                                        name="route_name"
                                        label="URI"
                                        fullWidth
                                        variant="outlined"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 input">
                                    <Controller
                                        as={Select}
                                        control={control}
                                        id="tags"
                                        name="tags"
                                        label="Page Kind"
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            onBlur: handleBlur
                                        }}
                                        value={savedValues.tags}
                                    >
                                        <MenuItem value="orness_codefest_2022">Orness Codefest 2022</MenuItem>
                                    </Controller>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid section">
                            <h4>Page Content</h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="editor">
                                        <WYSIWYGEditor
                                            ref={ref}
                                            tools={[
                                                "header",
                                                "list",
                                                "image"
                                            ]}
                                            editorJSConfig={{
                                                placeholder: "My Content...",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid section">
                            <div className="row">
                                <div className="col-md-6">
                                    <span className="error">
                                        {
                                            globalError
                                                ? globalError
                                                : Object.keys(errors).length > 0
                                                    ? "Please verify fields."
                                                    : null
                                        }
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="fl-r"
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <DJCFooter />
        </>
    );
});

export default Render;
