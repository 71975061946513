import React from 'react';
import { Drawer, IconButton } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { IHeaderLink } from '.';
import { SIGN_UP_ROUTE } from "../../../infrastructure/globals/routes";
import { DojoCodesBrandLogo } from '../../../assets/assets';
import Gravatar from '../gravatar';

interface RenderProps {
    isLinkActive: (value: string) => string;
    redirect: (to: string) => () => void;
    handleSignIn: () => void;
    isUserConnected: boolean;
    handleOpenDrawer: () => void;
    headerLinks: Record<string, IHeaderLink>
    hash: string;
    handleToggleLeftDrawer: () => void;
    isLeftDrawerOpen: boolean;
}

const Render = (props: RenderProps) => {
    const {
        isLinkActive,
        redirect,
        handleSignIn,
        isUserConnected,
        handleOpenDrawer,
        handleToggleLeftDrawer,
        headerLinks,
        hash,
        isLeftDrawerOpen
    } = props;

    return (
        <>
            <header className="djc--header">
                <div className="djc--header-logo">
                    <div className="d-lg-none mobile-nav-btn">
                        <button
                            className="custom simple-btn-icon ml-8"
                            onClick={handleToggleLeftDrawer}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    <img src={DojoCodesBrandLogo} alt="brand logo" />
                    <h1>
                        <a href="/">
                            <span className="dark">Dojo</span><span className="primary">.Codes</span>
                        </a>
                    </h1>
                </div>
                <div className="djc--header-content d-none d-sm-none d-md-none d-lg-flex">
                    <nav className="djc--header-navbar d-none d-lg-block">
                        <ol>
                            {
                                Object.values(headerLinks).map((link, i) => (
                                    !isUserConnected && link.loggedInRequired ? (
                                        null
                                    ) : (
                                        <li key={`${i}-${link.label}`} className={link.disabled ? "disabled" : ""}>
                                            <Link
                                                className={isLinkActive(link.linkTo)}
                                                key={link.linkTo}
                                                to={link.linkTo}
                                            >
                                                {link.label}
                                                {
                                                    link.children !== undefined && (
                                                        <i className="fa fa-caret-down" />
                                                    )
                                                }
                                            </Link>

                                            {
                                                link.children !== undefined && (
                                                    <ul className="dropdown">
                                                        {
                                                            link.children.map((child) => (
                                                                <li key={`${link.label}-${child.label}`}>
                                                                    <Link
                                                                        className={isLinkActive(child.linkTo)}
                                                                        key={child.linkTo}
                                                                        to={child.linkTo}
                                                                    >
                                                                        {child.label}
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                )
                                            }
                                        </li>
                                    )
                                ))
                            }
                        </ol>
                    </nav>
                    <div className="djc--header-buttons">
                        {
                            !isUserConnected ? (
                                <div className="d-none d-lg-block">
                                    <div className="sign-buttons">
                                        <button
                                            className="outlined"
                                            onClick={redirect(SIGN_UP_ROUTE)}
                                        >
                                            S'enregistrer
                                        </button>
                                        <button
                                            onClick={handleSignIn}
                                        >
                                            Se Connecter
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <IconButton
                                        aria-label="open-right-drawer"
                                        className="btn-avatar"
                                        onClick={handleOpenDrawer}
                                    >
                                        <Gravatar
                                            alt="gravatar avatar"
                                            hash={hash}
                                        />
                                    </IconButton>
                                </>
                            )
                        }
                    </div>
                </div>
                {
                    isUserConnected && (
                        <IconButton
                            aria-label="open-right-drawer"
                            className="d-lg-none btn-avatar mobile-nav-btn"
                            onClick={handleOpenDrawer}
                        >
                            <Gravatar
                                alt="gravatar avatar"
                                hash={hash}
                            />
                        </IconButton>
                    )
                }

                <Drawer
                    anchor="left"
                    open={isLeftDrawerOpen}
                    onClose={handleToggleLeftDrawer}
                >
                    <div className="left-drawer">
                        <nav className="left-drawer-nav">
                            <ol>
                                {
                                    Object.values(headerLinks).map((link, i) => (
                                        !isUserConnected && link.loggedInRequired ? (
                                            null
                                        ) : (
                                            <React.Fragment key={`${i}-${link.label}`}>
                                                <Link
                                                    className={link.disabled ? "disabled" : ""}
                                                    key={link.linkTo}
                                                    to={link.linkTo}
                                                    onClick={handleToggleLeftDrawer}
                                                >
                                                    <li className={isLinkActive(link.linkTo)}>
                                                        {
                                                            link.icon && (
                                                                <i className={`${link.icon} mr-8`} />
                                                            )
                                                        }
                                                        {link.label}
                                                    </li>
                                                </Link>

                                                {
                                                    link.children !== undefined && (
                                                        <ul className="dropdown">
                                                            {
                                                                link.children.map((child, j) => (
                                                                    <li key={`${j}-${link.label}-${child.label}`}>
                                                                        <Link
                                                                            className={isLinkActive(child.linkTo)}
                                                                            key={child.linkTo}
                                                                            to={child.linkTo}
                                                                            onClick={handleToggleLeftDrawer}
                                                                        >
                                                                            {child.label}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    ))
                                }
                            </ol>
                        </nav>
                        {
                            !isUserConnected && (
                                <>
                                    <button
                                        className="outlined fullwidth mb-8"
                                        onClick={redirect(SIGN_UP_ROUTE)}
                                    >
                                        S'enregistrer
                                    </button>
                                    <button
                                        className="fullwidth"
                                        onClick={handleSignIn}
                                    >
                                        Se Connecter
                                    </button>
                                </>
                            )
                        }
                    </div>
                </Drawer>
            </header>
        </>
    );
};

export default Render;