import { ISignUpStep } from "../types";

const accountInformationsStep: ISignUpStep = ({
    label: "Compte",
    fields: [
        {
            as: "string",
            id: "field--username",
            name: "username",
            label: "Nom d'utilisateur"
        },
        {
            as: "email",
            id: "field--email",
            name: "email",
            label: "E-mail"
        },
        {
            as: "password",
            id: "field--password",
            name: "password",
            label: "Mot de passe"
        },
        {
            as: "password",
            id: "field--password-confirm",
            name: "passwordConfirm",
            label: "Mot de passe (vérification)"
        }
    ],
    defaultValues: {
        username: "",
        email: "",
        password: "",
        passwordConfirm: ""
    },
    verify: (fields: Record<string, any>) => {
        const errors: Record<string, any> = {};

        const usernamePattern = /^([A-Za-z0-9_-]+)$/g;
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const passwordReg = /^(?=.*[A-Z]){1,}(?=.*\d)(?=.*[^\W])[A-Za-z\d_\W]{3,}$/g;

        if (fields['username'].length < 2) {
            errors['username'] = "2 caractères minimum.";
        }

        if (fields['username'].match(usernamePattern) === null) {
            errors['username'] = "Le nom d'utilisateur contient des caractères interdits. Merci d'utiliser uniquement chiffres, lettres, et \"-\", \"_\"";
        }

        if (emailPattern.test(fields['email']) === false) {
            errors['email'] = "Mauvais email.";
        }

        if (passwordReg.test(fields['password']) === false) {
            errors['password'] = "Mot de passe invalide. 1 majuscule, 1 chiffre and 1 caractère spécial minimum.";
        }

        if (fields['password'] !== fields['passwordConfirm']) {
            errors['passwordConfirm'] = 'Le mot de passe ne correspond pas.';
        }

        return errors;
    }
});

export default accountInformationsStep;
