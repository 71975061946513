import React from 'react';
import { 
    Drawer, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    ListSubheader
} from '@material-ui/core';

import { Action, ISection } from './const';


interface IRenderProps {
    open: boolean;
    handleClose: () => void;
    handleLogout: () => void;
    handleAction: (action: Action) => () => void;
    isUserAdmin: boolean;
    items: ISection[];
}

const Render = (props: IRenderProps) => {
    const {
        open,
        handleClose,
        handleLogout,
        handleAction,
        isUserAdmin,
        items
    } = props;

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <div
                className="djc--logged-drawer"
                role="presentation"
            >
                <List className="content">
                    {
                        items.map((section) => {
                            if (section.adminRequired && !isUserAdmin) {
                                return null;
                            }

                            return (
                                <React.Fragment key={section.label}>
                                    <ListSubheader>{section.label}</ListSubheader>
                                    {
                                        section.items.map((item) => {
                                            const Icon = item.icon;

                                            return (
                                                <ListItem
                                                    key={item.label}
                                                    button
                                                    onClick={handleAction(item.action)}
                                                    disabled={item.disabled}
                                                >
                                                    <ListItemIcon>
                                                        <Icon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.label} />
                                                </ListItem>
                                            );
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </List>

                <button
                    onClick={handleLogout}
                    className="fullwidth"
                >
                    Déconnexion
                </button>
            </div>
        </Drawer>
    );
};

export default Render;