import React, { KeyboardEvent } from 'react';

import Render from './render';
import toast from 'toasted-notes';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutationPostResetPassword } from '../../../infrastructure/services/authentication';
import Toast from '../../components/toast';

export interface IFormInputs {
    new_password: string;
    new_password_verify: string;
};

const rules = {
    new_password: {
        required: true
    },
    new_password_verify: {
        required: true
    }
};

interface IResetPasswordViewProps {
    token: string;
};

const ResetPasswordView = (props: IResetPasswordViewProps) => {
    const { token } = props;

    const {
        control,
        errors,
        setError,
        handleSubmit
    } = useForm<IFormInputs>({
        mode: "all",
        defaultValues: {
            new_password: "",
            new_password_verify: ""
        }
    });

    const [resetPassword] = useMutationPostResetPassword();

    const history = useHistory();

    const onSubmit = async (data: IFormInputs) => {
        if (!data.new_password || !data.new_password_verify) {
            return;
        }

        const passwordReg = /^(?=.*[A-Z]){1,}(?=.*\d)(?=.*[^\W])[A-Za-z\d_\W]{3,}$/g;
        if (!passwordReg.test(data.new_password)) {
            setError("new_password", {});
            return;
        }

        if (data.new_password !== data.new_password_verify) {
            setError("new_password", {
                message: "Les deux mots de passes ne sont pas identiques",
                shouldFocus: true
            });
            setError("new_password_verify", {
                message: "Les deux mots de passes ne sont pas identiques",
                shouldFocus: true
            });

            return;
        }

        const response = await resetPassword({
            password: data.new_password,
            token: token
        });

        if (response.data !== undefined) {
            if (response.data) {
                toast.notify(
                    <Toast
                        text="Mot de passe correctement modifié !"
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
                redirect("/login")();
            } else {
                toast.notify(
                    <Toast
                        text="Erreur durant la réinitalisation. Vérifiez votre token ou réessayer plus tard."
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
            }
        } else if (response.error !== undefined) {
            const error = (response.error as any);

            toast.notify(
                <Toast
                    text={error.data.detail.message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    const redirect = (to: string) => {
        return () => {
            history.push(to);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        }
    };

    return (
        <Render
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            rules={rules}
            errors={errors}
            handleKeyDown={handleKeyDown}
        />
    );
};

export default ResetPasswordView;
