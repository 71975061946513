import React, { useMemo } from 'react';
import { ICampaignSummary, ICampaignParticipantStatusResults } from '../../../../infrastructure/services/campaign/types';
import DJCFooter from '../../../components/footer';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';
import Markdown from '../../../components/markdown';

import Scoreboard from '../../../components/scoreboard';
import { IUser } from '../../../../infrastructure/services/users/types';
import { IScoreboardResults, IScoreboardSingleResults } from '../../../../infrastructure/services/scoring/types';

interface IRenderProps {
    campaign: ICampaignSummary;
    completionMessage: string | null;
    userResults: IScoreboardSingleResults | null;
    scoreboard: IScoreboardResults | null;
    campaignParticipation: ICampaignParticipantStatusResults;
    currentUser: IUser;
};

const calculatePercent = (first: number, second: number) => (
    Math.floor(((second - (first - 1)) / second) * 100)
);

const Render = (props: IRenderProps) => {
    const {
        campaign,
        completionMessage,
        userResults,
        scoreboard,
        campaignParticipation,
        currentUser
    } = props;

    const totalTime = useMemo<string[]>(() => {
        let seconds = 0;
        for (let entry of campaignParticipation.entries) {
            const start = new Date(entry.start_time);
            const end = new Date(entry.end_time);

            seconds += (end.getTime() - start.getTime()) / 1000;
        }

        const format = (val: number) => `0${Math.floor(val)}`.slice(-2)
        const minutes = (seconds % 3600) / 60

        return [minutes, seconds % 60].map(format)
    }, [campaignParticipation]);

    return (
        <>
            <div className="djc--view-campaigns-done">
                <div className="content">
                    <h2 className="campaign-top-title">Résultats - <a href={`${CAMPAIGNS_ROUTE}/${campaign.route_name}`}>{campaign.name}</a></h2>
                    <div className="mb-16" />
                    <div className="container-fluid bg-blank pd-16">
                        {
                            userResults && (
                                <div className="container-fluid section">
                                    <h4>Résultats</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="better-than-section">
                                                <div className="left">
                                                    <span className="capitalize">
                                                        Meilleur que
                                                    </span>
                                                    <span className="better-percent">
                                                        {calculatePercent(userResults.rank, userResults.player_amount)}%
                                                    </span>
                                                    <span className="capitalize">
                                                        des participants
                                                    </span>
                                                </div>
                                                <div className="right">
                                                    <EqualizerIcon className="dark" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 stats-section">
                                            <div className="stats">
                                                <EqualizerIcon className="dark" />
                                                <span className="stat-name">Classement</span>
                                                <span className="stat-value">
                                                    <span className="primary bold">{userResults.rank} </span>
                                                    / {userResults.player_amount} participants
                                                </span>
                                            </div>
                                            <div className="stats">
                                                <ScheduleIcon className="dark" />
                                                <span className="stat-name">Durée totale</span>
                                                <span className="stat-value">
                                                    <span className="primary bold">{totalTime[0]} </span>
                                                    minutes
                                                    <span className="primary bold"> {totalTime[1]} </span>
                                                    secondes
                                                </span>
                                            </div>
                                            <div className="stats">
                                                <WhatshotIcon className="dark" />
                                                <span className="stat-name">Points</span>
                                                <span className="stat-value">
                                                    <span className="primary bold">{userResults.points} </span>
                                                    points
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            completionMessage && (
                                <div className="container-fluid section">
                                    <h4></h4>
                                    <Markdown children={completionMessage} />
                                </div>
                            )
                        }
                        {
                            scoreboard && (
                                <div className="container-fluid section">
                                    <h4>Tableau des scores</h4>
                                    <Scoreboard
                                        colorizeLabels={[currentUser.username]}
                                        scores={scoreboard.players.reduce<any>((prev, curr) => {
                                            return [
                                                ...prev,
                                                {
                                                    label: curr.player,
                                                    value: curr.points,
                                                    avatar: curr.avatar
                                                }
                                            ]
                                        }, [])}
                                    />
                                </div>
                            )
                        }
                        {
                            !userResults && (
                                <div className="container-fluid section">
                                    <h4>Merci d'avoir participé !</h4>
                                </div>)
                        }
                        <div className="container-fluid section">
                            {
                                /*
                                <h4>Challenges Results</h4>

                                challenges.map(challenge => (
                                    <div key={challenge.id} className="challenge-results">
                                        <div className="pd-8">
                                            <h4>{challenge.name}</h4>
                                            <div className="stats-container container-fluid">
                                                <div className="row stat">
                                                    <span className="stat-name col-md-4">Design</span>
                                                    <span className="col-md-6">{generateProgress(4, 50)}</span>
                                                    <span className="stat-value col-md-2"><span className="primary bold">4 </span>/ 50</span>
                                                </div>
                                                <div className="row stat">
                                                    <span className="stat-name col-md-4">Language Knowledge</span>
                                                    <span className="col-md-6">{generateProgress(97, 180)}</span>
                                                    <span className="stat-value col-md-2"><span className="primary bold">97 </span>/ 180</span>
                                                </div>
                                                <div className="row stat">
                                                    <span className="stat-name col-md-4">Problem Solving</span>
                                                    <span className="col-md-6">{generateProgress(1, 10)}</span>
                                                    <span className="stat-value col-md-2"><span className="primary bold">1 </span>/ 10</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                */
                            }
                        </div>
                    </div>
                </div>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
