import { TextField } from '@material-ui/core';
import React from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import { useMutationPostTagCreate } from '../../../../../../infrastructure/services/tags';
import Toast from '../../../../../components/toast';
import useForm, { IError } from '../../../../../hooks/form/useForm';

type Inputs = {
    id: string;
    name: string;
    icon?: string | null;
}

const AdminTagsCreateView = () => {
    const [fetchCreateTag] = useMutationPostTagCreate();

    const history = useHistory();

    const handleValidate = (v: Inputs) => {
        const errors: IError<Inputs> = {};

        if (v.id.length === 0) {
            errors.id = {
                error: true,
                message: "Le champ \"ID\" est obligatoire."
            }
        }

        if (v.name.length === 0) {
            errors.name = {
                error: true,
                message: "Le champ \"Nom\" est obligatoire."
            }
        }

        return errors;
    }

    const { values, handleBlur, handleChange, errors, containsError, fulfilled } = useForm<Inputs>({
        defaultValues: {
            id: "",
            name: "",
            icon: null
        },
        required: [
            "id", "name"
        ],
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/others/tags");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const tag_kind = "CampaignTags";
        const resp = await fetchCreateTag({
            tag_kind: tag_kind,
            tag: {
                id: values.id,
                icon: values.icon || "",
                name: values.name
            }
        }
        );
        if (resp.data) {
            toast.notify(
                <Toast text={"Tag crée avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/others/tags');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-tag" />
                    <h1>Créer Tag</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-name"
                                    name="name"
                                    label="Nom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-icon"
                                    name="icon"
                                    label="Icon"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    error={errors.icon !== undefined}
                                    helperText={errors.icon?.message}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminTagsCreateView;