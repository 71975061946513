import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SIGN_IN_THEN_REDIRECT_TO_ROUTE } from '../../../infrastructure/globals/routes';
import { selectIsConnecting } from '../../../infrastructure/services/authentication/slice';
import { selectIsUserConnected } from '../../../infrastructure/services/users/slice';
import Render from './render';

interface ILoggedRouteProps {
    children: any;
};

const LoggedRoute = (props: ILoggedRouteProps) => {
    const history = useHistory();

    const isConnecting = useSelector(selectIsConnecting);
    const isUserConnected = useSelector(selectIsUserConnected);

    useEffect(() => {
        if (isConnecting) {
            return;
        }

        if (!isUserConnected) {
            history.push(SIGN_IN_THEN_REDIRECT_TO_ROUTE(history.location.pathname));
        }
    }, [isConnecting, isUserConnected, history]);

    if (!isUserConnected || isConnecting) {
        return null;
    }

    return (
        <Render>
            {
                props.children
            }
        </Render>
    );
};

export default LoggedRoute;
