import { Avatar } from '@material-ui/core';
import React from 'react';
import { IUser } from '../../../infrastructure/services/users/types';

interface RenderProps {
    user: IUser;
};

const Render = (props: RenderProps) => {
    const { user } = props;
    
    return (
        <div className="djc--view-user">
            <div className="container-fluid section">
                <div className="row">
                    <div className="col-md">
                        { /* MOBILE */ }
                        <div className="d-md-none mb-16">
                            <div className="mobile-row mb-16">
                                <Avatar className="avatar-mobile">{user.username.slice(0, 2).toUpperCase()}</Avatar>
                                <h3>{user.username}</h3>
                            </div>
                            <span className="bold">{ user.first_name} { user.last_name }</span>
                        </div>
                        { /* OTHERS */ }
                        <div className="d-none d-md-block">hide on screens smaller than lg</div>
                    </div>
                    <div className="col-md">
                    { /* MOBILE */ }
                        <div className="d-md-none">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-2 no-pad">
                                        <p className="bold mb-2">
                                            Email:
                                        </p>
                                        <p className="bold">
                                            Joined on:
                                        </p>
                                    </div>
                                    <div className="col-10 no-pad">
                                        <p className="mb-2">
                                            { user.email }
                                        </p>
                                        <p>
                                            { user.join_date }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { /* OTHERS */ }
                        <div className="d-none d-md-block">hide on screens smaller than lg</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Render;
