import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { ARTICLES_EDIT_ROUTE } from '../../../infrastructure/globals/routes';
import { selectIsOneScopeIncluded, selectIsScopeIncluded } from '../../../infrastructure/services/authentication/slice';
import { useMutationDeletePageById, useMutationGetPages } from '../../../infrastructure/services/pages';
import { IPage } from '../../../infrastructure/services/pages/types';

import Render from './render';
import { AdminAction } from './types';
import Toast from '../../components/toast';

interface IArticlesListViewProps {
    filtersOn?: "news" | "events";
}

const ArticlesListView = (props: IArticlesListViewProps) => {

    const { filtersOn } = props;

    const history = useHistory();

    const [dirty, setDirty] = useState<boolean>(true);
    const [articles, setArticles] = useState<Array<IPage>>([]);

    const isScopeInlcuded = useSelector(selectIsScopeIncluded);
    const isOneScopeIncluded = useSelector(selectIsOneScopeIncluded);

    const [fetchPages] = useMutationGetPages();
    const [deletePage] = useMutationDeletePageById();

    useEffect(() => {
        const load = async () => {
            const articles = [];

            const results = await fetchPages(filtersOn);
            if (results.data) {
                const { pages } = results.data;

                articles.push(...pages.filter(p => p.visible));
            }

            setArticles(articles);
            setDirty(false);
        };

        load();
    }, [filtersOn, fetchPages]);

    const handleDeleteArticle = async (articleId: string) => {
        const resp = await deletePage({ id: articleId });

        if (resp.error) {
            toast.notify(
                <Toast
                    text="Error during page deletion. Please try again later."
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "top"
                }
            );
        } else {
            const nextArticles = [...articles.filter(article => article.id !== articleId)];
            setArticles(nextArticles);

            toast.notify(
                <Toast
                    text="Article deleted."
                />,
                {
                    duration: 10000,
                    position: "top"
                }
            );
        }
    };

    const handleAdminActions = (action: AdminAction, article: IPage) => {
        return () => {
            switch (action) {
                case "delete": {
                    handleDeleteArticle(article.id);
                    break;
                }
                case "edit": {
                    history.push(`${ARTICLES_EDIT_ROUTE}/${article.id}`);
                    break;
                }
                default:
                    break;
            }
        };
    }

    if (dirty) {
        return null;
    }

    return (
        <Render
            articles={articles}
            handleAdminActions={handleAdminActions}
            isScopeInlcuded={isScopeInlcuded}
            isOneScopeIncluded={isOneScopeIncluded}
        />
    )
};

export default ArticlesListView;
