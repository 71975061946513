import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import {
    CAMPAIGNS_ROUTE,
    CGU_ROUTE,
    CONTACT_ROUTE,
    HOME_ROUTE,
    SETTINGS_ROUTE,
    SIGN_IN_ROUTE,
    SIGN_UP_ROUTE,
    USER_ROUTE,
    EVENTS_ROUTE,
    VERIFY_MAIL,
    ARTICLES_ROUTE,
    ARTICLES_CREATE_ROUTE,
    ARTICLES_EVENTS_ROUTE,
    ARTICLES_NEWS_ROUTE,
    ARTICLES_EDIT_ROUTE,
    ABOUT_ROUTE,
    RESET_PASSWORD_ROUTE
} from "../../../infrastructure/globals/routes";

import DJCHeader from "../../components/header";
import HomepageView from "../homepage";
import LoginView from "../login";

import { theme } from "../../globals/theme";
import LoginModal from '../../components/login_modal';
import SignupView from '../signup';
import LoggedDrawer from '../../components/logged_drawer';
import LoggedRoute from '../../components/logged_route';
import UnloggedRoute from '../../components/unlogged_route';
import SettingsView from '../settings';
import UserView from '../user';
import CampaignsView from '../campaigns';
import ContactUsView from '../contact_us';
import CampaignsDetailView from '../campaigns/view';
import CampaignsParticipateView from '../campaigns/participate';
import CampaignsDoneView from '../campaigns/done';
import CGIView from '../cgi';
import EventsView from '../events';
import VerifyMailView from '../verify_mail';
import ArticleView from '../articles/view';
import ArticlesListView from '../articles';
import ArticleCreateView from '../articles/create';
import ArticleEditView from '../articles/edit';
import AboutView from '../about';
import ResetPasswordView from '../reset_password';
import ResetPasswordRequestView from '../reset_password request';
import AlertMessage from '../../components/alert_message';
import AuthUpdateGuildAndPhoneView from '../update_guild_and_phone';
import AdminView from '../admin';
import AdminArticlesListView from '../admin/views/articles/list';
import AdminHomeView from '../admin/views/home';
import AdminUsersListView from '../admin/views/users/list';
import AdminLeaderboardListView from '../admin/views/scoring/leaderboard/list';
import AdminRulesListView from '../admin/views/scoring/rules/list';
import AdminCampaignsListView from '../admin/views/campaigns/list';
import AdminChallengesListView from '../admin/views/challenges/list';
import AdminEnvironmentsListView from '../admin/views/environments/list';
import AdminTagsListView from '../admin/views/tags/list';
import AdminActionsListView from '../admin/views/actions/list';
import AdminTagsCreateView from '../admin/views/tags/create';
import AdminActionsCreateView from '../admin/views/actions/create';
import AdminLeaderboardCreateView from '../admin/views/scoring/leaderboard/create';
import AdminRuleCreateView from '../admin/views/scoring/rules/create';
import AdminArticleCreateView from '../admin/views/articles/create';
import AdminEnvironmentCreateView from '../admin/views/environments/create';
import AdminPATListView from '../admin/views/PAT/list';
import AdminPATCreateView from '../admin/views/PAT/create';
import AdminCampaignsCreateView from '../admin/views/campaigns/create';
import AdminUserEditView from '../admin/views/users/edit';
import AdminArticleEditView from '../admin/views/articles/edit';
import AdminEnvironmentEditView from '../admin/views/environments/edit';
import AdminCampaignsEditView from '../admin/views/campaigns/edit';
import CampaignsLeaderboardView from '../campaigns/leaderboard';
import AdminTempAccountsListView from '../admin/views/temp_accounts/list';
import AdminTempAccountCreateView from '../admin/views/temp_accounts/create';
import TempAccount from '../../components/temp_account';

interface RenderProps {
    isSignInDialogOpen: boolean
    isPaddingTopDisabled: boolean;
    showConfirmationInformations: boolean;
    handleConfirmationInformationsValidated: () => void;
    handleConfirmationInformationsClosed: () => void;
};

const Render = (props: RenderProps) => {
    const {
        isSignInDialogOpen,
        isPaddingTopDisabled,
        showConfirmationInformations,
        handleConfirmationInformationsValidated,
        handleConfirmationInformationsClosed
    } = props;

    const pageContentClass = isPaddingTopDisabled ? (
        "djc--page-content no-padding-top"
    ) : (
        "djc--page-content"
    );

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <DJCHeader />

                <div className={pageContentClass}>

                    <Switch>
                        <Route
                            path={`${VERIFY_MAIL}/:token`}
                            render={({ match: { params: { token } } }) => {
                                return (
                                    <VerifyMailView
                                        token={token || ""}
                                    />
                                )
                            }}
                        />
                        <Route exact path={RESET_PASSWORD_ROUTE}>
                            <UnloggedRoute>
                                <ResetPasswordRequestView />
                            </UnloggedRoute>
                        </Route>
                        <Route
                            path={`${RESET_PASSWORD_ROUTE}/:token`}
                            render={({ match: { params: { token } } }) => {
                                return (
                                    <ResetPasswordView
                                        token={token || ""}
                                    />
                                );
                            }}
                        />
                        <Route exact path={ABOUT_ROUTE}>
                            <AboutView />
                        </Route>
                        <Route exact path={ARTICLES_ROUTE}>
                            <ArticlesListView />
                        </Route>
                        <Route exact path={ARTICLES_NEWS_ROUTE}>
                            <ArticlesListView
                                filtersOn="news"
                            />
                        </Route>
                        <Route exact path={ARTICLES_EVENTS_ROUTE}>
                            <ArticlesListView
                                filtersOn="events"
                            />
                        </Route>
                        <Route exact path={ARTICLES_CREATE_ROUTE}>
                            <LoggedRoute>
                                <ArticleCreateView />
                            </LoggedRoute>
                        </Route>
                        <Route
                            path={`${ARTICLES_EDIT_ROUTE}/:id`}
                            render={({ match: { params: { id } } }) => {
                                return (
                                    <LoggedRoute>
                                        <ArticleEditView
                                            id={id || "-1"}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path={`${ARTICLES_ROUTE}/:slug`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <ArticleView
                                        slug={slug || ""}
                                    />
                                )
                            }}
                        />
                        <Route
                            path={`${EVENTS_ROUTE}/:slug`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <EventsView
                                            slug={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route exact path={CGU_ROUTE}>
                            <CGIView />
                        </Route>
                        <Route exact path={HOME_ROUTE}>
                            <HomepageView />
                        </Route>
                        <Route exact path={CONTACT_ROUTE}>
                            <ContactUsView />
                        </Route>
                        <Route exact path={SIGN_UP_ROUTE}>
                            <UnloggedRoute>
                                <SignupView />
                            </UnloggedRoute>
                        </Route>
                        <Route
                            path={`${SIGN_UP_ROUTE}/r/:slug+`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <UnloggedRoute redirectTo={slug || ""}>
                                        <SignupView />
                                    </UnloggedRoute>
                                )
                            }}
                        />
                        <Route exact path={SIGN_IN_ROUTE}>
                            <UnloggedRoute>
                                <LoginView />
                            </UnloggedRoute>
                        </Route>
                        <Route
                            path={`${SIGN_IN_ROUTE}/r/:slug+`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <UnloggedRoute redirectTo={slug || ""}>
                                        <LoginView redirectTo={slug || ""} />
                                    </UnloggedRoute>
                                )
                            }}
                        />
                        <Route exact path={CAMPAIGNS_ROUTE}>
                            <LoggedRoute>
                                <CampaignsView />
                            </LoggedRoute>
                        </Route>
                        <Route
                            path={`${CAMPAIGNS_ROUTE}/:slug/results`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <CampaignsDoneView
                                            slug={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path={`${CAMPAIGNS_ROUTE}/:slug/leaderboard`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <CampaignsLeaderboardView
                                        slug={slug || ""}
                                    />
                                )
                            }}
                        />
                        <Route
                            path={`${CAMPAIGNS_ROUTE}/:slug/participate`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <CampaignsParticipateView
                                            slug={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path={`${CAMPAIGNS_ROUTE}/:slug`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <CampaignsDetailView
                                            slug={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route exact path={SETTINGS_ROUTE}>
                            <LoggedRoute>
                                <SettingsView />
                            </LoggedRoute>
                        </Route>
                        <Route
                            path={`${SETTINGS_ROUTE}/:slug`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <SettingsView
                                            option={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route exact path={USER_ROUTE}>
                            <LoggedRoute>
                                <UserView
                                    self
                                />
                            </LoggedRoute>
                        </Route>
                        <Route
                            path={`${USER_ROUTE}/:slug`}
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <UserView
                                            user={slug || ""}
                                        />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path={"/authentication/update/guild-and-phone"}
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AuthUpdateGuildAndPhoneView />
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminHomeView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/articles/edit/:slug"
                            exact
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminArticleEditView slug={slug || ""} />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/articles"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminArticlesListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/articles/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminArticleCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/users/edit/:slug"
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminUserEditView slug={slug || ""} />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/users"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminUsersListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/temp_accounts"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminTempAccountsListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/temp_accounts/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminTempAccountCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/PAT"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminPATListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/PAT/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminPATCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/campaigns/edit/:slug"
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminCampaignsEditView slug={slug || ""} />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/campaigns"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminCampaignsListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/campaigns/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminCampaignsCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/challenges"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminChallengesListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/environments/edit/:slug"
                            exact
                            render={({ match: { params: { slug } } }) => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminEnvironmentEditView slug={slug || ""} />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/environments"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminEnvironmentsListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/environments/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminEnvironmentCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/others/tags"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminTagsListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/others/tags/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminTagsCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/others/actions"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminActionsListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/others/actions/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminActionsCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/scoring/rules"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminRulesListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/scoring/leaderboard"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminLeaderboardListView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/scoring/leaderboard/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminLeaderboardCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/admin/scoring/rules/create"
                            exact
                            render={() => {
                                return (
                                    <LoggedRoute>
                                        <AdminView>
                                            <AdminRuleCreateView />
                                        </AdminView>
                                    </LoggedRoute>
                                )
                            }}
                        />
                        <Route
                            path="/test_candidat/:token"
                            render={({ match: { params: { token } } }) => {
                                return (
                                    <TempAccount token={token} />
                                )
                            }}
                        />
                        <Route
                            path="*"
                            render={() => (
                                <Redirect to={"/"} />
                            )}
                        />
                    </Switch>
                </div>


                <LoginModal
                    open={isSignInDialogOpen}
                />

                <LoggedDrawer />

                {
                    showConfirmationInformations && (
                        <AlertMessage
                            open
                            kind="info"
                            title="Oops !"
                            content="Oups ! Il semblerait qu'un de nos ingénieurs étourdis aient oublié de correctement stocker votre numéro de téléphone ainsi que votre école. Si vous le voulez-bien, merci de mettre à jour ces informations :)"
                        >
                            <div className="right">
                                <button
                                    className="cancel-btn"
                                    onClick={handleConfirmationInformationsClosed}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="validate-btn"
                                    onClick={handleConfirmationInformationsValidated}
                                >
                                    Mettre à jour
                                </button>
                            </div>
                        </AlertMessage>
                    )
                }
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default Render;
