import { GlobalState, GlobalActionsTypes, TOGGLE_SIGN_IN_DIALOG, TOGGLE_LOGGED_DRAWER } from "./types";

const initialState: GlobalState = {
    isSignInDialogOpen: false,
    isLoggedDrawerOpen: false
};

export function globalReducer(
    state = initialState,
    action: GlobalActionsTypes
): GlobalState {
    switch (action.type) {
        case TOGGLE_SIGN_IN_DIALOG:
            return {
                ...state,
                isSignInDialogOpen: !state.isSignInDialogOpen
            };
        case TOGGLE_LOGGED_DRAWER:
            return {
                ...state,
                isLoggedDrawerOpen: !state.isLoggedDrawerOpen
            };
        default:
            return state;
    }
};
