import { Drawer } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

interface IProps {
    titleKey: string;
    open: boolean;
    fetchFunc: any;
    fetchArgs: any[];
    onClose: () => void;
}

const DetailsDrawer = (props: IProps) => {
    const {
        titleKey,
        open,
        fetchFunc,
        fetchArgs,
        onClose
    } = props;

    const [data, setData] = useState<any | null>(null);

    const fetch = async () => {
        const resp = await fetchFunc(...fetchArgs);

        if (resp.data) {
            setData(resp.data);
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (open) {
            setData(null);
            fetch();
        }
    }, [open]);

    const generateInput = ({ key, value }: { key: string, value: any }, padding = 0): any => {
        if (
            !!value 
            && typeof value === "object"
            && (
                !Array.isArray(value)
                || Array.isArray(value) && value.length > 0 && value.every(v => typeof v === "object" && !Array.isArray(v))
            )
        ) {
            const paddingValue = padding * 8;
            const paddingContentValue = paddingValue + 8;
            
            return (
                <React.Fragment key={key}>
                    <div className="mt-32" />

                    <div style={{ paddingLeft: `${paddingValue}px` }}>
                        <h5>{key}</h5>
                        <hr />
                        <div style={{ paddingLeft: `${paddingContentValue}px` }}>
                            {
                                Object.keys(value)
                                    .sort((a, b) => sortData(value, a, b))
                                    .map((k) => generateInput({ key: k, value: value[k] }, padding + 1))
                            }
                        </div>
                    </div>


                    <div className="mb-32" />
                </React.Fragment>   
            )
        }

        return (
            <div key={key} className="form-group">
                <label
                    htmlFor={`field-${key}`}
                    className="col-12 col-form-label"
                >
                    { key }
                </label>
                <div className="col-12">
                    <input
                        type="text"
                        readOnly
                        className="form-control full-width"
                        id={`field-${key}`}
                        value={JSON.stringify(value)}
                    />
                </div>
            </div>
        );
    }

    const sortData = (data: any, a: string, b: string) => {
        const isAClassic = data[a] === null || data[a] === undefined || typeof data[a] !== "object" || Array.isArray(a);
        const isBClassic = data[b] === null || data[a] === undefined || typeof data[b] !== "object" || Array.isArray(b);
        
        if (isAClassic && isBClassic) {
            return 0;
        }

        if (isAClassic && !isBClassic) {
            return -1;
        }

        if (!isAClassic && isBClassic) {
            return 1;
        }
        
        if (!isAClassic && !isBClassic) {
            return Object.keys(data[a]).length - Object.keys(data[b]).length;
        }

        return 0;
    }

    const generateForm = useCallback(() => {
        if (!data) {
            return <form></form>
        }

        const formEl = React.createElement(
            "form", 
            null, 
            Object.keys(data)
                .sort((a, b) => sortData(data, a, b))
                .map(key => generateInput({
                    key: key,
                    value: data[key]
                }))
        );

        return formEl;
    }, [data]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <div className="details-drawer-content">
                <h4>Details: {data !== null ? data[titleKey] : null}</h4>
                { generateForm() }
            </div>
        </Drawer>
    )
};

export default DetailsDrawer;
