import React from 'react';
import { ICampaignSummary } from '../../../../infrastructure/services/campaign/types';
import DJCFooter from '../../../components/footer';

import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';

import Scoreboard from '../../../components/scoreboard';
import { IUser } from '../../../../infrastructure/services/users/types';
import { IScoreboardResults } from '../../../../infrastructure/services/scoring/types';

interface IRenderProps {
    campaign: ICampaignSummary;
    scoreboard: IScoreboardResults | null;
    currentUser: IUser | null;
};

const Render = (props: IRenderProps) => {
    const {
        campaign,
        scoreboard,
        currentUser
    } = props;

    return (
        <>
            <div className="djc--view-campaigns-done">
                <div className="content">
                    <h2 className="campaign-top-title">Leaderboard - <a href={`${CAMPAIGNS_ROUTE}/${campaign.route_name}`}>{campaign.name}</a></h2>
                    <div className="mb-16" />

                    <div className="container-fluid bg-blank pd-16">
                        {
                            scoreboard && (
                                <div className="container-fluid section">
                                    <h4>Tableau des scores</h4>
                                    <Scoreboard
                                        colorizeLabels={currentUser ? [currentUser.username] : []}
                                        scores={scoreboard.players.reduce<any>((prev, curr) => {
                                            return [
                                                ...prev,
                                                {
                                                    label: curr.player,
                                                    value: curr.points,
                                                    avatar: curr.avatar
                                                }
                                            ]
                                        }, [])}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
