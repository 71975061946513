import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutationPostRuleCreate } from '../../../../../../../infrastructure/services/scoring';
import { useMutationGetChallengesList } from '../../../../../../../infrastructure/services/challenge';
import useForm, { IError } from '../../../../../../hooks/form/useForm';
import Toast from '../../../../../../components/toast';

type Inputs = {
    id: string;
    name: string;
    challenge: string;
    from_campaign: string;
    points: number;
    time: number;
}

const AdminRuleCreateView = () => {
    const [fetchCreate] = useMutationPostRuleCreate();
    const [fetchAllChallenges] = useMutationGetChallengesList();

    const [options, setOptions] = useState<string[]>([]);
    const history = useHistory();

    // const init = async () => {
    //     const resp = await fetchAllRules();

    //     if (resp.data) {
    //         setOptions(resp.data.rules.map(rule => rule.id));
    //     }
    // };

    // useEffect(() => {
    //     init();
    // }, []);

    const handleValidate = (v: Inputs) => {
        const errors: IError<Inputs> = {};

        if (v.id.length === 0) {
            errors.id = {
                error: true,
                message: "Le champ \"ID\" est obligatoire."
            }
        }

        if (v.name.length === 0) {
            errors.name = {
                error: true,
                message: "Le champ \"Nom\" est obligatoire."
            }
        }

        if (v.challenge.length === 0) {
            errors.challenge = {
                error: true,
                message: "Le champ \"Challenge\" est obligatoire."
            }
        }

        return errors;
    }

    const {
        values,
        handleBlur,
        handleChange,
        handleBlurAutocomplete,
        handleChangeAutocomplete,
        errors,
        containsError,
        fulfilled
    } = useForm<Inputs>({
        defaultValues: {
            id: "",
            name: "",
            challenge: "",
            from_campaign: "*",
            points: 1,
            time: 0
        },
        required: [
            "id", "name", "challenge"
        ],
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/scoring/rule");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchCreate({
            id: values.id,
            name: values.name,
            rule: {
                challenge: values.challenge,
                from_campaign: values.from_campaign,
                base: values.points,
                time: values.time
            }
        });
        if (resp.data) {
            toast.notify(
                <Toast text={"Règle créée avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/scoring/rule');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-trophy" />
                    <h1>Créer Leaderboard</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-name"
                                    name="name"
                                    label="Nom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-challenge"
                                    name="challenge"
                                    label="Challenge"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.challenge !== undefined}
                                    helperText={errors.challenge?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-campaign"
                                    name="from_campaign"
                                    label="Campagne associée"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-points"
                                    name="base"
                                    label="Nombre de points"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-time"
                                    name="time"
                                    label="Temps de réalisation"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminRuleCreateView;
