import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationDeleteChallengeById, useMutationGetChallengeById, useMutationGetChallengesList } from "../../../../../../infrastructure/services/challenge";
import { IChallenge } from "../../../../../../infrastructure/services/challenge/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";

type DataGrid = Array<{
    id: string;
    name: string;
    author: string;
    difficulty: number;
    active: string;
    public: string;
}>;

const AdminChallengesListView = () => {
    const [ fetchChallenges ] = useMutationGetChallengesList();
    const [ deleteChallengeById ] = useMutationDeleteChallengeById();
    const [ fetchChallengeById ] = useMutationGetChallengeById();

    const [challenges, setChallenges] = useState<Array<IChallenge>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return challenges.map((challenge) => ({
            id: challenge.id,
            name: challenge.name,
            author: challenge.author,
            difficulty: challenge.difficulty,
            active: challenge.disabled ? "false": "true",
            public: challenge.private ? "false": "true"
        }));
    }, [challenges]);

    const getChallenges = async (): Promise<IChallenge[]> => {
        const resp = await fetchChallenges({
            show_disabled: true,
            show_private: true
        });

        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.challenges;
    }

    const init = async () => {
        const challengesResp = await getChallenges();
        setChallenges(challengesResp);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteChallengeById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...challenges].filter(a => !idsToDelete.includes(a.id));

        setChallenges(next);
        setFetching(false);
    }

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-bug" />
                    <h1>Challenges</h1>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Challenges <CircularProgress className="inline-block-important" /></span> : `Challenges (${challenges.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'name',
                            disablePadding: true,
                            label: "Nom",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'author',
                            disablePadding: true,
                            label: "Auteur",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'difficulty',
                            disablePadding: true,
                            label: "Difficulté",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'active',
                            disablePadding: true,
                            label: "Actif",
                            numeric: false,
                            url: false,
                            boolean: true
                        },
                        {
                            id: 'public',
                            disablePadding: true,
                            label: "Public",
                            numeric: false,
                            url: false,
                            boolean: true
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="name"
                fetchFunc={fetchChallengeById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminChallengesListView;
