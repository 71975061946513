import React from 'react';

import Render from './render';

interface IToastProps {
    text: string;
    type?: "default" | "alert" | undefined;
};

const Toast = (props: IToastProps) => {
    const {
        text,
        type
    } = props;

    return (
        <Render
            text={text}
            type={type || "default"}
        />
    );
};

export default Toast;
