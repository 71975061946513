import { api } from "..";
import { IChallenge } from "../challenge/types";
import {
    ICampaignSummary,
    ICampaignCheckInputs,
    ICampaignCheckResults,
    ICampaignCreateForm,
    ICampaignDeleteInputs,
    ICampaignFetchAll,
    ICampaignJoinForm,
    ICheckOneCampaignResults,
    ICheckOneCampaignInputs,
    ICampaignDoneResults,
    ICampaignParticipantStatusInput,
    ICampaignParticipantStatusResults,
    ICampaignIsParticipantResults,
    IUpdateCampaignInputs,
    ICampaign
} from "./types";

const campaignExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCampaigns: build.query<ICampaignFetchAll, void>({
            query: () => ({
                url: '/campaign',
                method: 'GET'
            })
        }),
        getCampaignById: build.mutation<ICampaignSummary, string>({
            query: (arg) => ({
                url: `/campaign/${arg}`,
                method: 'GET'
            })
        }),
        getCampaignByIdAdmin: build.mutation<ICampaign, string>({
            query: (arg) => ({
                url: `/campaign/${arg}`,
                method: 'GET'
            })
        }),
        getCampaignsMutation: build.mutation<ICampaignFetchAll, void>({
            query: () => ({
                url: '/campaign',
                method: 'GET'
            })
        }),
        createCampaign: build.mutation<ICampaign, ICampaignCreateForm>({
            query: (arg: ICampaignCreateForm) => ({
                url: '/campaign',
                method: 'POST',
                body: arg
            }),
        }),
        getCampaignFromRoute: build.query<ICampaignSummary, string>({
            query: (route: string) => ({
                url: `/route/campaign/${route}`,
                method: 'GET'
            })
        }),
        getCampaignFromRouteAdmin: build.query<ICampaign, string>({
            query: (route: string) => ({
                url: `/route/campaign/${route}`,
                method: 'GET'
            })
        }),
        joinCampaignById: build.mutation<boolean, ICampaignJoinForm>({
            query: (args: ICampaignJoinForm) => ({
                url: `/campaign/${args.id}/join`,
                method: 'POST',
                body: {
                    password: args.password
                }
            })
        }),
        leaveCampaignById: build.mutation<boolean, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/leave`,
                method: 'POST',
                body: {}
            })
        }),
        getCurrentChallengeForCampaignById: build.mutation<IChallenge, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/current`,
                method: 'GET'
            })
        }),
        getNextChallengeForCampaignById: build.mutation<IChallenge, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/next`,
                method: 'GET'
            })
        }),
        checkCurrentChallengeForCampaignById: build.mutation<ICampaignCheckResults, ICampaignCheckInputs>({
            query: (args: ICampaignCheckInputs) => ({
                url: `/campaign/${args.id}/inputs`,
                method: 'POST',
                body: {
                    environment: args.environment,
                    files: [{
                        path: args.path,
                        type: "File",
                        permissions: 644,
                        data: Buffer.from(args.code).toString('base64')
                    }]
                }
            })
        }),
        deleteCampaignById: build.mutation<void, ICampaignDeleteInputs>({
            query: (id: ICampaignDeleteInputs) => ({
                url: `/campaign/${id}`,
                method: 'DELETE'
            })
        }),
        getCampaignIsDone: build.query<ICampaignDoneResults, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/done`,
                method: 'GET'
            })
        }),
        getMutationCampaignIsDone: build.mutation<ICampaignDoneResults, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/done`,
                method: 'GET'
            })
        }),
        postCheckOne: build.mutation<ICheckOneCampaignResults, ICheckOneCampaignInputs>({
            query: (args: ICheckOneCampaignInputs) => ({
                url: `/campaign/${args.campaign_id}/inputs/${args.check_id}`,
                method: 'POST',
                body: {
                    environment: args.environment,
                    files: [{
                        path: args.path,
                        type: "File",
                        permissions: 644,
                        data: Buffer.from(args.code).toString('base64')
                    }]
                }
            })
        }),
        getCampaignParticipantStatus: build.mutation<ICampaignParticipantStatusResults, ICampaignParticipantStatusInput>({
            query: (args: ICampaignParticipantStatusInput) => ({
                url: `/campaign/${args.id}/participant/${args.username}`,
                method: "GET"
            })
        }),
        getIsCampaignParticipant: build.mutation<ICampaignIsParticipantResults, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/is_self_participant`,
                method: "GET"
            })
        }),
        patchUpdateCampaignById: build.mutation<ICampaign, IUpdateCampaignInputs>({
            query: (args) => ({
                url: `/campaign/${args.id}`,
                method: "PATCH",
                body: args.body
            })
        }),
        postSkipNextChallenge: build.mutation<boolean, string>({
            query: (id: string) => ({
                url: `/campaign/${id}/skip_challenge`,
                method: "POST",
            })
        })
    }),
    overrideExisting: false
});

const {
    getCampaigns: {
        useQuery: useGetCampaigns
    },
    getCampaignsMutation: {
        useMutation: useMutationGetCampaignsList
    },
    createCampaign: {
        useMutation: useMutationCreateCampaign
    },
    getCampaignFromRoute: {
        useQuery: useGetCampaignFromRoute
    },
    getCampaignFromRouteAdmin: {
        useQuery: useGetCampaignFromRouteAdmin
    },
    getCurrentChallengeForCampaignById: {
        useMutation: useMutationGetCurrentChallengeForCampaignById
    },
    getNextChallengeForCampaignById: {
        useMutation: useMutationGetNextChallengeForCampaignById
    },
    joinCampaignById: {
        useMutation: useMutationJoinCampaignById
    },
    leaveCampaignById: {
        useMutation: useMutationLeaveCampaignById
    },
    checkCurrentChallengeForCampaignById: {
        useMutation: useMutationCheckCurrentChallengeForCampaignById
    },
    deleteCampaignById: {
        useMutation: useMutationDeleteCampaignById
    },
    getCampaignIsDone: {
        useQuery: useGetCampaignIsDone
    },
    getMutationCampaignIsDone: {
        useMutation: useMutationCampaignIsDone
    },
    postCheckOne: {
        useMutation: useMutationPostCampaignCheckOneCheck
    },
    getCampaignParticipantStatus: {
        useMutation: useMutationGetCampaignParticipantStatus
    },
    getIsCampaignParticipant: {
        useMutation: useMutationIsCampaignParticipant
    },
    getCampaignById: {
        useMutation: useMutationGetCampaignById
    },
    getCampaignByIdAdmin: {
        useMutation: useMutationGetCampaignByIdAdmin
    },
    patchUpdateCampaignById: {
        useMutation: useMutationPatchUpdateCampaign
    },
    postSkipNextChallenge: {
        useMutation: useMutationPostSkipNextChallenge
    }
} = campaignExtendedApi.endpoints;

export { campaignExtendedApi };
export {
    useGetCampaigns,
    useMutationGetCampaignsList,
    useMutationCreateCampaign,
    useGetCampaignFromRoute,
    useGetCampaignFromRouteAdmin,
    useMutationGetCurrentChallengeForCampaignById,
    useMutationGetNextChallengeForCampaignById,
    useMutationJoinCampaignById,
    useMutationLeaveCampaignById,
    useMutationCheckCurrentChallengeForCampaignById,
    useMutationDeleteCampaignById,
    useMutationCampaignIsDone,
    useGetCampaignIsDone,
    useMutationPostCampaignCheckOneCheck,
    useMutationGetCampaignParticipantStatus,
    useMutationIsCampaignParticipant,
    useMutationGetCampaignById,
    useMutationGetCampaignByIdAdmin,
    useMutationPatchUpdateCampaign,
    useMutationPostSkipNextChallenge
};
