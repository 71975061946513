import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import { CAMPAIGNS_ROUTE } from '../../../infrastructure/globals/routes';

import { useMutationVerifyMailToken } from '../../../infrastructure/services/mail';
import Toast from '../../components/toast';

interface IVerifyMailViewProps {
    token: string;
} 

const VerifyMailView = (props: IVerifyMailViewProps) => {
    const { token } = props;
    
    const history = useHistory();
    const [fetchVerifyMail] = useMutationVerifyMailToken();

    const verifyToken = useCallback(async () => {
        const resp = await fetchVerifyMail(token);

        if (resp.error) {
            toast.notify(
                <Toast
                    text={`Erreur durant la vérification du mail. Merci de vérifier votre token ou de réessayer plus tard.`}
                />,
                {
                    duration: 10000,
                    position: "top"
                }
            );
        } else if (resp.data !== undefined) {
            if (resp.data === true) {
                toast.notify(
                    <Toast
                        text={`Email correctement vérifié !`}
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
            } else {
                toast.notify(
                    <Toast
                        text={`Erreur durant la vérification du mail. Merci de vérifier votre token ou de réessayer plus tard.`}
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
            }
        }

        history.push(`${CAMPAIGNS_ROUTE}`);
    }, [history, fetchVerifyMail, token]);

    useEffect(() => {
        verifyToken();
    }, [token, verifyToken]);

    return null;
}

export default VerifyMailView;
