import React from 'react';

import { IFormInputs } from './index';
import { CircularProgress, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Controller, Control, DeepMap, FieldError } from "react-hook-form";
import DJCFooter from '../../components/footer';
import { CGU_ROUTE, SIGN_IN_ROUTE } from '../../../infrastructure/globals/routes';
import { DojoCodesBrandLogo } from '../../../assets/assets';
import Stepper from '../../components/stepper';

import { ISignUpStep, ISignUpStepField } from './types';

interface IRenderProps {
    onSubmit: () => void;
    errors: DeepMap<IFormInputs, FieldError>;
    isSignUpButtonDisabled: boolean;
    steps: Array<ISignUpStep>;
    activeStep: ISignUpStep;
    activeStepIdx: number;
    isBackDisabled: boolean;
    generateField: (field: ISignUpStepField) => JSX.Element | null;
    handleBack: () => void;
};

const Render = (props: IRenderProps) => {
    const {
        onSubmit,
        errors,
        isSignUpButtonDisabled,
        steps,
        activeStep,
        activeStepIdx,
        generateField,
        handleBack,
        isBackDisabled
    } = props;

    return (
        <>
            <div className="djc--view-signup-content">
                <section className="djc--view-signup-topbar">
                    <div className="djc--header-logo">
                        <img src={DojoCodesBrandLogo} alt="brand logo" />
                        <h1>
                            <a href="/">
                                <span className="dark">Dojo</span><span className="primary">.Codes</span>
                            </a>
                        </h1>
                    </div>
                </section>

                <Stepper
                    steps={steps.map(step => step.label)}
                    activeStep={activeStepIdx}
                />

                <section className="djc--view-signup-form">
                    <h1>Inscription</h1>
                    <form>
                        {
                            activeStep.fields.map((field) => generateField(field))
                        }
                    </form>
                    <div className="djc--view-signup-form-actions">
                        <Link to={`${SIGN_IN_ROUTE}`}>Déjà un compte ?</Link>
                        <button
                            onClick={handleBack}
                            disabled={isBackDisabled}
                        >
                            Revenir en arrière
                        </button>
                        <button
                            onClick={onSubmit}
                            disabled={isSignUpButtonDisabled}
                        >
                            {
                                activeStepIdx === steps.length - 1
                                    ? "S'inscrire"
                                    : "Suivant"
                            }
                            {
                                isSignUpButtonDisabled && (
                                    <CircularProgress />
                                )
                            }
                        </button>
                    </div>

                    <div className="divider" />

                    <section className="djc--view-signup-form-legals">
                        <p>
                            En cliquant sur “S'inscrire”, vous acceptez les <Link to={CGU_ROUTE}>Conditions Générales d'Utilisation</Link> ainsi que
                            la <Link to={CGU_ROUTE}>Politique de Confidentialité</Link> de Dojo.Codes.
                        </p>
                    </section>
                </section>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
