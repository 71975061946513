import React, { useEffect } from 'react';
import Markdown from '../../components/markdown';

import CGUMockRaw from './mock.md';

const Render = () => {
    const [ready, setReady] = React.useState(false);
    const [text, setText] = React.useState<string>("");

    useEffect(() => {
        const load = async () => {
            const result = await fetch(CGUMockRaw);
            setText(await result.text());

            setReady(true);
        }

        load();
    }, []);

    if (!ready) {
        return null;
    }

    return (
        <div className="djc--view-cgi">
            <div className="content">
                <div className="container-fluid bg-blank pd-16">
                    <div className="container-fluid section">
                        <Markdown
                            children={text}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Render;
