import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import { useMutationGetTagList } from '../../../../../../infrastructure/services/tags';
import Toast from '../../../../../components/toast';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import { ChallengeSelectionMode } from '../../../../../../infrastructure/services/campaign/types';
import { useMutationGetCampaignByIdAdmin, useMutationPatchUpdateCampaign } from '../../../../../../infrastructure/services/campaign';
import { useMutationGetChallengesList } from '../../../../../../infrastructure/services/challenge';
import { useMutationGetScoreboardList } from '../../../../../../infrastructure/services/scoring';
import PasswordField from '../../../../../components/password_field';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EnhancedTable from '../../../../../components/table';
import { IChallenge } from '../../../../../../infrastructure/services/challenge/types';

type Inputs = {
    id: string;
    name: string;
    max_participants: number;
    duration: number;
    start_date: string;
    expiration_date: string;

    route_name: string;
    password: string;

    thumbnail: string;

    challenge_selection_mode: ChallengeSelectionMode;
    challenges: string;

    description: string;
    post_completion_description: string;

    tags: string;
    user_tags_requirements: string;

    associated_scoreboard: string;
    challenge_skip: boolean;
}

interface IProps {
    slug: string;
}

type DataGrid = Array<{
    id: string;
    name: string;
    challenge_type: string;
    difficulty: number | string;
    duration: number | string;
    description: string;
}>;

const AdminCampaignsEditView = (props: IProps) => {
    const { slug } = props;

    const [fetchCampaign] = useMutationGetCampaignByIdAdmin();
    const [updateCampaign] = useMutationPatchUpdateCampaign();

    const [fetchChallenges] = useMutationGetChallengesList();
    const [fetchTags] = useMutationGetTagList();
    const [fetchLeaderboards] = useMutationGetScoreboardList();

    const [challenges, setChallenges] = useState<Array<IChallenge>>([]);
    const [campaignChallenges, setCampaignChallenges] = useState<Array<string>>([]);
    const [newChallenges, updateChallenges] = useState<Array<string>>([]);
    const [tagsOptions, setTagsOptions] = useState<string[]>([]);
    const [leaderboardsOptions, setLeaderboardsOptions] = useState<string[]>([]);
    const [skipChallenge, setSkipChallenge] = useState<boolean>(false);
    const [campaignTagsOptions] = useState<string[]>([
        "challenges", "formation", "recruitment"
    ]);
    const [campaignChallengeSelectionModeOptions] = useState<ChallengeSelectionMode[]>([
        "Random", "Sequence", "IncreasingDifficulty", "Alphabetical"
    ]);

    const getChallengeDifficulty = (tags: string[]): string => {
        let difficulty = ""
        tags.forEach((tag) => {
            if (tag.startsWith("difficulty")) {
                difficulty = tag.split(":")[1];
            }
        });

        return difficulty
    };

    const getChallengeType = (tags: string[]): string => {
        let type = ""
        tags.forEach((tag) => {
            if (tag.startsWith("type")) {
                type = tag.split(":")[1];
            }
        });

        return type
    };

    const dataGrid = useMemo<DataGrid>(() => {
        let prequal_challenges: Array<IChallenge> = [];
        challenges.forEach((challenge) => {
            if (challenge.tags.includes("prequal")) {
                prequal_challenges.push(challenge);
            }
        })

        return prequal_challenges.map((challenge) => ({
            id: challenge.id,
            name: challenge.name,
            challenge_type: getChallengeType(challenge.tags),
            difficulty: getChallengeDifficulty(challenge.tags),
            duration: challenge.difficulty || "",
            description: challenge.description || "",
        }));
    }, [challenges]);

    const history = useHistory();

    const raise = (error: string) => {
        toast.notify(
            <Toast
                text={error}
            />,
            {
                duration: 10000,
                position: "top",
                type: "error"
            }
        );

        handleCancel();
    }

    const initChallenges = async () => {
        const resp = await fetchChallenges({
            show_disabled: true,
            show_private: true
        });

        if (resp.data) {
            setChallenges(resp.data.challenges);
        }
    };

    const initTags = async () => {
        const resp = await fetchTags("CampaignTags");

        if (resp.data) {
            setTagsOptions(resp.data.tags.map(v => v.id));
        }
    };

    const initLeaderboards = async () => {
        const resp = await fetchLeaderboards();

        if (resp.data) {
            setLeaderboardsOptions(resp.data.scoreboards.map(v => v.id));
        }
    };

    const initCampaign = async () => {
        const resp = await fetchCampaign(slug);

        if (resp.data) {
            setCampaignChallenges(resp.data.challenges);
            updateValues({
                associated_scoreboard: resp.data.associated_scoreboard || "",
                challenge_selection_mode: resp.data.challenge_selection_mode,
                challenges: resp.data.challenges.join(','),
                description: resp.data.description || "",
                duration: resp.data.duration,
                expiration_date: resp.data.expiration_date || "",
                id: resp.data.id,
                max_participants: resp.data.max_participants,
                name: resp.data.name,
                post_completion_description: resp.data.post_completion_description,
                route_name: resp.data.route_name,
                start_date: resp.data.start_date,
                tags: resp.data.tags.join(','),
                user_tags_requirements: resp.data.user_tags_requirements.join(','),
                thumbnail: resp.data.thumbnail || "",
                challenge_skip: resp.data.challenge_skip
            })
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            raise(message);
        }
    }

    const init = async () => {
        await initCampaign();
        await initChallenges();
        await initTags();
        await initLeaderboards();
    };

    useEffect(() => {
        init();
    }, []);

    const generateError = (errors: IError<Inputs>, field: string, condition: boolean, errorMessage: string) => {
        if (!condition) {
            return errors;
        }

        return {
            ...errors,
            [field]: { error: true, message: errorMessage }
        };
    }

    const handleValidate = (v: Inputs) => {
        let errors: IError<Inputs> = {};

        errors = generateError(errors, "id", v.id.length === 0, "Le champ \"ID\" est obligatoire.");
        errors = generateError(errors, "name", v.name.length === 0, "Le champ \"Nom\" est obligatoire.");
        errors = generateError(errors, "max_participants", v.max_participants === 0, "Le champ \"Nb. max joueurs\" doit être supérieur à 0.");
        errors = generateError(errors, "route_name", v.route_name.match(/^([A-Za-z0-9-]+)$/g) === null, "Le champ \"Route\" est incorrect.");
        errors = generateError(errors, "description", v.description.length === 0, "Le champ \"Description\" est obligatoire.");
        errors = generateError(errors, "post_completion_description", v.post_completion_description.length === 0, "Le champ \"Message Campagne Complétée\" est obligatoire.");
        errors = generateError(errors, "tags", v.tags.length === 0, "Le champ \"Tags\" est obligatoire.");

        return errors;
    }

    const { values, updateValues, handleBlur, handleChange, handleChangeAutocomplete, handleBlurAutocomplete, handleChangeSingleAutocomplete, errors, containsError, fulfilled } = useForm<Inputs>({
        defaultValues: {
            id: "",
            name: "",
            associated_scoreboard: "",
            challenge_selection_mode: "Random",
            challenges: "",
            description: "",
            duration: 0,
            expiration_date: "",
            max_participants: 0,
            password: "",
            post_completion_description: "",
            route_name: "",
            start_date: "",
            tags: "recruitment",
            user_tags_requirements: "",
            thumbnail: "",
            challenge_skip: false
        },
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/campaigns");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }
        const resp = await updateCampaign({
            id: slug,
            body: {
                id: values.id,
                associated_scoreboard: values.associated_scoreboard.length === 0 ? null : values.associated_scoreboard,
                challenge_selection_mode: values.challenge_selection_mode,
                challenges: newChallenges,
                description: values.description,
                duration: values.duration,
                expiration_date: values.expiration_date.length === 0 ? null : values.expiration_date,
                max_participants: values.max_participants,
                name: values.name,
                password: values.password.length === 0 ? null : values.password,
                post_completion_description: values.post_completion_description,
                route_name: values.route_name,
                start_date: values.start_date,
                tags: values.tags.split(',').map(v => v.trim()).filter(v => v.length > 0),
                user_tags_requirements: values.user_tags_requirements.split(',').map(v => v.trim()).filter(v => v.length > 0),
                thumbnail: values.thumbnail,
                mode: "Speedrun",
                challenge_skip: values.challenge_skip
            }
        });
        if (resp.data) {
            toast.notify(
                <Toast text={"Modifications sauvegardées."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/campaigns');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            raise(message);
        }
    };

    const updateSelectedChallenges = (ids: string[]) => {
        updateChallenges(ids);

    }

    const getInitialSelection = (id: string) => {
        let is_present = false;
        campaignChallenges.forEach((challenge) => {
            if (challenge == id) {
                is_present = true;
            }
        })
        return is_present;
    }

    const handleSkipChallengeChange = (event: any) => {
        const target = (event.target as any);
        setSkipChallenge(target.checked);
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-gamepad" />
                    <h1>Editer Campagne</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.id}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-name"
                                    name="name"
                                    label="Nom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-route-name"
                                    name="route_name"
                                    label="Route"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.route_name}
                                    fullWidth
                                    variant="outlined"
                                    error={errors.route_name !== undefined}
                                    helperText={errors.route_name?.message}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <PasswordField
                                    id="input-password"
                                    label="Mot de passe"
                                    name="password"
                                    inputProps={{
                                        onBlur: handleBlur,
                                        onChange: handleChange,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-start-date"
                                    name="start_date"
                                    label="Début"
                                    onBlur={handleBlur}
                                    value={values.start_date}
                                    onChange={handleChange}
                                    fullWidth
                                    type="datetime-local"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={errors.start_date !== undefined}
                                    helperText={errors.start_date?.message}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-expiration-date"
                                    name="expiration_date"
                                    label="Fin"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.expiration_date}
                                    fullWidth
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    error={errors.expiration_date !== undefined}
                                    helperText={errors.expiration_date?.message}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="skip-challenge"
                                            onChange={handleSkipChallengeChange}
                                            color="primary"
                                            checked={skipChallenge}
                                        />
                                    }
                                    label="Possibilité de passer les challenges"
                                />
                            </div>
                        </div>

                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-max-participants"
                                    name="max_participants"
                                    label="Joueurs Max"
                                    value={values.max_participants}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={errors.max_participants !== undefined}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <TextField
                                    id="input-duration"
                                    name="duration"
                                    label="Durée (min)"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.duration}
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={errors.duration !== undefined}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid section col-6">
                        <h4>Textes</h4>
                        <div className="p-4">
                            <div className="row mb-16 align-center">
                                <div className="col-12">
                                    <TextField
                                        id="input-description"
                                        name="description"
                                        label="Contenu"
                                        value={values.description}
                                        multiline
                                        rows={10}
                                        rowsMax={20}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        variant="outlined"
                                        error={errors.description !== undefined}
                                        helperText={errors.description?.message || "MD syntax"}
                                    />
                                </div>
                            </div>
                            <div className="row mb-16 align-center">
                                <div className="col-12">
                                    <TextField
                                        id="input-post-completion-description"
                                        name="post_completion_description"
                                        label="Message Campagne Complétée"
                                        multiline
                                        value={values.post_completion_description}
                                        rows={10}
                                        rowsMax={20}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        variant="outlined"
                                        error={errors.post_completion_description !== undefined}
                                        helperText={errors.post_completion_description?.message || "MD syntax (affiché lors de la fin d'une campagne)"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid section col-6">
                        <h4>Image</h4>
                        <div className="p-4">
                            <div className="row mb-8 align-left">
                                <div>
                                    <TextField
                                        id="input-thumbnail"
                                        name="thumbnail"
                                        label="Thumbnail (src)"
                                        value={values.thumbnail}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        variant="outlined"
                                        error={errors.thumbnail !== undefined}
                                        helperText={errors.thumbnail?.message || "lien"}
                                    />
                                </div>
                                <div className="row mb-8 align-left">
                                    <div className="admin-campaigns-create-img-container">
                                        <div className="dummy" />
                                        {
                                            values.thumbnail.length ? (
                                                <img src={values.thumbnail} alt="campaign thumbnail" />
                                            ) : (
                                                <div className="img-skeleton" />
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container-fluid section">
                    <EnhancedTable
                        title={<h4>Challenges</h4>}
                        handleGlobalCheckboxSelected={updateSelectedChallenges}
                        handleGlobalInitialSelection={getInitialSelection}
                        headCells={[
                            {
                                id: 'id',
                                disablePadding: true,
                                label: "ID",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                            {
                                id: 'name',
                                disablePadding: true,
                                label: "Nom",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                            {
                                id: 'challenge_type',
                                disablePadding: true,
                                label: "Type",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                            {
                                id: "difficulty",
                                disablePadding: true,
                                label: "Difficulty",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                            {
                                id: "duration",
                                disablePadding: true,
                                label: "Durée (min)",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                            {
                                id: "description",
                                disablePadding: true,
                                label: "Description",
                                numeric: false,
                                url: false,
                                boolean: false
                            },
                        ]}
                        rows={dataGrid}
                    />
                </div>

                <div className="container-fluid section">
                    <h4>Tags</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <Autocomplete
                                    id="input-tags"
                                    multiple
                                    options={campaignTagsOptions}
                                    clearOnEscape
                                    value={values.tags.split(',').filter(v => v.length > 0)}
                                    onBlur={handleBlurAutocomplete("tags")}
                                    onChange={handleChangeAutocomplete("tags")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"tags"}
                                            label={"Tags Campagne"}
                                            variant="outlined"
                                            error={errors.tags !== undefined}
                                            helperText={errors.tags?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <Autocomplete
                                    id="input-tags-requirements"
                                    multiple
                                    options={tagsOptions}
                                    clearOnEscape
                                    value={values.user_tags_requirements.split(',').filter(v => v.length > 0)}
                                    onBlur={handleBlurAutocomplete("user_tags_requirements")}
                                    onChange={handleChangeAutocomplete("user_tags_requirements")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"user_tags_requirements"}
                                            label={"Tags Requis"}
                                            variant="outlined"
                                            error={errors.user_tags_requirements !== undefined}
                                            helperText={errors.user_tags_requirements?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid section">
                    <h4>Scoreboard</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Autocomplete
                                    id="input-associated-scoreboard"
                                    options={leaderboardsOptions}
                                    clearOnEscape
                                    value={values.associated_scoreboard}
                                    onBlur={handleBlurAutocomplete("associated_scoreboard")}
                                    onChange={handleChangeSingleAutocomplete("associated_scoreboard")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"associated_scoreboard"}
                                            label={"Leaderboard associé"}
                                            variant="outlined"
                                            error={errors.associated_scoreboard !== undefined}
                                            helperText={errors.associated_scoreboard?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid section">
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                onClick={handleSave}
                                disabled={containsError || !fulfilled}
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminCampaignsEditView;