import { Button, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';

export interface INavLink {
    link: string;
    label: string;
}

interface IProps {
    links: INavLink[];
    current: string;
}

const Nav = (props: IProps) => {
    const { links, current } = props;

    const [isOpen, setOpen] = React.useState(false);

    const toggleOpen = () => {
        setOpen(last => !last);
    }

    const activeClassName = (href: string) => {
        if (href === "/" && current === "/") {
            return "active";
        }

        return current.includes(href) ? "active" : null;
    };

    const currentItem = React.useMemo(() => {
        const idx = links.findIndex(k => k.link === current);
        return idx > -1 ? links[idx] : links[0];
    }, [current, links]);

    const linksToShow = React.useMemo(() => {
        return links.filter(k => k.link !== current);
    }, [current, links]);

    return (
        <nav className="djc--component-nav mobile">
            <div className={`bg-over ${!isOpen ? 'hide' : 'visible'}`} />
            <div className="content">
                <Button onClick={toggleOpen} className="current" disabled={linksToShow.length === 0}>
                    <span>{currentItem.label}</span>
                    { isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }
                </Button>
                <div className={`list-links ${!isOpen ? 'hide' : 'visible'}`}>
                    {
                        linksToShow.map((link) => {
                            return (
                                <a
                                    href={link.link}
                                    className={`list-link-row ${activeClassName(link.link)}`}
                                >
                                    {link.label}
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </nav>
    )
};

export default Nav;
