import { api } from "..";
import {
    ICreateEnvironment,
    IEnvironment,
    IEnvironmentSummary,
    IUpdateEnvironmentInputs
} from "./types";

const environmentsExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEnvironmentById: build.mutation<IEnvironment, string>({
            query: (id: string) => ({
                url: `/environments/${id}`,
                method: 'GET'
            })
        }),
        getEnvironments: build.mutation<{ environments: Array<IEnvironmentSummary> }, void | { only_visible?: boolean }>({
            query: (args) => ({
                url: "/environments",
                method: "GET",
                params: {
                    only_visible: (args && args.only_visible !== undefined) ? args.only_visible : true
                }
            })
        }),
        postCreateEnvironment: build.mutation<ICreateEnvironment, ICreateEnvironment>({
            query: (args) => ({
                url: "/environments",
                method: "POST",
                body: args
            })
        }),
        patchUpdateEnvironment: build.mutation<IEnvironment, IUpdateEnvironmentInputs>({
            query: (args) => ({
                url: `/environments/${args.id}`,
                method: "PATCH",
                body: args.body
            })
        })
    }),
    overrideExisting: false
});


const {
    getEnvironmentById: {
        useMutation: useMutationGetEnvironmentById
    },
    getEnvironments: {
        useMutation: useMutationGetEnvironments
    },
    postCreateEnvironment: {
        useMutation: useMutationPostCreateEnvironment
    },
    patchUpdateEnvironment: {
        useMutation: useMutationPatchUpdateEnvironment
    }
} = environmentsExtendedApi.endpoints;

export { environmentsExtendedApi };
export {
    useMutationGetEnvironmentById,
    useMutationGetEnvironments,
    useMutationPostCreateEnvironment,
    useMutationPatchUpdateEnvironment
};
