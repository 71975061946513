import { ISignUpStep } from "../types";

const accountInformationsStep: ISignUpStep = ({
    label: "Identité",
    fields: [
        {
            as: "string",
            id: "field--first-name",
            name: "firstName",
            label: "Prénom"
        },
        {
            as: "string",
            id: "field--last-name",
            name: "lastName",
            label: "Nom de famille"
        },
        {
            as: "phone",
            id: "field--phone",
            name: "phone",
            label: "Numéro de téléphone",
            helperText: "Conformément à notre politique de confidentialité, cette information sera utilisée uniquement pour prendre contact avec vous si vous gagnez l'un de nos évenements pour la remise des prix."
        },
        {
            as: "guild",
            id: "field--guild",
            name: "guild",
            label: "École ou Entreprise",
            helperText: "Cette information servira pour les classements afin de représenter votre école ou votre entreprise !"
        },
        {
            as: "experience",
            id: "field--experience",
            name: "experience",
            label: "Expérience professionnelle (nombre d'années)",
            helperText: "L'expérience professionnelle concerne les jobs liés à l'informatique et inclut les stages. Cette information servira pour réaliser des statitisques."
        },
    ],
    defaultValues: {
        firstName: "",
        lastName: "",
        phone: "",
        guild: null,
        experience: null
    },
    verify: (fields: Record<string, any>) => {
        const errors: Record<string, any> = {};

        if (fields['firstName'].length === 0) {
            errors['firstName'] = "Veuillez entrez votre prénom.";
        }

        if (fields['lastName'].length === 0) {
            errors['lastName'] = "Veuillez entrez votre nom de famille.";
        }
        if (fields['phone'].length < 10) {
            errors['phone'] = "Veuillez entrez votre numéro de téléphone.";
        }

        return errors;
    }
});

export default accountInformationsStep;
