import React from 'react';
import { MojesiCity, CompletedSuccess, GiftCard, Spaceship, CodeTyping, LogoBattleDev, LogoOrnessDojo } from '../../../assets/assets';
import { ARTICLES_ROUTE } from '../../../infrastructure/globals/routes';
import DJCFooter from '../../components/footer';
import Countdown from './countdown';
import { HeroButtonAction } from './types';

interface IRenderProps {
    handleOnHeroButtonClicked: (action: HeroButtonAction) => () => void;
    isCountdownFinished: boolean;
    targetDate: number;
};

const Render = (props: IRenderProps) => {
    const {
        handleOnHeroButtonClicked,
        isCountdownFinished,
        targetDate
    } = props;

    return (
        <>
            <div className="djc--view-homepage">
                <div className="hero-container container-fluid">
                    <img
                        alt="hero-background"
                        src={MojesiCity}
                        className="hero-background"
                    />

                    <div className="heading">
                        <h3>
                            {
                                isCountdownFinished
                                    ? "MAINTENANT DISPONIBLE"
                                    : "BIENTÔT DISPONIBLE"
                            }
                        </h3>
                        <div className="orness-battle-dev-img-container">
                            <img
                                src={LogoBattleDev}
                                alt="orness battle dev 2023"
                            />
                        </div>

                        <Countdown
                            date={targetDate}
                        />

                        <button
                            onClick={handleOnHeroButtonClicked("more-info")}
                            className="header-btn-learn-more"
                        >
                            Plus D'informations
                        </button>
                    </div>
                </div>

                <section>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-12 col-sm-12 col-md-6 mb-16 mb-sm-0 order-2 order-md-1">
                                    <img
                                        src={CodeTyping}
                                        alt="Code Typing"
                                        className="illustration working-man-illustration"
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 order-1 order-md-2">
                                    <h2 className="section-title">
                                        Trouve un challenge qui te correspond
                                    </h2>
                                    <p className="mb-16">
                                        Nos campagnes ont été pensées pour inclure le plus grand nombre de passionnés. Que le développement soit ton métier, que tu sois débutant ou juste pratiquant la programmation comme hobby, tu trouveras forcément quelque chose pour mettre en œuvre et affiner tes compétences ici !<br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 mb-16 mb-sm-0 order-1 order-md-1">
                                    <h2 className="section-title">
                                        Progresse tout en t’amusant
                                    </h2>
                                    <p className="mb-16">
                                        Plonge-toi dans l’univers de nos campagnes en défiant tes amis, tes collègues et des développeurs de toute la France ! Sur dojo.codes, tu peux comparer ton score aux autres écoles et entreprises, ou battre simplement ton propre record pour atteindre de nouveaux objectifs.
                                    </p>
                                </div>

                                <div className="col-12 col-md-6 order-2 order-md-2">
                                    <img
                                        src={CompletedSuccess}
                                        alt="Completed Challenge"
                                        className="illustration lifting-man-illustration"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-12 col-sm-12 col-md-6 mb-16 mb-sm-0 order-2 order-md-1">
                                    <img
                                        src={GiftCard}
                                        alt="shipping package"
                                        className="illustration shipping-package-illustration"
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 mb-md-0 mb-16 order-1 order-md-2">
                                    <h2 className="section-title">
                                        Remporte l’un des nombreux lots
                                    </h2>
                                    <p className="mb-16">
                                        À part la reconnaissance de ton entourage et la gloire dans le monde des DevOps, nos campagnes te donnent la possibilité de remporter des prix en matériel informatique, gamer ou autre !
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="programming-languages">
                    <h2>Nous les supportons</h2>
                    <div className="content">
                        <i className="devicon-python-plain"></i>
                        <i className="devicon-bash-plain"></i>
                        <i className="devicon-c-plain"></i>
                        <i className="devicon-cplusplus-plain"></i>
                        <i className="devicon-java-plain"></i>
                        <i className="devicon-javascript-plain"></i>
                        <i className="devicon-csharp-plain"></i>
                        <i className="devicon-go-plain"></i>
                    </div>
                </section>

                <section className="and-now">
                    <img
                        src={Spaceship}
                        alt="spaceship"
                        className="background"
                    />

                    <div className="content">
                        <h2>Orness Battle Dev 2023</h2>

                        <p>
                            Inscrivez vous pour participer à cet événement
                            100% online<br /> <a href={`${ARTICLES_ROUTE}/orness-battle-dev-2022`}>Orness Battle Dev 2023</a>
                        </p>

                        <div className="container-fluid battle-dev-2022--arguments-container">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="battle-dev-2022--argument">
                                        <div className="top">
                                            <i className="fa fa-laptop-code" />

                                            <h3>Un Challenge</h3>
                                        </div>
                                        <p>Entraîne-toi tout en t’amusant, avec un challenge Dev inédit</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="battle-dev-2022--argument">
                                        <div className="top">
                                            <i className="fa fa-space-shuttle" />

                                            <h3>Du Fun</h3>
                                        </div>
                                        <p>Plonge-toi dans l’univers décalé Orness Battle Dev en défiant les développeurs de plusieurs écoles.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="battle-dev-2022--argument">
                                        <div className="top">
                                            <i className="fa fa-gift" />
                                            <h3>Des lots à gagner</h3>
                                        </div>
                                        <p>Tente de remporter une trotinette électrique !</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={handleOnHeroButtonClicked("more-info")}
                            className="btn-register-now"
                        >
                            Challenge Accepted !
                        </button>
                    </div>
                </section>
            </div>

            <DJCFooter
                dark
            />
        </>
    );
};

export default Render;
