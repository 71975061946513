import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationGetEnvironmentById, useMutationGetEnvironments } from "../../../../../../infrastructure/services/environments";
import { IEnvironment, IEnvironmentSummary } from "../../../../../../infrastructure/services/environments/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    name: string;
}>;

const AdminEnvironmentsListView = () => {
    const [fetchEnvironments] = useMutationGetEnvironments();
    const [fetchEnvironmentById] = useMutationGetEnvironmentById();

    const history = useHistory();

    const [environments, setEnvironments] = useState<Array<IEnvironmentSummary>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return environments.map((env) => ({
            id: env.id,
            name: env.name,
        }));
    }, [environments]);

    const getEnvironments = async (): Promise<IEnvironmentSummary[]> => {
        const resp = await fetchEnvironments({ only_visible: false });
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.environments;
    }

    const init = async () => {
        const environmentsResp = await getEnvironments();
        setEnvironments(environmentsResp);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push("/admin/environments/create");
    }

    const handleEdit = (ids: string[]) => {
        history.push(`/admin/environments/edit/${ids[0]}`);
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-cogs" />
                    <h1>Environnements</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCreate}>Créer un environnement</button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalEditClicked={handleEdit}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Environnements <CircularProgress className="inline-block-important" /></span> : `Environnements (${environments.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'name',
                            disablePadding: true,
                            label: "Nom",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'interpret_as',
                            disablePadding: true,
                            label: "Interpreteur",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'visible',
                            disablePadding: true,
                            label: "Visible",
                            numeric: false,
                            url: false,
                            boolean: true
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="id"
                fetchFunc={fetchEnvironmentById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminEnvironmentsListView;
