import React from 'react';
import { MojesiCity, OrnessOrangeBrand, WorkingMan } from '../../../assets/assets';
import DJCFooter from '../../components/footer';

const AboutView = () => {
    return (
        <>
            <div className="djc--view-about">
                {/* <header>
                    <img src={MojesiCity} alt="mojesi city background" />
                    <h1>
                        Dojo.Codes : Qu'est-ce que c'est ?
                    </h1>
                </header> */}
                <div className="content">
                    <section>
                        <h1 className="primary mb-16">
                            Dojo.Codes : Qu'est-ce que c'est ?
                        </h1>
                        <p>
                            Dojo Codes est une plateforme où l’on peut pratiquer la programmation, progresser et affiner ses compétences en code à l’aide de différentes activités ludiques. 
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Imaginé et créé par les ingénieurs Orness, Dojo Codes s’inscrit dans une tradition de proposer des rencontres et créer des liens, notamment en proposant aux passionnés d’appliquer et challenger leurs connaissances tout en s’amusant.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            En 2020, nous avons décidé qu’il était temps de se renouveler et proposer un nouveau jeu. Avec le contexte, un nouveau défi s’est présenté devant nous : pouvoir proposer un jeu à distance, accessible au plus grand nombre.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Grâce à la compétence et à l’implication de nos ingénieurs, nous avons réussi à le relever et à concevoir non seulement un jeu, mais un outil sur mesure : Dojo Codes by Orness.
                        </p>
                    </section>
                    <section className="img-right">
                        {/* <div className="img-container">
                            <img src={WorkingMan} alt="test" />
                        </div> */}
                        <h3>Pourquoi Dojo.Codes</h3>
                        <p>
                            Vous connaissez le dojo, le lieu consacré à la pratique des arts martiaux ou à la méditation ? Et bien, Dojo Codes est le lieu (virtuel) consacré à la pratique de la programmation, où l’on peut progresser et se perfectionner en continu.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Le dōjō est le lieu où l'on étudie et cherche la voie, où l'on progresse. Le symbole du dojo évoque l’assiduité, l’implication et l’enthousiasme, faisant écho aux valeurs d’Orness : audace, compétence, exigence, agilité. 
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Ajoutez à cela la créativité et le partage et vous avez Dojo Codes by Orness, la plateforme qui permet d’affiner ses compétences en s’amusant. 
                        </p>
                    </section>
                    <section className="img-right">
                        <div className="img-container">
                            <img src={OrnessOrangeBrand} alt="test" />
                        </div>
                        <h3>Qui est Orness ?</h3>
                        <p>
                            Orness est une société de conseil et d’ingénierie en transformation numérique fondée à Paris en 2001. Avec une structure à taille humaine et une culture agile, la communauté de nos collaborateurs est composée de talents qui s’investissent avec passion et exigence. 
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Traversant différents courants technologiques au cours de ces 20 dernières années, nous avons bâti notre excellence sur le talent de nos ingénieurs et leur capacité à s’adapter aux enjeux modernes. Nous nous sommes notamment révélés comme acteurs reconnus dans le domaine du DevOps, de l’infrastructure et des SI.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Envie d’en savoir plus ? Venez découvrir Orness sur notre site web ou nos pages de réseaux sociaux !
                        </p>
                        <br/>
                        <br/>
                    </section>
                </div>
            </div>
            
            <DJCFooter />
        </>
    );
};

export default AboutView;
