import {
    GlobalActionsTypes,
    TOGGLE_SIGN_IN_DIALOG,
    TOGGLE_LOGGED_DRAWER
} from './types';

export function toggleSignInDialog() : GlobalActionsTypes {
    return {
        type: TOGGLE_SIGN_IN_DIALOG,
        payload: null
    };
};

export function toggleLoggedDrawer() : GlobalActionsTypes {
    return {
        type: TOGGLE_LOGGED_DRAWER,
        payload: null
    };
};
