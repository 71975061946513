import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectScopes } from '../../../infrastructure/services/authentication/slice';
import { useGetSelf } from '../../../infrastructure/services/users';

interface IRoute {
    icon: string;
    href: string;
    label: string;
    scope: string | null;
}

interface ISection {
    label: string;
    scope: string | null;
    items: Array<IRoute>;
}

const NAV: Array<ISection> = [
    {
        label: "Dashboard",
        scope: null,
        items: [
            {
                icon: "fas fa-home",
                href: "/admin",
                label: "Dashboard",
                scope: null
            }
        ]
    },
    {
        label: "Campagnes",
        scope: "campaign_admin,challenge_admin",
        items: [
            {
                icon: "fas fa-gamepad",
                href: "/admin/campaigns",
                label: "Campagnes",
                scope: "campaign_admin"
            },
            {
                icon: "fas fa-bug",
                href: "/admin/challenges",
                label: "Challenges",
                scope: "challenge_admin"
            },
            {
                icon: "fas fa-cogs",
                href: "/admin/environments",
                label: "Environnements",
                scope: "environment_admin"
            }
        ]
    },
    {
        label: "Articles",
        scope: "page_admin",
        items: [
            {
                icon: "far fa-newspaper",
                href: "/admin/articles",
                label: "Articles",
                scope: "page_admin"
            }
        ]
    },
    {
        label: "Utilisateurs",
        scope: "users_admin",
        items: [
            {
                icon: "fas fa-users",
                href: "/admin/users",
                label: "Utilisateurs",
                scope: "users_admin"
            },
            {
                icon: "fas fa-users",
                href: "/admin/temp_accounts",
                label: "Comptes temporaires",
                scope: "users_admin"
            },
            {
                icon: "fas fa-key",
                href: "/admin/PAT",
                label: "PAT",
                scope: "users_admin"
            }
        ]
    },
    {
        label: "Scoring",
        scope: "score_admin",
        items: [
            {
                icon: "fas fa-cog",
                href: "/admin/scoring/rules",
                label: "Règles",
                scope: "score_admin"
            },
            {
                icon: "fas fa-trophy",
                href: "/admin/scoring/leaderboard",
                label: "Leaderboard",
                scope: "score_admin"
            }
        ]
    },
    {
        label: "Autres",
        scope: null,
        items: [
            {
                icon: "fas fa-tag",
                href: "/admin/others/tags",
                label: "Tags",
                scope: "tags_admin"
            },
            {
                icon: "fas fa-database",
                href: "/admin/others/actions",
                label: "Actions",
                scope: "actions_admin"
            }
        ]
    }
]

interface IProps {
    children: any;
}

const AdminView = (props: IProps) => {
    const history = useHistory();

    const scopes = useSelector(selectScopes);
    const [dirty, setDirty] = React.useState(true);

    const [fetchSelf] = useGetSelf();

    const isVisibleWithScopes = useCallback((givenScopesStr: string | null) => {
        if (!givenScopesStr) {
            return true;
        }

        const requiredScopes = givenScopesStr.split(',');
        return requiredScopes.every(scope => scopes.includes(scope));
    }, [scopes]);

    const isActiveLink = (link: string) => {
        if (link === "/admin") {
            if (history.location.pathname === "/admin") {
                return "selected"
            }

            return "";
        }

        return history.location.pathname.includes(link) ? "selected" : ""
    }

    const init = async () => {
        const resp = await fetchSelf();

        if (!resp.data || !["Admin", "Owner", "Moderator"].includes(resp.data.role)) {
            history.push("/");
            return;
        }

        setDirty(false);
    };

    useEffect(() => {
        init();
    }, []);

    if (dirty) {
        return null;
    }

    return (
        <div className="djc--view-admin">
            <div className="side-nav">
                {
                    NAV.map((nav) => (
                        isVisibleWithScopes(nav.scope) && (
                            <section key={nav.label} className="section">
                                <h4>{nav.label}</h4>
                                {
                                    nav.items.map((item) => (
                                        <>
                                            {
                                                isVisibleWithScopes(item.scope) && (
                                                    <Link
                                                        key={`${item.label}-${item.href}`}
                                                        to={item.href}
                                                        className={`item ${isActiveLink(item.href)}`}
                                                    >
                                                        <i className={item.icon}></i>
                                                        {item.label}
                                                    </Link>
                                                )
                                            }
                                        </>
                                    ))
                                }
                            </section>
                        )
                    ))
                }
            </div>
            <div className="content">
                {
                    props.children
                }
            </div>
        </div>
    );
};

export default AdminView;
