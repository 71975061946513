import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationDeleteTagById, useMutationGetTagById, useMutationGetTagList } from "../../../../../../infrastructure/services/tags";
import { ITag } from "../../../../../../infrastructure/services/tags/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    name: string;
    icon: string;
}>;

const AdminTagsListView = () => {
    const [fetchTags] = useMutationGetTagList();
    const [deleteTagById] = useMutationDeleteTagById();
    const [fetchTagById] = useMutationGetTagById();

    const history = useHistory();

    const [tags, setTags] = useState<Array<ITag>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
            icon: tag.icon
        }));
    }, [tags]);

    const getTags = async (): Promise<ITag[]> => {
        const resp = await fetchTags("UserTags");
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.tags;
    }

    const init = async () => {
        const tagsResp = await getTags();
        setTags(tagsResp);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteTagById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...tags].filter(a => !idsToDelete.includes(a.id));

        setTags(next);
        setFetching(false);
    }

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push("/admin/others/tags/create");
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-tag" />
                    <h1>Tags</h1>
                </div>

                <div className="actions">
                    <button
                        onClick={handleCreate}
                    >
                        Créer un tag
                    </button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Tags <CircularProgress className="inline-block-important" /></span> : `Tags (${tags.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'name',
                            disablePadding: true,
                            label: "Nom",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'icon',
                            disablePadding: true,
                            label: "Icon",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="name"
                fetchFunc={fetchTagById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminTagsListView;
