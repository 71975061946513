import React from 'react';
import DJCFooter from '../../components/footer';
import Nav, { INavLink } from '../../components/nav';

interface RenderProps {
    options: Record<string, INavLink>;
    children: any;
    currentOption: string;
};

const Render = (props: RenderProps) => {
    const {
        options,
        currentOption,
        children
    } = props;

    return (
        <>
            <div className="djc--view-settings">
                <div className="settings--root">
                    <div className="container-fluid">
                        <Nav
                            current={currentOption}
                            links={Object.values(options)}
                        />

                        { children }
                    </div>
                </div>
            </div>
            <DJCFooter />
        </>
    );
};

export default Render;
