import React from 'react';

import { Link } from 'react-router-dom';

interface IRenderProps {
    dark: boolean;
}

const Render = (props: IRenderProps) => {
    const { dark } = props;

    const today = new Date();

    const bgThemeCss = dark ? "dark" : "light";

    return (
        <section className={`djc--footer ${bgThemeCss}`}>
            <section className="djc--footer-section">
                <Link to={"/"}>
                    Accueil
                </Link>
                <Link to={"/articles"}>
                    Actualités
                </Link>
                <Link to={"/cgu"}>
                    CGU
                </Link>
                <Link to={"/about"}>
                    A Propos
                </Link>
                <Link to={"/contact"}>
                    Contact
                </Link>
            </section>
            
            <section className="djc--footer-section legals">
                <span>
                    © { today.getUTCFullYear() } Dojo.Codes (v{(process.env.REACT_APP_VERSION || "").trim()}) by 
                    <a target="_blank" rel="noreferrer" href="https://www.orness.com/">
                        Orness
                    </a> - Fait avec ♡
                </span>
            </section>
        </section>
    );
};

export default Render;
