import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationDeleteCampaignById, useMutationGetCampaignByIdAdmin, useMutationGetCampaignsList } from "../../../../../../infrastructure/services/campaign";
import { ICampaignSummary } from "../../../../../../infrastructure/services/campaign/types";
import { getHumanizedDateTime } from "../../../../../../infrastructure/utils/dates";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    name: string;
    mode: string;
    max_participants: number | string;
    duration: number | string;
    start_date: string;
    end_date: string;
    route_name: string;
    associated_scoreboard: string;
    is_protected: string;
    participants_amount: number;
}>;

const AdminCampaignsListView = () => {
    const [fetchCampaigns] = useMutationGetCampaignsList();
    const [deleteCampaignById] = useMutationDeleteCampaignById();
    const [fetchCampaignDetailById] = useMutationGetCampaignByIdAdmin();

    const history = useHistory();

    const [campaigns, setCampaigns] = useState<Array<ICampaignSummary>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return campaigns.map((campaign) => ({
            id: campaign.id,
            name: campaign.name,
            mode: campaign.mode,
            max_participants: campaign.max_participants || "null",
            duration: campaign.duration || "null",
            start_date: getHumanizedDateTime(campaign.start_date),
            end_date: campaign.expiration_date ? getHumanizedDateTime(campaign.expiration_date) : "null",
            route_name: `/campaigns/${campaign.route_name}`,
            associated_scoreboard: campaign.associated_scoreboard || "null",
            is_protected: campaign.is_protected ? "true" : "false",
            participants_amount: campaign.participants_amount
        }));
    }, [campaigns]);

    const getCampaigns = async (): Promise<ICampaignSummary[]> => {
        const resp = await fetchCampaigns();

        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.campaigns;
    }

    const init = async () => {
        const campaignsResp = await getCampaigns();
        setCampaigns(campaignsResp);
        setFetching(false);
    };

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteCampaignById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...campaigns].filter(a => !idsToDelete.includes(a.id));

        setCampaigns(next);
        setFetching(false);
    }

    useEffect(() => {
        init();
    }, []);

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push('/admin/campaigns/create');
    };

    const handleEdit = (ids: string[]) => {
        history.push(`/admin/campaigns/edit/${ids[0]}`);
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-gamepad" />
                    <h1>Campagnes</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCreate}>Créer une campagne</button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalEditClicked={handleEdit}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Campagnes <CircularProgress className="inline-block-important" /></span> : `Campagnes (${campaigns.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'name',
                            disablePadding: true,
                            label: "Nom",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'mode',
                            disablePadding: true,
                            label: "Mode",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "max_participants",
                            disablePadding: true,
                            label: "Max. J.",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "duration",
                            disablePadding: true,
                            label: "Durée",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "start_date",
                            disablePadding: true,
                            label: "Début",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "end_date",
                            disablePadding: true,
                            label: "Fin",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "route_name",
                            disablePadding: true,
                            label: "Route",
                            numeric: false,
                            url: true,
                            boolean: false
                        },
                        {
                            id: "associated_scoreboard",
                            disablePadding: true,
                            label: "Scoreboard",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "is_protected",
                            disablePadding: true,
                            label: "Protégé",
                            numeric: false,
                            url: false,
                            boolean: true
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="name"
                fetchFunc={fetchCampaignDetailById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminCampaignsListView;
