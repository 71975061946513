import React from 'react';

import MuiPhoneNumber from 'material-ui-phone-number';
import { TextField } from '@material-ui/core';
import DJCFooter from '../../components/footer';
import { DojoCodesBrandLogo } from '../../../assets/assets';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { schoolsMocked } from '../../mock/schools';

interface IRenderProps {
    handleInputChanged: (n: string) => (e: any) => void;
    handleSubmit: () => void;
    errors: Record<string, string>;
};

const Render = (props: IRenderProps) => {
    const {
        handleInputChanged,
        handleSubmit,
        errors
    } = props;

    return (
        <>
            <div className="djc--view-reset-phone-guild-content">
                <section className="djc--view-reset-phone-guild-topbar">
                    <div className="djc--header-logo">
                        <img src={DojoCodesBrandLogo} alt="brand logo" />
                        <h1>
                            <a href="/">
                                <span className="dark">Dojo</span><span className="primary">.Codes</span>
                            </a>
                        </h1>
                    </div>
                </section>
                <section className="djc--view-reset-phone-guild-form">
                    <h1>Mettre à jour votre numéro de téléphone et votre guild</h1>
                    {
                        /*
                        isLoginError && (
                            <span className="invalid-credentials">Invalid credentials please verify them and retry</span>
                        )
                        */
                    }
                    <form>
                        <MuiPhoneNumber
                            defaultCountry={'fr'}
                            variant="outlined"
                            fullWidth
                            className="input"
                            id="input-phone"
                            name={"phone"}
                            label={"Numéro de téléphone"}
                            regions="europe"
                            onChange={handleInputChanged("phone")}
                            error={errors['phone']}
                            helperText={
                                errors['phone'] !== undefined
                                    ? errors['phone']
                                    : ""
                            }
                        />
                        <Autocomplete
                            id="input-guild"
                            className="input"
                            fullWidth
                            onChange={(_, v) => handleInputChanged("guild")(v)}
                            freeSolo
                            options={schoolsMocked.map((school) => {
                                if (school.label && school.short_name) {
                                    return `${school.label} (${school.short_name})`;
                                }

                                if (school.label) {
                                    return school.label;
                                }

                                if (school.short_name) {
                                    return school.short_name;
                                }
                            })}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    name="guild"
                                    label="Guild"
                                    onChange={handleInputChanged("guild")}
                                    onBlur={handleInputChanged("guild")}
                                    variant="outlined"
                                    className="input"
                                    fullWidth
                                    error={errors['guild']}
                                    helperText={
                                        errors['guild'] !== undefined
                                            ? errors['guild']
                                            : ""
                                    }
                                />
                            )}
                        />
                    </form>

                    <div className="djc--view-reset-phone-guild-form-actions">
                        <button
                            onClick={handleSubmit}
                            disabled={Object.keys(errors).length > 0}
                        >
                            Modifier
                        </button>
                    </div>
                </section>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
