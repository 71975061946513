import React, { useMemo, useState } from 'react';

import AccountInformationsStep from './steps/account_informations';
import PersonnalInformationsStep from './steps/personnal_informations';

import Render from './render';

import MuiPhoneNumber from 'material-ui-phone-number';
import toast from 'toasted-notes';
import { useMutationPostRegister, usePostAutoToken } from '../../../infrastructure/services/authentication';
import Toast from '../../components/toast';
import { useDispatch } from 'react-redux';
import { setAccessToken, setScopes } from '../../../infrastructure/services/authentication/slice';
import { saveCredentialsOnLocalStorage, saveScopesOnLocalStorage } from '../../../infrastructure/services/authentication/utils';
import { setCurrentUser } from '../../../infrastructure/services/users/slice';
import { useGetSelf } from '../../../infrastructure/services/users';
import { ISignUpStepField } from './types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { schoolsMocked } from '../../mock/schools';

export interface IFormInputs {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
    phone: string | null;
    experience: string | null;
};

const SignupView = () => {

    const [fetchRegister] = useMutationPostRegister();
    const [fetchLogin] = usePostAutoToken();
    const [getSelf] = useGetSelf();

    const dispatch = useDispatch();

    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [activeStepIdx, setActiveStepIdx] = useState<number>(0);

    const [errors, setErrors] = useState<Record<string, string>>({});

    const steps = React.useMemo(() => ([
        AccountInformationsStep,
        PersonnalInformationsStep
    ]), []);

    const activeStep = React.useMemo(() => {
        if (activeStepIdx < 0) {
            return steps[0];
        }

        if (activeStepIdx >= steps.length) {
            return steps[steps.length - 1];
        }

        return steps[activeStepIdx];
    }, [activeStepIdx, steps]);

    const [fields, setFields] = useState<Record<string, any>>({ ...activeStep.defaultValues });
    const [values, setValues] = useState<Record<string, any>>({ ...activeStep.defaultValues });

    React.useEffect(() => {
        setErrors({});

        const nextFields = ({ ...steps[activeStepIdx].defaultValues });
        Object.keys(activeStep.defaultValues).forEach((key) => {
            if (values[key] !== undefined) {
                nextFields[key] = values[key];
            }
        });
        setFields(nextFields);

        let updated = false;
        const nextValues = { ...values };

        Object.keys(activeStep.defaultValues).forEach((key) => {
            if (nextValues[key] === undefined) {
                nextValues[key] = activeStep.defaultValues[key];
                updated = true;
            }
        });

        if (updated) {
            setValues(nextValues);
        }
    }, [activeStepIdx]);

    const isBackDisabled = useMemo(() => {
        return activeStepIdx === 0;
    }, [activeStepIdx]);

    const handleBack = () => {
        if (activeStepIdx > 0) {
            setActiveStepIdx(last => last - 1);
        }
    };

    const onSubmit = async () => {
        setSubmitDisabled(true);

        const errors = activeStep.verify(fields);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setSubmitDisabled(false);

            return;
        }

        if (activeStepIdx < steps.length - 1) {
            setValues(last => ({ ...last, ...fields }));
            setActiveStepIdx(last => last + 1);
            setSubmitDisabled(false);
            return;
        }

        const finalValues = ({
            ...values,
            ...fields
        });
        const resp = await fetchRegister({
            username: finalValues['username'],
            email: finalValues['email'],
            password: finalValues['password'],
            first_name: finalValues['firstName'],
            last_name: finalValues['lastName'],
            phone: finalValues['phone'],
            guild: finalValues['guild'],
            experience: finalValues['experience'],
            registration_actions: []
        });

        if (resp.error) {
            const error = (resp.error as any);
            if (error.status === 409) {
                toast.notify(
                    <Toast
                        text={error.data.detail.message}
                        type="alert"
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
                switch (error.data.detail.error) {
                    case "UsernameAlreadyExists":
                        setErrors({
                            username: "Le nom d'utilisateur existe déjà."
                        });
                        break;
                    case "EmailAlreadyExists":
                        setErrors({
                            email: "L'email utilisé existe déjà."
                        });
                        break;
                    default:
                        toast.notify(
                            <Toast
                                text="Error during account creation. Please verify your informations."
                                type="alert"
                            />,
                            {
                                duration: 10000,
                                position: "top"
                            }
                        );
                        break;
                }
            } else {
                toast.notify(
                    <Toast
                        text="Unexpected Error. Please try again later."
                        type="alert"
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );
            }
        } else if (resp.data) {
            const resultLogin = await fetchLogin({
                username: finalValues['username'],
                password: finalValues['password']
            });

            if (resultLogin.data) {
                toast.notify(
                    <Toast
                        text="Your account is created. Please check your email to verify."
                    />,
                    {
                        duration: 10000,
                        position: "top"
                    }
                );

                dispatch(setAccessToken({
                    access_token: resultLogin.data.access_token
                }));
                dispatch(setScopes({
                    scopes: resultLogin.data.scopes
                }));

                saveCredentialsOnLocalStorage(resultLogin.data, fields['username']);
                saveScopesOnLocalStorage(resultLogin.data.scopes);

                const selfResult = await getSelf();
                if (selfResult.data) {
                    dispatch(setCurrentUser(selfResult.data));
                }
            }
        }

        setSubmitDisabled(false);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        const nextFields = {
            ...fields,
            [name]: value
        };

        setFields(nextFields);
    }

    const handleChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        const nextFields = {
            ...fields,
            [name]: value
        };

        const nextValues = {
            ...values,
            [name]: value
        };

        setFields(nextFields);
        setValues(nextValues);
    }

    const generateField = (field: ISignUpStepField): JSX.Element | null => {
        switch (field.as) {
            case "phone":
                return (
                    <MuiPhoneNumber
                        defaultCountry={'fr'}
                        variant="outlined"
                        fullWidth
                        className="input"
                        onBlur={handleBlur}
                        id={field.id}
                        name={field.name}
                        label={field.label}
                        error={errors[field.name] !== undefined}
                        regions="europe"
                        helperText={
                            field.helperText && errors[field.name] === undefined
                                ? field.helperText
                                : (
                                    errors[field.name] !== undefined
                                        ? errors[field.name]
                                        : null
                                )
                        }
                    />
                )
            case "guild":
                return (
                    <Autocomplete
                        id={field.id}
                        className="input"
                        freeSolo
                        options={schoolsMocked.map((school) => {
                            if (school.label && school.short_name) {
                                return `${school.label} (${school.short_name})`;
                            }

                            if (school.label) {
                                return school.label;
                            }

                            if (school.short_name) {
                                return school.short_name;
                            }
                        })}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                name={field.name}
                                label={field.label}
                                variant="outlined"
                                className="input"
                                onBlur={handleBlur}
                                error={errors[field.name] !== undefined}
                                helperText={
                                    field.helperText && errors[field.name] === undefined
                                        ? field.helperText
                                        : (
                                            errors[field.name] !== undefined
                                                ? errors[field.name]
                                                : null
                                        )
                                }
                            />
                        )}
                    />
                )
            case "experience":
                return (<TextField
                    id="input-experience"
                    name="experience"
                    label="Expérience professionnelle (nombre d'années)"
                    value={values.experience}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.experience !== undefined}
                    variant="outlined"
                    helperText={
                        field.helperText && errors[field.name] === undefined
                            ? field.helperText
                            : (
                                errors[field.name] !== undefined
                                    ? errors[field.name]
                                    : null
                            )
                    }

                />)
            case "string":
            case "email":
            case "password": {
                return (
                    <TextField
                        key={field.id}
                        id={field.id}
                        name={field.name}
                        label={field.label}
                        type={field.as}
                        value={values[field.name]}
                        defaultValue={null}
                        variant="outlined"
                        fullWidth
                        className="input"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors[field.name] !== undefined}
                        helperText={
                            errors[field.name] !== undefined
                                ? errors[field.name]
                                : null
                        }
                    />
                )
            }
            default: {
                return null;
            }
        }
    }

    return (
        <Render
            onSubmit={onSubmit}
            errors={errors}
            isSignUpButtonDisabled={isSubmitDisabled}
            steps={steps}
            activeStep={activeStep}
            activeStepIdx={activeStepIdx}
            isBackDisabled={isBackDisabled}
            handleBack={handleBack}
            generateField={generateField}
        />
    );
};

export default SignupView;
