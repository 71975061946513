import React, { useRef, useState } from 'react';
import * as yup from "yup";
import toast from 'toasted-notes';
import { useMutationPostCreatePage } from '../../../../infrastructure/services/pages';

import Render from './render';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormInputs } from './types';
import { useForm } from 'react-hook-form';
import { slugify } from '../../../../infrastructure/utils/slugify';
import { useHistory } from 'react-router-dom';
import { ARTICLES_ROUTE } from '../../../../infrastructure/globals/routes';
import Toast from '../../../components/toast';
import WYSIWYGEditor from '../../../components/wysiwyg_editor';

const defaultValues: IFormInputs = {
    title: "",
    content: "",
    tags: "news",
    route_name: "",
    visible: true
};

const schema = yup.object().shape({
    title: yup.string().required(),
    content: yup.string(),
    tags: yup.string().required(),
    route_name: yup.string().required(),
    visible: yup.boolean(),
});

const resolver = async (values: IFormInputs) => (
    yupResolver(schema)((values as any))
);

const ArticleCreateView = () => {
    const editorRef = useRef<WYSIWYGEditor | null>(null);

    const history = useHistory();

    const [ createPage ] = useMutationPostCreatePage();

    const [savedValues, setSavedValues] = useState<IFormInputs>({...defaultValues});
    const [globalError, setGlobalError] = useState<string | null>(null);

    const {
        control,
        handleSubmit,
        errors,
        setError
    } = useForm<IFormInputs>({
        mode: "onBlur",
        defaultValues: defaultValues,
        resolver: resolver as any
    });

    const onSubmit = async (data: IFormInputs) => {
        const content = await editorRef.current?.fromModelToMd()
        
        const body = {
            id: data.title,
            title: data.title,
            content: content || "",
            route_name: slugify(data.route_name),
            tags: [data.tags],
            visible: defaultValues.visible
        };

        const results = await createPage(body);

        if (results.data) {
            history.push(`${ARTICLES_ROUTE}/${body.route_name}`);
        }

        if (results.error) {
            const error = results.error as any;
            const { detail } = error.data;

            toast.notify(
                <Toast
                    text="Error during page creation. Please verify or try again later."
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "top"
                }
            );

            if (detail.error === "PageRouteAlreadyExists") {
                setError("route_name", {
                    message: detail.message,
                    shouldFocus: true
                });
            }
            setGlobalError(detail.message);
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const target: HTMLInputElement = (event.target as HTMLInputElement);

        if (!Object.keys(savedValues).includes(target.name)) {
            return;
        }

        if ((savedValues as any)[target.name] === target.value) {
            return;
        }

        let nextValue = target.value;
        if (target.name === "route_name") {
            nextValue = slugify(target.value);
        }

        setSavedValues(last => ({
            ...last,
            [target.name]: nextValue
        }));
    };

    return (
        <Render
            ref={editorRef}
            control={control}
            errors={errors}
            globalError={globalError}
            savedValues={savedValues}
            isCreatingPage={false}
            handleBlur={handleBlur}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}

        />
    );
};

export default ArticleCreateView;
