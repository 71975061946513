const LOCAL_STORAGE_CODE_EDITOR_KEYS = {
    theme: "code_editor__theme",
    font_size: "code_editor__font_size",
    tab_spaces: "code_editor__tab_spaces"
};

export const LOCAL_STORAGE_KEYS = {
    code_editor: {
        ...LOCAL_STORAGE_CODE_EDITOR_KEYS
    }
};
