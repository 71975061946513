import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../infrastructure/services/users/slice';
import Gravatar from '../../../components/gravatar';
import md5 from 'crypto-js/md5';
import MuiPhoneNumber from 'material-ui-phone-number';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { schoolsMocked } from '../../../mock/schools';
import PasswordField from '../../../components/password_field';
import { useMutationUpdateSelfUser } from '../../../../infrastructure/services/users';
import useForm, { generateError, IError } from '../../../hooks/form/useForm';
import Toast from '../../../components/toast';
import toast from 'toasted-notes';

type Inputs = {
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
    guild: string;
    experience: string;
    password_confirm: string;
}

const AccountNav = () => {
    const currentUser = useSelector(selectCurrentUser);

    const [updateSelf] = useMutationUpdateSelfUser();
    const [fetching, setFetching] = React.useState(false);

    const handleValidate = useCallback((v: Inputs) => {
        let errors: IError<Inputs> = {};
        const passwordReg = /^(?=.*[A-Z]){1,}(?=.*\d)(?=.*[^\W])[A-Za-z\d_\W]{3,}$/g;

        errors = generateError<Inputs>(errors, "last_name", v.last_name.length < 1, "Le nom de famille doit contenir au moins un caractère");
        errors = generateError<Inputs>(errors, "first_name", v.first_name.length < 1, "Le prénom doit contenir au moins un caractère");
        if (v.password.length || v.password_confirm.length) {
            errors = generateError<Inputs>(errors, "password", v.password.match(passwordReg) === null, "Mot de passe invalide. 1 majuscule, 1 chiffre and 1 caractère spécial minimum");
            errors = generateError<Inputs>(errors, "password", v.password !== v.password_confirm, "Mot de passe différents");
            errors = generateError<Inputs>(errors, "password_confirm", v.password !== v.password_confirm, "Mot de passe différents");
        }

        return errors;
    }, []);

    const {
        values,
        handleBlur,
        handleChange,
        handleChangeSingleAutocomplete,
        handleChangeSpecificFieldWithValue,
        updateValues,
        errors,
        containsError,
        fulfilled,
        containsModification
    } = useForm<Inputs>({
        defaultValues: {
            first_name: currentUser?.first_name || "",
            guild: currentUser?.guild || "",
            experience: currentUser?.experience || "",
            last_name: currentUser?.last_name || "",
            phone: currentUser?.phone || "",
            password: "",
            password_confirm: ""
        },
        validate: handleValidate,
    });

    const handleSave = async () => {
        if (Object.values(errors).length) {
            return;
        }

        setFetching(true);
        const resp = await updateSelf({
            username: currentUser?.username || "",
            body: {
                first_name: values.first_name ? values.first_name.trim() : undefined,
                last_name: values.last_name ? values.last_name.trim() : undefined,
                guild: values.guild ? values.guild.trim() : undefined,
                experience: values.experience ? values.experience.trim() : undefined,
                password: values.password && values.password.length > 0 ? values.password : undefined,
                phone: values.phone ? values.phone.trim() : undefined
            }
        });
        setFetching(false);

        if (resp.status === "fulfilled") {
            toast.notify(
                <Toast text={"Modifications sauvegardées."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );
            const nextDefaultValues: Inputs = {
                first_name: values.first_name ? values.first_name.trim() : "",
                last_name: values.last_name ? values.last_name.trim() : "",
                guild: values.guild ? values.guild.trim() : "",
                experience: values.experience ? values.experience.trim() : "",
                phone: values.phone ? values.phone.trim() : "",
                password: "",
                password_confirm: ""
            };

            updateValues(nextDefaultValues);
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    }

    return (
        <div className="container pt-2">
            <div className="page-section row">
                <div className="col-12 col-lg-6">
                    <div className="container-fluid">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div className="d-flex gravatar-container">
                                <Gravatar
                                    alt="gravatar user"
                                    hash={
                                        currentUser && currentUser.email
                                            ? md5(currentUser.email.toLowerCase().trim()).toString()
                                            : ""
                                    }
                                />
                            </div>
                            <div className="d-flex flex-column ml-16">
                                <h5>Changer la photo</h5>
                                <span>Pour changer votre avatar, merci de vous rendre sur <a href="https://gravatar.com/" rel="noreferrer" target="_blank">gravatar</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section">
                <h3>Mon profil</h3>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-16">
                            <TextField
                                id="input-username"
                                name="username"
                                label="Nom d'utilisateur"
                                value={currentUser?.username}
                                fullWidth
                                variant="outlined"
                                disabled
                                required
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <TextField
                                id="input-first-name"
                                name="first_name"
                                label="Prénom"
                                value={values.first_name}
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.first_name !== undefined}
                                helperText={errors.first_name?.message}
                                variant="outlined"
                                required
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <TextField
                                id="input-last-name"
                                name="last_name"
                                label="Nom"
                                value={values.last_name}
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.last_name !== undefined}
                                helperText={errors.last_name?.message}
                                variant="outlined"
                                required
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <TextField
                                id="input-experience"
                                name="experience"
                                label="Expérience professionnelle (nombre d'années)"
                                value={values.experience}
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.experience !== undefined}
                                helperText={errors.experience?.message}
                                variant="outlined"
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <MuiPhoneNumber
                                defaultCountry={'fr'}
                                variant="outlined"
                                fullWidth
                                id="input-phone"
                                name={"phone"}
                                label={"Numéro de téléphone"}
                                regions="europe"
                                value={values.phone}
                                error={errors.phone !== undefined}
                                helperText={errors.phone?.message}
                                onChange={handleChangeSpecificFieldWithValue("phone")}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <Autocomplete
                                id="input-guild"
                                fullWidth
                                freeSolo
                                value={values.guild}
                                onChange={handleChangeSingleAutocomplete("guild")}
                                options={schoolsMocked.map<string>((school) => {
                                    if (school.label && school.short_name) {
                                        return `${school.label} (${school.short_name})`;
                                    }

                                    if (school.label) {
                                        return school.label;
                                    }

                                    if (school.short_name) {
                                        return school.short_name;
                                    }

                                    return school.short_name;
                                })}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        name="guild"
                                        label="École ou Travail"
                                        variant="outlined"
                                        fullWidth
                                        error={errors.guild !== undefined}
                                        helperText={errors.guild?.message}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section">
                <h3>Sécurité</h3>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-16">
                            <PasswordField
                                id="input-password"
                                name="password"
                                label="Mot de passe"
                                helperText={errors.password ? errors.password.message : undefined}
                                inputProps={{
                                    fullWidth: true,
                                    required: false,
                                    value: values.password,
                                    error: errors.password !== undefined,
                                    onChange: handleChange,
                                    inputProps: {
                                        autoComplete: 'new-password'
                                    }
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-16">
                            <PasswordField
                                id="input-password-confirm"
                                name="password_confirm"
                                label="Mot de passe (vérification)"
                                helperText={errors.password_confirm ? errors.password_confirm.message : undefined}
                                inputProps={{
                                    fullWidth: true,
                                    required: false,
                                    value: values.password_confirm,
                                    error: errors.password_confirm !== undefined,
                                    onChange: handleChange,
                                    inputProps: {
                                        autoComplete: 'new-password'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-section">
                <h3>Valider</h3>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-16">
                            <button
                                className="rainbow rounded"
                                onClick={handleSave}
                                disabled={fetching || !containsModification || containsError || !fulfilled}
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountNav;