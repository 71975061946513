import { Avatar } from '@material-ui/core';
import React from 'react';

interface IProps {
    readonly hash: string;
    alt: string;
}

const Gravatar = (props: IProps) => {
    return (
        <Avatar 
            className="gravatar"
            alt={props.alt} 
            src={`https://www.gravatar.com/avatar/${props.hash}?d=mp`}
        />
    );
};

export default Gravatar;
