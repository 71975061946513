import React, { ChangeEvent } from 'react';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { ICampaignSummary, ICampaignParticipantStatusEntry } from '../../../../infrastructure/services/campaign/types';
import DJCFooter from '../../../components/footer';
import { JOIN_CAMPAIGN, JOIN_CAMPAIGN_WITH_PASSWORD, LEADERBOARD, LEAVE_CAMPAIGN, PARTICIPATE, SHOW_RESULTS } from './types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, Tabs, TextField } from '@material-ui/core';
import Markdown from '../../../components/markdown';
import { IScoreboardResults } from '../../../../infrastructure/services/scoring/types';
import Scoreboard from '../../../components/scoreboard';
import { stringifyDuration } from '../../../../infrastructure/utils/dates';
import CodeEditor, { getDefaultCodeConfig } from '../participate/code_editor';

interface IRenderProps {
    campaign: ICampaignSummary;
    userAlreadyParticipating: boolean;
    isJoinButtonDisabled: { disabled: boolean, reason: string | null };
    isPasswordDialogOpen: boolean;
    passwordError: string | null;
    password: string | null;
    isParticipateDisabled: { disabled: boolean, reason: string | null };
    isCampaignDone: boolean;
    scoreboard: IScoreboardResults | null;
    username: string | null;
    participantStatus: Array<ICampaignParticipantStatusEntry>;
    getHumanizedDuration: (d: number) => string;
    getHumanizedDate: (s: string) => string;
    getStringifiedParticipants: (a: string[], b: number | null) => string;
    isScopeInlcuded: (s: string) => boolean;
    isOneScopeIncluded: (...s: string[]) => boolean;
    handleUserAction: (a: string) => () => void;
    handleAdminAction: (a: string) => () => void;
    handleClosePasswordDialog: () => void;
    handlePasswordChanged: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Render = (props: IRenderProps) => {

    const {
        campaign,
        userAlreadyParticipating,
        isJoinButtonDisabled,
        isPasswordDialogOpen,
        passwordError,
        password,
        isParticipateDisabled,
        isCampaignDone,
        scoreboard,
        username,
        participantStatus,
        getHumanizedDuration,
        getHumanizedDate,
        getStringifiedParticipants,
        handleUserAction,
        handleClosePasswordDialog,
        handlePasswordChanged
    } = props;

    const [tabValue, setTabValue] = React.useState<number>(1);
    return (
        <>
            <div className="djc--view-campaign-detail">
                <header>
                    <img
                        src={campaign.thumbnail || "https://picsum.photos/1920/600?random=2"}
                        alt="campaign header"
                    />

                    <div className="header-desc">
                        <h1>{campaign.name}</h1>
                    </div>
                </header>

                <div className="tabs">
                    <Tabs
                        value={tabValue}
                        onChange={(_, v) => setTabValue(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        variant="fullWidth"
                        className="tabs-comp"
                    >
                        <Tab
                            label="Campagne"
                            title="Informations de la campagne"
                            value={1}
                        />
                        <Tab
                            label="Leaderboard"
                            title="Scores associés à la campagne"
                            value={2}
                            disabled={scoreboard === null}
                        />
                        <Tab
                            label="Soumissions"
                            title="Historique du code soumis durant la campagne"
                            value={3}
                            disabled={participantStatus.length === 0}
                        />
                    </Tabs>
                </div>
                <div className="content">
                    {
                        tabValue === 1 && (
                            <div className="container-fluid bg-blank pd-16">
                                <div className="row">
                                    <div className="col-md-4 order-first order-md-last desc-col">
                                        <div className="desc-section">
                                            <h3>Rejoindre</h3>
                                            {
                                                userAlreadyParticipating ? (
                                                    <button
                                                        className="outlined block mb-4 fullwidth"
                                                        onClick={handleUserAction(LEAVE_CAMPAIGN)}
                                                    >
                                                        Quitter la campagne
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="outlined block mb-4 fullwidth"
                                                        onClick={handleUserAction(JOIN_CAMPAIGN)}
                                                        disabled={isJoinButtonDisabled.disabled}
                                                        title={isJoinButtonDisabled.reason || ""}
                                                    >
                                                        Rejoindre la campagne
                                                        {
                                                            (campaign.is_protected || isJoinButtonDisabled.disabled) && (
                                                                <LockOutlinedIcon className="ml-4" />
                                                            )
                                                        }
                                                    </button>
                                                )
                                            }
                                            {
                                                userAlreadyParticipating && (
                                                    <button
                                                        className="block mb-4 fullwidth"
                                                        onClick={handleUserAction(PARTICIPATE)}
                                                        disabled={isParticipateDisabled.disabled}
                                                        title={isParticipateDisabled.reason || ""}
                                                    >
                                                        Participer
                                                    </button>
                                                )
                                            }
                                            {
                                                isCampaignDone && (
                                                    <button
                                                        className="block mb-4 fullwidth"
                                                        onClick={handleUserAction(SHOW_RESULTS)}
                                                    >
                                                        Voir mes résultats
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <div className="desc-section">
                                            <h3>Informations</h3>
                                            <p><span className="bold">Durée:</span> {getHumanizedDuration(campaign.duration)}</p>
                                            <p><span className="bold">Mode:</span> {campaign.mode}</p>
                                            <p><span className="bold">Nombre de joueurs:</span> {campaign.participants_amount}</p>
                                            {
                                                campaign.user_tags_requirements.length > 0 && (
                                                    <p className="tags">
                                                        <span className="bold">Tags Requis:</span>
                                                        {
                                                            campaign.user_tags_requirements.map(tag => (
                                                                <span className="tag" key={tag}>
                                                                    {tag}
                                                                </span>
                                                            ))
                                                        }
                                                    </p>
                                                )
                                            }
                                            <p><span className="bold">Début:</span> {getHumanizedDate(campaign.start_date)}</p>
                                            {
                                                campaign.expiration_date && (
                                                    <p>
                                                        <span className="bold">Fin:</span> {getHumanizedDate(campaign.expiration_date)}
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <h1 className="campaign-title">{campaign.name}</h1>
                                        <Markdown
                                            children={campaign.description || ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (scoreboard && tabValue === 2) && (
                            <div className="container-fluid bg-blank pd-16">
                                <div className="row">
                                    <div className="col-12 desc-col">
                                        <div className="desc-section">
                                            <h3>Leaderboard</h3>
                                            <Scoreboard
                                                colorizeLabels={username ? [username] : []}
                                                scores={scoreboard.players.reduce<any>((prev, curr) => {
                                                    return [
                                                        ...prev,
                                                        {
                                                            label: curr.player,
                                                            value: curr.points,
                                                            avatar: curr.avatar
                                                        }
                                                    ]
                                                }, [])}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (participantStatus.length > 0 && tabValue === 3) && (
                            <div className="container-fluid bg-blank pd-16">
                                {
                                    participantStatus.map((entry, idx) => (
                                        <div className="row mb-5" key={entry.challenge_id}>
                                            <div className="col-12 desc-col">
                                                <div className="desc-section">
                                                    <h3>#{idx + 1}: {entry.challenge_name}</h3>
                                                    <div className="container-fluid mb-16 pd-0">
                                                        <div className="row justify-content-between">
                                                            <div className="col-12 col-md-4">
                                                                <span><span className="bold mr-8">Essais:</span>{entry.tries}</span>
                                                            </div>
                                                            <div className="col-12 col-md-4 text-left text-center-sm">
                                                                <span><span className="bold mr-8">Env:</span>{entry.environment}</span>
                                                            </div>
                                                            <div className="col-12 col-md-4 text-left text-right-sm">
                                                                <span><span className="bold mr-8">Durée:</span>{stringifyDuration(Date.parse(entry.end_time), Date.parse(entry.start_time))}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container-fluid pd-0">
                                                        <div className="code-visualization-container">
                                                            <CodeEditor
                                                                environment={{
                                                                    description: "",
                                                                    id: "",
                                                                    interpret_as: entry.environment,
                                                                    name: "",
                                                                    placeholder: entry.code
                                                                }}
                                                                codeConfig={getDefaultCodeConfig()}
                                                                readonly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>

            <Dialog
                open={isPasswordDialogOpen}
                onClose={handleClosePasswordDialog}
                aria-labelledby="form-password"
            >
                <DialogTitle id="form-password-title">Password required</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In order to participate to this campaign, you need to provide a password.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        error={passwordError !== null}
                        helperText={passwordError !== null ? passwordError : ""}
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        onChange={handlePasswordChanged}
                        value={password}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <button
                        className="outlined"
                        onClick={handleClosePasswordDialog}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleUserAction(JOIN_CAMPAIGN_WITH_PASSWORD)}
                    >
                        Join
                    </button>
                </DialogActions>
            </Dialog>

            <DJCFooter />
        </>
    );
};

export default Render;
