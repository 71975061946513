import { api } from "..";
import { IUpdateQueryArgs, IUser } from "./types";

const usersExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: () => ({
                url: '/users',
                method: 'GET'
            })
        }),
        getUsersMutation: build.mutation<{ users: IUser[] }, string | void>({
            query: (filter: string | void) => ({
                url: `/users?filter=${(filter === undefined) ? "" : filter}`,
                method: 'GET',
            })
        }),
        deleteUserByUsername: build.mutation<void, string>({
            query: (arg) => ({
                url: `/users/${arg}`,
                method: 'DELETE',
                validateStatus: (status) => status === 200
            })
        }),
        getSelf: build.mutation<IUser, void>({
            query: () => ({
                url: '/users/self',
                method: 'GET'
            })
        }),
        getUserByUsername: build.mutation<IUser, string>({
            query: (username: string) => ({
                url: `/users/${username}`,
                method: 'GET'
            })
        }),
        updateUserInfos: build.mutation<void, IUpdateQueryArgs>({
            query: (args: IUpdateQueryArgs) => ({
                url: `/users/${args.username}`,
                method: "PATCH",
                body: args.body,
                validateStatus: (status: number) => status === 200
            })
        }),
        updateSelfInfos: build.mutation<void, IUpdateQueryArgs>({
            query: (args: IUpdateQueryArgs) => ({
                url: '/users/self',
                method: "PATCH",
                body: args.body,
                validateStatus: (status: number) => status === 200
            })
        })
    }),
    overrideExisting: false
});

const {
    getUsers: {
        useQuery: useGetUsers
    },
    getUserByUsername: {
        useMutation: useGetUserByUsername
    },
    getUserByUsername: {
        useMutation: useMutationGetUserByUsername
    },
    getSelf: {
        useMutation: useGetSelf
    },
    updateUserInfos: {
        useMutation: useMutationUpdateUserInfos
    },
    getUsersMutation: {
        useMutation: useMutationGetUsers
    },
    deleteUserByUsername: {
        useMutation: useMutationDeleteUserByUsername
    },
    updateSelfInfos: {
        useMutation: useMutationUpdateSelfUser
    }
} = usersExtendedApi.endpoints;

export { usersExtendedApi };
export {
    useGetUsers,
    useGetSelf,
    useGetUserByUsername,
    useMutationUpdateUserInfos,
    useMutationGetUsers,
    useMutationGetUserByUsername,
    useMutationDeleteUserByUsername,
    useMutationUpdateSelfUser
};
