import React, { KeyboardEvent } from 'react';

import { TextField } from '@material-ui/core';
import { IFormInputs } from '.';
import { FieldError, Control, DeepMap, Controller } from 'react-hook-form';
import DJCFooter from '../../components/footer';
import { DojoCodesBrandLogo } from '../../../assets/assets';

interface IRenderProps {
    handleSubmit: (_: (data: IFormInputs) => void) => any;
    onSubmit: (data: IFormInputs) => void;
    control: Control;
    rules: Record<string, Record<string, any>>;
    errors: DeepMap<IFormInputs, FieldError>;
    handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
};

const Render = (props: IRenderProps) => {
    const {
        handleSubmit,
        onSubmit,
        control,
        rules,
        errors,
        handleKeyDown
    } = props;

    return (
        <>
            <div className="djc--view-reset-password-content">
                <section className="djc--view-reset-password-topbar">
                    <div className="djc--header-logo">
                        <img src={DojoCodesBrandLogo} alt="brand logo" />
                        <h1>
                            <a href="/">
                                <span className="dark">Dojo</span><span className="primary">.Codes</span>
                            </a>
                        </h1>
                    </div>
                </section>
                <section className="djc--view-reset-password-form">
                    <h1>Réinitialiser le mot de passe</h1>
                    {
                        /*
                        isLoginError && (
                            <span className="invalid-credentials">Invalid credentials please verify them and retry</span>
                        )
                        */
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            as={TextField}
                            name="new_password"
                            control={control}
                            id="field--new-password"
                            label="Nouveau Mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            className="input"
                            onKeyDown={handleKeyDown}
                            rules={{ ...rules.new_password }}
                            error={!!errors.new_password}
                            helperText={
                                errors.new_password ?
                                    errors.new_password.message ?
                                        errors.new_password.message
                                            : "Mot de passe invalide. 1 majuscule, 1 chiffre and 1 caractère spécial minimum."
                                        : ""
                                }
                        />
                        <Controller
                            as={TextField}
                            name="new_password_verify"
                            control={control}
                            id="field--new-password-verify"
                            label="Confirmer Nouveau Mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            className="input"
                            onKeyDown={handleKeyDown}
                            rules={{ ...rules.new_password_verify }}
                            error={!!errors.new_password_verify}
                            helperText={
                                errors.new_password_verify ?
                                    errors.new_password_verify.message ?
                                        errors.new_password_verify.message
                                            : "Les deux mots de passes ne sont pas identiques."
                                        : ""
                                }
                        />
                    </form>

                    <div className="djc--view-reset-password-form-actions">
                        <button
                            onClick={handleSubmit(onSubmit)}
                        >
                            Modifier
                        </button>
                    </div>
                </section>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
