import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";
import { useMutationDeletePATById, useMutationGetPATById, useMutationGetPATList } from "../../../../../../infrastructure/services/PAT";
import { IPublicPAT } from "../../../../../../infrastructure/services/PAT/types";


type DataGrid = Array<{
    id: string;
    scopes: string;
}>;

const AdminPATListView = () => {
    const [fetchPATs] = useMutationGetPATList();
    const [deletePATById] = useMutationDeletePATById();
    const [fetchPATById] = useMutationGetPATById();

    const history = useHistory();

    const [PATs, setPATs] = useState<Array<IPublicPAT>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return PATs.map((pat) => ({
            id: pat.id,
            scopes: pat.scopes.join(', ')
        }));
    }, [PATs]);

    const getPATs = async (): Promise<IPublicPAT[]> => {
        const resp = await fetchPATs();
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.access_tokens;
    }

    const init = async () => {
        const resp = await getPATs();
        setPATs(resp);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deletePATById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...PATs].filter(a => !idsToDelete.includes(a.id));

        setPATs(next);
        setFetching(false);
    }

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push("/admin/PAT/create");
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-key" />
                    <h1>Personnal Access Token</h1>
                </div>

                <div className="actions">
                    <button
                        onClick={handleCreate}
                    >
                        Créer un PAT
                    </button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>PATs <CircularProgress className="inline-block-important" /></span> : `PATs (${PATs.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'scopes',
                            disablePadding: true,
                            label: "Scopes",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="name"
                fetchFunc={fetchPATById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminPATListView;
