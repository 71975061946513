import React from 'react';

interface IRenderProps {
    text: string;
    type: "default" | "alert";
};

const Render = (props: IRenderProps) => {
    const {
        text,
        type
    } = props;

    const cssClass = (
        type === "alert" ?
            'red' :
            'dark'
    );

    return (
        <span className={cssClass}>
            { text }
        </span>
    );
};

export default Render;
