import React, { useMemo } from 'react';

type KIND = "alert" | "warning" | "info";

interface IProps {
    open: boolean;
    title: string;
    content: string;
    kind?: KIND;
    children: any;
    onClose?: () => void;
}

const AlertMessage = (props: IProps) => {
    const {
        open, 
        kind: kindProps,
        title,
        content
    } = props;

    const kind = useMemo<KIND>(
        () => (kindProps === undefined) ? "info" : kindProps,
        [kindProps]
    );

    if (!open) {
        return null;
    }

    const generateIconBasedOnKind = () => {
        if (kind === "alert") {
            return <i className="fas fa-exclamation-triangle"></i>;
        }
        if (kind === "info") {
            return <i className="fas fa-info-circle"></i>
        }

        return <i className="fas fa-exclamation-circle"></i>
    };

    return (
        <div
            className="djc--alert-message-container"
        >
            <div className="box">
                <div className={`kind ${kind}`}>
                    <div className="kind-title">
                        { generateIconBasedOnKind() }
                        <h3>{title}</h3>
                    </div>
                </div>
                <div className="content">
                    <p>
                        { content }
                    </p>
                </div>
                <div className={`footer ${kind}`}>
                    {  props.children }
                </div>
            </div>
        </div>
    );
}

export default AlertMessage;
