import React, { useCallback, useEffect, useState } from 'react';

import Render from './render';
import { useGetCampaigns } from '../../../infrastructure/services/campaign';
import { ICampaignSummary } from '../../../infrastructure/services/campaign/types';
import { FilterChanged, ICampaignsFilters } from './types';
import { moment } from '../../../infrastructure/moment';

export interface ICampaignsSummary {
    label: string;
    campaigns: ICampaignSummary[];
}

const CampaignsView = () => {
    const { data: campaignsData, isFetching } = useGetCampaigns();

    const [fetchedCampaigns, setFetchedCampaigns] = useState<ICampaignsSummary[] | null>(null);
    const [campaigns, setCampaigns] = useState<ICampaignsSummary[] | null>(null);

    const [filtersValues, setFiltersValues] = useState<ICampaignsFilters>({
        campaign_name: null,
        campaign_game_mode: "Any",
        show_campaigns_not_started: true,
        show_campaigns_in_progress: true,
        show_campaigns_finished: true
    });

    const applyFilters = (filtersToApply: ICampaignsFilters, campaignsToFilter: ICampaignsSummary[]): ICampaignsSummary[] => {
        let nextCampaigns = [...campaignsToFilter];

        for (let i = 0; i < campaignsToFilter.length; i += 1) {
            const category = campaignsToFilter[i];
            for (let j = 0; j < campaignsToFilter[i].campaigns.length; j += 1) {
                const campaign = campaignsToFilter[i].campaigns[j];

                let hide: boolean = false;
                if (!hide && filtersToApply.campaign_name !== null && filtersToApply.campaign_name.length > 0) {
                    hide = (
                        !campaign
                            .name
                            .toLocaleLowerCase()
                            .startsWith(filtersToApply.campaign_name.toLowerCase())
                    )
                }

                if (!hide && filtersToApply.campaign_game_mode !== "Any") {
                    hide = (
                        campaign.mode !== filtersToApply.campaign_game_mode
                    )
                }

                if (!hide && !filtersToApply.show_campaigns_not_started) {
                    hide = category.label === "Upcoming";
                }

                if (!hide && !filtersToApply.show_campaigns_in_progress) {
                    hide = category.label === "In Progress";
                }

                if (!hide && !filtersToApply.show_campaigns_finished) {
                    hide = category.label === "Finished";
                }

                nextCampaigns[i].campaigns[j] = ({
                    ...campaign,
                    hide: hide
                });
            }
        }

        return nextCampaigns;
    };

    const handleFiltersChanged = (event: FilterChanged) => {
        const target = (event.target as any);
        const name = target.name;
        const isCheckbox = target.type === "checkbox";
        const value: string | boolean = isCheckbox ? target.checked as boolean : target.value as string;

        const nextFilters = {
            ...filtersValues,
            [name]: (
                isCheckbox
                    ? value
                    : (
                        (value as string).length === 0
                            ? null
                            : value
                    )
            )
        };
        setFiltersValues(nextFilters);

        if (fetchedCampaigns !== null) {
            const nextCampaigns = applyFilters(nextFilters, fetchedCampaigns);
            setCampaigns(nextCampaigns);
        }
    };

    const cmpByStartDate = (a: ICampaignSummary, b: ICampaignSummary) => (
        a.start_date < b.start_date
            ? 1
            : a.start_date > b.start_date
                ? -1
                : 0
    );

    const cmpByStartDateReversed = (a: ICampaignSummary, b: ICampaignSummary) => (
        a.start_date < b.start_date
            ? -1
            : a.start_date > b.start_date
                ? 1
                : 0
    );

    const sortCampaigns = useCallback((data: ICampaignSummary[]): ICampaignsSummary[] => {
        const now = new Date();

        const results = data.reduce<Record<string, ICampaignSummary[]>>((prev, campaign: ICampaignSummary) => {
            const start = new Date(campaign.start_date);
            const expiration = campaign.expiration_date;

            if (start > now) {
                return {
                    ...prev,
                    upcoming: [
                        ...prev.upcoming,
                        campaign
                    ]
                };
            }

            if (expiration) {
                const end = new Date(expiration);

                if (end < now) {
                    return {
                        ...prev,
                        previous: [
                            ...prev.previous,
                            campaign
                        ]
                    };
                } else {
                    return {
                        ...prev,
                        ongoing: [
                            ...prev.ongoing,
                            campaign
                        ]
                    };
                }
            }

            return {
                ...prev,
                ongoing: [
                    ...prev.ongoing,
                    campaign
                ]
            };
        }, {
            upcoming: [],
            previous: [],
            ongoing: []
        });

        return [
            {
                label: "En Cours",
                campaigns: results.ongoing.sort(cmpByStartDate)
            },
            {
                label: "Pas Encore Démarré",
                campaigns: results.upcoming.sort(cmpByStartDateReversed)
            },
            {
                label: "Fini",
                campaigns: results.previous.sort(cmpByStartDate)
            }
        ].filter(a => a.campaigns.length > 0);
    }, []);

    useEffect(() => {
        if (!isFetching && campaignsData) {
            const nextFetchCampaigns = sortCampaigns(campaignsData?.campaigns);
            setFetchedCampaigns(nextFetchCampaigns);
            setCampaigns(applyFilters(filtersValues, nextFetchCampaigns));
        }
    }, [campaignsData, isFetching, sortCampaigns]);

    const getHumanizedDate = (dateStr: string, endStr: string | null): string => {
        try {
            const date = new Date(dateStr);
            const now = new Date();

            if (endStr) {
                const end = new Date(endStr);
                if (end < now) {
                    return `Fini ${moment(date).from(moment(now))}`;
                }
            }

            // Already started
            if (date < now) {
                return `Démarré ${moment(date).from(moment(now))}`;
            } else {
                return `Démarre ${moment(now).to(moment(date))}`;
            }
        } catch (e) {
            return "???";
        }
    };

    const getStringifiedParticipants = (participantsAmount: number, maxParticipants: number | null): string => {
        if (maxParticipants) {
            return `${participantsAmount} / ${maxParticipants} joueurs`
        }

        return `${participantsAmount} joueurs`
    };

    if (!campaigns || isFetching) {
        return null;
    }

    return (
        <Render
            getHumanizedDate={getHumanizedDate}
            getStringifiedParticipants={getStringifiedParticipants}
            campaigns={campaigns}
            filtersValues={filtersValues}
            handleFiltersChanged={handleFiltersChanged}
        />
    );
};

export default CampaignsView;
