import React, { useMemo, useState } from 'react';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps } from '@material-ui/core';

interface IProps {
    id: string;
    label: string;
    name: string;
    inputProps: Partial<OutlinedInputProps>;
    helperText?: string;
}

const PasswordField = (props: IProps) => {
    const { id, label, name, inputProps: inputPropsProps, helperText } = props;
    
    const inputProps = useMemo(() => {
        return inputPropsProps;
    }, [inputPropsProps]);

    const [hidden, setHidden] = useState<boolean>(true);

    const handleClick = () => {
        setHidden((last: boolean) => !last);
    }

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel error={inputProps.error} htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                {...inputProps}
                id={id}
                type={hidden ? "password" : "text"}
                fullWidth
                name={name}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="hide text"
                            onClick={handleClick}
                        >
                            { hidden ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                    </InputAdornment>
                }
            />
            {
                helperText && (
                    <FormHelperText error={inputProps.error}>
                        {helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}

export default PasswordField;