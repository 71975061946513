import { BaseConfig } from "./types";

interface ENVIRONMENT_CONFIGURATION {
    REACT_APP_PUBLIC_BACKEND_URI?: string
};

declare global {
    interface Window {
        _env_: ENVIRONMENT_CONFIGURATION;
    }
}

let API_URL: string | undefined;
if (window.hasOwnProperty("_env_")) {
    API_URL = window._env_.REACT_APP_PUBLIC_BACKEND_URI;
} else if (process.env['REACT_APP_PUBLIC_BACKEND_URI']) {
    API_URL = process.env['REACT_APP_PUBLIC_BACKEND_URI'];
}

const setupConfig = (): BaseConfig => ({
    env: process.env.NODE_ENV,
    baseroute: API_URL || "http://localhost:8000",
    version: process.env['REACT_APP_VERSION'] || '1.0.0'
});

const config = setupConfig();

export default config;
