import React, { useEffect, useState } from "react";

import toast from "toasted-notes";
import CloseIcon from "@material-ui/icons/Close";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {
    AppBar,
    CircularProgress,
    createStyles,
    Dialog,
    DialogContent,
    IconButton,
    makeStyles,
    Slide,
    Theme,
    Toolbar,
    Typography,
    withStyles,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { useMutationGetCampaignParticipantStatus } from "../../../../infrastructure/services/campaign";
import {
    ICampaignParticipantStatusResults,
    ICampaignParticipantStatusEntry,
} from "../../../../infrastructure/services/campaign/types";
import Toast from "../../../components/toast";
import CodeEditor from "./code_editor";
import { ICodeEditorConfig } from "./types";

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        padding: "0px 16px !important",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 9999,
        },
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        progress: {
            width: "100px !important",
            height: "100px !important",
            margin: "auto",
        },
        contentFetching: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        content: {
            padding: 0
        },
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    onClose: () => void;
    username: string;
    campaignId: string;
    codeConfig: ICodeEditorConfig;
}

const CodeHistoryDialog = (props: IProps) => {
    const classes = useStyles();

    const { open, onClose, username, campaignId, codeConfig } = props;

    const [
        fetchCampaignParticipantStatus,
    ] = useMutationGetCampaignParticipantStatus();

    const [isFetching, setIsFetching] = useState(true);
    const [openedPanel, setOpenedPanel] = useState(-1);
    const [data, setData] = useState<ICampaignParticipantStatusResults | null>(
        null
    );

    const handlePanelOpen = (idx: number) => () => {
        setOpenedPanel(last => last === idx ? -1 : idx);
    }

    const [entries, setEntries] = useState<
        Array<ICampaignParticipantStatusEntry>
    >([]);

    const init = React.useCallback(async () => {
        setIsFetching(true);
        const resp = await fetchCampaignParticipantStatus({
            id: campaignId,
            username: username,
        });

        if (!resp.data || resp.error) {
            toast.notify(
                <Toast
                    text={"Erreur innatendu. Merci de réessayer dans quelques instants."}
                />,
                {
                    duration: 10000,
                    position: "top",
                }
            );
            onClose();
            return;
        }

        const nextEntries = [...resp.data.entries].sort((a, b) => {
            if (a.start_time === null) {
                return -1;
            }
            if (b.start_time === null) {
                return 1;
            }

            return Date.parse(a.start_time) - Date.parse(b.start_time);
        });

        setEntries(nextEntries);
        setData(resp.data);
        setIsFetching(false);
    }, [username, campaignId, onClose, fetchCampaignParticipantStatus]);

    useEffect(() => {
        init();
    }, [open]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            className={classes.root}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Historique des soumissions
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                className={isFetching ? classes.contentFetching : classes.content}
            >
                {isFetching && <CircularProgress className={classes.progress} />}
                {!isFetching && data && (
                    <>
                        {
                            entries.map((entry, idx) => (
                                <Accordion
                                    key={entry.challenge_id}
                                    square
                                    expanded={openedPanel === idx}
                                    onChange={handlePanelOpen(idx)}
                                    className="accordion-code-history"
                                >
                                    <AccordionSummary
                                        aria-controls={`panel-${idx}-content`}
                                        id={`panel-${idx}-header`}
                                    >
                                        <Typography>Challenge #{idx + 1}: {entry.challenge_name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: "100%",
                                                height: "100%",
                                                minHeight: "50vh",
                                                maxHeight: "80vh"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    position: "absolute",
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    top: 0
                                                }}
                                            >
                                                <CodeEditor
                                                    environment={{
                                                        description: "",
                                                        id: "",
                                                        interpret_as: entry.environment,
                                                        name: "",
                                                        placeholder: entry.code
                                                    }}
                                                    codeConfig={codeConfig}
                                                    readonly={true}
                                                />
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CodeHistoryDialog;
