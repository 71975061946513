import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab/useAutocomplete";
import { useCallback, useEffect, useState } from "react";

export type IError<T> = Partial<{ [K in keyof T]: { error: boolean, message: string } }>;

interface IProps<T> {
    defaultValues: T;
    validate: (v: T) => IError<T>;
    required?: string[];
}

type ITouched<T> = Partial<{ [K in keyof T]: boolean }>;

export const generateError = <T>(errors: IError<T>, field: string, condition: boolean, errorMessage: string) => {
    if (!condition) {
        return errors;
    }

    return {
        ...errors,
        [field]: { error: true, message: errorMessage }
    };
}

const useForm = <T>(props: IProps<T>) => {
    const { defaultValues, validate, required } = props;

    const [values, setValues] = useState<T>({ ...defaultValues });
    const [errors, setErrors] = useState<IError<T>>({});
    const [touched, setTouched] = useState<ITouched<T>>({});
    const [isModified, setModified] = useState<boolean>(false);

    const updateModified = useCallback(() => {
        const nextModified = Object.keys(values)
            .some(key => (values as any)[key] !== (defaultValues as any)[key]);

        if (nextModified !== isModified) {
            setModified(nextModified);
        }
    }, [values, defaultValues, isModified]);

    useEffect(() => {
        updateModified();
    }, [updateModified]);

    const isFulfilled = () => {
        if (!required || required.length === 0) {
            return true;
        }

        return required.every(k => (touched as any)[k] !== undefined);
    }

    const [fulfilled, setFulfilled] = useState<boolean>(isFulfilled());

    const updateValues = useCallback((nextValues: Partial<T>) => {
        const next = {
            ...values,
            ...nextValues
        };

        setValues(next);
    }, [values]);

    const handleValidate = useCallback((nextTouched: ITouched<T>, nextValues: T) => {
        const nextErr = validate(nextValues);
        const filtered = Object.keys(nextErr)
            .filter(k => Object.keys(nextTouched).includes(k))
            .reduce<IError<T>>((prev, curr) => ({
                ...prev,
                [curr]: (nextErr as any)[curr]
            }), {});

        if (Object.keys(nextErr) === Object.keys(errors)) {
            return;
        }

        setErrors(filtered);
    }, [validate, errors]);

    const handleUpdate = useCallback((
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (!e.target.name) {
            return;
        }

        const value = e.target.type === "checkbox"
            ? (e.target as any).checked
            : e.target.value;

        const nextTouched = { ...touched, [e.target.name]: true };
        const nextValues = { ...values, [e.target.name]: value };
        let flag = false;

        if (nextTouched !== touched) {
            setTouched(nextTouched);
            flag = true;
        }

        if (nextValues !== values) {
            setValues(nextValues);
            flag = true;
        }

        if (flag) {
            handleValidate(nextTouched, nextValues);
        }

        const nextFulfilled = isFulfilled();
        if (fulfilled !== nextFulfilled) {
            setFulfilled(nextFulfilled);
        }
    }, [defaultValues]);

    const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleUpdate(e);
    }, [defaultValues]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        if (!e) {
            return;
        }

        handleUpdate(e);
    }, [defaultValues]);

    const handleChangeSpecificFieldWithValue = useCallback((name: string) => (v: any) => {
        if (!(name in values)) {
            return;
        }

        const next = {
            ...values,
            [(name as any)]: v
        };

        setValues(next);
    }, [defaultValues]);

    const handleComplexMUIUpdate = useCallback((nextTouched: ITouched<T>, nextValues: T) => {
        handleValidate(nextTouched, nextValues);
        const nextFulfilled = isFulfilled();
        if (fulfilled !== nextFulfilled) {
            setFulfilled(nextFulfilled);
        }
    }, [defaultValues]);

    const handleBlurAutocomplete = useCallback((name: string) => (e: React.FocusEvent<HTMLDivElement>) => {
        const nextTouched = { ...touched, [name]: true };
        if (nextTouched !== touched) {
            setTouched(nextTouched);
        }
        handleComplexMUIUpdate(nextTouched, values);
    }, [defaultValues])

    const handleChangeAutocomplete = useCallback((name: string) => (event: React.ChangeEvent<{}>, value: string[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string> | undefined) => {
        const nextTouched = { ...touched, [name]: true };
        const nextValues = { ...values, [name]: value.join(',') };

        if (nextTouched !== touched) {
            setTouched(nextTouched);
        }
        if (nextValues !== values) {
            setValues(nextValues);
        }

        handleComplexMUIUpdate(nextTouched, nextValues);
    }, [defaultValues]);

    const handleChangeSingleAutocomplete = useCallback((name: string) => (event: React.ChangeEvent<{}>, value: string | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string> | undefined) => {
        const nextTouched = { ...touched, [name]: true };
        const nextValues = { ...values, [name]: value };

        if (nextTouched !== touched) {
            setTouched(nextTouched);
        }
        if (nextValues !== values) {
            setValues(nextValues);
        }

        handleComplexMUIUpdate(nextTouched, nextValues);
    }, [defaultValues]);

    return {
        handleBlur,
        handleChange,
        handleBlurAutocomplete,
        handleChangeAutocomplete,
        handleChangeSingleAutocomplete,
        updateValues,
        handleChangeSpecificFieldWithValue,
        values,
        errors,
        containsError: Object.keys(errors).length > 0,
        fulfilled,
        containsModification: isModified
    }
}

export default useForm;