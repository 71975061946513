import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUserByUsername } from '../../../infrastructure/services/users';
import { selectCurrentUser } from '../../../infrastructure/services/users/slice';
import { IUser } from '../../../infrastructure/services/users/types';

import Render from './render';

interface UserViewProps {
    self?: boolean;
    user?: string;
};

const UserView = (props: UserViewProps) => {
    const { self, user } = props;

    const currentUser = useSelector(selectCurrentUser);
    const [getUserByUsername] = useGetUserByUsername();

    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [readyToDraw, setReadyToDraw] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            if (self && currentUser) {
                setSelectedUser(currentUser);
                setReadyToDraw(true);
                
                return;
            }
            
            if (user) {
                const resp = await getUserByUsername(user);

                if (resp.data) {
                    setSelectedUser(resp.data);
                    setReadyToDraw(true);
                    
                    return;
                } 
                
                if (resp.error) {
                    // TODO: Handle error permission & 404
                }
            }
        };

        fetchUser();
    }, [self, user, currentUser, getUserByUsername]);

    if (!readyToDraw || !selectedUser) {
        return null;
    }

    return (
        <Render
            user={selectedUser}
        />
    );
};

export default UserView;