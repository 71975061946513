import { api } from "..";
import { IRole } from "./types";

const rolesExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRoleById: build.mutation<IRole, string>({
            query: (arg) => ({
                url: `/roles/${arg}`,
                method: "GET"
            })
        }),
    }),
    overrideExisting: false
});

const {
    endpoints: {
        getRoleById: {
            useMutation: useMutationGetRoleById
        }
    }
} = rolesExtendedApi;

export { rolesExtendedApi };

export {
    useMutationGetRoleById
}
