import { api } from "..";
import { IReportInputs } from "./types";

const reportsExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        postGenerateReport: build.mutation<void, IReportInputs>({
            query: (args: IReportInputs) => ({
                url: `/report`,
                method: "POST",
                body: args
            })
        }),
    }),
    overrideExisting: false
});

const {
    endpoints: {
        postGenerateReport: {
            useMutation: useMutationPostGenerateReport
        }
    }
} = reportsExtendedApi;

export { reportsExtendedApi };

export {
    useMutationPostGenerateReport
}


