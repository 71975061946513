import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../../components/toast";

import EnhancedTable from "../../../../../../components/table";
import { useMutationDeleteRuleById, useMutationGetRuleById, useMutationGetRuleList } from "../../../../../../../infrastructure/services/scoring";
import { IRule } from "../../../../../../../infrastructure/services/scoring/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    challenges: string;
    from_campaign: string;
    base: number;
    time: number;
    difficulty: number | undefined;
    code_length: number | undefined;
    code_quality: number | undefined;
    code_execution_time: number | undefined;
}>;

const AdminRulesListView = () => {
    const [fetchRules] = useMutationGetRuleList();
    const [deleteRuleById] = useMutationDeleteRuleById();
    const [fetchRuleById] = useMutationGetRuleById();

    const history = useHistory();

    const [rules, setRules] = useState<Array<IRule>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return rules.map((rule) => ({
            id: rule.id,
            challenges: rule.rule.challenge,
            from_campaign: rule.rule.from_campaign,
            base: rule.rule.base,
            time: rule.rule.time,
            difficulty: rule.rule.difficulty,
            code_length: rule.rule.code_length,
            code_quality: rule.rule.code_quality,
            code_execution_time: rule.rule.code_execution_time,
        }));
    }, [rules]);

    const getRules = async (): Promise<IRule[]> => {
        const resp = await fetchRules();
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.rules;
    }

    const init = async () => {
        const rulesResp = await getRules();
        setRules(rulesResp);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteRuleById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...rules].filter(a => !idsToDelete.includes(a.id));

        setRules(next);
        setFetching(false);
    }

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push("/admin/scoring/rules/create");
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fa fa-cog" />
                    <h1>Règles</h1>
                </div>
                <div className="actions">
                    <button onClick={handleCreate}>
                        Créer une règle
                    </button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Règles <CircularProgress className="inline-block-important" /></span> : `Règles (${rules.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'challenges',
                            disablePadding: true,
                            label: "Challenges",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'from_campaign',
                            disablePadding: true,
                            label: "Campagne",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "base",
                            disablePadding: true,
                            label: "Base",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'time',
                            disablePadding: true,
                            label: "Temps",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'difficulty',
                            disablePadding: true,
                            label: "Difficulté",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'code_length',
                            disablePadding: true,
                            label: "Taille Code",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'code_quality',
                            disablePadding: true,
                            label: "Qualité Code",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'code_execution_time',
                            disablePadding: true,
                            label: "Durée exec. Code",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="id"
                fetchFunc={fetchRuleById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminRulesListView;
