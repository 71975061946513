import React from 'react';

interface IRenderProps {
    children: any
}

const Render = (props: IRenderProps) => {
    return (
        <>
            { props.children }
        </>
    );
};

export default Render;
