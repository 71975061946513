import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "./types";
import { RootState } from "../../stores";

interface IInitialState {
    currentUser: IUser | null
};

const initialState: IInitialState = {
    currentUser: null
};

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setCurrentUser: (
            state,
            { payload }: PayloadAction<IUser>
        ) => {
            state.currentUser = payload;
        },
        clearCurrentUser: (
            state,
            { payload }: PayloadAction<void>
        ) => {
            state.currentUser = null;
        }
    },
    extraReducers: (builder) => {}
});

export const { setCurrentUser, clearCurrentUser } = userSlice.actions;
export default userSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.user.currentUser || null;
export const selectIsUserConnected = (state: RootState) => state.user.currentUser !== null;
export const selectUsername = (state: RootState) => (
    state.user.currentUser ? state.user.currentUser.username : null
);
export const selectEmail = (state: RootState) => (
    state.user.currentUser ? state.user.currentUser.email : null
);
