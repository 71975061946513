import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '../../../infrastructure/globals/routes';
import { selectIsConnecting } from '../../../infrastructure/services/authentication/slice';
import { selectIsUserConnected } from '../../../infrastructure/services/users/slice';
import Render from './render';

interface IUnloggedRouteProps {
    children: any;
    redirectTo?: string | null;
};

const UnloggedRoute = (props: IUnloggedRouteProps) => {
    const { redirectTo } = props;

    const history = useHistory();

    const isConnecting = useSelector(selectIsConnecting);
    const isUserConnected = useSelector(selectIsUserConnected);


    useEffect(() => {
        if (isConnecting) {
            return;
        }

        if (isUserConnected) {
            if (redirectTo) {
                history.push(`/${redirectTo}`);
            } else {
                history.push(HOME_ROUTE);
            }
        }
    }, [isUserConnected, history]);

    if (isUserConnected || isConnecting) {
        return null;
    }

    return (
        <Render>
            {
                props.children
            }
        </Render>
    );
};

export default UnloggedRoute;
