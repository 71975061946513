import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationDeleteActionById, useMutationGetActionById, useMutationGetActionsList } from "../../../../../../infrastructure/services/actions";
import { IAction } from "../../../../../../infrastructure/services/actions/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    route_name: string;
    tags: string;
}>;

const AdminActionsListView = () => {
    const [ fetchActions ] = useMutationGetActionsList();
    const [ deleteActionById ] = useMutationDeleteActionById();
    const [ fetchActionById ] = useMutationGetActionById();

    const history = useHistory();

    const [fetching, setFetching] = useState<boolean>(true);
    const [actions, setActions] = useState<Array<IAction>>([]);
    const [detail, setDetail] = useState<any | null>(null);
    
    const dataGrid = useMemo<DataGrid>(() => {
        return actions.map((action) => ({
            id: action.id,
            route_name: action.route_name,
            tags: action.tags.join(', ')
        }));
    }, [actions]);

    const getActions = async (): Promise<IAction[]> => {
        const resp = await fetchActions();
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.actions;
    }

    const init = async () => {
        const actionsResp = await getActions();
        setActions(actionsResp);
        setFetching(false);
    };

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteActionById(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const nextActions = [...actions].filter(a => !idsToDelete.includes(a.id));

        setActions(nextActions);
        setFetching(false);
    }

    useEffect(() => {
        init();
    }, []);

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push('/admin/others/actions/create');
    }
    
    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-database" />
                    <h1>Actions</h1>
                </div>

                <div className="actions">
                    <button
                        onClick={handleCreate}
                    >
                        Créer une action
                    </button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Actions <CircularProgress className="inline-block-important" /></span> : `Actions (${actions.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'route_name',
                            disablePadding: true,
                            label: "Route",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'tags',
                            disablePadding: true,
                            label: "Tags",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="id"
                fetchFunc={fetchActionById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminActionsListView;
