import React, { KeyboardEvent } from 'react';
import {
    Backdrop,
    Fade,
    Modal,
    TextField
} from '@material-ui/core';
import { DeepMap, Control, FieldError, Controller } from 'react-hook-form';
import { IFormInputs } from '.';
import { Link } from 'react-router-dom';
import { CGU_ROUTE, RESET_PASSWORD_ROUTE } from '../../../infrastructure/globals/routes';

interface RenderProps {
    open: boolean;
    handleClose: () => void;
    handleSignUp: () => void;
    handleSubmit: (_: (data: IFormInputs) => void) => any;
    onSubmit: (data: IFormInputs) => void;
    control: Control;
    rules: Record<string, Record<string, any>>;
    errors: DeepMap<IFormInputs, FieldError>;
    isLoading: boolean;
    isLoginError: boolean;
    handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
    customErrors: Record<string, string>;
}

const Render = (props: RenderProps) => {
    const {
        open,
        handleClose,
        handleSignUp,
        handleSubmit,
        onSubmit,
        control,
        rules,
        errors,
        isLoading,
        isLoginError,
        handleKeyDown,
        customErrors
    } = props;

    return (
        <Modal
            aria-labelledby="sign-in-modal"
            aria-describedby="sign-in-form-modal"
            open={open}
            onClose={handleClose}
            className="djc--sign-in-modal"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <div className="djc--sign-in-modal-body">
                    <div className="informations">
                        <h2>Re-Bonjour :)</h2>
                        <div className="divider" />
                        <p>Connectez vous pour commencer à utiliser <b>Dojo.</b><span className="bold orange">Codes</span></p>
                    </div>
                    <div className="form">
                        {
                            isLoginError && (
                                <span className="invalid-credentials">Invalid credentials please verify them and retry</span>
                            )
                        }
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                as={TextField}
                                control={control}
                                name="username"
                                id="field--username"
                                label="Nom d'utilisateur"
                                variant="outlined"
                                fullWidth
                                className="input"
                                onKeyDown={handleKeyDown}
                                rules={{ ...rules.username }}
                                error={!!errors.username || isLoginError || !!customErrors["username"]}
                            />
                            <Controller
                                as={TextField}
                                control={control}
                                name="password"
                                id="field--password"
                                label="Mot de passe"
                                variant="outlined"
                                fullWidth
                                type="password"
                                className="input"
                                onKeyDown={handleKeyDown}
                                rules={{ ...rules.password }}
                                error={!!errors.password || isLoginError}
                            />
                        </form>
                        <div className="actions">
                            <a href={RESET_PASSWORD_ROUTE}>Mot de passe oublié ?</a>
                            <button
                                onClick={handleSubmit(onSubmit)}
                                disabled={isLoading}
                            >
                                Se Connecter
                            </button>
                        </div>

                        <div className="divider" />

                        <div className="create-account">
                            <h3>Vous n'avez pas de compte ?</h3>
                            <button
                                onClick={handleSignUp}
                            >
                                S'enregistrer
                            </button>
                            <span>
                                En cliquant sur “S'inscrire”, vous acceptez les <Link onClick={handleClose} to={CGU_ROUTE}>Conditions Générales d'Utilisation</Link> ainsi que 
                                la <Link onClick={handleClose} to={CGU_ROUTE}>Politique de Confidentialité</Link> de Dojo.Codes.
                            </span>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default Render;
