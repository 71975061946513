import { IAutoToken, IRefreshToken } from "./types";
import {
    LOCAL_STORAGE__AUTH_ACCESS_TOKEN,
    LOCAL_STORAGE__AUTH_USERNAME,
    LOCAL_STORAGE__TOKEN_TYPE,
    LOCAL_STORAGE__SCOPES
} from './const';

export const saveCredentialsOnLocalStorage = (credentials: IAutoToken | IRefreshToken, username?: string) => {
    if (username) {
        localStorage.setItem(btoa(LOCAL_STORAGE__AUTH_USERNAME), btoa(username));
    }
    localStorage.setItem(btoa(LOCAL_STORAGE__AUTH_ACCESS_TOKEN), btoa(credentials.access_token));
    localStorage.setItem(btoa(LOCAL_STORAGE__TOKEN_TYPE), btoa(credentials.token_type));
};

export const saveScopesOnLocalStorage = (scopes: string[]): void => {
    localStorage.setItem(btoa(LOCAL_STORAGE__SCOPES), btoa(scopes.join(',')));
}

export const getTokenFromLocalStorage = () => {
    const item = localStorage.getItem(btoa(LOCAL_STORAGE__AUTH_ACCESS_TOKEN));
    if (!item) {
        return null;
    }
    return atob(item);
};

export const getScopesFromLocalStorage = (): string[] => {
    const scopes = localStorage.getItem(btoa(LOCAL_STORAGE__SCOPES));

    if (!scopes) {
        return [];
    }

    const value = atob(scopes);
    if (!/^([a-z_]+,?)+$/g.test(value)) {
        return [];
    }

    return value.trim().split(',');
};

export const cleanAuthOnLocalStorage = () => {
    localStorage.removeItem(btoa(LOCAL_STORAGE__AUTH_USERNAME));
    localStorage.removeItem(btoa(LOCAL_STORAGE__AUTH_ACCESS_TOKEN));
    localStorage.removeItem(btoa(LOCAL_STORAGE__TOKEN_TYPE));
};
