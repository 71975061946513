import React from 'react';

interface IButtonProps {
    text: string;
}

const MDButton = (props: IButtonProps) => {
    const { text } = props;

    const values = React.useMemo(() => {
        return text.split('\n').map(subtxt => subtxt.split(': '))
    }, [text]);

    const href = React.useMemo(() => {
        const v = values.find(v => v[0] === "href") ;
        return v === undefined ? "#": v[1];
    }, [values]);

    const label = React.useMemo(() => {
        const v = values.find(v => v[0] === "label") ;
        return v === undefined ? "UNDEFINED LABEL": v[1];
    }, [values]);

    const style = React.useMemo(() => {
        const classes = ["btn-md-default"];
        const style_ = values.find(v => v[0] === "style");
        const alignment_ = values.find(v => v[0] === "alignment");
        if (style_ !== undefined) {
            classes.push(`btn-md-${style_[1]}`)
        }
        if (alignment_ !== undefined) {
            classes.push(`alignment-${alignment_[1]}`)
        }
        return classes.join(' ');
    }, [values]);

    return (
        <a
            href={href}
            className={style}
        >
            { label }
        </a>
    );
};

export default MDButton;
