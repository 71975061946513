import { api } from "..";
import {
    IAutoToken,
    IAutoTokenInputs,
    IRefreshToken,
    IRegisterForm,
    IRegisterResponse,
    IResetPasswordForm,
    IResetPasswordRequestForm
} from "./types";

const authenticationExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        postAutoToken: build.mutation<IAutoToken, IAutoTokenInputs>({
            query: (inputs: IAutoTokenInputs) => {
                const body = new URLSearchParams();
                body.append("username", inputs.username);
                body.append("password", inputs.password);

                return ({
                    url: '/authentication/auto_token',
                    method: 'POST',
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "accept": "application/json"
                    },
                    body: body
                });
            },
        }),
        postRefresh: build.mutation<IRefreshToken, string>({
            query: (accessToken: string) => ({
                url: '/authentication/refresh',
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
        }),
        postRegister: build.mutation<IRegisterResponse, IRegisterForm>({
            query: (args: IRegisterForm) => ({
                url: '/authentication/register',
                method: 'POST',
                body: {
                    username: args.username,
                    email: args.email,
                    password: args.password,
                    first_name: args.first_name,
                    last_name: args.last_name,
                    phone: args.phone,
                    guild: args.guild,
                    experience: args.experience,
                    registration_actions: args.registration_actions
                }
            })
        }),
        postResetPasswordRequest: build.mutation<boolean, IResetPasswordRequestForm>({
            query: (args) => ({
                url: "/authentication/reset_password",
                method: "POST",
                body: {
                    "email": args.email
                }
            })
        }),
        postResetPassword: build.mutation<boolean, IResetPasswordForm>({
            query: (args) => ({
                url: `/authentication/reset_password/${args.token}`,
                method: "POST",
                body: {
                    "token": args.token,
                    "password": args.password
                }
            })
        })
    }),
    overrideExisting: false
});

const {
    endpoints: {
        postAutoToken: {
            useMutation: usePostAutoToken
        },
        postRefresh: {
            useMutation: usePostRefresh
        },
        postRegister: {
            useMutation: useMutationPostRegister
        },
        postResetPassword: {
            useMutation: useMutationPostResetPassword
        },
        postResetPasswordRequest: {
            useMutation: useMutationPostResetPasswordRequest
        }
    }
} = authenticationExtendedApi;

export {
    usePostAutoToken,
    usePostRefresh,
    useMutationPostRegister,
    useMutationPostResetPasswordRequest,
    useMutationPostResetPassword
};
export { authenticationExtendedApi };
