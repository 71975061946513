export const saveLocalObjFor = (campaign_id: string, challenge_id: string, env_id: string, code: string) => {
    const b64Key = btoa(`${campaign_id}_${challenge_id}`);
    const obj = {
        [btoa(env_id)]: code
    };

    localStorage.setItem(b64Key, JSON.stringify(obj));
}

export const updateLocalObjFor = (campaign_id: string, challenge_id: string, obj: any) => {
    const b64Key = btoa(`${campaign_id}_${challenge_id}`);

    localStorage.setItem(b64Key, JSON.stringify(obj));
};

export const saveLocalCode = (campaign_id: string, challenge_id: string, env_id: string, code: string) => {
    const savedData = getLocalSavedObjFor(campaign_id, challenge_id);
    if (!savedData) {
        saveLocalObjFor(campaign_id, challenge_id, env_id, code);
        return;
    }

    const b64Key = btoa(env_id);
    savedData[b64Key] = code;

    updateLocalObjFor(campaign_id, challenge_id, savedData);
};

export const clearLocalCode = (campaign_id: string, challenge_id: string) => {
    const b64Key = btoa(`${campaign_id}_${challenge_id}`);

    localStorage.removeItem(b64Key);
};

export const getLocalSavedObjFor = (campaign_id: string, challenge_id: string) => {
    const b64Key = btoa(`${campaign_id}_${challenge_id}`);
    const value = localStorage.getItem(b64Key);

    if (!value) {
        return null;
    }

    return JSON.parse(value);
}

export const getLocalCode = (campaign_id: string, challenge_id: string, env_id: string) => {
    const data = getLocalSavedObjFor(campaign_id, challenge_id);
    if (!data) {
        return null;
    }

    const b64Key = btoa(env_id);
    const value = data[b64Key];
    if (!value) {
        return null;
    }

    return value;
};

export const getLocalCodeOr = (campaign_id: string, challenge_id: string, env_id: string, code: string) => {
    const localCode = getLocalCode(campaign_id, challenge_id, env_id);
    if (localCode !== null) {
        return localCode;
    }

    return code;
}
