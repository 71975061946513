import React, { useCallback, useEffect, useRef, useState } from 'react';

import toast from 'toasted-notes'

import {
    updateDocumentTitleForParticipatingTo,
    updateDocumentTitleToDefault
} from '../../../../infrastructure/logic/document';
import {
    useGetCampaignFromRoute,
    useMutationCheckCurrentChallengeForCampaignById,
    useMutationGetCurrentChallengeForCampaignById,
    useMutationGetNextChallengeForCampaignById,
    useMutationPostCampaignCheckOneCheck,
    useMutationPostSkipNextChallenge
} from '../../../../infrastructure/services/campaign';
import {
    useMutationCheckAll,
} from '../../../../infrastructure/services/challenge';
import { ICampaignSummary } from '../../../../infrastructure/services/campaign/types';
import { IChallenge } from '../../../../infrastructure/services/challenge/types';
import DJCerror from '../../../components/error';

import Render from './render';
import { ConfigAction, DEFAULT_CODE, ICodeEditorConfig, IConsoleOutput, TopbarButtonAction } from './types';
import { useHistory } from 'react-router-dom';
import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';
import Toast from '../../../components/toast';
import { useMutationGetEnvironmentById, useMutationGetEnvironments } from '../../../../infrastructure/services/environments';
import { IEnvironment, IEnvironmentSummary } from '../../../../infrastructure/services/environments/types';
import { LOCAL_STORAGE_KEYS } from '../../../../infrastructure/localStorageKeys';
import { IScoreboardSingleResults } from '../../../../infrastructure/services/scoring/types';
import { useMutationGetScoreboardSingleResults } from '../../../../infrastructure/services/scoring';
import { useSelector } from 'react-redux';
import { selectUsername } from '../../../../infrastructure/services/users/slice';
import { getDefaultCodeConfig } from './code_editor';
import { clearLocalCode, getLocalCode, getLocalCodeOr, saveLocalCode } from '../../../../infrastructure/utils/localStorage';
import { useMutationPostGenerateReport } from '../../../../infrastructure/services/reports';

export interface ICampaignsParticipateProps {
    slug: string;
};

const intervals: Array<number> = [];
const listeners: Array<any> = [];

const CampaignsParticipateView = (props: ICampaignsParticipateProps) => {
    const { slug } = props;

    const codeEditorRef = useRef<any>();
    const history = useHistory();

    const [isReadyToRender, setReadyToRender] = useState<boolean>(false);
    const [activePanelTab, setActivePanelTab] = useState<number>(0);

    const {
        data: campaign,
        isUninitialized,
        isLoading,
        isError,
        error,
    } = useGetCampaignFromRoute(slug);

    const currentUsername = useSelector(selectUsername);

    const [fetchNextChallenge] = useMutationGetNextChallengeForCampaignById();
    const [fetchCurrentChallenge] = useMutationGetCurrentChallengeForCampaignById();
    const [fetchCheckOne] = useMutationPostCampaignCheckOneCheck();
    const [fetchCheckAll] = useMutationCheckAll();
    const [fetchSubmitCurrentChallenge] = useMutationCheckCurrentChallengeForCampaignById();
    const [fetchEnvironmentById] = useMutationGetEnvironmentById();
    const [fetchAllEnvironments] = useMutationGetEnvironments();
    const [fetchScoreboardSingleResults] = useMutationGetScoreboardSingleResults();
    const [fetchSkipChallenge] = useMutationPostSkipNextChallenge();
    const [generateReport] = useMutationPostGenerateReport();

    const [userResults, setUserResults] = useState<IScoreboardSingleResults | null>(null);

    const [checksProcessing, setChecksProcessing] = useState<Record<string, boolean>>({});
    const [currentChallenge, setCurrentChallenge] = useState<IChallenge | null>(null);
    const [consoleData, setConsoleData] = useState<IConsoleOutput | null>(null);
    const [checksResults, setChecksResults] = useState<Record<string, 'INDETERMINATE' | 'FAILED' | 'SUCCEED'>>({});
    const [isPassButtonLoading, setPassButtonLoading] = useState<boolean>(false);
    const [isSubmitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);
    const [currentEditedFile, setCurrentEditedFile] = useState<string>()

    const [environements, setEnvironments] = useState<IEnvironmentSummary[]>([]);
    const [currentEnvironement, setCurrentEnvironement] = useState<IEnvironmentSummary | null>();
    const [isCheckAllLoading, setIsCheckAllLoading] = useState<boolean>(false);
    const [isCodeHistoryOpen, setCodeHistoryOpen] = useState<boolean>(false);

    const updateUserResults = async (camp: ICampaignSummary) => {
        if (camp.associated_scoreboard && currentUsername) {
            fetchScoreboardSingleResults({
                id: camp.associated_scoreboard,
                username: currentUsername
            }).then((resp) => {
                if (resp.data) {
                    setUserResults(resp.data);
                }
            });
        }
    };

    const [codeConfig, setCodeConfig] = useState<ICodeEditorConfig>(getDefaultCodeConfig());

    const redirectTo = (url: string) => {
        history.push(url);
    };

    const fetchEnvironments = useCallback(
        async (
            _environments: string[],
            placeholders: Record<string, string>,
            campaign_id: string,
            challenge_id: string,
        ): Promise<IEnvironmentSummary[]> => {
            const fetched: IEnvironmentSummary[] = [];

            if (!_environments.length) {
                const envs = await fetchAllEnvironments();
                if (envs.data) {
                    fetched.push(
                        ...envs.data.environments.map((env) => {
                            const defaultCode = (
                                placeholders[env.id] !== undefined
                                    ? placeholders[env.id]
                                    : undefined
                            );
                            const savedCode = getLocalCodeOr(
                                campaign_id,
                                challenge_id,
                                env.id,
                                defaultCode || ""
                            );
                            return {
                                ...env,
                                placeholder: defaultCode,
                                saved_code: savedCode
                            }
                        })
                    );
                } else {
                    fetched.push({
                        id: "python",
                        name: "Python",
                        interpret_as: "python",
                        description: "",
                        placeholder: ""
                    });
                }

                return fetched;
            }

            for (let env of _environments) {
                const resp = await fetchEnvironmentById(env);

                if (resp.error) {
                    fetched.push({
                        id: env,
                        name: env,
                        interpret_as: "text",
                        description: "",
                        placeholder: ""
                    });

                    toast.notify(
                        <Toast
                            text="Unable to fetch one required environment !"
                            type="alert"
                        />,
                        {
                            duration: 10000,
                            position: "top"
                        }
                    );
                } else if (resp.data) {
                    const defaultCode = (
                        placeholders[resp.data.id] !== undefined
                            ? placeholders[resp.data.id]
                            : undefined
                    );
                    const savedCode = getLocalCodeOr(
                        campaign_id,
                        challenge_id,
                        resp.data.id,
                        defaultCode || ""
                    );

                    fetched.push({
                        ...resp.data,
                        placeholder: (
                            placeholders[resp.data.id] !== undefined
                                ? placeholders[resp.data.id]
                                : " "
                        ),
                        saved_code: savedCode
                    });
                }
            }

            return fetched;
        }, [fetchEnvironmentById, fetchAllEnvironments]
    );

    const fetchChallenge = useCallback(
        async (campaign_id: string, challenge: IChallenge, _fetchEnvironments: (env: string[], placeholders: Record<string, string>, campaign_id: string, challenge_id: string) => Promise<IEnvironmentSummary[]>, currentEnv: IEnvironmentSummary | null = null) => {
            const fetchEnvironments = await _fetchEnvironments(challenge.environments, challenge.placeholders, campaign_id, challenge.id);

            setEnvironments(fetchEnvironments);

            let newEnvironment = currentEnv ? currentEnv : fetchEnvironments[0]
            if (currentEnv) {
                for (const fetchEnv of fetchEnvironments) {
                    if (fetchEnv.id == currentEnv.id) {
                        newEnvironment = fetchEnv
                    }
                }
            }

            setCurrentEnvironement(newEnvironment);
            const resp = await fetchEnvironmentById(newEnvironment.id)
            if (resp.data && resp.data.files.length > 0) {
                setCurrentEditedFile(resp.data.files[0].path)
            }
            else {
                setCurrentEditedFile("")
            }

            setCurrentChallenge(challenge);
            setChecksResults(Object.keys(challenge.inputs).reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr]: 'INDETERMINATE'
                }
            }, {}));

            setReadyToRender(true);
        }, [campaign]
    );

    const fetchChallengeMeta = async (currentCampaign: ICampaignSummary) => {
        let needToNext = false;

        await updateUserResults(currentCampaign);
        const resp = await fetchCurrentChallenge(currentCampaign.id);

        if (resp.error) {
            const error = (resp.error as any);
            if (error.status === 403) { // Campaign unjoinded yet || missing tags
                toast.notify(
                    <Toast
                        text="You not joined the campaign yet. May be a server error. Please verify, or try again later."
                        type="alert"
                    />,
                    {
                        duration: 10000,
                        position: "bottom-left"
                    }
                );
                redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
            } else if (error.status === 424) { // Need to next
                needToNext = true;
            } else {
                toast.notify(
                    <Toast
                        text="Unexpected error. Please try again later."
                        type="alert"
                    />,
                    {
                        duration: 10000,
                        position: "bottom-left"
                    }
                );
                redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
            }
        } else if (resp.data) {
            const challengeResp = resp.data;
            await fetchChallenge(currentCampaign.id, challengeResp, fetchEnvironments);
        }

        if (needToNext) {
            const nextResp = await fetchNextChallenge(currentCampaign.id);

            if (nextResp.error) {
                const nextError = (nextResp.error as any);

                if (nextError.status === 403) { // campaign unjoined yet || missing tags
                    toast.notify(
                        <Toast
                            text="You not joined the campaign yet. May be a server error. Please verify, or try again later."
                            type="alert"
                        />,
                        {
                            duration: 10000,
                            position: "bottom-left"
                        }
                    );
                    redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
                } else {
                    toast.notify(
                        <Toast
                            text="Unexpected server error. Please try again later"
                            type="alert"
                        />,
                        {
                            duration: 10000,
                            position: "bottom-left"
                        }
                    );
                    redirectTo(`${CAMPAIGNS_ROUTE}/${slug}`);
                }
            } else if (nextResp.data) {
                const challengeResp = nextResp.data;
                await fetchChallenge(currentCampaign.id, challengeResp, fetchEnvironments);
            }
        }
    };

    const saveCodeToLocal = (
        campaign_id: string,
        challenge_id: string,
        env_id: string
    ) => {
        if (!codeEditorRef.current) {
            return;
        }

        saveLocalCode(
            campaign_id,
            challenge_id,
            env_id,
            codeEditorRef.current.getCodeContent()
        );
    }

    const handleSpecialKeysPressed = (campaign_id: string, challenge_id: string, env_id: string) => (e: KeyboardEvent) => {
        if (e.key === "r" && e.ctrlKey) {
            e.preventDefault();

            handleCheckAllClicked();
        }

        if (e.key === "s" && e.ctrlKey) {
            e.preventDefault();

            saveCodeToLocal(
                campaign_id,
                challenge_id,
                env_id,
            );

            toast.notify(
                <Toast
                    text="Code sauvegardé"
                />,
                {
                    duration: 5000,
                    position: "bottom-left"
                }
            );
        }
    }

    useEffect(() => {
        if (campaign) {
            updateDocumentTitleForParticipatingTo(campaign);
            fetchChallengeMeta(campaign);
        }

        return () => {
            updateDocumentTitleToDefault();
        };
    }, [campaign]);

    useEffect(() => {
        if (!campaign || !currentChallenge || !currentEnvironement) {
            return;
        }

        let interval = intervals.pop();
        while (interval) {
            clearInterval(interval);
            interval = intervals.pop();
        }

        let lastListener = listeners.pop();
        while (lastListener) {
            document.removeEventListener("keydown", lastListener);
            lastListener = listeners.pop();
        }

        const nextInterval = setInterval(
            saveCodeToLocal, 30000,
            campaign.id,
            currentChallenge.id,
            currentEnvironement.id
        );

        intervals.push(nextInterval);

        const listener = handleSpecialKeysPressed(campaign.id, currentChallenge.id, currentEnvironement.id);
        listeners.push(listener);
        document.addEventListener('keydown', listener);

        return () => {
            for (let interval of intervals) {
                clearInterval(interval);
            }
        }
    }, [campaign, currentChallenge, currentEnvironement])

    const updateActivePanelTab = (_: any, value: number) => {
        setActivePanelTab(value);
    };

    const handleTestRunned = (test: string) => {
        return async () => {
            if (!campaign || !currentChallenge || !codeEditorRef.current || !currentEnvironement) {
                return;
            }

            setChecksProcessing({
                ...checksProcessing,
                [test]: true
            });

            const code = codeEditorRef.current.getCodeContent(true);

            const resp = await fetchCheckOne({
                campaign_id: `${campaign.id}`,
                check_id: test,
                environment: currentEnvironement.id,
                code: code,
                path: currentEditedFile ? currentEditedFile : ""
            });

            if (resp.error) {
                const error = (resp.error as any);

                toast.notify(
                    <Toast
                        text="Unexpected error occured. Please try again later."
                        type="alert"
                    />,
                    {
                        duration: 10000,
                        position: "bottom-left"
                    }
                );
                console.error(error);
            } else if (resp.data) {
                setChecksResults({
                    ...checksResults,
                    [test]: resp.data.success ? 'SUCCEED' : 'FAILED'
                });

                let errors = resp.data.stderr ? resp.data.stderr : null;
                if (errors === null) {
                    if (resp.data.status === "Failure") {
                        errors = resp.data.details;
                    };
                };
                setConsoleData({
                    success: resp.data.success,
                    input: code.length ? code : "\\n",
                    output: resp.data.stdout ? resp.data.stdout : " ",
                    expected: (
                        currentChallenge.inputs[test].expected_stdout
                            ? currentChallenge.inputs[test].expected_stdout
                            : " "
                    ),
                    errors: errors,
                    check_key: test
                });
                setActivePanelTab(1);
            }

            setChecksProcessing({
                ...checksProcessing,
                [test]: false
            });
        };
    };

    const handleCheckAllClicked = async () => {
        if (!currentChallenge || !currentEnvironement) {
            return;
        }

        setIsCheckAllLoading(true);
        const nextChecksProcessing = Object.keys(currentChallenge.inputs)
            .reduce((prev, curr) => {
                return ({
                    ...prev,
                    [curr]: true
                });
            }, {});
        setChecksProcessing(nextChecksProcessing);

        const code = codeEditorRef.current.getCodeContent(true);
        const resp = await fetchCheckAll({
            challenge_id: currentChallenge.id,
            environment: currentEnvironement.id,
            code: code,
            path: currentEditedFile ? currentEditedFile : ""
        });

        if (resp.error) {
            toast.notify(
                <Toast
                    text="Unexpected error occured. Please try again later."
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "bottom-left"
                }
            )
            console.error(resp.error);
        } else if (resp.data) {
            if (resp.data.success) { // All checks are ok
                const nextChecksResults = Object.keys(checksResults)
                    .reduce((prev, curr) => {
                        return {
                            ...prev,
                            [curr]: 'SUCCEED'
                        };
                    }, {});

                setChecksResults(nextChecksResults);
            } else {
                const checks = resp.data.inputs_results;
                if (!checks) {
                    const nextChecksResults = Object.keys(checksResults)
                        .reduce((prev, curr) => {
                            return {
                                ...prev,
                                [curr]: 'FAILED'
                            };
                        }, {});

                    setChecksResults(nextChecksResults);
                } else {
                    const nextChecksResults = Object.keys(checks)
                        .reduce((prev, curr) => {
                            return {
                                ...prev,
                                [curr]: checks[curr] ? 'SUCCEED' : 'FAILED'
                            };
                        }, {});

                    setChecksResults(nextChecksResults);
                }
            }
        }

        setChecksProcessing({});
        setIsCheckAllLoading(false);
    };

    const handlePassClicked = async () => {
        if (!campaign || !currentChallenge || !currentEnvironement) {
            return;
        }
        setPassButtonLoading(true);

        const code = codeEditorRef.current.getCodeContent(true);
        const checkResp = await fetchSubmitCurrentChallenge({
            id: campaign.id,
            environment: currentEnvironement.id,
            code: code,
            path: currentEditedFile ? currentEditedFile : ""
        });

        if (checkResp.error) {

            console.error(checkResp.error);
            toast.notify(
                <Toast
                    text="Unexpected error occured. Please try again later."
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "bottom-left"
                }
            );

        } else if (checkResp.data) {

            if (checkResp.data.success) {
                const nextResp = await fetchNextChallenge(campaign.id);
                if (nextResp.error) {
                    const error = (nextResp.error as any);
                    if (error.status === 422) {
                        redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                    } else {
                        toast.notify(
                            <Toast
                                text="Unexpected error occured. Please try again later."
                                type="alert"
                            />,
                            {
                                duration: 10000,
                                position: "bottom-left"
                            }
                        );
                    }
                } else if (nextResp.data) {
                    clearLocalCode(campaign.id, currentChallenge.id);

                    const challengeResp = nextResp.data;
                    await fetchChallenge(campaign.id, challengeResp, fetchEnvironments);

                    codeEditorRef.current.resetCode();
                    setConsoleData(null);
                    setActivePanelTab(0);
                }
            } else {
                const skipResp = await fetchSkipChallenge(campaign.id);
                if (skipResp.error) {
                    const error = (skipResp.error as any);
                    if (error.status === 422) {
                        redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                    } else {
                        toast.notify(
                            <Toast
                                text="Unexpected error occured. Please try again later."
                                type="alert"
                            />,
                            {
                                duration: 10000,
                                position: "bottom-left"
                            }
                        );
                    }
                } else {
                    const nextResp = await fetchNextChallenge(campaign.id);
                    if (nextResp.error) {
                        const error = (nextResp.error as any);
                        if (error.status === 422) {
                            generateReport({
                                campaign_id: campaign.id,
                                username: currentUsername
                            })
                            redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                        } else {
                            toast.notify(
                                <Toast
                                    text="Unexpected error occured. Please try again later."
                                    type="alert"
                                />,
                                {
                                    duration: 10000,
                                    position: "bottom-left"
                                }
                            );
                        }
                    } else if (nextResp.data) {
                        clearLocalCode(campaign.id, currentChallenge.id);

                        const challengeResp = nextResp.data;
                        await fetchChallenge(campaign.id, challengeResp, fetchEnvironments);

                        codeEditorRef.current.resetCode();
                        setConsoleData(null);
                        setActivePanelTab(0);
                    }
                }
            }
        }
        setPassButtonLoading(false);
    }

    const handleSubmitClicked = async () => {
        if (!campaign || !currentChallenge || !currentEnvironement) {
            return;
        }

        setSubmitButtonLoading(true);

        // 1 - Call /campaign/{id}/check
        //  1.1 - If error ????
        // 2 - Call /campaign/{id}/next
        //  2.1 - If 422, campaign is finished..
        //  2.2 - If 200, update current campaign by the given one

        setIsCheckAllLoading(true);
        const nextChecksProcessing = Object.keys(currentChallenge.inputs)
            .reduce((prev, curr) => {
                return ({
                    ...prev,
                    [curr]: true
                });
            }, {});
        setChecksProcessing(nextChecksProcessing);

        const code = codeEditorRef.current.getCodeContent(true);
        const checkResp = await fetchSubmitCurrentChallenge({
            id: campaign.id,
            environment: currentEnvironement.id,
            code: code,
            path: currentEditedFile ? currentEditedFile : ""
        });

        if (checkResp.error) {

            console.error(checkResp.error);
            toast.notify(
                <Toast
                    text="Unexpected error occured. Please try again later."
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "bottom-left"
                }
            );

        } else if (checkResp.data) {
            if (checkResp.data.success) {
                const nextResp = await fetchNextChallenge(campaign.id);
                if (nextResp.error) {
                    const error = (nextResp.error as any);
                    if (error.status === 422) {
                        if (campaign.challenge_skip == true) {
                            generateReport({
                                campaign_id: campaign.id,
                                username: currentUsername
                            })
                        }
                        redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                    } else {
                        toast.notify(
                            <Toast
                                text="Unexpected error occured. Please try again later."
                                type="alert"
                            />,
                            {
                                duration: 10000,
                                position: "bottom-left"
                            }
                        );
                    }
                } else if (nextResp.data) {
                    toast.notify(
                        <Toast
                            text={`"${currentChallenge.name}" completed !`}
                        />,
                        {
                            duration: 10000,
                            position: "bottom-left"
                        }
                    );

                    clearLocalCode(campaign.id, currentChallenge.id);

                    const challengeResp = nextResp.data;
                    await fetchChallenge(campaign.id, challengeResp, fetchEnvironments, currentEnvironement);

                    codeEditorRef.current.resetCode();
                    setConsoleData(null);
                    setActivePanelTab(0);
                }

            } else {
                if (["Timeout", "Started"].includes((checkResp.data as any).status)) {
                    toast.notify(
                        <Toast
                            text="Timeout ! Please check your code"
                            type="alert"
                        />,
                        {
                            duration: 10000,
                            position: "bottom-left"
                        }
                    );

                    const nextChecksResults = Object.keys(checksResults)
                        .reduce((prev, curr) => {
                            return {
                                ...prev,
                                [curr]: 'FAILED'
                            };
                        }, {});

                    setChecksResults(nextChecksResults);
                } else {
                    toast.notify(
                        <Toast
                            text="Some checks failed !"
                            type="alert"
                        />,
                        {
                            duration: 10000,
                            position: "bottom-left"
                        }
                    );

                    const checks = checkResp.data.inputs_results;
                    if (!checks) {
                        const nextChecksResults = Object.keys(checksResults)
                            .reduce((prev, curr) => {
                                return {
                                    ...prev,
                                    [curr]: 'FAILED'
                                };
                            }, {});

                        setChecksResults(nextChecksResults);
                    } else if (Object.keys(checks).length > 0) {
                        const nextChecksResults = Object.keys(checks)
                            .reduce((prev, curr) => {
                                return {
                                    ...prev,
                                    [curr]: checks[curr] ? 'SUCCEED' : 'FAILED'
                                };
                            }, {});

                        setChecksResults(nextChecksResults);
                    }
                    if (campaign.challenge_skip) {
                        const skipResp = await fetchSkipChallenge(campaign.id);
                        if (skipResp.error) {
                            const error = (skipResp.error as any);
                            if (error.status === 422) {
                                redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                            } else {
                                toast.notify(
                                    <Toast
                                        text="Unexpected error occured. Please try again later."
                                        type="alert"
                                    />,
                                    {
                                        duration: 10000,
                                        position: "bottom-left"
                                    }
                                );
                            }
                        } else {
                            const nextResp = await fetchNextChallenge(campaign.id);
                            if (nextResp.error) {
                                const error = (nextResp.error as any);
                                if (error.status === 422) {
                                    redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
                                } else {
                                    toast.notify(
                                        <Toast
                                            text="Unexpected error occured. Please try again later."
                                            type="alert"
                                        />,
                                        {
                                            duration: 10000,
                                            position: "bottom-left"
                                        }
                                    );
                                }
                            } else if (nextResp.data) {
                                clearLocalCode(campaign.id, currentChallenge.id);

                                const challengeResp = nextResp.data;
                                await fetchChallenge(campaign.id, challengeResp, fetchEnvironments);

                                codeEditorRef.current.resetCode();
                                setConsoleData(null);
                                setActivePanelTab(0);
                            }
                        }
                    }
                }
            }
        }

        setSubmitButtonLoading(false);
        setChecksProcessing({});
        setIsCheckAllLoading(false);
    };

    const handleSelectedEnvChanged = async (elem: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = elem.target;

        const nextEnv = environements.find(env => env.id === value);
        if (nextEnv) {
            if (currentEnvironement) {
                const nextCurrentEnv = { ...currentEnvironement };
                nextCurrentEnv.saved_code = codeEditorRef.current.getCodeContent();

                const nextEnvs = [...environements];
                const envId = environements.findIndex(v => v.id === nextCurrentEnv.id);
                if (envId !== -1) {
                    nextEnvs[envId] = nextCurrentEnv;
                    setEnvironments(nextEnvs);
                }
                setCurrentEnvironement(nextCurrentEnv);
            }

            setCurrentEnvironement(nextEnv);

            const resp = await fetchEnvironmentById(nextEnv.id);
            if (resp.data && resp.data.files.length > 0) {
                setCurrentEditedFile(resp.data.files[0].path);
            }
            else {
                setCurrentEditedFile("")
            }

            if (!codeEditorRef.current) {
                return;
            }

            if (campaign && currentChallenge) {
                const savedCode = getLocalCode(
                    campaign.id,
                    currentChallenge.id,
                    nextEnv.id
                );

                if (savedCode) {
                    codeEditorRef.current.setCode(savedCode);
                    return;
                }
            }

            codeEditorRef.current.setCode(nextEnv.placeholder || "");
        }
    };

    const toggleCodeHistory = () => {
        setCodeHistoryOpen(last => !last);
    }

    const handleResetCodeToDefaultEnv = () => {
        if (!codeEditorRef.current) {
            return;
        }

        codeEditorRef.current.resetCode();
    };

    const handleTopbarClicked = (action: TopbarButtonAction) => () => {
        switch (action) {
            case "config":
                setCodeConfig({
                    ...codeConfig,
                    isCodeEditorConfigOpen: true
                });
                break;
            case "resetToDefault":
                handleResetCodeToDefaultEnv();
                break;
            case "codeHistory":
                toggleCodeHistory();
                break;
            default:
                break;
        }
    };

    const handleConfigValueChanged = (action: ConfigAction) => (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        switch (action) {
            case "font-size":
                codeEditorRef.current.updateFontSize(e.target.value);
                setCodeConfig({
                    ...codeConfig,
                    fontSize: e.target.value as string
                });
                localStorage.setItem(LOCAL_STORAGE_KEYS.code_editor.font_size, e.target.value as string);
                break;
            case "theme":
                codeEditorRef.current.updateTheme(e.target.value);
                setCodeConfig({
                    ...codeConfig,
                    theme: e.target.value as string
                });
                localStorage.setItem(LOCAL_STORAGE_KEYS.code_editor.theme, e.target.value as string);
                break;
            case "tabSpaces":
                setCodeConfig({
                    ...codeConfig,
                    tabSpaces: e.target.value as number
                });
                localStorage.setItem(LOCAL_STORAGE_KEYS.code_editor.tab_spaces, (e.target.value as number).toString());
                break;
            default:
                break;
        }
    };

    const handleCloseCodeEditor = () => {
        setCodeConfig({
            ...codeConfig,
            isCodeEditorConfigOpen: false
        });
    }

    if (isError && error) {
        const err = (error as any);
        let errorMsg = "Internal Error";

        if (err.data && err.data.detail) {
            errorMsg = err.data.detail.message;
        }

        return (
            <DJCerror
                status={err.status}
                errorMessage={errorMsg}
            />
        )
    }

    const handleTimeExpired = () => {
        toast.notify(
            <Toast
                text="La campagne est terminée, veillez lever vos stylos :) !"
            />,
            {
                duration: 10000,
                position: "top"
            }
        );

        redirectTo(campaign ? `/campaigns/${campaign.route_name}` : '/campaigns');
    }

    if (isUninitialized || isLoading || !isReadyToRender) {
        return null;
    }

    if (!campaign || !currentChallenge || !currentEnvironement || !currentUsername) {
        return (
            <DJCerror
                status={500}
                errorMessage="Service Temporarily Unavailable"
            />
        )
    }

    return (
        <Render
            campaign={campaign}
            currentChallenge={currentChallenge}
            activePanelTab={activePanelTab}
            consoleOutput={consoleData}
            codeEditorRef={codeEditorRef}
            checksProcessing={checksProcessing}
            checksResults={checksResults}
            environements={environements}
            currentEditedFile={currentEditedFile}
            currentEnvironement={currentEnvironement}
            isCheckAllLoading={isCheckAllLoading}
            isPassButtonLoading={isPassButtonLoading}
            isSubmitButtonLoading={isSubmitButtonLoading}
            codeConfig={codeConfig}
            userResults={userResults}
            isCodeHistoryOpen={isCodeHistoryOpen}
            username={currentUsername}
            updateActivePanelTab={updateActivePanelTab}
            handleTestRunned={handleTestRunned}
            handleCheckAllClicked={handleCheckAllClicked}
            handlePassClicked={handlePassClicked}
            handleSubmitClicked={handleSubmitClicked}
            handleSelectedEnvChanged={handleSelectedEnvChanged}
            handleTopbarClicked={handleTopbarClicked}
            handleConfigValueChanged={handleConfigValueChanged}
            handleCloseCodeEditor={handleCloseCodeEditor}
            handleTimeExpired={handleTimeExpired}
        />
    );
}

export default CampaignsParticipateView;
