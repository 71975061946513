import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import './styles/global.scss';

import reportWebVitals from './reportWebVitals';
import DojoCodesView from "./domain/views/dojocodes";
import { rootReducer } from './infrastructure/stores';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from './infrastructure/middlewares/logger';
import { api } from './infrastructure/services';

import 'toasted-notes/src/styles.css'
import 'bootstrap-social/bootstrap-social.css';

import 'bootstrap/dist/js/bootstrap.bundle';

import 'bs-stepper/dist/css/bs-stepper.min.css';

const store = configureStore({
  reducer: rootReducer,
  middleware: [logger, ...getDefaultMiddleware().concat(api.middleware)]
})

ReactDOM.render(
  <Provider store={store}>
    <DojoCodesView />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
