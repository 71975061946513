import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../../infrastructure/globals/routes';
import { usePostRefresh } from '../../../infrastructure/services/authentication';
import { setAccessToken, setIsConnecting, setScopes } from '../../../infrastructure/services/authentication/slice';
import { cleanAuthOnLocalStorage, getScopesFromLocalStorage, getTokenFromLocalStorage, saveCredentialsOnLocalStorage } from '../../../infrastructure/services/authentication/utils';
import { useGetSelf } from '../../../infrastructure/services/users';
import { setCurrentUser, selectCurrentUser } from '../../../infrastructure/services/users/slice';

import { isSignInDialogOpenSelector } from '../../../infrastructure/stores/global/selectors';
import Render from './render';

const DISABLE_TOP_PADDING_FOR = [
    SIGN_IN_ROUTE,
    SIGN_UP_ROUTE
];

const DojoCodesView = () => {
    const isSignInDialogOpen = useSelector(isSignInDialogOpenSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const [refresh, { isLoading: isRefreshing }] = usePostRefresh();
    const [getSelf, { isLoading: isLoadingSelf }] = useGetSelf();
    const [isReadyToDraw, setReadyToDraw] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    const [showConfirmationInformations, setShowConfirmationInformations] = useState(false);

    const tryToLogin = useCallback(async () => {
        const accessToken = getTokenFromLocalStorage();
        const scopes = getScopesFromLocalStorage();

        if (accessToken) {
            dispatch(setIsConnecting(true));
            try {
                const result = await refresh(accessToken);
                
                if (result.data) {
                    dispatch(setAccessToken({
                        access_token: result.data.access_token
                    }));
                    dispatch(setScopes({
                        scopes: scopes
                    }));
                    
                    saveCredentialsOnLocalStorage(result.data);
                }

                const selfResult = await getSelf();
                if (selfResult.data) {
                    dispatch(setCurrentUser(selfResult.data));
                }
            } catch (e) {
                cleanAuthOnLocalStorage();
            }

            
            dispatch(setIsConnecting(false));
        }

        setReadyToDraw(true);
    }, [dispatch, getSelf, refresh]);

    useEffect(() => {
        tryToLogin();
    }, [tryToLogin]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (
            ((currentUser.guild === null || currentUser.guild === "")
            || (currentUser.phone === null || currentUser.phone === ""))
            && localStorage.getItem(btoa("@@user__confirmInformationsSaved")) === null
         ) {
            setShowConfirmationInformations(true);
        }
    }, [currentUser]);

    const handleConfirmationInformationsValidated = () => {
        setShowConfirmationInformations(false);
        localStorage.setItem(btoa("@@user__confirmInformationsSaved"), "1");
        window.location.href = "/authentication/update/guild-and-phone";
    };

    const handleConfirmationInformationsClosed = () => {
        localStorage.setItem(btoa("@@user__confirmInformationsSaved"), "1");
        setShowConfirmationInformations(false);
    }


    if (!isReadyToDraw || isRefreshing || isLoadingSelf) {
        return null;
    }

    return (
        <Render
            isSignInDialogOpen={isSignInDialogOpen}
            isPaddingTopDisabled={false}
            showConfirmationInformations={showConfirmationInformations}
            handleConfirmationInformationsValidated={handleConfirmationInformationsValidated}
            handleConfirmationInformationsClosed={handleConfirmationInformationsClosed}
        />
    );
};

export default DojoCodesView;
