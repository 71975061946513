
import { moment } from '../moment';

export const getHumanizedDateTime = (dateStr: string) : string => {
    const date = new Date(dateStr);
    return moment(date).format("Do MMMM YYYY, hh:mm:ss")
};

export const getHumanizedDate = (dateStr: string) : string => {
    const date = new Date(dateStr);
    return moment(date).format("Do MMMM YYYY")
};

export const calculateDurationBetweenTwoDate = (date1: number, date2: number) => {
    const seconds = ~~((new Date(date1).getTime() - new Date(date2).getTime()) / 1000);
    const hours = ~~(seconds / 3600);
    const minutes = ~~(seconds / 60) % 60;
    const sec = seconds % 60;

    return [hours, minutes, sec];
}

export const stringifyDuration = (date1: number, date2: number) => {
    const [hours, minutes, secs] = calculateDurationBetweenTwoDate(date1, date2);

    if (hours >= 1) {
        return `${hours}h ${minutes}m ${secs}s`;
    }

    return `${minutes}m ${secs}s`;
}

export const strigifySecondsToHMS = (seconds: number) => {
    const hours = ~~(seconds / 3600);
    const minutes = ~~(seconds / 60) % 60;
    const sec = seconds % 60;

    if (hours >= 1) {
        return `${hours}h ${minutes}m ${sec}s`;
    }

    return `${minutes}m ${sec}s`;
};