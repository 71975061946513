import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#eaa583',
            main: '#B1518E',
            dark: '#a06446',
            contrastText: '#fff',
        },
        secondary: {
            light: '#7c6779',
            main: '#34125F',
            dark: '#402d3d',
            contrastText: '#000',
        },
    },
});