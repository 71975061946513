import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import humanizedDuration from 'humanize-duration';

import { useGetCampaignFromRoute, useMutationCampaignIsDone, useMutationDeleteCampaignById, useMutationGetCampaignParticipantStatus, useMutationIsCampaignParticipant, useMutationLeaveCampaignById } from '../../../../infrastructure/services/campaign';
import DJCerror from '../../../components/error';

import Render from './render';
import { useMutationJoinCampaignById } from '../../../../infrastructure/services/campaign';
import { ICampaignSummary, ICampaignParticipantStatusEntry } from '../../../../infrastructure/services/campaign/types';
import { moment } from '../../../../infrastructure/moment';

import { updateDocumentTileForCampaign, updateDocumentTitleToDefault } from '../../../../infrastructure/logic/document';
import { useSelector } from 'react-redux';
import { selectIsOneScopeIncluded, selectIsScopeIncluded } from '../../../../infrastructure/services/authentication/slice';
import { ADMIN__DELETE_CAMPAIGN, ADMIN__EDIT_CAMPAIGN, JOIN_CAMPAIGN, JOIN_CAMPAIGN_WITH_PASSWORD, LEADERBOARD, LEAVE_CAMPAIGN, PARTICIPATE, SHOW_RESULTS } from './types';
import { selectCurrentUser } from '../../../../infrastructure/services/users/slice';
import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';
import { useHistory } from 'react-router-dom';
import { useMutationGetScoreboardResultsById } from '../../../../infrastructure/services/scoring';
import { IScoreboardResults } from '../../../../infrastructure/services/scoring/types';

interface ICampaignViewProps {
    slug: string;
};

const CampaignsDetailView = (props: ICampaignViewProps) => {
    const { slug } = props;

    const history = useHistory();

    const [userAlreadyParticipating, setUserAlreadyParticipating] = useState<boolean>(false);
    const [isReadyToRender, setReadyToRender] = useState(false);

    const [isPasswordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);
    const [password, setPassword] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [isCampaignDone, setCampaignDone] = useState<boolean>(false);
    const [scoreboard, setScoreboard] = useState<IScoreboardResults | null>(null);
    const [participantStatus, setParticipantStatus] = useState<Array<ICampaignParticipantStatusEntry>>([]);

    const currentUser = useSelector(selectCurrentUser);
    const isScopeInlcuded = useSelector(selectIsScopeIncluded);
    const isOneScopeIncluded = useSelector(selectIsOneScopeIncluded);

    const {
        data: campaign,
        isUninitialized,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCampaignFromRoute(slug);

    const [fetchScoreboardById] = useMutationGetScoreboardResultsById();

    const [fetchJoinCampaignById] = useMutationJoinCampaignById();
    const [fetchLeaveCampaignById] = useMutationLeaveCampaignById();
    const [fetchDeleteCampaignById] = useMutationDeleteCampaignById();
    const [fetchIsCampaignFinished] = useMutationCampaignIsDone();
    const [fetchCampaignParticipantStatus] = useMutationGetCampaignParticipantStatus();
    const [fetchIsCampaignParticipant] = useMutationIsCampaignParticipant();

    const init = useCallback(async (camp: ICampaignSummary) => {
        if (currentUser) {
            const userParticipating = await fetchIsCampaignParticipant(
                camp.id
            )
            if (userParticipating.data !== undefined) {
                if (userParticipating.data.is_participant) {
                    setUserAlreadyParticipating(true);
                }
            }
        }

        const campaignDoneResp = await fetchIsCampaignFinished(camp.id);
        if (campaignDoneResp.data !== undefined) {
            if (campaignDoneResp.data.completed) {
                setCampaignDone(true);
            }
        }

        setReadyToRender(true);
    }, [currentUser, fetchIsCampaignFinished]);

    const initScoreboard = useCallback(async (scoreboardId: string) => {
        const scoreboardResults = await fetchScoreboardById(scoreboardId);

        if (scoreboardResults.data) {
            setScoreboard(scoreboardResults.data);
        }
    }, [fetchScoreboardById]);

    const initCampaignParticipantStatus = useCallback(async (camp: ICampaignSummary, username: string) => {
        const participantResults = await fetchCampaignParticipantStatus({
            id: camp.id,
            username: username
        });

        if (participantResults.data) {
            const nextEntries = [...participantResults.data.entries]
                .filter(a => a.end_time !== null)
                .sort((a, b) => {
                    if (a.start_time === null) {
                        return -1;
                    }
                    if (b.start_time === null) {
                        return 1;
                    }

                    return Date.parse(a.start_time) - Date.parse(b.start_time);
                });

            setParticipantStatus(nextEntries);
        }
    }, [fetchCampaignParticipantStatus]);

    useEffect(() => {
        if (campaign) {
            updateDocumentTileForCampaign(campaign);
            init(campaign);

            if (currentUser) {
                initCampaignParticipantStatus(campaign, currentUser.username);
            }
            if (campaign.associated_scoreboard) {
                initScoreboard(campaign.associated_scoreboard);
            }
        }

        return () => {
            updateDocumentTitleToDefault();
        };
    }, [campaign, currentUser, init, initScoreboard, initCampaignParticipantStatus]);

    const redirectTo = (url: string) => {
        history.push(url);
    };

    const getHumanizedDuration = (duration: number): string => {
        return humanizedDuration(duration * 60000, { units: ["d", "h", "m"] });
    };

    const getHumanizedDate = (dateStr: string): string => {
        const date = new Date(dateStr);
        return moment(date).format("Do MMMM YYYY, hh:mm:ss")
    };

    const getStringifiedParticipants = (participants: string[], maxParticipants: number | null): string => {
        if (maxParticipants) {
            return `${participants.length} / ${maxParticipants}`
        }

        return `${participants.length}`
    };

    const isParticipateDisabled = useMemo<{ disabled: boolean, reason: string | null }>(() => {
        if (!campaign) {
            return {
                disabled: true,
                reason: "wtf ?"
            };
        }

        if (isCampaignDone) {
            return {
                disabled: true,
                reason: "La campagne est déjà terminée :)."
            };
        }

        const now = new Date();
        const start = new Date(campaign.start_date);

        if (now < start) {
            return {
                disabled: true,
                reason: "La campagne n'a pas encore commencée."
            };
        }

        if (campaign.expiration_date) {
            const expiration = new Date(campaign.expiration_date);

            if (now >= expiration) {
                return {
                    disabled: true,
                    reason: "La campagne est terminée."
                };
            }
        }

        return {
            disabled: false,
            reason: null
        };
    }, [campaign, isCampaignDone]);

    const tryToJoinCampaign = async (password: string | null = null) => {
        if (!campaign) {
            return {
                joined: false,
                reason: "No Campaign"
            };
        }

        const resp = await fetchJoinCampaignById({
            id: campaign.id,
            password: password
        });

        if (resp.error) {
            const error = (resp.error as any);
            if (error.status === 409) { // user already joined
                // TODO: Handle
                return {
                    joined: false,
                    reason: "User Already Joined this Campaign."
                };
            } else if (error.status === 403) {
                if (error.data.detail.error === "InvalidCampaignPassword") {
                    return {
                        joined: false,
                        reason: "Invalid Password"
                    };
                }
            } else { // Server error
                return {
                    joined: false,
                    reason: "Internal Server Error"
                };
            }
        } else if (!!resp.data && resp.data === true) {
            if (currentUser) {
                refetch();
                setUserAlreadyParticipating(true);
            }
        }

        return {
            joined: true,
            reason: null
        };
    };

    const handleJoinCampaignWithPassword = async () => {
        if (!campaign) {
            return;
        }

        const joinedResp = await tryToJoinCampaign(password);
        if (joinedResp.joined) {
            setPasswordDialogOpen(false);
            if (passwordError) {
                setPasswordError(null);
            }
            setPassword(null);
        } else {
            setPasswordError(joinedResp.reason);
        }
    };

    const handleJoinCampaign = async () => {
        if (!campaign) {
            return;
        }

        if (campaign.is_protected) {
            setPasswordDialogOpen(true);
        } else {
            tryToJoinCampaign();
        }
    };

    const handleLeaveCampaign = async () => {
        if (!campaign) {
            return;
        }

        const resp = await fetchLeaveCampaignById(campaign.id);
        if (resp.error) {
            const error = (resp.error as any);

            if (error.status === 403) { // already leaved
                // TODO: handle
            } else { // Unexpected error

            }
        } else if (!!resp.data && resp.data === true) {
            refetch();
            setUserAlreadyParticipating(false);
            setCampaignDone(false);
        }
    };

    const handleParticipate = () => {
        redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/participate`);
    };

    const handleDeleteCampaign = async () => {
        if (!campaign) {
            return;
        }

        const resp = await fetchDeleteCampaignById(campaign.id);
        if (resp.error) {
            // TODO: HANDLE ERROR
        }
        /*

        if (resp.data) {
            if (resp.data.status === 200 || resp.data.status === 204) {
                redirectTo(CHALLENGES_ROUTE);
            }
        }

        */
    };

    const handleEditCampaign = () => {

    };

    const handleShowResults = () => {
        redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/results`);
    };

    const handleUserAction = (action: string) => async () => {
        switch (action) {
            case JOIN_CAMPAIGN:
                handleJoinCampaign();
                break;
            case JOIN_CAMPAIGN_WITH_PASSWORD:
                handleJoinCampaignWithPassword();
                break;
            case LEAVE_CAMPAIGN:
                handleLeaveCampaign();
                break;
            case PARTICIPATE:
                handleParticipate();
                break;
            case SHOW_RESULTS:
                handleShowResults();
                break;
            case LEADERBOARD:
                redirectTo(`${CAMPAIGNS_ROUTE}/${slug}/leaderboard`);
                break;
            default:
                break;
        }
    };

    const handleAdminAction = (action: string) => async () => {
        switch (action) {
            case ADMIN__EDIT_CAMPAIGN:
                handleEditCampaign();
                break;
            case ADMIN__DELETE_CAMPAIGN:
                handleDeleteCampaign();
                break;
            default:
                break;
        }
    };

    const handleClosePasswordDialog = () => {
        setPasswordDialogOpen(false);
    };

    const isJoinButtonDisabled = () => {
        if (!campaign) {
            return {
                disabled: true,
                reason: "404"
            };
        }

        if (!currentUser) {
            return {
                disabled: true,
                reason: "You should be logged in !"
            };
        }

        if (campaign.user_tags_requirements.length > 0) {
            for (let tag of campaign.user_tags_requirements) {
                if (!currentUser.tags.includes(tag)) {
                    return {
                        disabled: true,
                        reason: "One required tag is missing on your account !"
                    }
                }
            }
        }

        return {
            disabled: false,
            reason: null
        };
    };

    const handlePasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    if (isError && error) {
        const err = (error as any);
        let errorMsg = "Internal Error";

        if (err.data && err.data.detail) {
            errorMsg = err.data.detail.message;
        }

        return (
            <DJCerror
                status={err.status}
                errorMessage={errorMsg}
            />
        )
    }

    if (isUninitialized || isLoading || !isReadyToRender) {
        return null;
    }

    if (!campaign) {
        return (
            <DJCerror
                status={404}
                errorMessage="Service Temporarily Unavailable"
            />
        )
    }

    return (
        <Render
            campaign={campaign}
            userAlreadyParticipating={userAlreadyParticipating}
            isPasswordDialogOpen={isPasswordDialogOpen}
            passwordError={passwordError}
            password={password}
            isParticipateDisabled={isParticipateDisabled}
            isCampaignDone={isCampaignDone}
            scoreboard={scoreboard}
            username={currentUser ? currentUser.username : null}
            participantStatus={participantStatus}
            isJoinButtonDisabled={isJoinButtonDisabled()}
            getHumanizedDuration={getHumanizedDuration}
            getHumanizedDate={getHumanizedDate}
            getStringifiedParticipants={getStringifiedParticipants}
            isScopeInlcuded={isScopeInlcuded}
            isOneScopeIncluded={isOneScopeIncluded}
            handleUserAction={handleUserAction}
            handleAdminAction={handleAdminAction}
            handleClosePasswordDialog={handleClosePasswordDialog}
            handlePasswordChanged={handlePasswordChanged}
        />
    );
};

export default CampaignsDetailView;
