import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutationPostCreatePage } from '../../../../../../infrastructure/services/pages';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import Toast from '../../../../../components/toast';

type Inputs = {
    id: string;
    title: string;
    content: string;
    route_name: string;
    visible: boolean;
    tags: string;
}

const AdminArticleCreateView = () => {
    const [ fetchCreate ] = useMutationPostCreatePage();

    const [options, setOptions] = useState<string[]>(["news", "events"]);
    const history = useHistory();

    const handleValidate = (v: Inputs) => {
        const errors: IError<Inputs> = {};

        if (v.title.length === 0) {
            errors.title = {
                error: true,
                message: "Le champ \"Titre\" est obligatoire."
            }
        }

        if (v.route_name.length === 0) {
            errors.route_name = {
                error: true,
                message: "Le champ \"Nom de Route\" est obligatoire."
            }
        } else if (v.route_name.match(/^([A-Za-z0-9-]+)$/g) === null) {
            errors.route_name = {
                error: true,
                message: "Mauvais format. La route doit contenir que des lettres, chiffres et des \"-\" (pas d'espaces)"
            }
        }

        if (v.content.length === 0) {
            errors.content = { error: true, message: "Le champ \"Contenu\" est obligatoire." };
        }

        return errors;
    }

    const {
        values,
        handleBlur, 
        handleChange,
        handleBlurAutocomplete,
        handleChangeAutocomplete,
        errors, 
        containsError, 
        fulfilled 
    } = useForm<Inputs>({
        defaultValues: {
            id: "",
            title: "",
            content: "",
            visible: true,
            route_name: "",
            tags: ""
        },
        required: [
            "title", "content", "route_name"
        ],
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/articles");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchCreate({
            id: values.title,
            title: values.title,
            content: values.content,
            route_name: values.route_name,
            visible: values.visible,
            tags: values.tags === null
                ? []
                : values.tags.split(',').map(s => s.trim()).filter(s => s.length > 0)
        });

        if (resp.data) {
            toast.notify(
                <Toast text={"Article créé avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/articles');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-newspaper" />
                    <h1>Créer Article</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12">
                                <TextField
                                    id="input-title"
                                    name="title"
                                    label="Titre"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.title !== undefined}
                                    helperText={errors.title?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Autocomplete
                                    id="input-tags"
                                    freeSolo
                                    multiple
                                    options={options}
                                    onBlur={handleBlurAutocomplete("tags")}
                                    onChange={handleChangeAutocomplete("tags")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"tags"}
                                            label={"Tags"}
                                            variant="outlined"
                                            error={errors.tags !== undefined}
                                            helperText={errors.tags?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-16 align-center">
                            <div className="col-6">
                                <TextField
                                    id="input-route-name"
                                    name="route_name"
                                    label="Route"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.route_name !== undefined}
                                    helperText={errors.route_name?.message}
                                />
                            </div>
                            <div className="col-6">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.visible}
                                            onChange={handleChange}
                                            name="visible"
                                            color="primary"
                                        />
                                    }
                                    label="Visible ?"
                                />
                            </div>
                        </div>
                        <div className="row mb-16 align-center">
                            <div className="col-12">
                                <TextField
                                    id="input-content"
                                    name="content"
                                    label="Contenu"
                                    multiline
                                    rowsMax={20}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.content !== undefined}
                                    helperText={errors.content?.message || "Format MD"}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarderee
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminArticleCreateView;
