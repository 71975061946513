import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ARTICLES_ROUTE } from '../../../../infrastructure/globals/routes';
import { useMutationGetPageBySlug } from '../../../../infrastructure/services/pages';
import { IPage } from '../../../../infrastructure/services/pages/types';
import Render from './render';

interface IArticleViewProps {
    slug: string;
};

const ArticleView = (props: IArticleViewProps) => {
    const { slug } = props;

    const history = useHistory();

    const [dirty, setDirty] = useState<boolean>(true);
    const [article, setArticle] = useState<IPage | null>(null);

    const [getPageBySlug] = useMutationGetPageBySlug();

    const redirectTo = useCallback((url: string) => {
        history.push(url);
    }, [history]);

    useEffect(() => {
        const loadFromBackend = async (slug: string) => {
            const results = await getPageBySlug({ slug: slug });

            if (results.data) {
                setArticle(results.data);
                setDirty(false);
            } else {
                redirectTo(ARTICLES_ROUTE);
            }
        }

        loadFromBackend(slug);
    }, [slug]);

    if (dirty || !article) {
        return null;
    }

    return (
        <Render
            article={article}
        />
    );
};

export default ArticleView;
