import React, { useState } from 'react';

import Render from './render';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import Toast from '../../components/toast';
import { useMutationUpdateUserInfos } from '../../../infrastructure/services/users';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../infrastructure/services/users/slice';
import { IUpdateQueryBody } from '../../../infrastructure/services/users/types';

const AuthUpdateGuildAndPhoneView = () => {

    const history = useHistory();
    const [values, setValues] = useState<Record<string, string | null>>({
        phone: null,
        guild: null
    });
    const [errors, setErrors] = useState<Record<string, string>>({});

    const currentUser = useSelector(selectCurrentUser);
    const [updateUserInfos] = useMutationUpdateUserInfos();

    const handleSubmit = async () => {
        if (!currentUser) {
            return false;
        }

        const body: Partial<IUpdateQueryBody> = {};
        if (values['phone']) { body.phone = values['phone'] };
        if (values['guild']) { body.guild = values['guild'] };

        const resp = await updateUserInfos({
            username: currentUser.username,
            body: body
        })

        if (resp.error) {
            const error = (resp.error as any);
            toast.notify(
                <Toast
                    text={error.data.detail.message}
                    type="alert"
                />,
                {
                    duration: 10000,
                    position: "top"
                }
            );

            return;
        }

        toast.notify(
            <Toast
                text="Informations sauvegardées correctement, merci :)"
                type="default"
            />,
            {
                duration: 10000,
                position: "top"
            }
        );
        
        redirect("/")();
    };

    const redirect = (to: string) => {
        return () => {
            history.push(to);
        }
    };

    const validate = (nextValues: Record<string, string | null>) => {
        const nextErrors: Record<string, string> = {};

        setErrors(nextErrors);
        return Object.keys(nextErrors).length > 0;
    };

    const handleInputChanged = (name: string) => (e: any) => {
        const nextValues: Record<string, string | null> = {...values};
        if (name === "phone") {
            nextValues[name] = e;
        } else {
            const value = e.target !== undefined ? e.target.value : e;
            nextValues[name] = value;
        }

        setValues(nextValues);
        validate(nextValues);
    };

    return (
        <Render
            errors={errors}
            handleSubmit={handleSubmit}
            handleInputChanged={handleInputChanged}
        />
    );
};

export default AuthUpdateGuildAndPhoneView;
