import React from 'react';

interface IStepperProps {
    steps: Array<string>;
    activeStep: number;
}

class Stepper extends React.Component<IStepperProps> {

    render() {
        return (
            <div className="bs-stepper">
                <div className="bs-stepper-header" role="tablist">
                    { /* <!-- your steps here --> */ }
                    {
                        this.props.steps.map((step, i) => (
                            <>
                                <div
                                    key={step}
                                    className={i === this.props.activeStep ? "step active" : "step"}
                                    data-target={`#${step}-part`}
                                >
                                    <div
                                        className="step-trigger custom"
                                        role="tab"
                                        aria-controls={`${step}-part`}
                                        id={`${step}-part-trigger`}
                                    >
                                        <span className="bs-stepper-circle">{i + 1}</span>
                                        <span className="bs-stepper-label">{step}</span>
                                    </div>
                                </div>

                                {
                                    i < this.props.steps.length - 1 && (
                                        <div className="line"></div>
                                    )
                                }
                            </>
                        ))
                    }
                </div>
            </div>
        )
    }
};

export default Stepper;
