import { Hal } from '../../../assets/assets';

interface IRenderProps {
    status: number;
    errorMessage: string;
};

const Render = (props: IRenderProps) => {
    const { status, errorMessage } = props;

    return (
        <div className="djc--error">
            <div className="left">
                <h1>{status}</h1>
                <h3>I'm sorry Dave, I'm afraid I can't do that.</h3>
                <p>{errorMessage}</p>
            </div>
            <div className="right">
                <img
                    src={Hal}
                    alt="Hal"
                />
            </div>
        </div>
    );
};

export default Render;
