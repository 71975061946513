import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import { Button, Collapse } from 'react-bootstrap';

import { ICampaignsSummary } from '.';
import { CAMPAIGNS_ROUTE } from '../../../infrastructure/globals/routes';
import DJCFooter from '../../components/footer';
import { FilterChanged, ICampaignsFilters } from './types';

interface RenderProps {
    getHumanizedDate: (date: string, endStr: string | null) => string;
    getStringifiedParticipants: (p: number, m: number | null) => string;
    handleFiltersChanged: (event: FilterChanged) => void;
    campaigns: ICampaignsSummary[];
    filtersValues: ICampaignsFilters;
};

const Render = (props: RenderProps) => {
    const {
        getHumanizedDate,
        getStringifiedParticipants,
        handleFiltersChanged,
        campaigns,
        filtersValues
    } = props;

    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <>
            <div className="djc--view-campaigns">
                <div className="container-fluid bg-blank pd-16 filters">
                    <div className="row">
                        <div className="col-md-10 col-sm-6 col-6">
                            <Button
                                onClick={() => setExpanded(last => !last)}
                                aria-controls="example-collapse-text"
                                aria-expanded={expanded}
                            >
                                Filtres
                                {
                                    expanded ? (
                                        <i className="fas fa-caret-up ml-8"></i>
                                    ) : (
                                        <i className="fas fa-caret-down ml-8"></i>
                                    )
                                }
                            </Button>
                        </div>
                    </div>
                    <Collapse in={expanded}>
                        <div className="filters--content">
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-12">
                                        <FormLabel component="legend">General</FormLabel>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 mb-16 mb-md-0">
                                        <TextField
                                            variant="outlined"
                                            label="Nom"
                                            fullWidth
                                            name="campaign_name"
                                            onChange={handleFiltersChanged}
                                            value={filtersValues.campaign_name}
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 mb-16 mb-md-0">
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel
                                                id="select-game-mode-label"
                                            >
                                                Mode
                                            </InputLabel>
                                            <Select
                                                labelId="select-game-mode-label"
                                                id="select-game-mode"
                                                fullWidth
                                                variant="outlined"
                                                value={filtersValues.campaign_game_mode}
                                                name="campaign_game_mode"
                                                onChange={handleFiltersChanged}
                                            >
                                                <MenuItem value="Any">
                                                    Any
                                                </MenuItem>
                                                <MenuItem value={"Speedrun"}>Speedrun</MenuItem>
                                                <MenuItem value={"Sequence"}>Sequence</MenuItem>
                                                <MenuItem value={"Alphabetical"}>Alphabetical</MenuItem>
                                                <MenuItem value={"Increasing Difficulty"}>Increasing Difficulty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-16">
                                        <FormControl
                                            component="fieldset"
                                        >
                                            <FormLabel component="legend">Status de la Campagne</FormLabel>

                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="show_campaigns_not_started"
                                                        />
                                                    }
                                                    label="Pas Encore Démarré"
                                                    checked={filtersValues.show_campaigns_not_started}
                                                    onChange={handleFiltersChanged}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="show_campaigns_in_progress"
                                                        />
                                                    }
                                                    label="En Cours"
                                                    checked={filtersValues.show_campaigns_in_progress}
                                                    onChange={handleFiltersChanged}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="show_campaigns_finished"
                                                        />
                                                    }
                                                    label="Fini"
                                                    checked={filtersValues.show_campaigns_finished}
                                                    onChange={handleFiltersChanged}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className="container-fluid bg-blank pd-0 pd-md-16">
                    {
                        campaigns.map((category) => (
                            <React.Fragment key={category.label}>
                                <h2 className="campaign-label">{category.label}</h2>
                                <div className="category-container">
                                    {
                                        category.campaigns.map((campaign) => (
                                            <div className={`campaign-container ${campaign.hide ? "hide" : ""}`} key={campaign.id}>
                                                <div className="thumbnail">
                                                    <a
                                                        href={`${CAMPAIGNS_ROUTE}/${campaign.route_name}`}
                                                    >
                                                        <img
                                                            className="thumbnail"
                                                            src={
                                                                campaign.thumbnail
                                                                || "https://picsum.photos/252/210?random=2"
                                                            }
                                                            alt="campaign thumbnail"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="description">
                                                    <a
                                                        href={`${CAMPAIGNS_ROUTE}/${campaign.route_name}`}
                                                        className="title"
                                                        aria-label={campaign.name}
                                                        title={campaign.name}
                                                    >
                                                        {campaign.name}
                                                    </a>
                                                    <span>{campaign.mode}</span>
                                                    <span>{getHumanizedDate(campaign.start_date, campaign.expiration_date)}</span>
                                                    <span>{getStringifiedParticipants(campaign.participants_amount, campaign.max_participants)}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>
            <DJCFooter />
        </>
    );
}

export default Render;
