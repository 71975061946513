import React, { useEffect } from 'react';
import moment from 'moment-timezone';

interface ICountdownProps {
    date: number;
}

interface ITimeLeft {
    years: number;
    days: number;
    hours: number;
    min: number;
    sec: number;
    millisec: number;
}

const Countdown = (props: ICountdownProps) => {
    const { date } = props;

    const calculateCountdown = React.useCallback(() => {
        const end = moment.tz(date, "Europe/Paris");
        const now = moment(new Date());
        const duration = moment.duration(end.diff(now)).asSeconds();
        let diff = duration;  
        
        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        if (diff <= 0) {
            return null;
        }
      
        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        
        timeLeft.sec = Math.floor(diff);

        return timeLeft;
    }, [date]);

    const padLeadingZeros = (nb: number): string => {
        return nb < 10 ? `0${nb}` : `${nb}`;
    };

    const [timeleft, setTimeleft] = React.useState<ITimeLeft>(
        calculateCountdown() || {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0
        }
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const next = calculateCountdown();

            if (next !== null) {
                setTimeleft(next);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [calculateCountdown]);

    return (
        <div className="countdown">
            <div className="number">
                <div className="time">
                    <h3>{padLeadingZeros(timeleft.days)}</h3>
                </div>
                <div className="time-desc">
                    <h4>jours</h4>
                </div>
            </div>
            <div className="number">
                <div className="time">
                    <h3>{padLeadingZeros(timeleft.hours)}</h3>
                </div>
                <div className="time-desc">
                    <h4>heures</h4>
                </div>
            </div>
            <div className="number">
                <div className="time">
                    <h3>{padLeadingZeros(timeleft.min)}</h3>
                </div>
                <div className="time-desc">
                    <h4>mins</h4>
                </div>
            </div>
            <div className="number secs">
                <div className="time">
                    <h3>{padLeadingZeros(timeleft.sec)}</h3>
                </div>
                <div className="time-desc">
                    <h4>secs</h4>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
