import { api } from "..";
import { IActionApplied, IActionApplyForm } from "./types";

const routesExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        applyAction: build.mutation<IActionApplied, IActionApplyForm>({
            query: (route_name: IActionApplyForm) => ({
                url: `/route/action/${route_name}/apply`,
                method: 'GET'
            })
        })
    }),
    overrideExisting: false
});

const {
    endpoints: {
        applyAction: {
            useMutation: useMutationApplyAction
        }
    }
} = routesExtendedApi;

export { routesExtendedApi };
export {
    useMutationApplyAction
}
