import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import Toast from '../../../../../components/toast';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import { useMutationPostCreatePAT } from '../../../../../../infrastructure/services/PAT';
import { getScopesAsArray } from '../../../../../../infrastructure/services/authentication/scopes';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CopyToClipboardField from '../../../../../components/copy_to_clipboard_field';

type Inputs = {
    id: string;
    scopes: string;
}

const AdminPATCreateView = () => {
    const [fetchCreate] = useMutationPostCreatePAT();

    const history = useHistory();
    const [options, _] = useState(getScopesAsArray());

    const [open, setOpen] = useState(false);
    const [PAT, setPAT] = useState<string>("");

    const handleValidate = (v: Inputs) => {
        const errors: IError<Inputs> = {};

        if (v.id.length === 0) {
            errors.id = {
                error: true,
                message: "Le champ \"ID\" est obligatoire."
            }
        }

        return errors;
    }

    const {
        values,
        handleBlur,
        handleChange,
        handleBlurAutocomplete,
        handleChangeAutocomplete,
        errors,
        containsError,
        fulfilled
    } = useForm<Inputs>({
        defaultValues: {
            id: "",
            scopes: ""
        },
        required: [
            "id"
        ],
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/PAT");
    };

    const handleClose = () => {
        history.push("/admin/PAT");
    }

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchCreate({
            id: values.id,
            scopes: values.scopes.split(',').map(v => v.trim()).filter(v => v.length > 0)
        });
        if (resp.data) {
            setPAT(resp.data.token);
            setOpen(true);
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-key" />
                    <h1>Créer PAT</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Autocomplete
                                    id="input-scopes"
                                    freeSolo
                                    multiple
                                    options={options}
                                    onBlur={handleBlurAutocomplete("scopes")}
                                    onChange={handleChangeAutocomplete("scopes")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"scopes"}
                                            label={"Scopes"}
                                            variant="outlined"
                                            error={errors.scopes !== undefined}
                                            helperText={errors.scopes?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                aria-labelledby="alert-dialog-copy-token-title"
                aria-describedby="alert-dialog-copy-token-description"
            >
                <DialogTitle
                    id="alert-dialog-copy-token-title"
                >
                    PAT généré avec succès
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-copy-token-description">
                        Vous trouverez ci dessous votre PAT généré. Veillez à bien le sauvegarder, il ne vous sera plus redonné par la suite.
                        <div className="mb-16" />
                        <CopyToClipboardField
                            value={PAT}
                            id="pat"
                            label="PAT"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        J'ai bien copié mon PAT
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default AdminPATCreateView;