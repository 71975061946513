import React, { useEffect, useMemo, useState } from "react";
import { useMutationDeletePageById, useMutationGetPageById, useMutationGetPages } from "../../../../../../infrastructure/services/pages";
import toast from 'toasted-notes';
import { IPage } from "../../../../../../infrastructure/services/pages/types";
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    title: string;
    author: string;
    visible: string;
    route_name: string;
}>;

const AdminArticlesListView = () => {
    const [ fetchArticles ] = useMutationGetPages();
    const [ deleteArticleById ] = useMutationDeletePageById();
    const [ fetchArticleById ] = useMutationGetPageById();

    const history = useHistory();

    const [articles, setArticles] = useState<Array<IPage>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return articles.map((article) => ({
            id: article.id,
            title: article.title,
            author: article.author,
            route_name: `/articles/${article.route_name}`,
            visible: article.visible ? "true" : "false",
        }));
    }, [articles]);

    const fetchByType = async (type: void | "news" | "events") => {
        const resp = await fetchArticles(type);

        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching ${type || "global articles"}. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.pages;
    }

    const init = async () => {
        const global = await fetchByType();
        const news = await fetchByType("news");
        const events = await fetchByType("events");

        const merged = [...global, ...news, ...events];

        setArticles(merged);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleEdit = (ids: string[]) => {
        history.push(`/admin/articles/edit/${ids[0]}`);
    }

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteArticleById({id: id});
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const nextArticles = [...articles].filter(a => !idsToDelete.includes(a.id));

        setArticles(nextArticles);
        setFetching(false);
    }

    const handleDetail = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail({id: ids[0]});
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleCreate = () => {
        history.push('/admin/articles/create')
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fa fa-newspaper" />
                    <h1>Articles</h1>
                </div>

                <div className="actions">
                    <button
                        onClick={handleCreate}
                    >
                        Créer un article
                    </button>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalEditClicked={handleEdit}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Articles <CircularProgress className="inline-block-important" /></span> : `Articles (${articles.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "ID",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'title',
                            disablePadding: true,
                            label: "Titre",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'author',
                            disablePadding: true,
                            label: "Auteur",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "route_name",
                            disablePadding: true,
                            label: "Route",
                            numeric: false,
                            url: true,
                            boolean: false
                        },
                        {
                            id: 'visible',
                            disablePadding: true,
                            label: "Visible",
                            numeric: false,
                            url: false,
                            boolean: true
                        }
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="title"
                fetchFunc={fetchArticleById}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminArticlesListView;
