import React, { useCallback, useEffect, useMemo, useState} from 'react';

import MD5 from 'crypto-js/md5';
import { useLocation, useHistory } from 'react-router-dom'

import Render from './render';
import HomepageRender from './homepage.render';
import {
    CAMPAIGNS_ROUTE, 
    HEADER_HIDDEN_FOR, 
    HOME_ROUTE,
    ABOUT_ROUTE,
    CONTACT_ROUTE,
    ARTICLES_ROUTE,
    SIGN_IN_ROUTE,
} from "../../../infrastructure/globals/routes";
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoggedDrawer, toggleSignInDialog } from '../../../infrastructure/stores/global/actions';
import { selectEmail, selectIsUserConnected } from '../../../infrastructure/services/users/slice';

export interface IHeaderLink {
    linkTo: string;
    label: string;
    loggedInRequired: boolean;
    disabled: boolean;
    icon?: string;
    children?: Array<IHeaderLink>;
}

const headerLinks: Record<string, IHeaderLink> = {
    [HOME_ROUTE]: {
        linkTo: HOME_ROUTE,
        label: "Accueil",
        loggedInRequired: false,
        disabled: false,
        icon: "fas fa-home"
    },
    [ARTICLES_ROUTE]: {
        linkTo: ARTICLES_ROUTE,
        label: "Actualités",
        loggedInRequired: false,
        disabled: false,
        icon: "fas fa-book-open",
    },
    [CAMPAIGNS_ROUTE]: {
        linkTo: CAMPAIGNS_ROUTE,
        label: "Campagnes",
        loggedInRequired: true,
        disabled: false,
        icon: "fas fa-keyboard"
    },
    [ABOUT_ROUTE]: {
        linkTo: ABOUT_ROUTE,
        label: "A Propos",
        loggedInRequired: false,
        disabled: false,
        icon: "fas fa-info-circle"
    },
    [CONTACT_ROUTE]: {
        linkTo: CONTACT_ROUTE,
        label: "Contact",
        loggedInRequired: false,
        disabled: false,
        icon: "fas fa-envelope"
    },
};

const DJCHeader = () => {
    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    const isUserConnected = useSelector(selectIsUserConnected);
    const email = useSelector(selectEmail);

    const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState<boolean>(false);
    const [isOnTopScroll, setIsOnTopScroll] = useState<boolean>(true);

    const onScroll = useCallback((updateState: (nextState: boolean) => void) => () => {
        const nextState = window.pageYOffset === 0;
        if (nextState !== isOnTopScroll) {
            updateState(nextState);
        }
    }, [isOnTopScroll]);

    useEffect(() => {
        if (location.pathname === "/") {
            window.onscroll = onScroll(setIsOnTopScroll);
        } else {
            destruct();
        }
    }, [location, onScroll]);

    const destruct = () => {
        window.onscroll = null;
    };

    const handleToggleLeftDrawer = () => {
        setIsLeftDrawerOpen(last => !last);
    }

    const isLinkActive = (link: string) => {
        if (
            (headerLinks as any)[link] !== undefined
            && (headerLinks as any)[link].disabled
        ) {
            return 'disabled';
        }

        return (
            location.pathname === link
                ? 'active'
                : ''
        );
    }

    const redirect = (to: string) => {
        return () => {
            setIsLeftDrawerOpen(false);
            destruct();
            history.push(to);
        }
    }

    const handleSignIn = () => {
        if (window.innerWidth <= 990) {
            redirect(SIGN_IN_ROUTE)();
            return;
        }
        dispatch(toggleSignInDialog());
    };

    const isHeaderHidden = useMemo<boolean>(() => {
        if (HEADER_HIDDEN_FOR.includes(location.pathname)) {
            return true;
        }

        if (location.pathname === "/") {
            return false;
        }

        for (let route of HEADER_HIDDEN_FOR) {
            if (location.pathname.includes(route)) {
                return true;
            }
        }

        return false;
    }, [location]);

    const isHomepage = useMemo<boolean>(() => {
        return location.pathname === "/";
    }, [location]);

    if (isHeaderHidden) {
        return null;
    }

    const handleOpenDrawer = () => {
        dispatch(toggleLoggedDrawer());
    };

    if (isHomepage) {
        return (
            <HomepageRender
                isLinkActive={isLinkActive}
                redirect={redirect}
                handleSignIn={handleSignIn}
                isUserConnected={isUserConnected}
                handleOpenDrawer={handleOpenDrawer}
                headerLinks={headerLinks}
                hash={MD5(email || "").toString()}
                isLeftDrawerOpen={isLeftDrawerOpen}
                handleToggleLeftDrawer={handleToggleLeftDrawer}
                isOnTop={isOnTopScroll}
            />
        )
    }

    return (
        <Render
            isLinkActive={isLinkActive}
            redirect={redirect}
            handleSignIn={handleSignIn}
            isUserConnected={isUserConnected}
            handleOpenDrawer={handleOpenDrawer}
            headerLinks={headerLinks}
            hash={MD5(email || "").toString()}
            isLeftDrawerOpen={isLeftDrawerOpen}
            handleToggleLeftDrawer={handleToggleLeftDrawer}
        />
    );
};

export default DJCHeader;