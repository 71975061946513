import React from 'react';

import Render from './render';

interface IDJCErrorProps {
    status: number;
    errorMessage: string;
};

const DJCerror = (props: IDJCErrorProps) => {
    const { status, errorMessage } = props;

    return (
        <Render
            status={status}
            errorMessage={errorMessage}
        />
    );
};

export default DJCerror;
