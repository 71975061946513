import MarkdownIt from 'markdown-it';

import { OutputBlockData, OutputData } from "@editorjs/editorjs";
import { IHeaderBlockData, IImageBlockData, IListBlockData, IParagraphBlockData } from "./types";


export const textToMd = (str: string) => {
    return str
        // LINK
        .replace(
            /<a\s*(href="(?<href>.*?)")?>(?<text>.*?)<\/a>/g,
            "[$<text>]($<href>)"
        )
        // ITALIC
        .replaceAll(/<i>(.*?)(\s*)<\/i>/gmu, "*$1*$2")
        // BOLD
        .replaceAll(/<b>(.*?)(\s*)<\/b>/gmu, "**$1**$2")
}

export const isTextLink = (str: string) => {
    return (/<a\s*(href="(.*?)")?>(.*?)<\/a>/g.test(str))
};

export const fromBlockToMd = (block: OutputBlockData) => {
    switch (block.type) {
        case "paragraph": {
            const { text }: IParagraphBlockData = block.data;
            return textToMd(text);
        }
        case "header": {
            const { text, level }: IHeaderBlockData = block.data;
            return `${"#".repeat(level)} ${textToMd(text)}\n`;
        }
        case "list": {
            const { style, items }: IListBlockData = block.data;
            const divider = style === "ordered" ? "1." : "-";
            return items.map((text) => `${divider} ${textToMd(text)}`).join('\n') + '\n';
        }
        case "image": {
            const { caption, url }: IImageBlockData = block.data;

            return `![${caption}](${url})`;
        }
        default:
            return "";
    }
}

export const fromMdToBlock = (md: string): OutputData => {
    const markdownIt = new MarkdownIt({
        breaks: true,
        xhtmlOut: false,
    });
    const tokens = markdownIt.parse(md, {});
    const blocks = [];

    const results = [];
    const heap = [];
    let current: any[] | null = null;
    for (let i = 0; i < tokens.length; i += 1) {
        const token = tokens[i];
        if (token.type.endsWith("_open")) {
            console.log("**** encoutered open ****");
            if (current === null) {
                console.log("******* creating current *******");
                current = [];
            } else {
                console.log("******* pushing current to the heap *******");
                heap.push([...current]);
                current = [];
            }
        }

        if (!token.type.endsWith("_open") && !token.type.endsWith("_close")) {
            console.log("******* pushing token to current *******");
            current?.push(token);
        }

        if (token.type.endsWith("_close") && current) {
            console.log("**** encoutered close ****");

            console.log(`****** pushing ******`);
            console.log(current);
            console.log("");
            results.push(current);
            current = heap.pop() || null;
        }
    }

    console.log(results);

    return {
        blocks: [
            {
                type: "paragraph",
                data: {
                    text: "<em>hello</em> <b>world</b> !"
                }
            }
        ]
    }
};
