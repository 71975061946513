export const HOME_ROUTE = '/';
export const CAMPAIGNS_ROUTE = '/campaigns';
export const CHALLENGES_ROUTE = '/challenges';
export const SIGN_IN_ROUTE = '/login';
export const SIGN_UP_ROUTE = '/register';
export const ABOUT_ROUTE = '/about';
export const CONTACT_ROUTE = '/contact';
export const SETTINGS_ROUTE = '/settings';
export const USER_ROUTE = '/user';
export const CGI_ROUTE = '/cgu';
export const CGU_ROUTE = '/cgu';

export const EVENTS_ROUTE = '/action';

export const VERIFY_MAIL = '/mail/verify_mail';

export const RESET_PASSWORD_ROUTE = "/reset-password";

export const ARTICLES_ROUTE = '/articles';
export const ARTICLES_CREATE_ROUTE = '/articles/create';
export const ARTICLES_EDIT_ROUTE = '/articles/edit';
export const ARTICLES_NEWS_ROUTE = '/articles/news';
export const ARTICLES_EVENTS_ROUTE = '/articles/events';

export const SIGN_IN_THEN_REDIRECT_TO_ROUTE = (to: string) => `/login/r${to}`;

export const HEADER_HIDDEN_FOR = [
    SIGN_IN_ROUTE,
    SIGN_UP_ROUTE,
    RESET_PASSWORD_ROUTE,
    "/authentication/update/guild-and-phone"
];
