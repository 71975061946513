const logger = (store: any) => (next: CallableFunction) => (action: any) => {
    const previousState = store.getState();
    const result = next(action);
    const nextState = store.getState();
    
    if (!action.type) {
        return result;
    }

    console.group(`@@${action.type}`);
    console.info('previous state:', previousState);
    console.info('action:', action);
    console.info('next state:', nextState);
    console.groupEnd();

    return result;
}

export default logger;
