import React, { useCallback, useMemo } from 'react';

import Rainbow from 'rainbowvis.js';
import Gravatar from '../gravatar';

interface IScore {
    label: string;
    value: number;
    avatar: string;
}

interface IProps {
    scores: Array<IScore>;
    colorizeLabels?: Array<string>;
}

const Scoreboard = (props: IProps) => {
    const { scores: scoresProps, colorizeLabels: colorizeLabelsProps } = props;

    const sortedScores = useMemo(() => (
            scoresProps.sort((a, b) => b.value - a.value)
        ),
        [scoresProps]
    );

    const colorizeLabels = useMemo<string[]>(() => {
        return colorizeLabelsProps !== undefined ? colorizeLabelsProps : []
    }, [colorizeLabelsProps]);

    const maxValue = useMemo<IScore>(
        () => sortedScores[0],
        [sortedScores]
    );

    const rainbow = useMemo<any>(
        () => {
            const r = new Rainbow();
            r.setSpectrum("#65BCE5", "#B1518E")
            r.setNumberRange(0, scoresProps.length);
            return r;
        },
        [scoresProps]
    );

    const getWidthBasedByValue = (value: number) => {
        return (1 - ((maxValue.value - value) / maxValue.value)) * 100;
    }

    const getTextClassName = useCallback((i: number, label: string, defaultClass: string) => {
        const results: Array<string> = [defaultClass];

        if (colorizeLabels.find(v => v === label)) {
            results.push("bold", "primary-secondary");
        } else if (i < 3) {
            results.push("bold");
        }

        return results.join(' ');
    }, [colorizeLabels]);

    const getTextStyle = useCallback((i: number, label: string) => {
        if (colorizeLabels.find(v => v === label)) {
            return `#${rainbow.colourAt(i)}`;
        }

        return "inherit";
    }, [colorizeLabels, rainbow]);

    return (
        <div className="scoreboard">
            {
                sortedScores.map((score, i) => {
                    return (
                        <div
                            key={`${score.label}-${score.value}`}
                            className={`scoreboard-row scoreboard-row-${i}`}
                        >
                            <div className="scoreboard-row-bg-progress-bg" />
                            <div
                                className={`scoreboard-row-bg scoreboard-row-bg-${i}`}
                                style={{
                                    backgroundColor: `#${rainbow.colourAt(i)}`,
                                    width: `${getWidthBasedByValue(score.value)}%`
                                }}
                            />
                            <div className={`ribbon ribbon-${i} ${i >= 3 ? "hide" : null}`}>
                                <div className="behind-ribbon" />
                                <div className="front-ribbon" />
                            </div>
                            <span
                                className={getTextClassName(i, score.label, "label")}
                                style={{
                                    color: getTextStyle(i, score.label)
                                }}
                            >
                                <Gravatar alt="gravatar" hash={score.avatar} /> {score.label} (#{i + 1})
                            </span>
                            <span
                                className={getTextClassName(i, score.label, "value")}
                                style={{
                                    color: getTextStyle(i, score.label)
                                }}
                            >
                                {score.value} points
                            </span>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Scoreboard;