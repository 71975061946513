import { ISchool } from "../views/signup/types";

export const schoolsMocked: Array<ISchool> = [
    {
        "short_name": "ENSAPLV",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Paris-La Villette",
        "social": {
            "url": "http://www.paris-lavillette.archi.fr/",
            "twitter": "https://twitter.com/ENSAPLV",
            "facebook": "https://www.facebook.com/La-Salle-dExpo-ENSAPLV-246558815401564/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 19e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENS de Lyon",
        "label": "\u00c9cole normale sup\u00e9rieure de Lyon",
        "social": {
            "url": "http://www.ens-lyon.eu/",
            "twitter": "https://twitter.com/ENSdeLyon",
            "facebook": "https://www.facebook.com/ENSdeLyon?ref=hl"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 7e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sup\u00e9rieure des sciences \u00e9conomiques et commerciales",
        "social": {
            "url": "https://www.essec.edu/fr/",
            "twitter": "https://twitter.com/essec",
            "facebook": "https://www.facebook.com/essec"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Corse",
        "social": {
            "url": "https://www.universita.corsica/fr/",
            "twitter": "https://twitter.com/univcorse",
            "facebook": "https://www.facebook.com/univcorse"
        },
        "localisation": {
            "aca_city": "Corse",
            "city": "Corte",
            "dep": "Haute-Corse"
        }
    },
    {
        "short_name": "Paris 1",
        "label": "Universit\u00e9 Paris-I",
        "social": {
            "url": "http://www.univ-paris1.fr/",
            "twitter": "https://twitter.com/SorbonneParis1",
            "facebook": "https://www.facebook.com/UnivParis1PantheonSorbonne"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris-Saclay",
        "social": {
            "url": "https://www.universite-paris-saclay.fr/",
            "twitter": "https://twitter.com/UnivParisSaclay",
            "facebook": "https://www.facebook.com/UParisSaclay"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Saint-Aubin",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "",
        "label": "Centrale Lille Institut",
        "social": {
            "url": "https://centralelille.fr/",
            "twitter": "https://twitter.com/Centralelille",
            "facebook": "https://www.facebook.com/EcoleCentraleLille/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Villeneuve-d'Ascq",
            "dep": "Nord"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure de l'\u00e9lectronique et de ses applications de Cergy",
        "social": {
            "url": "https://www.ensea.fr/fr",
            "twitter": "https://twitter.com/ensea1",
            "facebook": "https://www.facebook.com/pages/Ensea/136481359731543"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "ENSLL",
        "label": "\u00c9cole nationale sup\u00e9rieure Louis Lumi\u00e8re",
        "social": {
            "url": "http://www.ens-louis-lumiere.fr/",
            "twitter": "https://twitter.com/ENSLouisLumiere",
            "facebook": "https://www.facebook.com/pages/Ecole-nationale-sup%C3%A9rieure-Louis-Lumi%C3%A8re/149297845167063"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Saint-Denis",
            "dep": "Seine-Saint-Denis"
        }
    },
    {
        "short_name": "IEP de Toulouse",
        "label": "Institut d'\u00e9tudes politiques de Toulouse",
        "social": {
            "url": "http://www.sciencespo-toulouse.fr/",
            "twitter": "https://twitter.com/SciencesPo_Tlse",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "Institut national du patrimoine",
        "social": {
            "url": "http://www.inp.fr/",
            "twitter": "https://twitter.com/Inp_patrimoine",
            "facebook": "https://www.facebook.com/Institutnationaldupatrimoineparis/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  2e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Lille",
        "social": {
            "url": "https://www.univ-lille.fr/",
            "twitter": "https://twitter.com/univ_lille",
            "facebook": "https://www.facebook.com/UniversitedeLille/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 d'\u00c9vry-Val d'Essonne",
        "social": {
            "url": "https://www.univ-evry.fr/",
            "twitter": "https://twitter.com/UnivEvry",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-dEvry-Val-dEssonne/3922258983"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "\u00c9vry-Courcouronnes",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Junia",
        "social": {
            "url": "https://www.junia.com/",
            "twitter": "https://twitter.com/Junia_ingenieur",
            "facebook": "https://www.facebook.com/yncreahdf/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "Yncr\u00e9a M\u00e9diterran\u00e9e",
        "label": "Yncr\u00e9a M\u00e9diterran\u00e9e",
        "social": {
            "url": "http://www.yncrea-mediterranee.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Nice",
            "city": "Toulon",
            "dep": "Var"
        }
    },
    {
        "short_name": "Audencia",
        "label": "Audencia Nantes",
        "social": {
            "url": "https://www.audencia.com/",
            "twitter": "https://twitter.com/audencia",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Nantes",
            "dep": "Loire-Atlantique"
        }
    },
    {
        "short_name": "",
        "label": "CESI",
        "social": {
            "url": "https://www.cesi.fr/",
            "twitter": "https://twitter.com/GroupeCESI",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Puteaux",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "ECAM Lasalle",
        "label": "\u00c9cole catholique des arts et m\u00e9tiers",
        "social": {
            "url": "http://www.ecam.fr/",
            "twitter": "https://twitter.com/ecamlyon",
            "facebook": "https://www.facebook.com/ECAM.Lyon"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 5e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "EMLV",
        "label": "\u00c9cole de management L\u00e9onard De Vinci",
        "social": {
            "url": "https://www.emlv.fr/",
            "twitter": "https://twitter.com/emlvparis",
            "facebook": "https://www.facebook.com/emlvparis"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Courbevoie",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "EHESS",
        "label": "\u00c9cole des hautes \u00e9tudes en sciences sociales",
        "social": {
            "url": "https://www.ehess.fr/fr",
            "twitter": "https://twitter.com/EHESS_fr",
            "facebook": "https://www.facebook.com/ehess.fr?_rdr"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENSACF",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Clermont-Ferrand",
        "social": {
            "url": "http://www.clermont-fd.archi.fr/",
            "twitter": "https://twitter.com/ENSACF",
            "facebook": "https://www.facebook.com/Ensacf"
        },
        "localisation": {
            "aca_city": "Clermont-Ferrand",
            "city": "Clermont-Ferrand",
            "dep": "Puy-de-D\u00f4me"
        }
    },
    {
        "short_name": "ESA",
        "label": "\u00c9cole sup\u00e9rieure d\u2019agriculture",
        "social": {
            "url": "https://www.groupe-esa.com/",
            "twitter": "https://twitter.com/Groupe_ESA",
            "facebook": "https://www.facebook.com/GroupeESA.Angers.officiel"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "\u00c9SAM Caen/Cherbourg",
        "label": "\u00c9cole sup\u00e9rieure d'arts et m\u00e9dias de Caen - Cherbourg",
        "social": {
            "url": "http://www.esam-c2.fr/",
            "twitter": "https://twitter.com/esamCaenCh",
            "facebook": "https://www.facebook.com/Esam.Caen.Cherbourg/"
        },
        "localisation": {
            "aca_city": "Caen",
            "city": "Caen",
            "dep": "Calvados"
        }
    },
    {
        "short_name": "IEP d'Aix-en-Provence",
        "label": "Institut d'\u00e9tudes politiques d'Aix-en-Provence",
        "social": {
            "url": "http://www.sciencespo-aix.fr/",
            "twitter": "https://twitter.com/Sciences_Po_Aix",
            "facebook": "https://www.facebook.com/SciencesPo.Aix"
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Aix-en-Provence",
            "dep": "Bouches-du-Rh\u00f4ne"
        }
    },
    {
        "short_name": "Yncr\u00e9a Ouest",
        "label": "Institut sup\u00e9rieur de l'\u00e9lectronique et du num\u00e9rique de Brest",
        "social": {
            "url": "https://isen-brest.fr/",
            "twitter": "https://twitter.com/isenbrest",
            "facebook": "https://www.facebook.com/ISEN.Brest"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Brest",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "IPAG Business School",
        "social": {
            "url": "https://www.ipag.fr/",
            "twitter": "https://twitter.com/IpagBS",
            "facebook": "https://www.facebook.com/IPAGBusinessSchool/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Pau",
        "social": {
            "url": "https://www.univ-pau.fr/",
            "twitter": "https://twitter.com/universite_uppa",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-de-Pau-et-des-Pays-de-lAdour-UPPA/154751851238234?v=wall"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Pau",
            "dep": "Pyr\u00e9n\u00e9es-Atlantiques"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 d\u2019Amiens",
        "social": {
            "url": "https://www.u-picardie.fr/",
            "twitter": "https://twitter.com/upjv",
            "facebook": "https://www.facebook.com/UPJV.80/"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Amiens",
            "dep": "Somme"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Toulon",
        "social": {
            "url": "http://www.univ-tln.fr/",
            "twitter": "https://twitter.com/univtoulon",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-de-Toulon/155673624745?v=wall"
        },
        "localisation": {
            "aca_city": "Nice",
            "city": "La Garde",
            "dep": "Var"
        }
    },
    {
        "short_name": "CNAC",
        "label": "Centre national des arts du cirque",
        "social": {
            "url": "https://www.cnac.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Ch\u00e2lons-en-Champagne",
            "dep": "Marne"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole fran\u00e7aise d'Extr\u00eame-Orient",
        "social": {
            "url": "http://www.efeo.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 16e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENAC",
        "label": "\u00c9cole nationale de l'aviation civile",
        "social": {
            "url": "https://www.enac.fr/fr",
            "twitter": "https://twitter.com/enacfrance",
            "facebook": "https://www.facebook.com/enacfrance"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale des ponts et chauss\u00e9es",
        "social": {
            "url": "https://www.ecoledesponts.fr/",
            "twitter": "https://twitter.com/EcoledesPonts",
            "facebook": "https://www.facebook.com/EcoledesPonts/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Champs-sur-Marne",
            "dep": "Seine-et-Marne"
        }
    },
    {
        "short_name": "ENI Tarbes",
        "label": "\u00c9cole nationale d'ing\u00e9nieurs de Tarbes",
        "social": {
            "url": "http://www.enit.fr/",
            "twitter": "https://twitter.com/ENITarbes",
            "facebook": "https://www.facebook.com/EniDeTarbes/"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Tarbes",
            "dep": "Hautes-Pyr\u00e9n\u00e9es"
        }
    },
    {
        "short_name": "ENSA Toulouse",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Toulouse",
        "social": {
            "url": "http://www.toulouse.archi.fr/fr/index.html",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "\u00c9nsAD-Paris",
        "label": "\u00c9cole nationale sup\u00e9rieure des arts d\u00e9coratifs",
        "social": {
            "url": "https://www.ensad.fr/",
            "twitter": "https://twitter.com/Ecole_ArtsDeco",
            "facebook": "https://www.facebook.com/Ensad75"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ESAIP",
        "label": "\u00c9cole sup\u00e9rieure angevine en informatique et productique",
        "social": {
            "url": "https://www.esaip.org/",
            "twitter": "https://twitter.com/groupeesaip",
            "facebook": "https://www.facebook.com/groupeesaip"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Saint-Barth\u00e9lemy-d'Anjou",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Excelia Group",
        "social": {
            "url": "https://www.excelia-group.com/",
            "twitter": "https://twitter.com/ExceliaGroupLR",
            "facebook": "https://www.facebook.com/ExceliaGroupLaRochelle"
        },
        "localisation": {
            "aca_city": "Poitiers",
            "city": "La Rochelle",
            "dep": "Charente-Maritime"
        }
    },
    {
        "short_name": "IPT",
        "label": "Institut protestant de th\u00e9ologie",
        "social": {
            "url": "http://www.iptheologie.fr/index.php",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 14e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Dijon",
        "social": {
            "url": "http://www.u-bourgogne.fr/",
            "twitter": "https://twitter.com/univbourgogne",
            "facebook": "https://www.facebook.com/univbourgogne"
        },
        "localisation": {
            "aca_city": "Dijon",
            "city": "Dijon",
            "dep": "C\u00f4te-d'Or"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Rouen",
        "social": {
            "url": "https://www.univ-rouen.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/univ.rouen"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Mont-Saint-Aignan",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de technologie de Belfort-Montb\u00e9liard",
        "social": {
            "url": "http://www.utbm.fr/",
            "twitter": "https://twitter.com/utbm_fr",
            "facebook": "https://www.facebook.com/MyUTBM"
        },
        "localisation": {
            "aca_city": "Besan\u00e7on",
            "city": "Sevenans",
            "dep": "Territoire de Belfort"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Toulouse-III",
        "social": {
            "url": "http://www.univ-tlse3.fr/",
            "twitter": "https://twitter.com/UT3PaulSabatier",
            "facebook": "https://www.facebook.com/ut3paulsabatier"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ULCO",
        "label": "Universit\u00e9 du Littoral",
        "social": {
            "url": "http://www.univ-littoral.fr/",
            "twitter": "https://twitter.com/ULCO_Univ",
            "facebook": "https://www.facebook.com/ULCO.Univ/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Dunkerque",
            "dep": "Nord"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Paris XII",
        "social": {
            "url": "https://www.u-pec.fr/",
            "twitter": "https://twitter.com/UPECactus",
            "facebook": "https://www.facebook.com/upec.officiel/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Cr\u00e9teil",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "Brest BS",
        "label": "Brest Business School",
        "social": {
            "url": "https://brest-bs.com/",
            "twitter": "https://twitter.com/BrestBS",
            "facebook": "https://www.facebook.com/BrestBS"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Brest",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure des arts et industries textiles de Roubaix",
        "social": {
            "url": "http://www.ensait.fr/",
            "twitter": "https://twitter.com/ENSAITinfo",
            "facebook": "https://www.facebook.com/ENSAIT/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Roubaix",
            "dep": "Nord"
        }
    },
    {
        "short_name": "\u00c9sad Amiens",
        "label": "\u00c9cole sup\u00e9rieure d'art et de design d'Amiens",
        "social": {
            "url": "http://www.esad-amiens.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Amiens",
            "dep": "Somme"
        }
    },
    {
        "short_name": "ESEO",
        "label": "\u00c9cole sup\u00e9rieure d'\u00e9lectronique de l'Ouest",
        "social": {
            "url": "http://www.eseo.fr/",
            "twitter": "https://twitter.com/Groupe_ESEO",
            "facebook": "https://www.facebook.com/GroupeESEO/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "",
        "label": "Institut catholique de Toulouse",
        "social": {
            "url": "https://www.ict-toulouse.fr/",
            "twitter": "https://twitter.com/ICT_Toulouse",
            "facebook": "https://www.facebook.com/la.catho.ict"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "Ircom",
        "label": "Institut des relations publiques et de la communication d\u2019Angers",
        "social": {
            "url": "http://www.ircom.fr/",
            "twitter": "https://twitter.com/Ircom_Groupe",
            "facebook": "https://www.facebook.com/IrcomGroupe"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Les Ponts-de-C\u00e9",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "",
        "label": "ISMANS Groupe CESI",
        "social": {
            "url": "https://ismans.cesi.fr/",
            "twitter": "https://twitter.com/ismanscesi",
            "facebook": "https://www.facebook.com/ismanscesi/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Le Mans",
            "dep": "Sarthe"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 C\u00f4te d'Azur",
        "social": {
            "url": "https://univ-cotedazur.fr/",
            "twitter": "https://twitter.com/Univ_CotedAzur",
            "facebook": "https://www.facebook.com/UnivCotedAzur"
        },
        "localisation": {
            "aca_city": "Nice",
            "city": "Nice",
            "dep": "Alpes-Maritimes"
        }
    },
    {
        "short_name": "UN\u00ceMES",
        "label": "Universit\u00e9 de N\u00eemes",
        "social": {
            "url": "http://www.unimes.fr/",
            "twitter": "https://twitter.com/unimesfr",
            "facebook": "https://www.facebook.com/unimesfr"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "N\u00eemes",
            "dep": "Gard"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris-II",
        "social": {
            "url": "https://www.u-paris2.fr/fr",
            "twitter": "https://twitter.com/AssasParis2",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-Paris-II-Panth%C3%A9on-Assas/186728765857"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Toulouse-II",
        "social": {
            "url": "http://www.univ-tlse2.fr/",
            "twitter": "https://twitter.com/UTJeanJaures",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-Toulouse-Jean-Jaur%C3%A8s/256170874775"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ESTA Belfort",
        "label": "\u00c9cole sup\u00e9rieure des technologies et des affaires de Belfort",
        "social": {
            "url": "https://www.esta-groupe.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Besan\u00e7on",
            "city": "Belfort",
            "dep": "Territoire de Belfort"
        }
    },
    {
        "short_name": "EC Lyon",
        "label": "\u00c9cole centrale de Lyon",
        "social": {
            "url": "https://www.ec-lyon.fr/",
            "twitter": "https://twitter.com/CentraleLyon",
            "facebook": "https://www.facebook.com/CentraleLyon"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "\u00c9cully",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "ECE",
        "label": "\u00c9cole centrale d'\u00e9lectronique",
        "social": {
            "url": "https://www.ece.fr/ecole-ingenieur/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 15e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "EFR",
        "label": "\u00c9cole fran\u00e7aise de Rome",
        "social": {
            "url": "http://www.efrome.it/",
            "twitter": "https://twitter.com/ef_rome",
            "facebook": "https://www.facebook.com/EcoleFrancaiseDeRome/"
        },
        "localisation": {
            "aca_city": "",
            "city": "Rome",
            "dep": "Italie"
        }
    },
    {
        "short_name": "Ensci-Les Ateliers",
        "label": "\u00c9cole nationale sup\u00e9rieure de cr\u00e9ation industrielle - Les Ateliers",
        "social": {
            "url": "https://www.ensci.com/2017-2018-ensci-les-ateliers/",
            "twitter": "",
            "facebook": "https://www.facebook.com/ENSCILesAteliers/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  8e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sup\u00e9rieure de chimie organique et min\u00e9rale",
        "social": {
            "url": "https://www.escom.fr/",
            "twitter": "https://twitter.com/escom_chimie",
            "facebook": "https://www.facebook.com/Escom-Chimie-Page-Officielle-159150504133842/"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Compi\u00e8gne",
            "dep": "Oise"
        }
    },
    {
        "short_name": "ESJ",
        "label": "\u00c9cole sup\u00e9rieure de journalisme de Lille",
        "social": {
            "url": "http://esj-lille.fr/",
            "twitter": "https://twitter.com/ESJLille",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "Agrosup Dijon",
        "label": "Institut national sup\u00e9rieur des sciences agronomiques, de l'alimentation et de l'environnement",
        "social": {
            "url": "http://www.agrosupdijon.fr/",
            "twitter": "https://twitter.com/AgroSupDijon",
            "facebook": "https://www.facebook.com/pages/AgroSup-Dijon/150348118338708"
        },
        "localisation": {
            "aca_city": "Dijon",
            "city": "Dijon",
            "dep": "C\u00f4te-d'Or"
        }
    },
    {
        "short_name": "UniLaSalle",
        "label": "Institut polytechnique UniLaSalle",
        "social": {
            "url": "https://www.unilasalle.fr/",
            "twitter": "https://twitter.com/UniLaSalle_fr",
            "facebook": "https://www.facebook.com/UniLaSalle.fr/"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Beauvais",
            "dep": "Oise"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Rennes-I",
        "social": {
            "url": "https://www.univ-rennes1.fr/",
            "twitter": "https://twitter.com/UnivRennes1",
            "facebook": "https://www.facebook.com/UnivRennes1"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "ENSAPB",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Paris-Belleville",
        "social": {
            "url": "http://www.paris-belleville.archi.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/Ecole-nationale-sup%C3%A9rieure-darchitecture-de-Paris-Belleville-918926864869687/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 19e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENSTA Bretagne",
        "label": "\u00c9cole nationale sup\u00e9rieure de techniques avanc\u00e9es Bretagne",
        "social": {
            "url": "https://www.ensta-bretagne.fr/fr",
            "twitter": "https://twitter.com/ENSTABretagne",
            "facebook": "https://www.facebook.com/ENSTA.Bretagne/"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Brest",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "Bordeaux Sciences Agro",
        "label": "\u00c9cole nationale sup\u00e9rieure des sciences agronomiques de Bordeaux Aquitaine",
        "social": {
            "url": "https://www.agro-bordeaux.fr/",
            "twitter": "https://www.agro-bordeaux.fr/",
            "facebook": "https://www.facebook.com/AgroBordeaux/"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Gradignan",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "ESTIA",
        "label": "\u00c9cole sup\u00e9rieure des technologies industrielles avanc\u00e9es",
        "social": {
            "url": "http://www.estia.fr/",
            "twitter": "https://twitter.com/EstiaOfficiel",
            "facebook": "https://www.facebook.com/ingenieurs.estia"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Bidart",
            "dep": "Pyr\u00e9n\u00e9es-Atlantiques"
        }
    },
    {
        "short_name": "IEP de Lyon",
        "label": "Institut d'\u00e9tudes politiques de Lyon",
        "social": {
            "url": "https://www.sciencespo-lyon.fr/",
            "twitter": "https://twitter.com/scpolyon",
            "facebook": "https://www.facebook.com/ScPoLyon/"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 7e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "Sup'Biotech",
        "label": "Institut sup\u00e9rieur des biotechnologies de Paris",
        "social": {
            "url": "https://www.supbiotech.fr/",
            "twitter": "https://twitter.com/supbiotech",
            "facebook": "https://www.facebook.com/supbiotech"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Villejuif",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "Sorbonne U",
        "label": "Sorbonne Universit\u00e9",
        "social": {
            "url": "https://www.sorbonne-universite.fr/",
            "twitter": "https://twitter.com/Sorbonne_Univ_",
            "facebook": "https://www.facebook.com/sorbonne.univ/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Lyon-II",
        "social": {
            "url": "http://www.univ-lyon2.fr/",
            "twitter": "https://twitter.com/univ_lyon2",
            "facebook": "https://www.facebook.com/Universite.Lumiere.Lyon2?ref=ts"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 7e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "UPHF",
        "label": "Universit\u00e9 Polytechnique Hauts-de-France",
        "social": {
            "url": "https://www.uphf.fr/",
            "twitter": "https://twitter.com/UphfOfficiel",
            "facebook": "https://www.facebook.com/uphfofficiel/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Aulnoy-lez-Valenciennes",
            "dep": "Nord"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris-III",
        "social": {
            "url": "http://www.univ-paris3.fr/",
            "twitter": "https://twitter.com/SorbonneParis3",
            "facebook": "https://www.facebook.com/SorbonneNouvelleP3"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale v\u00e9t\u00e9rinaire d'Alfort",
        "social": {
            "url": "https://www.vet-alfort.fr/",
            "twitter": "https://twitter.com/Env_Alfort",
            "facebook": "https://www.facebook.com/%C3%89cole-nationale-v%C3%A9t%C3%A9rinaire-dAlfort-EnvA-435412449930517/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Maisons-Alfort",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "ENS de Rennes",
        "label": "\u00c9cole normale sup\u00e9rieure de Rennes",
        "social": {
            "url": "http://www.ens-rennes.fr/",
            "twitter": "https://twitter.com/ENSRennes",
            "facebook": "https://www.facebook.com/ENSRennes"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Bruz",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole sup\u00e9rieure de commerce de Dijon-Bourgogne",
        "social": {
            "url": "https://www.bsb-education.com/",
            "twitter": "https://twitter.com/BurgundySB",
            "facebook": "https://www.facebook.com/BSB.BurgundySB/"
        },
        "localisation": {
            "aca_city": "Dijon",
            "city": "Dijon",
            "dep": "C\u00f4te-d'Or"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs des travaux de la construction de Caen",
        "social": {
            "url": "http://www.esitc-caen.fr/",
            "twitter": "https://twitter.com/search?q=esitc%20caen&src=typd&lang=fr",
            "facebook": "https://www.facebook.com/ESITC-Caen-118805098294698/"
        },
        "localisation": {
            "aca_city": "Caen",
            "city": "\u00c9pron",
            "dep": "Calvados"
        }
    },
    {
        "short_name": "",
        "label": "ESCP Business School",
        "social": {
            "url": "https://escp.eu/fr",
            "twitter": "https://twitter.com/ESCP_bs",
            "facebook": "https://www.facebook.com/ESCPbs/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 15e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Hautes \u00e9tudes d'ing\u00e9nieur",
        "social": {
            "url": "https://www.hei.fr/",
            "twitter": "https://twitter.com/EcoleHEI",
            "facebook": "https://www.facebook.com/hautesetudesingenieur"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "INSA de Rennes",
        "label": "Institut national des sciences appliqu\u00e9es de Rennes",
        "social": {
            "url": "https://www.insa-rennes.fr/",
            "twitter": "https://twitter.com/INSA_Rennes",
            "facebook": "https://www.facebook.com/pages/INSA-de-Rennes-Page-Officielle/182840505091727"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Poitiers",
        "social": {
            "url": "https://www.univ-poitiers.fr/",
            "twitter": "https://twitter.com/UnivPoitiers",
            "facebook": "https://www.facebook.com/univpoitiers/"
        },
        "localisation": {
            "aca_city": "Poitiers",
            "city": "Poitiers",
            "dep": "Vienne"
        }
    },
    {
        "short_name": "AU",
        "label": "Universit\u00e9 d'Avignon",
        "social": {
            "url": "https://univ-avignon.fr/",
            "twitter": "https://twitter.com/univavignon",
            "facebook": "https://www.facebook.com/Universite.Avignon/"
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Avignon",
            "dep": "Vaucluse"
        }
    },
    {
        "short_name": "EA",
        "label": "\u00c9cole de l'air",
        "social": {
            "url": "https://devenir-aviateur.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Salon-de-Provence",
            "dep": "Bouches-du-Rh\u00f4ne"
        }
    },
    {
        "short_name": "ENSAS",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Strasbourg",
        "social": {
            "url": "http://www.strasbourg.archi.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/EnsaStrasbourg"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "F\u00e9d\u00e9ration Universitaire Polytechnique de Lille",
        "social": {
            "url": "http://www.univ-catholille.fr/",
            "twitter": "https://twitter.com/UnivCatholille",
            "facebook": "https://www.facebook.com/UniversiteCatholiqueDeLille"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "INSA de Rouen Normandie",
        "label": "Institut national des sciences appliqu\u00e9es de Rouen",
        "social": {
            "url": "https://www.insa-rouen.fr/",
            "twitter": "https://twitter.com/insarouen",
            "facebook": "https://www.facebook.com/insaderouen"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Saint-\u00c9tienne-du-Rouvray",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "INSA de Strasbourg",
        "label": "Institut national des sciences appliqu\u00e9es de Strasbourg",
        "social": {
            "url": "https://www.insa-strasbourg.fr/fr/",
            "twitter": "https://twitter.com/Insa_Strasbourg",
            "facebook": "https://www.facebook.com/pages/Insa-de-Strasbourg/132470930161050"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Mulhouse",
        "social": {
            "url": "https://www.uha.fr/",
            "twitter": "https://twitter.com/UHA68",
            "facebook": "https://www.facebook.com/UHA68"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Mulhouse",
            "dep": "Haut-Rhin"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 d'Orl\u00e9ans",
        "social": {
            "url": "https://www.univ-orleans.fr/fr",
            "twitter": "https://twitter.com/Univ_Orleans",
            "facebook": "https://www.facebook.com/univorleans/"
        },
        "localisation": {
            "aca_city": "Orl\u00e9ans-Tours",
            "city": "Orl\u00e9ans",
            "dep": "Loiret"
        }
    },
    {
        "short_name": "EHESP",
        "label": "\u00c9cole des hautes \u00e9tudes en sant\u00e9 publique",
        "social": {
            "url": "https://www.ehesp.fr/",
            "twitter": "https://twitter.com/EHESP",
            "facebook": "https://www.facebook.com/EHESP"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole fran\u00e7aise d'Ath\u00e8nes",
        "social": {
            "url": "http://www.efa.gr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/pages/%C3%89cole-Fran%C3%A7aise-dAth%C3%A8nes/474935445909878?hc_location=timeline"
        },
        "localisation": {
            "aca_city": "",
            "city": "Ath\u00e8nes",
            "dep": "Gr\u00e8ce"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale des travaux publics de l'\u00c9tat",
        "social": {
            "url": "http://www.entpe.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Vaulx-en-Velin",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "ENSMM Besan\u00e7on",
        "label": "\u00c9cole nationale sup\u00e9rieure de m\u00e9canique et des microtechniques",
        "social": {
            "url": "http://www.ens2m.fr/",
            "twitter": "https://twitter.com/ENSMMOfficiel",
            "facebook": "https://www.facebook.com/ENSMMOfficiel/"
        },
        "localisation": {
            "aca_city": "Besan\u00e7on",
            "city": "Besan\u00e7on",
            "dep": "Doubs"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Universit\u00e9 Catholique de l'Ouest",
        "social": {
            "url": "https://www.uco.fr/fr",
            "twitter": "https://twitter.com/UnivCathoOuest",
            "facebook": "https://www.facebook.com/Universite.UCO"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "I\u00c9SEG",
        "label": "Institut d'\u00e9conomie scientifique et de gestion",
        "social": {
            "url": "http://www.ieseg.fr/",
            "twitter": "https://twitter.com/IESEG",
            "facebook": "https://www.facebook.com/pages/IESEG-School-of-Management/358733860458"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "Institut Agro",
        "label": "Institut national d'enseignement sup\u00e9rieur pour l'agriculture, l'alimentation et l'environnement",
        "social": {
            "url": "https://www.institut-agro.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 16e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "Isara-Lyon",
        "label": "Institut sup\u00e9rieur d\u2019agriculture Rh\u00f4ne-Alpes",
        "social": {
            "url": "https://www.isara.fr/",
            "twitter": "https://twitter.com/isaralyon",
            "facebook": "https://www.facebook.com/isaralyon"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 7e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "ISDAT",
        "label": "Institut sup\u00e9rieur des arts de Toulouse",
        "social": {
            "url": "http://www.isdat.fr/",
            "twitter": "https://twitter.com/isdaT_fr",
            "facebook": "https://www.facebook.com/isdaT.fr"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Nantes",
        "social": {
            "url": "http://www.univ-nantes.fr/",
            "twitter": "https://twitter.com/UnivNantes",
            "facebook": "https://www.facebook.com/univnantes"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Nantes",
            "dep": "Loire-Atlantique"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Le Havre",
        "social": {
            "url": "https://www.univ-lehavre.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/univ.lehavre"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Le Havre",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Rennes-II",
        "social": {
            "url": "http://www.univ-rennes2.fr/",
            "twitter": "https://twitter.com/Univ_Rennes2",
            "facebook": "https://www.facebook.com/UnivRennes2"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Chamb\u00e9ry",
        "social": {
            "url": "https://www.univ-smb.fr/",
            "twitter": "https://twitter.com/Univ_Savoie",
            "facebook": "https://www.facebook.com/UnivSavoie"
        },
        "localisation": {
            "aca_city": "Grenoble",
            "city": "Chamb\u00e9ry",
            "dep": "Savoie"
        }
    },
    {
        "short_name": "HEC Paris",
        "label": "\u00c9cole des hautes \u00e9tudes commerciales de Paris",
        "social": {
            "url": "https://www.hec.edu/fr",
            "twitter": "https://twitter.com/HECParis",
            "facebook": "https://www.facebook.com/HECParis"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Jouy-en-Josas",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure d'informatique pour l'industrie et l'entreprise",
        "social": {
            "url": "https://www.ensiie.fr/",
            "twitter": "https://twitter.com/ensiie",
            "facebook": "https://www.facebook.com/ENSIIE.91.68"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "\u00c9vry-Courcouronnes",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sup\u00e9rieure des sciences commerciales d'Angers",
        "social": {
            "url": "http://www.essca.fr/",
            "twitter": "https://twitter.com/essca_ecole",
            "facebook": "https://www.facebook.com/Essca-International-Students-164464980253086/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "ESITC Paris",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs des travaux de la construction de Paris",
        "social": {
            "url": "https://www.esitc-paris.fr/fr",
            "twitter": "https://twitter.com/esitcparis",
            "facebook": "https://www.facebook.com/esitcparis/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Arcueil",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "ESIGELEC",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs en g\u00e9nie \u00e9lectrique",
        "social": {
            "url": "http://www.esigelec.fr/fr",
            "twitter": "https://twitter.com/GroupeESIGELEC",
            "facebook": "https://fr-fr.facebook.com/Page.ESIGELEC"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Saint-\u00c9tienne-du-Rouvray",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "IEP de Lille",
        "label": "Institut d'\u00e9tudes politiques de Lille",
        "social": {
            "url": "http://www.sciencespo-lille.eu/",
            "twitter": "https://twitter.com/ScPoLille",
            "facebook": "https://www.facebook.com/sciencespolille"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "IEP de Paris",
        "label": "Institut d'\u00e9tudes politiques de Paris",
        "social": {
            "url": "https://www.sciencespo.fr/",
            "twitter": "https://twitter.com/sciencespo",
            "facebook": "https://www.facebook.com/SciencesPo"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  7e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "INU Albi",
        "label": "Institut national universitaire Jean-Fran\u00e7ois Champollion",
        "social": {
            "url": "https://www.univ-jfc.fr/",
            "twitter": "https://twitter.com/UnivChampollion",
            "facebook": "https://www.facebook.com/universite.champollion"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Albi",
            "dep": "Tarn"
        }
    },
    {
        "short_name": "",
        "label": "Montpellier Business School",
        "social": {
            "url": "http://www.montpellier-bs.com/",
            "twitter": "https://twitter.com/Montpellier_BS",
            "facebook": "https://www.facebook.com/MontpellierBS"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "Montpellier",
            "dep": "H\u00e9rault"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Lyon-I",
        "social": {
            "url": "http://www.univ-lyon1.fr/",
            "twitter": "https://twitter.com/UnivLyon1",
            "facebook": "https://www.facebook.com/UnivLyon1"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Villeurbanne",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Clermont Auvergne",
        "social": {
            "url": "",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Clermont-Ferrand",
            "city": "Clermont-Ferrand",
            "dep": "Puy-de-D\u00f4me"
        }
    },
    {
        "short_name": "ECAM-EPMI",
        "label": "ECAM-EPMI",
        "social": {
            "url": "http://www.epmi.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale du g\u00e9nie de l'eau et de l'environnement de Strasbourg",
        "social": {
            "url": "https://engees.unistra.fr/",
            "twitter": "https://twitter.com/engees_ecole",
            "facebook": "https://www.facebook.com/engees.ecole/"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "La F\u00e9mis",
        "label": "\u00c9cole nationale sup\u00e9rieure des m\u00e9tiers de l'image et du son",
        "social": {
            "url": "http://www.femis.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/LaFemis.Paris/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 18e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sup\u00e9rieure d'informatique \u00e9lectronique automatique",
        "social": {
            "url": "https://www.esiea.fr/",
            "twitter": "https://twitter.com/GroupeESIEA",
            "facebook": "https://www.facebook.com/esiea"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ESB Nantes",
        "label": "\u00c9cole sup\u00e9rieure du bois",
        "social": {
            "url": "https://www.ecoledubois.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Nantes",
            "dep": "Loire-Atlantique"
        }
    },
    {
        "short_name": "EPF",
        "label": "EPF - \u00c9cole d'ing\u00e9nieurs",
        "social": {
            "url": "http://www.epf.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Sceaux",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "Isa",
        "label": "Institut sup\u00e9rieur d'agriculture de Lille",
        "social": {
            "url": "http://www.isa-lille.fr/",
            "twitter": "https://twitter.com/ISA_Lille",
            "facebook": "https://fr-fr.facebook.com/GroupeISA"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Institut catholique de Paris",
        "social": {
            "url": "https://www.icp.fr/",
            "twitter": "https://twitter.com/UnivCathoParis",
            "facebook": "https://www.facebook.com/InstitutCatholiquedeParis"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "IRTS Champagne-Ardenne",
        "label": "Institut r\u00e9gional du travail social de Champagne-Ardennes",
        "social": {
            "url": "https://irtsca.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Reims",
            "dep": "Marne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Montpellier",
        "social": {
            "url": "http://www.umontpellier.fr/",
            "twitter": "https://twitter.com/umontpellier",
            "facebook": "https://www.facebook.com/univmontpellier"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "Montpellier",
            "dep": "H\u00e9rault"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Tours",
        "social": {
            "url": "https://www.univ-tours.fr/",
            "twitter": "https://twitter.com/UnivTours",
            "facebook": "https://www.facebook.com/univ.tours/"
        },
        "localisation": {
            "aca_city": "Orl\u00e9ans-Tours",
            "city": "Tours",
            "dep": "Indre-et-Loire"
        }
    },
    {
        "short_name": "EM Normandie",
        "label": "\u00c9cole de management de Normandie",
        "social": {
            "url": "https://www.em-normandie.com/fr",
            "twitter": "https://twitter.com/emnormandie",
            "facebook": "https://www.facebook.com/EMNormandie"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Le Havre",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "ENSA-V",
        "label": "\u00c9cole nationale sup\u00e9rieure d\u2019architecture de Versailles",
        "social": {
            "url": "http://www.versailles.archi.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/EnsaVersailles/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Versailles",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure de formation de l\u2019enseignement agricole",
        "social": {
            "url": "http://www.ensfea.fr/",
            "twitter": "https://twitter.com/ENSFEA_Toulouse",
            "facebook": "https://www.facebook.com/ensfea.toulouse"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Castanet-Tolosan",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ESADHaR",
        "label": "\u00c9cole sup\u00e9rieure d'art et design Le Havre-Rouen",
        "social": {
            "url": "http://esadhar.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Rouen",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "ESIEE Amiens",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs en \u00e9lectronique et \u00e9lectrotechnique d'Amiens",
        "social": {
            "url": "http://www.esiee-amiens.fr/",
            "twitter": "https://twitter.com/esieeamiens",
            "facebook": "https://www.facebook.com/esiee.amiens/"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Amiens",
            "dep": "Somme"
        }
    },
    {
        "short_name": "",
        "label": "Institut national du sport, de l'expertise et de la performance",
        "social": {
            "url": "https://www.insep.fr/fr",
            "twitter": "https://twitter.com/INSEP_PARIS",
            "facebook": "https://www.facebook.com/insep.paris"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 12e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "IP Paris",
        "label": "Institut polytechnique de Paris",
        "social": {
            "url": "https://www.ip-paris.fr/",
            "twitter": "https://twitter.com/IP__Paris",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Palaiseau",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "",
        "label": "Mus\u00e9um national d'histoire naturelle",
        "social": {
            "url": "http://www.mnhn.fr/fr",
            "twitter": "https://twitter.com/Le_Museum",
            "facebook": "https://www.facebook.com/museumnationaldhistoirenaturelle"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "URCA",
        "label": "Universit\u00e9 de Reims",
        "social": {
            "url": "http://www.univ-reims.fr/",
            "twitter": "https://twitter.com/universitereims",
            "facebook": "https://www.facebook.com/Univ.ReimsChampagneArdenne"
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Reims",
            "dep": "Marne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Lyon-III",
        "social": {
            "url": "http://www.univ-lyon3.fr/",
            "twitter": "https://twitter.com/UJML",
            "facebook": "https://www.facebook.com/UnivJeanMoulinLyon3/"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 8e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "Universit\u00e9 PSL",
        "label": "Universit\u00e9 Paris sciences et lettres",
        "social": {
            "url": "https://www.psl.eu/",
            "twitter": "https://twitter.com/psl_univ",
            "facebook": "https://www.facebook.com/PSLuniv"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "CREPS Toulouse",
        "label": "Centre de ressources, d'expertise et de performance sportives de Toulouse",
        "social": {
            "url": "https://www.creps-toulouse-midi-pyrenees.jeunesse-sports.gouv.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Edhec Business School",
        "social": {
            "url": "http://www.edhec.com/",
            "twitter": "https://twitter.com/EDHEC_BSchool",
            "facebook": "https://www.facebook.com/edhec"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Roubaix",
            "dep": "Nord"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale d'ing\u00e9nieurs de Brest",
        "social": {
            "url": "http://www.enib.fr/",
            "twitter": "https://twitter.com/ENIB_INFO",
            "facebook": "https://www.facebook.com/enibrest"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Plouzan\u00e9",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure des arts et techniques du th\u00e9\u00e2tre",
        "social": {
            "url": "http://www.ensatt.fr/",
            "twitter": "https://twitter.com/Ensatt",
            "facebook": "https://www.facebook.com/ensatt.lyon/"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 5e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "IEP de Rennes",
        "label": "Institut d'\u00e9tudes politiques de Rennes",
        "social": {
            "url": "http://www.sciencespo-rennes.fr/",
            "twitter": "https://twitter.com/Sc_Po_Rennes",
            "facebook": "https://www.facebook.com/SciencesPoRennes"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "Institut polytechnique de Bordeaux",
        "social": {
            "url": "https://www.ipb.fr/",
            "twitter": "https://twitter.com/BordeauxINP",
            "facebook": "https://www.facebook.com/groupeIPB"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Talence",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 d'Angers",
        "social": {
            "url": "http://www.univ-angers.fr/",
            "twitter": "https://twitter.com/UnivAngers",
            "facebook": "https://www.facebook.com/univangers"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de La R\u00e9union",
        "social": {
            "url": "http://www.univ-reunion.fr/",
            "twitter": "https://twitter.com/univ_reunion",
            "facebook": "https://www.facebook.com/univreunion/"
        },
        "localisation": {
            "aca_city": "La R\u00e9union",
            "city": "Saint-Denis",
            "dep": "La R\u00e9union"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris-VIII",
        "social": {
            "url": "http://www.univ-paris8.fr/",
            "twitter": "https://twitter.com/UnivParis8",
            "facebook": "https://www.facebook.com/Paris8"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Saint-Denis",
            "dep": "Seine-Saint-Denis"
        }
    },
    {
        "short_name": "",
        "label": "Centre des hautes \u00e9tudes de la construction",
        "social": {
            "url": "http://www.chec.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Arcueil",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole centrale de Nantes",
        "social": {
            "url": "http://www.ec-nantes.fr/",
            "twitter": "https://twitter.com/CentraleNantes",
            "facebook": "https://www.facebook.com/pages/ECOLE-CENTRALE-DE-NANTES/57286116786#%21/pages/ECOLE-CENTRALE-DE-NANTES/57286116786"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Nantes",
            "dep": "Loire-Atlantique"
        }
    },
    {
        "short_name": "ENM",
        "label": "\u00c9cole nationale de la m\u00e9t\u00e9orologie",
        "social": {
            "url": "http://www.enm.meteo.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ESFF",
        "label": "\u00c9cole sup\u00e9rieure de fonderie et de forge",
        "social": {
            "url": "http://www.esff.fr/",
            "twitter": "https://twitter.com/SupDeuxF",
            "facebook": "https://www.facebook.com/esff.france/?ref=hl"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "S\u00e8vres",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "Groupe ICAM",
        "label": "Groupe Institut catholique d'arts et m\u00e9tiers",
        "social": {
            "url": "",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  8e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "INALCO",
        "label": "Institut national des langues et civilisations orientales",
        "social": {
            "url": "http://www.inalco.fr/",
            "twitter": "https://twitter.com/Inalco",
            "facebook": "https://www.facebook.com/inalco.languesO/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 13e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ISIPCA",
        "label": "Institut sup\u00e9rieur international du parfum, de la cosm\u00e9tique et de l'aromatique alimentaire",
        "social": {
            "url": "https://www.isipca.fr/",
            "twitter": "https://twitter.com/ISIPCAParis",
            "facebook": "https://www.facebook.com/IsipcaParis/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Versailles",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "KEDGE BS",
        "label": "KEDGE Business School",
        "social": {
            "url": "https://kedge.edu/",
            "twitter": "https://twitter.com/kedgebs",
            "facebook": "https://www.facebook.com/kedgebs"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Talence",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "UFC",
        "label": "Universit\u00e9 de Besan\u00e7on",
        "social": {
            "url": "https://www.univ-fcomte.fr/",
            "twitter": "https://twitter.com/fc_univ",
            "facebook": "https://www.facebook.com/UniversiteFrancheComte"
        },
        "localisation": {
            "aca_city": "Besan\u00e7on",
            "city": "Besan\u00e7on",
            "dep": "Doubs"
        }
    },
    {
        "short_name": "CNAM",
        "label": "Conservatoire national des arts et m\u00e9tiers",
        "social": {
            "url": "http://www.cnam.fr/",
            "twitter": "https://twitter.com/LeCnam",
            "facebook": "https://www.facebook.com/LeCnam.formation?rf=110888042265646"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  3e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "EI Purpan",
        "label": "\u00c9cole d'ing\u00e9nieurs de Purpan",
        "social": {
            "url": "http://www.purpan.fr/",
            "twitter": "https://twitter.com/EcoledePURPAN",
            "facebook": "https://www.facebook.com/EcoledIngenieursdePURPAN"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ESTACA",
        "label": "\u00c9cole sup\u00e9rieure des techniques a\u00e9ronautiques et de construction automobile",
        "social": {
            "url": "http://www.estaca.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Montigny-le-Bretonneux",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "ESITC de Metz",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs des travaux de la construction de Metz",
        "social": {
            "url": "http://www.esitc-metz.com/",
            "twitter": "",
            "facebook": "https://www.facebook.com/esitc.metz.esitc/"
        },
        "localisation": {
            "aca_city": "Nancy-Metz",
            "city": "Metz",
            "dep": "Moselle"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Institut catholique de Rennes",
        "social": {
            "url": "https://www.icrennes.org/",
            "twitter": "",
            "facebook": "https://www.facebook.com/icrennes"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Bruz",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "Institut national d'histoire de l'art",
        "social": {
            "url": "https://www.inha.fr/fr/",
            "twitter": "https://twitter.com/INHA_Fr",
            "facebook": "https://www.facebook.com/Institutnationaldhistoiredelart"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  2e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "INS HEA",
        "label": "Institut national sup\u00e9rieur de formation et de recherche pour l'\u00e9ducation des jeunes handicap\u00e9s et les enseignements adapt\u00e9s",
        "social": {
            "url": "http://www.inshea.fr/",
            "twitter": "https://twitter.com/ins_hea",
            "facebook": "https://www.facebook.com/inshea.page.officielle/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Suresnes",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "IPSA",
        "label": "Institut polytechnique des sciences avanc\u00e9es",
        "social": {
            "url": "https://www.ipsa.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Ivry-sur-Seine",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "ISBA-TP MARS",
        "label": "Institut sup\u00e9rieur du b\u00e2timent et des travaux publics",
        "social": {
            "url": "https://www.isba.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Marseille 13e",
            "dep": "Bouches-du-Rh\u00f4ne"
        }
    },
    {
        "short_name": "",
        "label": "Neoma Business School",
        "social": {
            "url": "https://www.neoma-bs.fr/",
            "twitter": "https://twitter.com/neomabs",
            "facebook": "https://www.facebook.com/NEOMAbs/"
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Reims",
            "dep": "Marne"
        }
    },
    {
        "short_name": "ISTOM",
        "label": "\u00c9cole d'ing\u00e9nieur d'agro-d\u00e9veloppement international",
        "social": {
            "url": "http://www.istom.fr/",
            "twitter": "https://twitter.com/istomingenieur",
            "facebook": "https://www.facebook.com/istomingenieur/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Angers",
            "dep": "Maine-et-Loire"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure maritime",
        "social": {
            "url": "https://www.supmaritime.fr/",
            "twitter": "https://twitter.com/ENSM",
            "facebook": "https://www.facebook.com/EcoleNationaleSuperieureMaritime"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Le Havre",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "EPITA",
        "label": "\u00e9cole pour l'informatique et les techniques avanc\u00e9es",
        "social": {
            "url": "https://www.epita.fr/",
            "twitter": "https://twitter.com/epita",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Le Kremlin-Bic\u00eatre",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "ESTP",
        "label": "\u00c9cole sp\u00e9ciale des travaux publics, du b\u00e2timent et de l'industrie",
        "social": {
            "url": "http://www.estp.fr/",
            "twitter": "https://twitter.com/estpparis",
            "facebook": "https://www.facebook.com/estpparis"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Cachan",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "UCLY",
        "label": "Institut catholique de Lyon",
        "social": {
            "url": "http://www.ucly.fr/",
            "twitter": "https://twitter.com/ucly",
            "facebook": "https://www.facebook.com/UCLyon/?fref=ts"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Lyon 2e",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "Vet Agro Sup",
        "label": "Institut d'enseignement sup\u00e9rieur et de recherche en alimentation, sant\u00e9 animale, sciences agronomiques et de l'environnement",
        "social": {
            "url": "http://www.vetagro-sup.fr/",
            "twitter": "https://twitter.com/VetAgroSup",
            "facebook": "https://www.facebook.com/VetAgroSup?fref=ts"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Marcy-l'\u00c9toile",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "INSA Centre Val de Loire",
        "label": "Institut national des sciences appliqu\u00e9es Centre Val de Loire",
        "social": {
            "url": "http://www.insa-centrevaldeloire.fr/",
            "twitter": "https://twitter.com/insacvl",
            "facebook": "https://www.facebook.com/insacvl"
        },
        "localisation": {
            "aca_city": "Orl\u00e9ans-Tours",
            "city": "Bourges",
            "dep": "Cher"
        }
    },
    {
        "short_name": "ISAE-SUPAERO",
        "label": "Institut sup\u00e9rieur de l'a\u00e9ronautique et de l'espace",
        "social": {
            "url": "https://www.isae-supaero.fr/fr/",
            "twitter": "https://twitter.com/ISAE_officiel",
            "facebook": "https://www.facebook.com/isae.supaero"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ISC Paris Business School",
        "label": "Institut sup\u00e9rieur du commerce de Paris",
        "social": {
            "url": "https://iscparis.com/",
            "twitter": "https://twitter.com/iscparisschool",
            "facebook": "https://www.facebook.com/ISCParisSchool"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 17e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "UBx",
        "label": "Universit\u00e9 de Bordeaux",
        "social": {
            "url": "https://www.u-bordeaux.fr/",
            "twitter": "https://twitter.com/univbordeaux",
            "facebook": "https://www.facebook.com/univbordeaux"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Talence",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "UGA",
        "label": "Universit\u00e9 Grenoble Alpes",
        "social": {
            "url": "https://www.univ-grenoble-alpes.fr/",
            "twitter": "https://twitter.com/UGrenobleAlpes",
            "facebook": "https://www.facebook.com/UGrenobleAlpes/"
        },
        "localisation": {
            "aca_city": "Grenoble",
            "city": "Saint-Martin-d'H\u00e8res",
            "dep": "Is\u00e8re"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Montpellier-III",
        "social": {
            "url": "http://www.univ-montp3.fr/",
            "twitter": "https://twitter.com/univpaulvalery",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-Paul-Val%C3%A9ry-Montpellier-III/9111853389"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "Montpellier",
            "dep": "H\u00e9rault"
        }
    },
    {
        "short_name": "Ensa de Normandie",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Normandie",
        "social": {
            "url": "http://www.rouen.archi.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/%C3%89cole-nationale-sup%C3%A9rieure-darchitecture-de-Normandie-104102103746618/"
        },
        "localisation": {
            "aca_city": "Rouen",
            "city": "Darn\u00e9tal",
            "dep": "Seine-Maritime"
        }
    },
    {
        "short_name": "ENS Chimie Rennes",
        "label": "\u00c9cole nationale sup\u00e9rieure de chimie de Rennes",
        "social": {
            "url": "http://www.ensc-rennes.fr/",
            "twitter": "https://twitter.com/ENSCR",
            "facebook": "https://www.facebook.com/pages/Ecole-nationale-sup%C3%A9rieure-de-chimie-de-Rennes-ENSCR/106800626112738"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "ENSPVM",
        "label": "\u00c9cole nationale sup\u00e9rieure du Paysage de Versailles-Marseille",
        "social": {
            "url": "http://www.ecole-paysage.fr/site/ensp_fr/index.htm",
            "twitter": "",
            "facebook": "https://www.facebook.com/EcoleNationaleSuperieurePaysage.PotagerduRoi"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Versailles",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "ESAD Reims",
        "label": "\u00c9cole sup\u00e9rieure d'art et de design de Reims",
        "social": {
            "url": "http://esad-reims.fr/fr/",
            "twitter": "https://twitter.com/ESADdeReims",
            "facebook": "https://www.facebook.com/ESAD.Reims.fr"
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Reims",
            "dep": "Marne"
        }
    },
    {
        "short_name": "IFAO",
        "label": "Institut fran\u00e7ais d'arch\u00e9ologie orientale du Caire",
        "social": {
            "url": "https://www.ifao.egnet.net/",
            "twitter": "https://twitter.com/ifaocaire",
            "facebook": "https://www.facebook.com/ifaocaire/"
        },
        "localisation": {
            "aca_city": "",
            "city": "Le Caire",
            "dep": "\u00c9gypte"
        }
    },
    {
        "short_name": "INSTN",
        "label": "Institut national des sciences et techniques nucl\u00e9aires",
        "social": {
            "url": "http://www-instn.cea.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Gif-sur-Yvette",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "PSPBB",
        "label": "P\u00f4le sup\u00e9rieur d'enseignement artistique Paris - Boulogne-Billancourt",
        "social": {
            "url": "https://www.pspbb.fr/fr/",
            "twitter": "https://twitter.com/_PSPBB",
            "facebook": "https://www.facebook.com/pspbb.pole"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  8e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "Skema",
        "label": "Skema Business School",
        "social": {
            "url": "http://www.skema-bs.fr/",
            "twitter": "https://twitter.com/Skema_BS",
            "facebook": "https://www.facebook.com/SKEMA.Business.School"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Lille",
            "dep": "Nord"
        }
    },
    {
        "short_name": "TBS",
        "label": "Toulouse Business School",
        "social": {
            "url": "https://www.tbs-education.fr/",
            "twitter": "https://twitter.com/tbs_education",
            "facebook": "https://www.facebook.com/TBSeducation"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Paris",
        "social": {
            "url": "https://u-paris.fr/",
            "twitter": "https://twitter.com/Univ_Paris",
            "facebook": "https://www.facebook.com/univparis/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "UTC",
        "label": "Universit\u00e9 de technologie de Compi\u00e8gne",
        "social": {
            "url": "https://www.utc.fr/",
            "twitter": "https://twitter.com/utc_compiegne",
            "facebook": "https://www.facebook.com/pages/UTC-Universit%C3%A9-de-Technologie-de-Compi%C3%A8gne/86348219317"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Compi\u00e8gne",
            "dep": "Oise"
        }
    },
    {
        "short_name": "EIGSI",
        "label": "\u00c9cole d'ing\u00e9nieurs en g\u00e9nie des syst\u00e8mes industriels",
        "social": {
            "url": "http://www.eigsi.fr/",
            "twitter": "https://twitter.com/EIGSI",
            "facebook": "https://www.facebook.com/eigsi.ingenieurs"
        },
        "localisation": {
            "aca_city": "Poitiers",
            "city": "La Rochelle",
            "dep": "Charente-Maritime"
        }
    },
    {
        "short_name": "EDL",
        "label": "\u00c9cole du Louvre",
        "social": {
            "url": "http://www.ecoledulouvre.fr/",
            "twitter": "https://twitter.com/EcoleduLouvre",
            "facebook": "https://www.facebook.com/EcoleduLouvre/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  1er",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENSAPM",
        "label": "\u00c9cole nationale sup\u00e9rieure d'architecture de Paris-Malaquais",
        "social": {
            "url": "http://paris-malaquais.archi.fr/",
            "twitter": "https://twitter.com/ENSAPM",
            "facebook": "https://www.facebook.com/Ecole-nationale-sup%C3%A9rieure-darchitecture-Paris-Malaquais-936226349797670/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENSBA",
        "label": "\u00c9cole nationale sup\u00e9rieure des beaux-arts",
        "social": {
            "url": "http://www.beauxartsparis.fr/fr/",
            "twitter": "https://twitter.com/beauxartsparis",
            "facebook": "https://www.facebook.com/beauxartsparis"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "IPC",
        "label": "Facult\u00e9s Libres de Philosophie et de Psychologie",
        "social": {
            "url": "http://www.ipc-paris.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 14e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "3IL",
        "label": "Institut d'ing\u00e9nierie informatique de Limoges",
        "social": {
            "url": "https://www.3il-ingenieurs.fr/",
            "twitter": "https://twitter.com/3iL_Ingenieurs",
            "facebook": "https://www.facebook.com/3iLIngenieurs"
        },
        "localisation": {
            "aca_city": "Limoges",
            "city": "Limoges",
            "dep": "Haute-Vienne"
        }
    },
    {
        "short_name": "INSA de Lyon",
        "label": "Institut national des sciences appliqu\u00e9es de Lyon",
        "social": {
            "url": "http://www.insa-lyon.fr/",
            "twitter": "https://twitter.com/insadelyon",
            "facebook": "https://www.facebook.com/INSAdeLyon?ref=sgm"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Villeurbanne",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "Toulouse INP",
        "label": "Institut national polytechnique de Toulouse",
        "social": {
            "url": "https://www.inp-toulouse.fr/",
            "twitter": "https://twitter.com/INP_Toulouse",
            "facebook": "https://www.facebook.com/inptoulouse/"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 d'Artois",
        "social": {
            "url": "http://www.univ-artois.fr/",
            "twitter": "https://twitter.com/Univ_Artois",
            "facebook": "https://www.facebook.com/Universite.Artois/"
        },
        "localisation": {
            "aca_city": "Lille",
            "city": "Arras",
            "dep": "Pas-de-Calais"
        }
    },
    {
        "short_name": "UBS",
        "label": "Universit\u00e9 Bretagne-Sud",
        "social": {
            "url": "http://www.univ-ubs.fr/",
            "twitter": "https://twitter.com/ubs_universite",
            "facebook": "https://www.facebook.com/univUBS/"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Lorient",
            "dep": "Morbihan"
        }
    },
    {
        "short_name": "UVSQ",
        "label": "Universit\u00e9 de Versailles-Saint-Quentin-en-Yvelines",
        "social": {
            "url": "http://www.uvsq.fr/",
            "twitter": "https://twitter.com/uvsq",
            "facebook": "https://www.facebook.com/UVSQ.78"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Versailles",
            "dep": "Yvelines"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Institut fran\u00e7ais de la mode",
        "social": {
            "url": "https://www.ifmparis.fr/",
            "twitter": "https://twitter.com/ifmparis",
            "facebook": "https://www.facebook.com/ifm.paris/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 13e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "CY Cergy Paris Universit\u00e9",
        "social": {
            "url": "https://www.cyu.fr/",
            "twitter": "https://twitter.com/UniversiteCergy",
            "facebook": "https://www.facebook.com/CYCergyParisUniversite/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "ECAM Rennes",
        "label": "ECAM Rennes Louis de Broglie",
        "social": {
            "url": "http://www.ecam-rennes.fr/",
            "twitter": "https://twitter.com/ECAMRennes",
            "facebook": "https://www.facebook.com/EcamRennesLouisDeBroglie?"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Bruz",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole navale",
        "social": {
            "url": "https://www.ecole-navale.fr/",
            "twitter": "https://twitter.com/Ecole_navale",
            "facebook": "https://www.facebook.com/Ecole.navale"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Lanv\u00e9oc",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "ESME",
        "label": "\u00c9cole sp\u00e9ciale de m\u00e9canique et d'\u00e9lectricit\u00e9",
        "social": {
            "url": "https://www.esme.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Ivry-sur-Seine",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "Supm\u00e9ca",
        "label": "Institut sup\u00e9rieur de m\u00e9canique de Paris",
        "social": {
            "url": "http://www.supmeca.fr/",
            "twitter": "https://twitter.com/supmeca",
            "facebook": "https://www.facebook.com/pages/Supm%C3%A9ca-Institut-Sup%C3%A9rieur-de-M%C3%A9canique-de-Paris/276132662428779"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Saint-Ouen-sur-Seine",
            "dep": "Seine-Saint-Denis"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Brest",
        "social": {
            "url": "http://www.univ-brest.fr/",
            "twitter": "https://twitter.com/UBO_UnivBrest",
            "facebook": "https://www.facebook.com/UBO.UnivBrest"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Brest",
            "dep": "Finist\u00e8re"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Lorraine",
        "social": {
            "url": "http://www.univ-lorraine.fr/",
            "twitter": "https://twitter.com/Univ_Lorraine",
            "facebook": "https://www.facebook.com/UnivLorraine"
        },
        "localisation": {
            "aca_city": "Nancy-Metz",
            "city": "Nancy",
            "dep": "Meurthe-et-Moselle"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris XIII",
        "social": {
            "url": "http://www.univ-paris13.fr/",
            "twitter": "https://twitter.com/univ_spn",
            "facebook": "https://www.facebook.com/univsorbonneparisnord"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Villetaneuse",
            "dep": "Seine-Saint-Denis"
        }
    },
    {
        "short_name": "",
        "label": "Casa de Vel\u00e1zquez de Madrid",
        "social": {
            "url": "http://www.casadevelazquez.org/",
            "twitter": "https://twitter.com/casadevelazquez",
            "facebook": "https://www.facebook.com/casadevelazquez"
        },
        "localisation": {
            "aca_city": "",
            "city": "Madrid",
            "dep": "Espagne"
        }
    },
    {
        "short_name": "CFJ",
        "label": "Centre de formation des journalistes",
        "social": {
            "url": "https://www.cfjparis.com/",
            "twitter": "https://twitter.com/cfjparis",
            "facebook": "https://www.facebook.com/CFJ.paris/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 12e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Coll\u00e8ge de France",
        "social": {
            "url": "https://www.college-de-france.fr/",
            "twitter": "https://twitter.com/cdf1530",
            "facebook": "https://www.facebook.com/College.de.France"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  5e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole centrale de Marseille",
        "social": {
            "url": "https://www.centrale-marseille.fr/",
            "twitter": "https://twitter.com/CentraleMars",
            "facebook": "https://www.facebook.com/CentraleMarseille"
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Marseille 13e",
            "dep": "Bouches-du-Rh\u00f4ne"
        }
    },
    {
        "short_name": "ISAE-ENSMA",
        "label": "\u00c9cole nationale sup\u00e9rieure de m\u00e9canique et d'a\u00e9rotechnique de Poitiers",
        "social": {
            "url": "https://www.ensma.fr/",
            "twitter": "https://twitter.com/isae_ensma",
            "facebook": "https://www.facebook.com/isae.ensma86/"
        },
        "localisation": {
            "aca_city": "Poitiers",
            "city": "Chasseneuil-du-Poitou",
            "dep": "Vienne"
        }
    },
    {
        "short_name": "ESILV",
        "label": "\u00c9cole sup\u00e9rieure d'ing\u00e9nieurs L\u00e9onard-de-Vinci",
        "social": {
            "url": "https://www.esilv.fr/",
            "twitter": "https://twitter.com/esilvparis",
            "facebook": "https://www.facebook.com/ESILVparis/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Courbevoie",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "",
        "label": "Institut europ\u00e9en d'administration des affaires",
        "social": {
            "url": "https://www.insead.edu/",
            "twitter": "https://twitter.com/insead",
            "facebook": "https://www.facebook.com/INSEAD/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Fontainebleau",
            "dep": "Seine-et-Marne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "Institut sup\u00e9rieur d'\u00e9lectronique de Paris",
        "social": {
            "url": "https://www.isep.fr/",
            "twitter": "https://twitter.com/ISEP",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 du Mans",
        "social": {
            "url": "http://www.univ-lemans.fr/",
            "twitter": "https://twitter.com/LeMansUniv",
            "facebook": "https://www.facebook.com/LeMansUniversite/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Le Mans",
            "dep": "Sarthe"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Perpignan",
        "social": {
            "url": "https://www.univ-perp.fr/",
            "twitter": "https://twitter.com/upvd1",
            "facebook": "https://www.facebook.com/UPVD66/"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "Perpignan",
            "dep": "Pyr\u00e9n\u00e9es-Orientales"
        }
    },
    {
        "short_name": "",
        "label": "Institut Mines-T\u00e9l\u00e9com",
        "social": {
            "url": "https://www.imt.fr/",
            "twitter": "https://twitter.com/IMTFrance",
            "facebook": "https://www.facebook.com/imt.france"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Palaiseau",
            "dep": "Essonne"
        }
    },
    {
        "short_name": "CNSMDP",
        "label": "Conservatoire national sup\u00e9rieur de musique et de danse de Paris",
        "social": {
            "url": "http://www.conservatoiredeparis.fr/accueil/",
            "twitter": "https://twitter.com/CnsmdParis",
            "facebook": "https://www.facebook.com/conservatoiredeparis.cnsmdp"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 19e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "EFREI Paris",
        "label": "\u00c9cole d'ing\u00e9nieur g\u00e9n\u00e9raliste en informatique et technologies du num\u00e9rique",
        "social": {
            "url": "http://www.efrei.fr/",
            "twitter": "https://twitter.com/Efrei_Paris",
            "facebook": "https://www.facebook.com/Efrei/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Villejuif",
            "dep": "Val-de-Marne"
        }
    },
    {
        "short_name": "ENSG-G\u00e9omatique",
        "label": "\u00c9cole nationale des sciences g\u00e9ographiques",
        "social": {
            "url": "http://www.ensg.eu/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Champs-sur-Marne",
            "dep": "Seine-et-Marne"
        }
    },
    {
        "short_name": "ENSICAEN",
        "label": "\u00c9cole nationale sup\u00e9rieure d'ing\u00e9nieurs de Caen",
        "social": {
            "url": "http://www.ensicaen.fr/",
            "twitter": "https://twitter.com/ENSICAEN",
            "facebook": "https://www.facebook.com/Ensicaen"
        },
        "localisation": {
            "aca_city": "Caen",
            "city": "Caen",
            "dep": "Calvados"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale v\u00e9t\u00e9rinaire de Toulouse",
        "social": {
            "url": "http://www.envt.fr/",
            "twitter": "https://twitter.com/env_toulouse",
            "facebook": "https://www.facebook.com/envtoulouse"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "ESIEE Paris",
        "label": "ESIEE - CCI Paris Ile-de-France Education",
        "social": {
            "url": "https://www.esiee.fr/",
            "twitter": "https://twitter.com/ESIEEPARIS",
            "facebook": "https://www.facebook.com/esieeparis"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Noisy-le-Grand",
            "dep": "Seine-Saint-Denis"
        }
    },
    {
        "short_name": "",
        "label": "Institut d'administration des entreprises de Paris",
        "social": {
            "url": "http://www.iae-paris.com/",
            "twitter": "https://twitter.com/iaeparis",
            "facebook": "https://www.facebook.com/iaeparis"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 13e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "Rennes SB",
        "label": "\u00c9cole sup\u00e9rieure de commerce de Rennes",
        "social": {
            "url": "https://www.rennes-sb.fr/?_ga=2.191541422.565139386.1613571078-130296347.1613571078",
            "twitter": "https://twitter.com/rennessb",
            "facebook": "https://www.facebook.com/RennesSB/"
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Rennes",
            "dep": "Ille-et-Vilaine"
        }
    },
    {
        "short_name": "UBM",
        "label": "Universit\u00e9 de Bordeaux-III",
        "social": {
            "url": "http://www.u-bordeaux-montaigne.fr/",
            "twitter": "https://twitter.com/UBMontaigne",
            "facebook": "https://www.facebook.com/UBordeauxMontaigne"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Pessac",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "UNICAEN",
        "label": "Universit\u00e9 de Caen",
        "social": {
            "url": "http://www.unicaen.fr/",
            "twitter": "https://twitter.com/universite_caen",
            "facebook": "https://www.facebook.com/unicaen"
        },
        "localisation": {
            "aca_city": "Caen",
            "city": "Caen",
            "dep": "Calvados"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de la Nouvelle-Cal\u00e9donie",
        "social": {
            "url": "https://unc.nc/",
            "twitter": "https://twitter.com/unc_nc",
            "facebook": "https://www.facebook.com/univ.nc"
        },
        "localisation": {
            "aca_city": "Nouvelle-Cal\u00e9donie",
            "city": "Noum\u00e9a",
            "dep": "Nouvelle-Cal\u00e9donie"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Limoges",
        "social": {
            "url": "https://www.unilim.fr/",
            "twitter": "https://twitter.com/unilim",
            "facebook": "https://www.facebook.com/unilim"
        },
        "localisation": {
            "aca_city": "Limoges",
            "city": "Limoges",
            "dep": "Haute-Vienne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Strasbourg",
        "social": {
            "url": "http://www.unistra.fr/",
            "twitter": "https://twitter.com/unistra",
            "facebook": "https://www.facebook.com/unistra/"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de technologie de Troyes",
        "social": {
            "url": "https://www.utt.fr/",
            "twitter": "https://twitter.com/UTTroyes",
            "facebook": "https://www.facebook.com/UTTroyes"
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Troyes",
            "dep": "Aube"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 des Antilles",
        "social": {
            "url": "http://www.univ-antilles.fr/",
            "twitter": "https://twitter.com/UnivAntilles",
            "facebook": "https://www.facebook.com/univ.antilles/"
        },
        "localisation": {
            "aca_city": "Guadeloupe",
            "city": "Pointe-\u00e0-Pitre",
            "dep": "Guadeloupe"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Gustave Eiffel",
        "social": {
            "url": "https://www.univ-gustave-eiffel.fr/",
            "twitter": "https://twitter.com/UGustaveEiffel",
            "facebook": "https://www.facebook.com/UniversiteGustaveEiffel/"
        },
        "localisation": {
            "aca_city": "Cr\u00e9teil",
            "city": "Champs-sur-Marne",
            "dep": "Seine-et-Marne"
        }
    },
    {
        "short_name": "UT1",
        "label": "Universit\u00e9 Toulouse-I",
        "social": {
            "url": "http://www.ut-capitole.fr/",
            "twitter": "https://twitter.com/UT1Capitole",
            "facebook": "https://www.facebook.com/ut1capitole"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale d'administration",
        "social": {
            "url": "https://www.ena.fr/",
            "twitter": "https://twitter.com/ena_fr",
            "facebook": "https://www.facebook.com/EcoleNationaleAdministration"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "ENSAPC",
        "label": "\u00c9cole nationale sup\u00e9rieure d'arts de Paris-Cergy",
        "social": {
            "url": "http://www.ensapc.fr/fr/",
            "twitter": "https://twitter.com/ensapc",
            "facebook": "https://www.facebook.com/Ensapc/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale sup\u00e9rieure des sciences de l'information et des biblioth\u00e8ques",
        "social": {
            "url": "https://www.enssib.fr/",
            "twitter": "https://twitter.com/enssib",
            "facebook": "https://www.facebook.com/enssib"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Villeurbanne",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "IFP school",
        "label": "\u00c9cole nationale sup\u00e9rieure du p\u00e9trole et des moteurs",
        "social": {
            "url": "https://www.ifp-school.com/",
            "twitter": "https://twitter.com/ifpschool",
            "facebook": "https://www.facebook.com/IFP-School-135706763121931/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Rueil-Malmaison",
            "dep": "Hauts-de-Seine"
        }
    },
    {
        "short_name": "ISIT Paris",
        "label": "Institut de management et de communication interculturels",
        "social": {
            "url": "http://www.isit-paris.fr/",
            "twitter": "https://twitter.com/ISIT",
            "facebook": "https://www.facebook.com/Ecole.ISIT"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris  6e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "IEP de Bordeaux",
        "label": "Institut d'\u00e9tudes politiques de Bordeaux",
        "social": {
            "url": "https://www.sciencespobordeaux.fr/",
            "twitter": "https://twitter.com/ScPoBx",
            "facebook": "https://www.facebook.com/SciencesPoBordeaux"
        },
        "localisation": {
            "aca_city": "Bordeaux",
            "city": "Pessac",
            "dep": "Gironde"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "\u00c9cole sp\u00e9ciale d'architecture",
        "social": {
            "url": "http://www.esa-paris.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/%C3%89cole-Sp%C3%A9ciale-dArchitecture-104888930938874/"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 14e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "AMU",
        "label": "Aix-Marseille Universit\u00e9",
        "social": {
            "url": "https://www.univ-amu.fr/",
            "twitter": "https://twitter.com/univamu",
            "facebook": "https://www.facebook.com/pages/Universit%C3%A9-de-Provence/5184636?rf=112212554767"
        },
        "localisation": {
            "aca_city": "Aix-Marseille",
            "city": "Marseille  7e",
            "dep": "Bouches-du-Rh\u00f4ne"
        }
    },
    {
        "short_name": "CU de Mayotte",
        "label": "Centre universitaire de formation et de recherche de Mayotte",
        "social": {
            "url": "http://www.univ-mayotte.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/CufrDembeni"
        },
        "localisation": {
            "aca_city": "Mayotte",
            "city": "Dembeni",
            "dep": "Mayotte"
        }
    },
    {
        "short_name": "ELISA",
        "label": "\u00c9cole d'ing\u00e9nieurs des sciences a\u00e9rospatiales",
        "social": {
            "url": "http://www.elisa-aerospace.fr/",
            "twitter": "https://twitter.com/ELISAerospace",
            "facebook": "https://www.facebook.com/ELISAerospace/"
        },
        "localisation": {
            "aca_city": "Amiens",
            "city": "Saint-Quentin",
            "dep": "Aisne"
        }
    },
    {
        "short_name": "",
        "label": "\u00c9cole nationale v\u00e9t\u00e9rinaire, agroalimentaire et de l'alimentation, Nantes-Atlantique",
        "social": {
            "url": "https://www.oniris-nantes.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "Nantes",
            "dep": "Loire-Atlantique"
        }
    },
    {
        "short_name": "HEAR",
        "label": "Haute \u00e9cole des arts du Rhin",
        "social": {
            "url": "http://www.hear.fr/",
            "twitter": "",
            "facebook": "https://www.facebook.com/HEAR.fr/"
        },
        "localisation": {
            "aca_city": "Strasbourg",
            "city": "Strasbourg",
            "dep": "Bas-Rhin"
        }
    },
    {
        "short_name": "ITECH",
        "label": "Institut textile et chimique",
        "social": {
            "url": "http://www.itech.fr/",
            "twitter": "",
            "facebook": "https://fr-fr.facebook.com/ItechLyon/"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "\u00c9cully",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "Itescia",
        "label": "Itescia",
        "social": {
            "url": "https://www.itescia.fr/",
            "twitter": "https://twitter.com/ITESCIA",
            "facebook": "https://www.facebook.com/itescia/"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Pontoise",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Guyane",
        "social": {
            "url": "http://www.univ-guyane.fr/",
            "twitter": "https://twitter.com/univguyane",
            "facebook": "https://www.facebook.com/univguyane"
        },
        "localisation": {
            "aca_city": "Guyane",
            "city": "Cayenne",
            "dep": "Guyane"
        }
    },
    {
        "short_name": "Y SCHOOLS",
        "label": "Y SCHOOLS",
        "social": {
            "url": "https://www.yschools.fr/",
            "twitter": "https://twitter.com/yschools_edu",
            "facebook": "https://www.facebook.com/YSCHOOLS/"
        },
        "localisation": {
            "aca_city": "Reims",
            "city": "Troyes",
            "dep": "Aube"
        }
    },
    {
        "short_name": "EBI",
        "label": "\u00c9cole de biologie industrielle",
        "social": {
            "url": "https://ebi-edu.com/fr/",
            "twitter": "https://twitter.com/ebicergy",
            "facebook": "https://www.facebook.com/hubebi"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Cergy",
            "dep": "Val-d'Oise"
        }
    },
    {
        "short_name": "Arts et M\u00e9tiers",
        "label": "\u00c9cole nationale sup\u00e9rieure d'arts et m\u00e9tiers",
        "social": {
            "url": "https://artsetmetiers.fr/",
            "twitter": "https://twitter.com/ArtsetMetiers_",
            "facebook": "https://www.facebook.com/AMParisTech"
        },
        "localisation": {
            "aca_city": "Paris",
            "city": "Paris 13e",
            "dep": "Paris"
        }
    },
    {
        "short_name": "ENS Chimie Montpellier",
        "label": "\u00c9cole nationale sup\u00e9rieure de chimie de Montpellier",
        "social": {
            "url": "http://www.enscm.fr/",
            "twitter": "https://twitter.com/enscmchimiemtp",
            "facebook": "https://www.facebook.com/ENSCM-570768569608466/"
        },
        "localisation": {
            "aca_city": "Montpellier",
            "city": "Montpellier",
            "dep": "H\u00e9rault"
        }
    },
    {
        "short_name": "ESM ST CYR",
        "label": "\u00c9cole sp\u00e9ciale militaire de Saint-Cyr",
        "social": {
            "url": "http://www.st-cyr.terre.defense.gouv.fr/",
            "twitter": "",
            "facebook": ""
        },
        "localisation": {
            "aca_city": "Rennes",
            "city": "Guer",
            "dep": "Morbihan"
        }
    },
    {
        "short_name": "CPE Lyon",
        "label": "\u00c9cole sup\u00e9rieure de chimie physique \u00e9lectronique de Lyon",
        "social": {
            "url": "http://www.cpe.fr/",
            "twitter": "https://twitter.com/CPELyon",
            "facebook": "https://fr-fr.facebook.com/cpelyon"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Villeurbanne",
            "dep": "Rh\u00f4ne"
        }
    },
    {
        "short_name": "EESPIG",
        "label": "ICN Business School",
        "social": {
            "url": "https://www.icn-artem.com/",
            "twitter": "https://twitter.com/icnbs",
            "facebook": "https://www.facebook.com/ICNBusinessSchool"
        },
        "localisation": {
            "aca_city": "Nancy-Metz",
            "city": "Nancy",
            "dep": "Meurthe-et-Moselle"
        }
    },
    {
        "short_name": "ICES",
        "label": "Institut catholique de Vend\u00e9e",
        "social": {
            "url": "https://ices.fr/",
            "twitter": "https://twitter.com/ices_univcatho",
            "facebook": "https://www.facebook.com/ices.univcatho/"
        },
        "localisation": {
            "aca_city": "Nantes",
            "city": "La Roche-sur-Yon",
            "dep": "Vend\u00e9e"
        }
    },
    {
        "short_name": "INSA de Toulouse",
        "label": "Institut national des sciences appliqu\u00e9es de Toulouse",
        "social": {
            "url": "http://www.insa-toulouse.fr/",
            "twitter": "https://twitter.com/INSAToulouse",
            "facebook": "https://www.facebook.com/INSAToulouse"
        },
        "localisation": {
            "aca_city": "Toulouse",
            "city": "Toulouse",
            "dep": "Haute-Garonne"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de La Rochelle",
        "social": {
            "url": "https://www.univ-larochelle.fr/",
            "twitter": "https://twitter.com/UnivLaRochelle",
            "facebook": "https://www.facebook.com/LaRochelleUniversite/"
        },
        "localisation": {
            "aca_city": "Poitiers",
            "city": "La Rochelle",
            "dep": "Charente-Maritime"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de la Polyn\u00e9sie Fran\u00e7aise",
        "social": {
            "url": "http://www.upf.pf/",
            "twitter": "https://twitter.com/univpf",
            "facebook": "https://www.facebook.com/univpf"
        },
        "localisation": {
            "aca_city": "Polyn\u00e9sie Fran\u00e7aise",
            "city": "Punaauia",
            "dep": "Polyn\u00e9sie Fran\u00e7aise"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 de Saint-\u00c9tienne",
        "social": {
            "url": "https://www.univ-st-etienne.fr/fr/index.html",
            "twitter": "https://twitter.com/Univ_St_Etienne",
            "facebook": "https://www.facebook.com/Universite.Jean.Monnet.Saint.Etienne"
        },
        "localisation": {
            "aca_city": "Lyon",
            "city": "Saint-\u00c9tienne",
            "dep": "Loire"
        }
    },
    {
        "short_name": "",
        "label": "Universit\u00e9 Paris-X",
        "social": {
            "url": "https://www.parisnanterre.fr/",
            "twitter": "https://twitter.com/uparisnanterre",
            "facebook": "https://www.facebook.com/universite.paris.nanterre"
        },
        "localisation": {
            "aca_city": "Versailles",
            "city": "Nanterre",
            "dep": "Hauts-de-Seine"
        }
    }
]