import React from 'react';

import Render from './render';

const CGIView = () => {
    return (
        <Render />
    );
};

export default CGIView;
