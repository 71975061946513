import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { OrnessCodeFest2022 } from '../../../assets/assets';
import { ARTICLES_ROUTE } from '../../../infrastructure/globals/routes';
import { AUTH_SCOPES } from '../../../infrastructure/services/authentication/scopes';
import { IPage } from '../../../infrastructure/services/pages/types';
import { getHumanizedDate } from '../../../infrastructure/utils/dates';
import DJCFooter from '../../components/footer';
import { AdminAction } from './types';

interface IRenderProps {
    articles: Array<IPage>;
    handleAdminActions: (action: AdminAction, article: IPage) => () => void;
    isScopeInlcuded: (s: string) => boolean;
    isOneScopeIncluded: (...s: string[]) => boolean;
}

interface IAdminActionsProps {
    onClick: (a: any) => void;
}

const AdminToggleActions = React.forwardRef<HTMLAnchorElement, IAdminActionsProps>((props: IAdminActionsProps, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
        }}
    >
        <i className="fas fa-ellipsis-v"></i>
    </a>
));

const Render = (props: IRenderProps) => {
    const {
        articles,
        handleAdminActions,
        isScopeInlcuded,
        isOneScopeIncluded
    } = props;

    return (
        <>
            <div className="djc--view-articles-list">
                <div className="container">
                    <div className="articles container-fluid">
                        <div className="row">
                            {
                                articles.map(article => (
                                    <div key={article.id} className="col-12 col-sm-12 col-md-6 col-lg-4">
                                        <div className="article">
                                            <a
                                                href={`${ARTICLES_ROUTE}/${article.route_name}`}
                                                className="thumbnail"
                                            >
                                                <img
                                                    src={OrnessCodeFest2022}
                                                    alt="article thumbnail"
                                                />
                                            </a>

                                            <div className="article-content">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-11">
                                                            <a
                                                                href={`${ARTICLES_ROUTE}/${article.route_name}`}
                                                                className="title"
                                                            >
                                                                {article.title}
                                                            </a>
                                                        </div>
                                                        {
                                                            isScopeInlcuded(AUTH_SCOPES.pages.ADMIN) && (
                                                                <div className="col-1">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            id="admin-actions"
                                                                            as={AdminToggleActions}
                                                                        />

                                                                        <Dropdown.Menu>
                                                                            {
                                                                                isOneScopeIncluded(
                                                                                    AUTH_SCOPES.campaigns.ADMIN,
                                                                                    AUTH_SCOPES.campaigns.WRITE
                                                                                ) && (
                                                                                    <Dropdown.Item
                                                                                        onClick={handleAdminActions("edit", article)}
                                                                                    >
                                                                                        Edit
                                                                                    </Dropdown.Item>
                                                                                )
                                                                            }
                                                                            {
                                                                                isOneScopeIncluded(
                                                                                    AUTH_SCOPES.campaigns.ADMIN,
                                                                                    AUTH_SCOPES.campaigns.DELETE
                                                                                ) && (
                                                                                    <Dropdown.Item
                                                                                        onClick={handleAdminActions("delete", article)}
                                                                                    >
                                                                                        Delete
                                                                                    </Dropdown.Item>
                                                                                )
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <p className="description">
                                                    {article.author} • {getHumanizedDate(article.created_at)}
                                                </p>

                                                {
                                                    article.tags.length > 0 && (
                                                        <div className="container-fluid">
                                                            <div className="row tags-container">
                                                                {
                                                                    article.tags.map((tag) => (
                                                                        <div className={`tag ${tag}`}>
                                                                            <span>
                                                                                {tag}
                                                                            </span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <DJCFooter />
        </>
    );
};

export default Render;
