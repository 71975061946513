import React, { Ref, useCallback, useEffect } from 'react';
import Split from 'react-split'

import moment from 'moment-timezone';

import SettingsIcon from '@material-ui/icons/Settings';
import ReplayIcon from '@material-ui/icons/Replay';
import HistoryIcon from '@material-ui/icons/History';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ICampaignSummary } from '../../../../infrastructure/services/campaign/types';
import { IChallenge } from '../../../../infrastructure/services/challenge/types';
import { CAMPAIGNS_ROUTE } from '../../../../infrastructure/globals/routes';

import { CODE_EDITOR_THEMES, ConfigAction, ICodeEditorConfig, IConsoleOutput, TopbarButtonAction } from './types';
import CodeEditor from './code_editor';
import { IEnvironmentSummary } from '../../../../infrastructure/services/environments/types';
import Markdown from '../../../components/markdown';
import { IScoreboardSingleResults } from '../../../../infrastructure/services/scoring/types';
import CodeHistoryDialog from './code_history';
import { strigifySecondsToHMS } from '../../../../infrastructure/utils/dates';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    if (value === index) {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                className="tab-panel"
                {...other}
            >
                {children}
            </div>
        )
    }

    return null;
}

interface IRenderProps {
    campaign: ICampaignSummary;
    currentChallenge: IChallenge;
    activePanelTab: number;
    consoleOutput: IConsoleOutput | null;
    codeEditorRef: Ref<any>;
    checksProcessing: Record<string, boolean>;
    checksResults: Record<string, 'INDETERMINATE' | 'FAILED' | 'SUCCEED'>;
    currentEditedFile: string | undefined;
    currentEnvironement: IEnvironmentSummary;
    environements: IEnvironmentSummary[];
    isCheckAllLoading: boolean;
    isPassButtonLoading: boolean;
    isSubmitButtonLoading: boolean;
    codeConfig: ICodeEditorConfig;
    userResults: IScoreboardSingleResults | null;
    isCodeHistoryOpen: boolean;
    username: string;
    updateActivePanelTab: (e: any, value: number) => void;
    handleTestRunned: (s: string) => () => void;
    handleCheckAllClicked: () => void;
    handlePassClicked: () => void;
    handleSubmitClicked: () => void;
    handleSelectedEnvChanged: (next: React.ChangeEvent<HTMLSelectElement>) => void;
    handleTopbarClicked: (action: TopbarButtonAction) => () => void;
    handleConfigValueChanged: (action: ConfigAction) => (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => void;
    handleCloseCodeEditor: () => void;
    handleTimeExpired: () => void;
};

const Render = (props: IRenderProps) => {
    const {
        campaign,
        currentChallenge,
        activePanelTab,
        consoleOutput,
        codeEditorRef,
        checksProcessing,
        checksResults,
        // currentEditedFile,
        currentEnvironement,
        environements,
        isCheckAllLoading,
        isPassButtonLoading,
        isSubmitButtonLoading,
        codeConfig,
        userResults,
        isCodeHistoryOpen,
        username,
        updateActivePanelTab,
        handleTestRunned,
        handleCheckAllClicked,
        handlePassClicked,
        handleSubmitClicked,
        handleSelectedEnvChanged,
        handleTopbarClicked,
        handleConfigValueChanged,
        handleCloseCodeEditor,
        handleTimeExpired
    } = props;

    const calculateExpirationTime = () => {
        if (!campaign.expiration_date) {
            return -1;
        }

        const expiration: string = campaign.expiration_date;
        const end = moment(Date.parse(expiration)).tz("UTC");
        const now = moment().tz("UTC");

        return Math.max(0, end.diff(now, "seconds"));
    };

    const [expirationTimer, setExpirationTime] = React.useState<number>(calculateExpirationTime());
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (campaign.expiration_date) {
            interval = setInterval(() => {
                const nextExpirationTime = calculateExpirationTime();
                setExpirationTime(nextExpirationTime);

                if (nextExpirationTime <= 0) {
                    handleTimeExpired();
                }
            }, 1000)
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, []);

    return (
        <div className="djc--campaigns-participate-view">
            <Split
                id="split"
                sizes={[50, 50]}
                minSize={400}
                gutterSize={10}
                snapOffset={10}
                dragInterval={1}
                direction="horizontal"
                cursor="col-resize"
            >
                <div className="desc">
                    <div className="topbar">
                        <h3 className="campaign-title">
                            <a href={`${CAMPAIGNS_ROUTE}/${campaign.route_name}`}>{campaign.name}</a> {'>'} {currentChallenge.name}
                        </h3>
                    </div>
                    <div className="desc-content">
                        <div className="metadata">
                            <div className="campaign-meta">
                                <span>Author: {currentChallenge.author}</span>
                                {
                                    expirationTimer !== -1 && (
                                        <span>Temps restant: {strigifySecondsToHMS(expirationTimer)}</span>
                                    )
                                }
                                {
                                    userResults !== null && (
                                        <span className="badge rounded-pill bg-primary text-dark rank-badge">
                                            <a target="_blank" rel="noreferrer" href={`/campaigns/${campaign.route_name}/leaderboard`}>
                                                Rank #{userResults.rank}
                                            </a>
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="markdown">
                            <Markdown
                                children={currentChallenge.instructions}
                            />

                            {
                                Object.keys(currentChallenge.inputs).length > 0 && (
                                    <Accordion className="mt-64">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="test-cases-content"
                                            id="test-cases-header"
                                        >
                                            <Typography>Test Cases</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div id="test-case-desc-content">
                                                {
                                                    Object.keys(currentChallenge.inputs).map(checkKey => (
                                                        <div className="test-case" key={checkKey}>
                                                            <p>{currentChallenge.inputs[checkKey].name}</p>
                                                            <div className="section">
                                                                <span>Files:</span>
                                                                <pre>{JSON.stringify(currentChallenge.inputs[checkKey].files)}</pre>
                                                            </div>
                                                            <div className="section">
                                                                <span>Parameters:</span>
                                                                <pre>{JSON.stringify(currentChallenge.inputs[checkKey].parameters)}</pre>
                                                            </div>
                                                            <div className="section">
                                                                <span>StdIn:</span>
                                                                <pre>{JSON.stringify(currentChallenge.inputs[checkKey].stdin)}</pre>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </div>
                    </div>

                    <div className="bottombar">
                    </div>
                </div>
                <div className="ide">
                    <div className="topbar">
                        <div className="topbar--element">
                            <select
                                defaultValue={environements[0].id}
                                className="form-select"
                                aria-label="Language Selection"
                                onChange={handleSelectedEnvChanged}
                            >
                                {
                                    environements.map(env => (
                                        <option
                                            value={env.id}
                                            key={env.id}
                                        >
                                            {env.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="topbar--element flex-end">
                            <button
                                type="button"
                                className="btn custom btn-icon ml-8"
                                onClick={handleTopbarClicked("codeHistory")}
                                title="Historique des soumissions"
                            >
                                <HistoryIcon />
                            </button>
                            <button
                                type="button"
                                className="btn custom btn-icon ml-8"
                                onClick={handleTopbarClicked("resetToDefault")}
                                title="Remise à zéro du code"
                            >
                                <ReplayIcon />
                            </button>
                            <button
                                type="button"
                                className="custom btn-icon ml-8"
                                onClick={handleTopbarClicked("config")}
                                title="Options"
                            >
                                <SettingsIcon />
                            </button>
                        </div>
                    </div>
                    <div className="content">
                        <CodeEditor
                            ref={codeEditorRef}
                            environment={currentEnvironement}
                            codeConfig={codeConfig}
                        />
                        <div className="bottom-panel">
                            <Tabs
                                value={activePanelTab}
                                aria-label="simple tabs example"
                                onChange={updateActivePanelTab}
                            >
                                <Tab label="Test Cases" />
                                <Tab label="Console Output" />
                            </Tabs>
                            <TabPanel value={activePanelTab} index={0}>
                                {
                                    Object.keys(currentChallenge.inputs).length === 0 && (
                                        <div className="default-output-message">
                                            <h3>No Test Cases, submit directly :)</h3>
                                        </div>
                                    )
                                }
                                {
                                    Object.keys(currentChallenge.inputs).map(checkKey => (
                                        <div className="test-case-root" key={checkKey}>
                                            <span className="test-name">
                                                {
                                                    checksResults[checkKey] === 'INDETERMINATE' && (
                                                        <IndeterminateCheckBoxIcon
                                                            className="tsp-3"
                                                        />
                                                    )
                                                }
                                                {
                                                    checksResults[checkKey] === 'FAILED' && (
                                                        <CheckBoxOutlineBlankIcon
                                                            className="red"
                                                        />
                                                    )
                                                }
                                                {
                                                    checksResults[checkKey] === 'SUCCEED' && (
                                                        <CheckBoxIcon
                                                            className="green"
                                                        />
                                                    )
                                                }
                                                {currentChallenge.inputs[checkKey].name}
                                            </span>
                                            <button
                                                className="outlined block mb-4 fullwidth"
                                                onClick={handleTestRunned(checkKey)}
                                                disabled={!!checksProcessing[checkKey]}
                                            >
                                                Run test
                                                {
                                                    !!checksProcessing[checkKey] && (
                                                        <CircularProgress />
                                                    )
                                                }
                                            </button>
                                        </div>
                                    ))
                                }
                            </TabPanel>
                            <TabPanel value={activePanelTab} index={1}>
                                {
                                    consoleOutput ? (
                                        <div className="console-output-content">
                                            <h3 className={`status ${consoleOutput.success ? "success" : "error"}`}>
                                                {consoleOutput.success ? "Success" : "Failed"}
                                            </h3>
                                            <div className="section">
                                                <span>Output</span>
                                                <pre>
                                                    {
                                                        consoleOutput.output
                                                    }
                                                </pre>
                                            </div>
                                            <div className="section">
                                                <span>Expected</span>
                                                <pre>
                                                    {
                                                        consoleOutput.expected
                                                    }
                                                </pre>
                                            </div>
                                            {consoleOutput.errors ? <div className="section">
                                                <span>Errors</span>
                                                <pre>
                                                    {
                                                        consoleOutput.errors
                                                    }
                                                </pre>
                                            </div> : null}


                                            <button
                                                className="outlined block mb-4 fl-r mt-16"
                                                onClick={handleTestRunned(consoleOutput.check_key)}
                                                disabled={!!checksProcessing[consoleOutput.check_key]}
                                            >
                                                Retry
                                                {
                                                    !!checksProcessing[consoleOutput.check_key] && (
                                                        <CircularProgress />
                                                    )
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="default-output-message">
                                            <h3>You must run your code first</h3>
                                        </div>
                                    )
                                }
                            </TabPanel>
                        </div>
                    </div>

                    <div className="bottombar">
                        <button
                            className="outlined block"
                            onClick={handleCheckAllClicked}
                            disabled={
                                isCheckAllLoading
                                || Object.keys(currentChallenge.inputs).length === 0
                            }
                        >
                            Check All
                            {
                                isCheckAllLoading && (
                                    <CircularProgress className="ml-8" />
                                )
                            }
                        </button>
                        <button
                            className="outlined block"
                            onClick={handlePassClicked}
                            disabled={
                                isCheckAllLoading
                                || Object.keys(currentChallenge.inputs).length === 0
                                || campaign.challenge_skip === false
                            }
                        >
                            Pass
                            {
                                isPassButtonLoading && (
                                    <CircularProgress className="ml-8" />
                                )
                            }
                        </button>
                        <button
                            className="block"
                            disabled={isSubmitButtonLoading}
                            onClick={handleSubmitClicked}
                        >
                            Submit
                            {
                                isSubmitButtonLoading && (
                                    <CircularProgress className="ml-8" />
                                )
                            }
                        </button>
                    </div>
                </div>
            </Split>

            <Dialog
                open={codeConfig.isCodeEditorConfigOpen}
                onClose={handleCloseCodeEditor}
                aria-labelledby="form-config-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-config-title">Config</DialogTitle>
                <DialogContent>
                    <div className="container-fluid">
                        <div className="row align-items-center mb-16">
                            <div className="col-6">
                                <p>Font Size</p>
                            </div>
                            <div className="col-6">
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel id="font-size-select-label">Font Size</InputLabel>
                                    <Select
                                        labelId="font-size-select-label"
                                        id="font-size"
                                        value={codeConfig.fontSize}
                                        onChange={handleConfigValueChanged("font-size")}
                                        label="Font Size"
                                    >
                                        {
                                            Array.from(Array(6).keys()).map((i) => (
                                                <MenuItem value={`${12 + i * 2}px`}>{12 + i * 2}px</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row align-items-center mb-16">
                            <div className="col-6">
                                <p>Theme</p>
                            </div>
                            <div className="col-6">
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel id="theme-select-label">Theme</InputLabel>
                                    <Select
                                        labelId="theme-select-label"
                                        id="theme-select"
                                        value={codeConfig.theme}
                                        onChange={handleConfigValueChanged("theme")}
                                        label="Theme"
                                    >
                                        {
                                            CODE_EDITOR_THEMES.map((theme) => (
                                                <MenuItem value={theme.id}>{theme.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row align-items-center mb-16">
                            <div className="col-6">
                                <p>Nombre d'espace pour tab</p>
                            </div>
                            <div className="col-6">
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                >
                                    <InputLabel id="tab-space-label">Tab/Spaces</InputLabel>
                                    <Select
                                        labelId="tab-space-label"
                                        id="tab-space-select"
                                        value={codeConfig.tabSpaces}
                                        onChange={handleConfigValueChanged("tabSpaces")}
                                        label="Tab/Spaces"
                                    >
                                        <MenuItem value={2}>2 espaces</MenuItem>
                                        <MenuItem value={3}>3 espaces</MenuItem>
                                        <MenuItem value={4}>4 espaces</MenuItem>
                                        <MenuItem value={5}>5 espaces</MenuItem>
                                        <MenuItem value={6}>6 espaces</MenuItem>
                                        <MenuItem value={7}>7 espaces</MenuItem>
                                        <MenuItem value={8}>8 espaces</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <CodeHistoryDialog
                open={isCodeHistoryOpen}
                onClose={handleTopbarClicked("codeHistory")}
                campaignId={campaign.id}
                username={username}
                codeConfig={codeConfig}
            />
        </div>
    )
};

export default Render;
