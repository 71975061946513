import qs from 'qs';
import {
    createApi,
    ApiWithInjectedEndpoints,
    BaseQueryFn
} from '@rtk-incubator/rtk-query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import config from '../config';
import { RootState } from '../stores';
import { getScopesFromLocalStorage, getTokenFromLocalStorage, saveCredentialsOnLocalStorage } from './authentication/utils';
import { setAccessToken, setScopes } from './authentication/slice';

const TIMEOUT = 60000;

const axiosBaseQuery = (
    { baseUrl, prepareHeaders }: {
        prepareHeaders: (getState: () => unknown) => Record<string, string>,
        baseUrl: string
    } = { baseUrl: '', prepareHeaders: () => ({}) }
): BaseQueryFn<
    {
        url: string,
        method: AxiosRequestConfig['method'],
        body?: AxiosRequestConfig['data'],
        params?: AxiosRequestConfig['params'],
        headers?: AxiosRequestConfig['headers'],
        paramsSerializer?: AxiosRequestConfig['paramsSerializer'],
        validateStatus?: AxiosRequestConfig['validateStatus']    
    },
    unknown,
    unknown
> => async({
    url,
    method = "GET",
    body = null,
    params = null,
    headers = {},
    paramsSerializer = (_params) => qs.stringify(
        _params, 
        {
            arrayFormat: 'comma',
            skipNulls: true
        }
    ),
    validateStatus = (status: number) => (status >= 200 && status <= 299)
}, api) => {
    try {
        const result = await axios({
            url: baseUrl + url,
            method: method,
            data: body,
            headers: {...prepareHeaders(api.getState), ...headers},
            params: params,
            paramsSerializer: paramsSerializer,
            validateStatus: validateStatus,
            timeout: TIMEOUT
        });

        return { data: result.data, status: result.status };
    } catch (axiosError) {
        const error = axiosError as AxiosError;
        const config = (error.config as any);

        if (error.response?.status === 401 && config._retry === undefined) {
            config._retry = true;

            const accessToken = getTokenFromLocalStorage();
            const scopes = getScopesFromLocalStorage();

            return axios({
                url: baseUrl + '/authentication/refresh',
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((resp) => {
                api.dispatch(setAccessToken({
                    access_token: resp.data.access_token
                }));
                api.dispatch(setScopes({
                    scopes: scopes
                }));

                saveCredentialsOnLocalStorage(resp.data);

                return axios({
                    url: baseUrl + url,
                    method: method,
                    data: body,
                    headers: {...prepareHeaders(api.getState), "Authorization": `Bearer ${resp.data.access_token}`, ...headers},
                    params: params,
                    paramsSerializer: paramsSerializer,
                    validateStatus: validateStatus,
                    timeout: TIMEOUT
                });
            }).catch(_ => {
                return {
                    error: {
                        status: error.response?.status,
                        data: error.response?.data
                    }
                }
            })
        }

        return {
            error: {
                status: error.response?.status,
                data: error.response?.data
            }
        }
    }
}

export const api = createApi({
    baseQuery: axiosBaseQuery({
        prepareHeaders: (getState) => {
            const token = (getState() as RootState).auth.accessToken;
            const headers: Record<string, string> = {};

            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            return headers;
        },
        baseUrl: config.baseroute
    }),
    // fetchBaseQuery({
    //     baseUrl: config.baseroute,
    //     prepareHeaders: (headers, { getState }) => {
    //         const token = (getState() as RootState).auth.accessToken;

    //         if (token) {
    //             headers.set("Authorization", `Bearer ${token}`);
    //         }

    //         return headers;
    //     }
    // }),
    keepUnusedDataFor: -1,
    endpoints: () => ({})
});

export const splittedApi = api as ApiWithInjectedEndpoints<
    typeof api,
    [
        typeof import('./authentication').authenticationExtendedApi,
        typeof import('./campaign').campaignExtendedApi,
        typeof import('./challenge').challengeExtendedApi,
        typeof import('./mail').mailExtendedApi,
        typeof import('./roles').rolesExtendedApi,
        typeof import('./route').routesExtendedApi,
        typeof import('./users').usersExtendedApi,
    ]
>