import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { renderers } from './renderers';


interface IProps {
    children: string;
    renderers?: any;
}

const Markdown = (props: IProps) => {
    const { children, renderers: propsRenderers } = props;
    
    return (
        <div className="markdown">
            <ReactMarkdown
                plugins={[gfm]}
                children={children}
                renderers={{...renderers, ...(propsRenderers || {})}}
            />
        </div>
    );
};

export default Markdown;
