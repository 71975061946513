import React, {
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';

import { UnControlled as CodeMirror } from 'react-codemirror2'

import FileCopyIcon from '@material-ui/icons/FileCopy';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/fold/foldcode';

import 'codemirror/mode/shell/shell';
import 'codemirror/mode/python/python';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/go/go';
import 'codemirror/mode/lua/lua';
import 'codemirror/mode/perl/perl';
import 'codemirror/mode/rust/rust';
import 'codemirror/mode/commonlisp/commonlisp';

import 'codemirror/theme/darcula.css';
import 'codemirror/theme/seti.css';
import 'codemirror/theme/ambiance.css';
import 'codemirror/theme/twilight.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/panda-syntax.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/dracula.css';


import { IEnvironmentSummary } from '../../../../infrastructure/services/environments/types';
import { ICodeEditorConfig } from './types';
import { LOCAL_STORAGE_KEYS } from '../../../../infrastructure/localStorageKeys';
import { IconButton } from '@material-ui/core';
import { copyTextToClipboard } from '../../../../infrastructure/utils/clipboard';

interface ICodeEditorProps {
    environment: IEnvironmentSummary;
    codeConfig: ICodeEditorConfig;
    readonly?: boolean;
}

export interface ICodeEditorRef {
    getCodeContent: () => string;
    clearCode: () => void;
    resetCode: () => void;
    setCode: (nextCode: string) => void;
    updateTheme: (nextTheme: string) => void;
    updateFontSize: (nextFontSize: string) => void;
}

export const getDefaultCodeConfig = (): ICodeEditorConfig => {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEYS.code_editor.theme) || "darcula";
    const savedFontSize = localStorage.getItem(LOCAL_STORAGE_KEYS.code_editor.font_size) || "12px";
    const savedTabSpaces = localStorage.getItem(LOCAL_STORAGE_KEYS.code_editor.tab_spaces);

    let tabSpaces = 4;
    if (savedTabSpaces) {
        try {
            tabSpaces = parseInt(savedTabSpaces.toString(), 10);
        } catch (e) { }
    }

    return ({
        theme: savedTheme,
        fontSize: savedFontSize,
        isCodeEditorConfigOpen: false,
        tabSpaces: tabSpaces
    });
};

const CodeEditor = forwardRef((props: ICodeEditorProps, ref: Ref<ICodeEditorRef>) => {
    const {
        environment: envProps,
        codeConfig: codeConfigProps,
        readonly: readonlyProps
    } = props;

    const readonly = useMemo(() => readonlyProps, [readonlyProps]);
    console.log("Readonly:");
    console.log(readonly);
    const environment = useMemo(() => envProps, [envProps]);
    const codeConfig = useMemo(() => codeConfigProps, [codeConfigProps]);

    const mirrorRef = useRef<CodeMirror>(null);

    const [defaultValue, setDefaultValue] = useState<string | null>(null);
    const [theme, setTheme] = useState<string>(codeConfig.theme);

    const updateCode = () => {
        const code = environment.saved_code || environment.placeholder;
        if (!readonly) {
            setDefaultValue(code || "");
            return;
        }

        const NB_LINES = 5;
        if (!code) {
            setDefaultValue("\n".repeat(NB_LINES));
        } else if (environment.placeholder && environment.placeholder.split('\n').length < 5) {
            setDefaultValue(environment.placeholder + "\n".repeat(NB_LINES - environment.placeholder.split('\n').length));
        } else {
            setDefaultValue(environment.placeholder || "");
        }
    };

    useEffect(() => {
        if (!mirrorRef.current) {
            return;
        }

        (mirrorRef.current as any).editor.doc.cm.refresh();
        (mirrorRef.current as any).editor.doc.cm.focus();

        updateCode();
    }, [environment]);

    const onEditorDidMount = (editor: any) => {
        editor.getWrapperElement().style["font-size"] = codeConfig.fontSize;
        editor.doc.cm.refresh();
        editor.doc.cm.focus();

        if (!readonly) {
            setDefaultValue(environment.saved_code || environment.placeholder || "");
            return;
        }

        updateCode();
        editor.doc.setCursor({ line: 0, ch: 0 });
    };

    const handleCopy = () => {
        if (!mirrorRef.current) {
            return;
        }

        copyTextToClipboard((mirrorRef.current as any).editor.doc.getValue());
    }

    useImperativeHandle(ref, () => ({
        resetCode: () => {
            if (!mirrorRef.current) {
                return;
            }

            (mirrorRef.current as any).editor.setValue(environment.placeholder || "");
        },
        setCode: (nextCode: string) => {
            if (!mirrorRef.current) {
                return;
            }

            (mirrorRef.current as any).editor.setValue(nextCode);
        },
        clearCode: () => {
            if (!mirrorRef.current) {
                return;
            }

            (mirrorRef.current as any).editor.setValue("");
        },
        getCodeContent: (replaceTabs?: boolean) => {
            if (!mirrorRef.current) {
                return "";
            }

            if (replaceTabs !== undefined && replaceTabs === true) {
                const value = (
                    ((mirrorRef.current as any).editor
                        .getValue() as string)
                        .replace(
                            /^(\t+)/gm,
                            (found: string, ...args: any[]) => {
                                const match = found.split("\t");
                                return " ".repeat(codeConfig.tabSpaces * (match.length - 1))
                            }
                        )
                )

                return value;
            }

            return ((mirrorRef.current as any).editor.getValue() as string);
        },
        updateTheme: (nextTheme: string) => {
            setTheme(nextTheme);
        },
        getCodeTheme: () => {
            return theme;
        },
        updateFontSize: (nextFontSize: string) => {
            if (!mirrorRef.current) {
                return;
            }

            (mirrorRef.current as any).editor.getWrapperElement().style["font-size"] = nextFontSize;
            (mirrorRef.current as any).editor.refresh();
        }
    }));

    const interpret_as = useMemo<string>(() => {
        if (environment.interpret_as === "bash") {
            return "shell";
        }
        if (environment.interpret_as === "java") {
            return "text/x-java";
        }
        if (environment.interpret_as === "c") {
            return "text/x-csrc";
        }
        if (environment.interpret_as === "cpp") {
            return "text/x-c++src";
        }
        if (environment.interpret_as === "c++") {
            return "text/x-c++src";
        }
        if (environment.interpret_as === "csharp") {
            return "text/x-csharp";
        }
        if (environment.interpret_as === "rust") {
            return "text/x-rustsrc";
        }
        if (environment.interpret_as === "lisp") {
            return "text/x-common-lisp";
        }
        return environment.interpret_as;
    }, [environment]);

    return (
        <>
            <div className="code-editor-container">
                <div className="fixed-wrapper">
                    <CodeMirror
                        ref={mirrorRef}
                        value={defaultValue || ""}
                        options={{
                            mode: interpret_as,
                            theme: theme,
                            lineNumbers: true,
                            indentUnit: codeConfig.tabSpaces,
                            indentWithTabs: true,
                            fontSize: 60,
                            style: { fontSize: "120px" },
                            readOnly: readonly,
                            cursorBlinkRate: readonly ? -1 : 530,
                            tabSize: codeConfig.tabSpaces
                        }}
                        editorDidMount={onEditorDidMount}
                    />

                    <div className="code-editor-btn-copy">
                        <IconButton
                            onClick={handleCopy}
                        >
                            <FileCopyIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    )
});

export default CodeEditor;
