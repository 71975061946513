import { combineReducers } from 'redux';
import { api } from '../services';
import { globalReducer } from './global/reducer';

import authSlice from '../services/authentication/slice';
import userSlice from '../services/users/slice';
import campaignSlice from '../services/campaign/slice';

export const rootReducer = combineReducers({
    global: globalReducer,
    [api.reducerPath]: api.reducer,
    auth: authSlice,
    user: userSlice,
    campaign: campaignSlice
});

export type RootState = ReturnType<typeof rootReducer>;
