
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessToken, setScopes } from '../../../infrastructure/services/authentication/slice';

import { useMutationPostLoginWithPAT } from '../../../infrastructure/services/PAT';
import { setCurrentUser } from '../../../infrastructure/services/users/slice';
import Render from './render';
import { useHistory } from 'react-router-dom';
import { cleanAuthOnLocalStorage, saveCredentialsOnLocalStorage, saveScopesOnLocalStorage } from '../../../infrastructure/services/authentication/utils';
import { useGetSelf } from '../../../infrastructure/services/users';
import { clearLocalCode } from '../../../infrastructure/utils/localStorage';


interface ITempAccountProps {
    token: string;
};

const TempAccount = (props: ITempAccountProps) => {
    const { token } = props;
    const [loginWithPAT] = useMutationPostLoginWithPAT();
    const history = useHistory();
    const dispatch = useDispatch();
    const [
        getSelf
    ] = useGetSelf();

    const init = async () => {
        const decodedToken = atob(token).split(':');
        const username = decodedToken[0];
        const authToken = decodedToken[1];
        const redirectTo = decodedToken[2];

        try {
            cleanAuthOnLocalStorage();
            const resp_pat_login = await loginWithPAT({
                username: username,
                token: authToken
            });
            if (resp_pat_login.data) {
                dispatch(setAccessToken({
                    access_token: resp_pat_login.data.access_token
                }));
                dispatch(setScopes({
                    scopes: resp_pat_login.data.scopes
                }));

                saveCredentialsOnLocalStorage(
                    {
                        access_token: resp_pat_login.data.access_token,
                        scopes: resp_pat_login.data.scopes,
                        token_type: resp_pat_login.data.token_type,
                    },
                    username);
                saveScopesOnLocalStorage(resp_pat_login.data.scopes);

                const selfResult = await getSelf();
                if (selfResult.data) {
                    dispatch(setCurrentUser(selfResult.data));
                }
                for (const stored_key of Object.keys(localStorage)) {
                    if (stored_key.startsWith("cHJlcXVhbF9")) {
                        localStorage.removeItem(stored_key);
                    }
                }
            }
            if (redirectTo == "home") {
                history.push("/home");
            }
            else {
                history.push("/campaigns/" + redirectTo);
            }
            history.push(redirectTo);
        } catch (e) {
            cleanAuthOnLocalStorage();
        }

    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Render>
        </Render>
    );
};

export default TempAccount;
