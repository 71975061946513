import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutationGetRuleList, useMutationPostScoreboardCreate } from '../../../../../../../infrastructure/services/scoring';
import useForm, { IError } from '../../../../../../hooks/form/useForm';
import Toast from '../../../../../../components/toast';

type Inputs = {
    id: string;
    route_name: string;
    rules: string | null;
}

const AdminLeaderboardCreateView = () => {
    const [ fetchCreate ] = useMutationPostScoreboardCreate();
    const [ fetchAllRules ] = useMutationGetRuleList();

    const [options, setOptions] = useState<string[]>([]);
    const history = useHistory();

    const init = async () => {
        const resp = await fetchAllRules();

        if (resp.data) {
            setOptions(resp.data.rules.map(rule => rule.id));
        }
    };

    useEffect(() => {
        init();
    }, []);

    const handleValidate = (v: Inputs) => {
        const errors: IError<Inputs> = {};

        if (v.id.length === 0) {
            errors.id = {
                error: true,
                message: "Le champ \"ID\" est obligatoire."
            }
        }

        if (v.route_name.length === 0) {
            errors.route_name = {
                error: true,
                message: "Le champ \"Nom de Route\" est obligatoire."
            }
        }

        return errors;
    }

    const {
        values,
        handleBlur, 
        handleChange,
        handleBlurAutocomplete,
        handleChangeAutocomplete,
        errors, 
        containsError, 
        fulfilled 
    } = useForm<Inputs>({
        defaultValues: {
            id: "",
            route_name: "",
            rules: ""
        },
        required: [
            "id", "route_name"
        ],
        validate: handleValidate,
    });

    const handleCancel = () => {
        history.push("/admin/scoring/leaderboard");
    };

    const handleSave = async () => {
        if (containsError) {
            return;
        }

        const resp = await fetchCreate({
            id: values.id,
            route_name: values.route_name,
            rules: values.rules === null
                ? []
                : values.rules.split(',').map(s => s.trim()).filter(s => s.length > 0)
        });
        if (resp.data) {
            toast.notify(
                <Toast text={"Leaderboard créé avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );

            history.push('/admin/scoring/leaderboard');
        } else if (resp.error) {
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-trophy" />
                    <h1>Créer Leaderboard</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>General</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-id"
                                    name="id"
                                    label="ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.id !== undefined}
                                    helperText={errors.id?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TextField
                                    id="input-route-name"
                                    name="route_name"
                                    label="Nom de Route"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    error={errors.route_name !== undefined}
                                    helperText={errors.route_name?.message}
                                />
                            </div>
                        </div>
                        <div className="row mb-16">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Autocomplete
                                    id="input-rules"
                                    freeSolo
                                    multiple
                                    options={options}
                                    onBlur={handleBlurAutocomplete("rules")}
                                    onChange={handleChangeAutocomplete("rules")}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"rules"}
                                            label={"Règles"}
                                            variant="outlined"
                                            error={errors.rules !== undefined}
                                            helperText={errors.rules?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <button
                                    onClick={handleSave}
                                    disabled={containsError || !fulfilled}
                                >
                                    Sauvegarder
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AdminLeaderboardCreateView;
