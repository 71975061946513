import React, { useEffect, useMemo, useState } from "react";
import toast from 'toasted-notes';
import Toast from "../../../../../components/toast";

import EnhancedTable from "../../../../../components/table";
import { useMutationDeleteUserByUsername, useMutationGetUsers, useMutationGetUserByUsername } from "../../../../../../infrastructure/services/users";
import { IUser } from "../../../../../../infrastructure/services/users/types";
import { CircularProgress } from "@material-ui/core";
import DetailsDrawer from "../../../components/details_drawer";
import { useHistory } from "react-router";

type DataGrid = Array<{
    id: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    role: string;
    activated: string;
}>;

const AdminUsersListView = () => {
    const [fetchUsers] = useMutationGetUsers();
    const [deleteUserByUsername] = useMutationDeleteUserByUsername();
    const [fetchUserByUsername] = useMutationGetUserByUsername();

    const history = useHistory();

    const [users, setUsers] = useState<Array<IUser>>([]);
    const [fetching, setFetching] = useState(true);
    const [detail, setDetail] = useState<any | null>(null);

    const dataGrid = useMemo<DataGrid>(() => {
        return users.map((user) => ({
            id: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone || "null",
            email: user.email,
            role: user.role,
            activated: user.disabled ? "false" : "true"
        }));
    }, [users]);

    const getUsers = async (): Promise<IUser[]> => {
        const resp = await fetchUsers();
        if (resp.error || !resp.data) {
            toast.notify(
                <Toast
                    text={`Error while fetching. Please try again later.`}
                    type="alert"
                />,
                { duration: 10000, position: "top" }
            );
            return [];
        }

        return resp.data.users;
    }

    const init = async () => {
        const users = await getUsers();
        setUsers(users);
        setFetching(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDetail = (ids: string[]) => {
        if (ids.length === 0) {
            return;
        }

        setDetail(ids[0]);
    }

    const onDrawerClose = () => {
        setDetail(null);
    };

    const handleEdit = (ids: string[]) => {
        history.push(`/admin/users/edit/${ids[0]}`);
    }

    const handleDelete = async (ids: string[]) => {
        setFetching(true);
        const idsToDelete: string[] = [];
        for (let id of ids) {
            const resp = await deleteUserByUsername(id);
            if (resp.error) {
                toast.notify(
                    <Toast
                        text={`Error while deleting ${id}. Please try again later.`}
                        type="alert"
                    />,
                    { duration: 10000, position: "top" }
                );
            } else {
                idsToDelete.push(id);
            }
        }

        const next = [...users];
        for (let username of idsToDelete) {
            const userIdx = next.findIndex(a => a.username === username);
            if (userIdx > -1) {
                next[userIdx] = {
                    ...next[userIdx],
                    disabled: true
                }
            }
        }

        setUsers(next);
        setFetching(false);
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fa fa-users" />
                    <h1>Utilisateurs</h1>
                </div>
            </div>
            <div className="body fixed-height">
                <EnhancedTable
                    handleGlobalDeleteClicked={handleDelete}
                    handleGlobalEditClicked={handleEdit}
                    handleGlobalDetailClicked={handleDetail}
                    title={fetching ? <span>Utilisateurs <CircularProgress className="inline-block-important" /></span> : `Utilisateurs (${users.length})`}
                    headCells={[
                        {
                            id: 'id',
                            disablePadding: true,
                            label: "Nom d'utilisateur",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'first_name',
                            disablePadding: true,
                            label: "Prénom",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'last_name',
                            disablePadding: true,
                            label: "Nom de F.",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: "phone",
                            disablePadding: true,
                            label: "Tel.",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'email',
                            disablePadding: true,
                            label: "Email",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'role',
                            disablePadding: true,
                            label: "Role",
                            numeric: false,
                            url: false,
                            boolean: false
                        },
                        {
                            id: 'activated',
                            disablePadding: true,
                            label: "Actif",
                            numeric: false,
                            url: false,
                            boolean: true
                        }
                    ]}
                    rows={dataGrid}
                />
            </div>

            <DetailsDrawer
                titleKey="username"
                fetchFunc={fetchUserByUsername}
                fetchArgs={[detail]}
                open={detail !== null}
                onClose={onDrawerClose}
            />
        </React.Fragment>
    );
};

export default AdminUsersListView;
