import { api } from "..";
import {
    IChallenge,
    ICheckAllInputs,
    ICheckAllResults,
    ICheckOneInputs,
    ICheckOneResults,
    IFetchAllChallenges
} from "./types";

const challengeExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getChallenges: build.query<IFetchAllChallenges, void>({
            query: () => ({
                url: '/challenge',
                method: 'GET'
            })
        }),
        deleteChallengeById: build.mutation<void, string>({
            query: (arg) => ({
                url: `/challenge/${arg}`,
                method: "DELETE",
                validateStatus: (status) => status === 204
            })
        }),
        getChallengesMutation: build.mutation<IFetchAllChallenges, void | { show_disabled?: boolean, show_private?: boolean }>({
            query: (args) => ({
                url: '/challenge',
                method: 'GET',
                params: {
                    show_disabled: (args && args.show_disabled !== undefined) ? args.show_disabled : null,
                    show_private: (args && args.show_private !== undefined) ? args.show_private : null
                }
            })
        }),
        getChallengeById: build.mutation<IChallenge, string>({
            query: (id: string) => ({
                url: `/challenge/${id}`,
                method: 'GET'
            })
        }),
        checkOne: build.mutation<ICheckOneResults, ICheckOneInputs>({
            query: (args: ICheckOneInputs) => ({
                url: `/challenge/${args.challenge_id}/inputs/${args.check_id}`,
                method: 'POST',
                body: {
                    environment: args.environment,
                    files: [{
                        path: args.path,
                        type: "File",
                        permissions: 644,
                        data: Buffer.from(args.code).toString('base64')
                    }]
                }
            })
        }),
        checkAll: build.mutation<ICheckAllResults, ICheckAllInputs>({
            query: (args: ICheckAllInputs) => ({
                url: `/challenge/${args.challenge_id}/inputs`,
                method: 'POST',
                body: {
                    environment: args.environment,
                    files: [{
                        path: args.path,
                        type: "File",
                        permissions: 644,
                        data: Buffer.from(args.code).toString('base64')
                    }]
                }
            })
        })
    }),
    overrideExisting: false
});


const {
    getChallenges: {
        useQuery: useGetChallenges
    },
    getChallengesMutation: {
        useMutation: useMutationGetChallengesList
    },
    getChallengeById: {
        useMutation: useMutationGetChallengeById
    },
    checkOne: {
        useMutation: useMutationCheckOne
    },
    checkAll: {
        useMutation: useMutationCheckAll
    },
    deleteChallengeById: {
        useMutation: useMutationDeleteChallengeById
    }
} = challengeExtendedApi.endpoints;

export { challengeExtendedApi };
export {
    useGetChallenges,
    useMutationGetChallengeById,
    useMutationCheckOne,
    useMutationCheckAll,
    useMutationGetChallengesList,
    useMutationDeleteChallengeById
};
