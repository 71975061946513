export type TopbarButtonAction = (
    "resetToDefault"
    | "config"
    | "codeHistory"
);

export interface IConsoleOutput {
    success: boolean;
    input: string;
    output: string;
    expected: string;
    check_key: string;
    errors?: string | null;
}

export const DEFAULT_CODE = `import sys;
import math;

# Auto-generated code below aims to helping you parse
# the standard input according to the problem statement

args = input()

# Do your stuff here
# Write an answer using print()

print("UNKNOWN")
`;

export type ConfigAction = (
    "theme"
    | "font-size"
    | "tabSpaces"
);

export interface CodeEditorTheme {
    label: string;
    id: string;
}

export const CODE_EDITOR_THEMES: Array<CodeEditorTheme> = [
    {
        label: "Darcula",
        id: "darcula"
    },
    {
        label: "Seti",
        id: "seti"
    },
    {
        label: "Ambiance",
        id: "ambiance"
    },
    {
        label: "Twilight",
        id: "twilight"
    },
    {
        label: "Panda",
        id: "panda-syntax"
    },
    {
        label: "Monokai",
        id: "monokai"
    },
    {
        label: "Dracula",
        id: "dracula"
    },
]

export interface ICodeEditorConfig {
    theme: string;
    fontSize: string;
    isCodeEditorConfigOpen: boolean;
    tabSpaces: number;
};
