import React from 'react';
import DJCFooter from '../../components/footer';

const Render = () => {
    return (
        <>
            <div className="djc--view-contact">
                <div className="container">
                    <div className="hero">
                        <h4>Vous avez une question ?</h4>
                        <h1>Nous <span className="primary">Contacter</span></h1>
                        <p>Nous sommes disponibles pour vous aider et répondre aux questions que vous pouvez avoir. On vous écoute :)</p>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row justify-content-around">
                                <div className="col-lg-4 col-md-4 col-xs-12">
                                    <div className="form contact-us-left">
                                        <div className="content">
                                            <h3>Siège social</h3>
                                            <div>
                                                <p><b>Pour venir nous voir</b></p>
                                                <div>
                                                    <p>19, rue Bergère</p>
                                                    <p>75009 Paris</p>
                                                    <p>+33 1 49 49 09 03</p>
                                                </div>
                                            </div>
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-xs-12">
                                    <div className="form contact-us-center">
                                        <div className="content">
                                            <h3>Discord</h3>
                                            <p>Pour toute question ou difficulté technique lors du Orness Battle Dev 2023, vous avez à votre disposition ce canal Discord où les créateurs du challenge et les organisateurs sont à votre écoute</p>
                                            <a href="https://discord.gg/688CxhaW8j">Channel Dojo.Codes</a>
                                            <i className="fab fa-discord"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-xs-12">
                                    <div className="form contact-us-right">
                                        <div className="content">
                                            <h3>Mail</h3>
                                            <div>
                                                <p><b>Pour nous contacter pour toute autre demande</b></p>
                                                <a href="mailto:event@orness.com">event@orness.com</a>
                                            </div>
                                            <i className="far fa-envelope"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DJCFooter />
        </>
    );
};

export default Render;
