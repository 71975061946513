import React from 'react';
import MDButton from './components/btn';
import Panel from './components/panel';

export const renderers = {
    image: ({ alt, src }: { alt: string, src: string }) => {
        return (
            <div className="article-image">
                <div className="img-container">
                    <img
                        src={src}
                        alt="article presentation"
                    />
                </div>
                <span className="article-image-meta">
                    { alt }
                </span>
            </div>
        )
    },
    code: ({ language, value }: { language: string, value: string }) => {
        if (language === 'panel') {
            return (
                <Panel src={value} />
            )
        }

        if (language === 'btn') {
            return (
                <MDButton text={value} />
            )
        }

        const className = language && `language-${language}`
        const code = React.createElement('code', className ? { className: className } : null, value)
        return React.createElement('pre', {}, code);
    }
};
