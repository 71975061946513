import { createSlice } from "@reduxjs/toolkit";
import { } from "./types";
import { RootState } from "../../stores";

interface IInitialState {
};

const initialState: IInitialState = {
};

const campaignSlice = createSlice({
    name: "campaign",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {}
});

// export const { } = campaignSlice.actions;
export default campaignSlice.reducer;
