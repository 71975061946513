import React, { KeyboardEvent, useState } from 'react';

import Render from './render';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { SIGN_UP_ROUTE } from '../../../infrastructure/globals/routes';
import { useDispatch } from 'react-redux';
import { usePostAutoToken } from '../../../infrastructure/services/authentication';
import { useGetSelf } from '../../../infrastructure/services/users';
import { setCurrentUser } from '../../../infrastructure/services/users/slice';
import { setAccessToken, setScopes } from '../../../infrastructure/services/authentication/slice';
import { cleanAuthOnLocalStorage, saveCredentialsOnLocalStorage, saveScopesOnLocalStorage } from '../../../infrastructure/services/authentication/utils';

export interface IFormInputs {
    username: string | null;
    password: string | null;
};

const rules = {
    username: {
        minLength: 2,
        required: true
    },
    password: {
        minLegth: 3,
        required: true
    }
};

interface ILoginViewProps {
    redirectTo?: string | null
};

const LoginView = (props: ILoginViewProps) => {
    const { redirectTo } = props;

    const {
        control,
        errors,
        handleSubmit,
        setError
    } = useForm<IFormInputs>({
        mode: "onTouched",
        defaultValues: {
            username: "",
            password: ""
        }
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const [customErrors, setCustomErrors] = useState<Record<string, string>>({});

    const [
        login, {
            isLoading: isLogging,
            isError: isLoginError
        }
    ] = usePostAutoToken();
    const [
        getSelf, {
            isLoading: isLoadingSelf
        }
    ] = useGetSelf();

    const onSubmit = async (data: IFormInputs) => {
        setCustomErrors({});

        if (!data.username || !data.password) {
            return;
        }

        const usernamePattern = /^([A-Za-z0-9_-]+)$/g;
        if (data.username.match(usernamePattern) === null) {
            setCustomErrors({
                ...customErrors,
                "username": "Le nom d'utilisateur contient des caractères interdits."
            });

            return;
        }
        
        try {
            const result = await login({
                username: data.username,
                password: data.password
            });

            if (result.data) {
                dispatch(setAccessToken({
                    access_token: result.data.access_token
                }));
                dispatch(setScopes({
                    scopes: result.data.scopes
                }));

                saveCredentialsOnLocalStorage(result.data, data.username);
                saveScopesOnLocalStorage(result.data.scopes);
            }

            const selfResult = await getSelf();
            if (selfResult.data) {
                dispatch(setCurrentUser(selfResult.data));
            }
        } catch (e) {
            cleanAuthOnLocalStorage();
        }
    };

    const redirect = (to: string) => {
        return () => {
            history.push(to);
        }
    };

    const handleSignUp = () => {
        if (redirectTo) {
            redirect(`${SIGN_UP_ROUTE}/r/${redirectTo}`)();
        } else {
            redirect(SIGN_UP_ROUTE)();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        }
    };

    return (
        <Render
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            rules={rules}
            errors={errors}
            handleSignUp={handleSignUp}
            isLoading={isLoadingSelf || isLogging}
            isLoginError={isLoginError}
            handleKeyDown={handleKeyDown}
            customErrors={customErrors}
        />
    );
};

export default LoginView;
