import React, { useCallback, useEffect, useMemo } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { CAMPAIGNS_ROUTE } from '../../../infrastructure/globals/routes';
import { useMutationApplyAction } from '../../../infrastructure/services/route';
import Toast from '../../components/toast';

interface IEventsViewProps {
    slug: string;
};

const EventsView = (props: IEventsViewProps) => {
    const { slug } = props;

    const history = useHistory();

    const actions = useMemo<string[]>(() => {
        return slug.split(',');
    }, [slug]);

    const [fetchApplyAction] = useMutationApplyAction();

    const applyActions = useCallback(async () => {
        const registeredTo: string[] = [];
        
        for (let action of actions) {
            const resp = await fetchApplyAction(action);

            if (resp.error) {
                console.error(resp.error);
            } else if (resp.data) {
                registeredTo.push(resp.data.id)
            }
        }

        toast.notify(
            <Toast
                text={`Successfully registered to: ${registeredTo.join(", ")}`}
            />,
            {
                duration: 10000,
                position: "top"
            }
        );


        history.push(CAMPAIGNS_ROUTE);
    }, [actions, fetchApplyAction, history]);

    useEffect(() => {
        if (actions.length) {
            applyActions();
        }
    }, [actions, applyActions]);

    return null;
};

export default EventsView;
