import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';

function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Array<any>, comparator: any) {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface IEnhancedTableHeadProps {
    classes: any;
    numSelected: number;
    onRequestSort: any;
    onSelectAllClick: any;
    order: 'asc' | 'desc';
    orderBy: string;
    rowCount: number;
    headCells: any[];
}

function EnhancedTableHead(props: IEnhancedTableHeadProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells
    } = props;

    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

interface IEnhancedTableToolbar {
    numSelected: number;
    title: any;
    handleDeleteClicked?: () => void;
    handleEditClicked?: () => void;
    handleDetailClicked?: () => void;
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbar) => {
    const classes = useToolbarStyles();
    const {
        numSelected,
        title,
        handleDeleteClicked,
        handleEditClicked,
        handleDetailClicked
    } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selectionnés
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {
                numSelected === 1 ? (
                    <>
                        {
                            handleDetailClicked && (
                                <Tooltip title="Detail">
                                    <IconButton aria-label="detail" onClick={handleDetailClicked}>
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        {
                            handleEditClicked && (
                                <Tooltip title="Edit">
                                    <IconButton aria-label="edit" onClick={handleEditClicked}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        {
                            handleDeleteClicked && (
                                <Tooltip title="Delete">
                                    <IconButton aria-label="delete" onClick={handleDeleteClicked}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </>
                ) : null
            }

            {numSelected > 1 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={handleDeleteClicked}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    paper: {
        width: '100%',
        height: `calc(100% - ${theme.spacing(8)}px)`,
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        overflowY: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

interface IProps {
    title: any;
    rows: any[];
    headCells: any[];
    handleGlobalEditClicked?: (selected: any[]) => void;
    handleGlobalDeleteClicked?: (selected: any[]) => void;
    handleGlobalDetailClicked?: (selected: any[]) => void;
    handleGlobalCheckboxSelected?: (selected: any[]) => void;
    handleGlobalInitialSelection?: (id: any) => boolean;
}

export default function EnhancedTable(props: IProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<"asc" | "desc">('asc');
    const [orderBy, setOrderBy] = React.useState<string>('');
    const [selected, setSelected] = React.useState<any[]>([]);

    const {
        rows,
        headCells,
        title,
        handleGlobalDeleteClicked,
        handleGlobalEditClicked,
        handleGlobalDetailClicked,
        handleGlobalCheckboxSelected,
        handleGlobalInitialSelection
    } = props;

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: any, name: any) => {
        if (event.target.type === "checkbox") {
            event.preventDefault();
            return;
        }

        setSelected([name]);
    };

    const handleClickCheckbox = async (event: any, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        if (!handleGlobalCheckboxSelected) {
            return;
        }
        await handleGlobalCheckboxSelected(newSelected);
    };

    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const selectInitialCheckboxes = (rows: any[]) => {
        if (!handleGlobalInitialSelection) {
            return;
        }

        let newSelected: any[] = [];
        for (let row of rows) {
            const is_initially_selected = handleGlobalInitialSelection(row.id);
            if (is_initially_selected && selected.indexOf(row.id) == -1) {
                const selectedIndex = selected.indexOf(row.id);
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selected, row.id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selected.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    newSelected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selected.slice(0, selectedIndex),
                        selected.slice(selectedIndex + 1),
                    );
                }
            }
        }
        if (newSelected) {
            setSelected(newSelected);
        }
    }

    const generateValue = (key: string, value: any) => {
        const headCell = headCells.find(h => h.id === key);
        if (!headCell) {
            return value;
        }

        if (headCell.url) {
            return <Link target="_blank" to={value}>{value}</Link>
        }

        if (headCell.boolean) {
            return value === "true"
                ? <span className="green-circle" />
                : <span className="red-circle" />
        }

        return value;
    }

    const handleEdit = async () => {
        if (!handleGlobalEditClicked) {
            return;
        }
        await handleGlobalEditClicked(selected);
        setSelected([]);
    }

    const handleDelete = async () => {
        if (!handleGlobalDeleteClicked) {
            return;
        }
        await handleGlobalDeleteClicked(selected);
        setSelected([]);
    }

    const handleDetail = async () => {
        if (!handleGlobalDetailClicked) {
            return;
        }
        await handleGlobalDetailClicked(selected);
    }

    React.useEffect(() => {
        selectInitialCheckboxes(rows)
    }, [rows])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    title={title}
                    handleEditClicked={handleGlobalEditClicked !== undefined ? handleEdit : undefined}
                    handleDeleteClicked={handleGlobalDeleteClicked !== undefined ? handleDelete : undefined}
                    handleDetailClicked={handleGlobalDetailClicked !== undefined ? handleDetail : undefined}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClickCheckbox(event, row.id)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.id}
                                            </TableCell>
                                            {
                                                Object.keys(row).filter(k => k !== "id").map((key) => (
                                                    <TableCell align="left" style={{ padding: 0 }}>
                                                        {generateValue(key, row[key])}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
