import { CircularProgress, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';
import { useHistory } from 'react-router';
import useForm, { IError } from '../../../../../hooks/form/useForm';
import Toast from '../../../../../components/toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutationPostRegister } from '../../../../../../infrastructure/services/authentication';
import { useMutationPostCreatePAT } from '../../../../../../infrastructure/services/PAT';
import CopyToClipboardField from '../../../../../components/copy_to_clipboard_field';
import { useMutationGetRoleById } from '../../../../../../infrastructure/services/roles';
import { useMutationGetCampaignsList } from '../../../../../../infrastructure/services/campaign';

type Inputs = {
    firstName: string;
    lastName: string;
    email: string;
    campaignName: string;
}

const AdminTempAccountCreateView = () => {

    const [fetchRegister] = useMutationPostRegister();
    const history = useHistory();
    const [fetching, setFetching] = useState<boolean>(false);
    const [tempUsername, setTempUsername] = useState<string>(`temp_account_${Math.random() * (99999999 - 10000000) + 10000000}`);
    const [accountCreated, setAccountCreated] = useState<boolean>(false);
    const [URL, setURL] = useState<string>("https://dojo.codes/test_candidat/");
    const [fetchCreate] = useMutationPostCreatePAT();
    const [fetchRole] = useMutationGetRoleById();
    const [fetchCampaigns] = useMutationGetCampaignsList();
    const [campaigns, setCampaigns] = useState<Array<string>>([]);

    const handleValidate = (v: Inputs) => {
        let errors: IError<Inputs> = {};
        return errors;
    }

    const initCampaigns = async () => {
        const resp = await fetchCampaigns();

        if (resp.data) {
            const campaigns_list: string[] = [];
            for (const campaign of resp.data.campaigns) {
                campaigns_list.push(campaign.name)
            }
            setCampaigns(campaigns_list);
        }
    };

    const init = async () => {
        await initCampaigns();
    }

    useEffect(() => {
        init();
    }, []);

    const { values, handleBlur, handleChange, handleChangeSingleAutocomplete, handleBlurAutocomplete } = useForm<Inputs>({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            campaignName: ""
        },
        validate: handleValidate,
    });

    const handleSave = async () => {
        setFetching(true);
        const resp = await fetchRegister({
            username: tempUsername,
            first_name: values.firstName.length === 0 ? "Unknown" : values.firstName,
            last_name: values.lastName.length === 0 ? "Unknown" : values.lastName,
            email: values.email.length === 0 ? `${tempUsername}@dojo.codes` : values.email,
            password: "",
            phone: null,
            guild: null,
            experience: null,
            registration_actions: []
        })
        if (resp.data) {
            toast.notify(
                <Toast text={"Compte temporaire créée avec succès."} />,
                {
                    duration: 10000,
                    position: "top",
                }
            );
            const expiration_date = new Date();
            expiration_date.setDate(expiration_date.getDate() + 7);
            const resp_role = await fetchRole("User")
            let scopes: string[] = [];
            if (resp_role.data) {
                scopes = resp_role.data.scopes;
            }
            const resp_pat = await fetchCreate({
                id: tempUsername,
                scopes: scopes,
                expiration_date: expiration_date
            });
            if (resp_pat.data) {
                const pat = resp_pat.data.token;
                const redirect = values.campaignName.length === 0 ? "home" : values.campaignName
                setURL(URL.concat(btoa(tempUsername + ':' + pat + ':' + redirect)));
                setTempUsername(`temp_account_${Math.random() * (99999999 - 10000000) + 10000000}`);
                setAccountCreated(true);
                setFetching(false);
            } else if (resp.error) {
                setFetching(false);
                const error = (resp.error as any);
                const message = (error.data && error.data.detail && error.data.detail.message)
                    ? error.data.detail.message
                    : "Unexpected server error. Please try again later.";

                toast.notify(
                    <Toast
                        text={message}
                    />,
                    {
                        duration: 10000,
                        position: "top",
                        type: "error"
                    }
                );
            }
        } else if (resp.error) {
            setFetching(false);
            const error = (resp.error as any);
            const message = (error.data && error.data.detail && error.data.detail.message)
                ? error.data.detail.message
                : "Unexpected server error. Please try again later.";

            toast.notify(
                <Toast
                    text={message}
                />,
                {
                    duration: 10000,
                    position: "top",
                    type: "error"
                }
            );
        }
    };

    const handleCancel = () => {
        history.push("/admin/temp_accounts");
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="title">
                    <i className="fas fa-cogs" />
                    <h1>Créer compte temporaire</h1>
                </div>

                <div className="actions">
                    <button onClick={handleCancel}>Annuler</button>
                </div>
            </div>
            <div className="body">
                <div className="container-fluid section">
                    <h4>Informations personnelles (facultatif)</h4>
                    <div className="p-4">
                        <div className="row mb-16 align-center">
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-first-name"
                                    name="firstName"
                                    label="Prénom"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.firstName}
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-last-name"
                                    name="lastName"
                                    label="Nom de Famille"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.lastName}
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <TextField
                                    id="input-email"
                                    name="email"
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    value={values.email}
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mb-16">
                                <Autocomplete
                                    id="input-campaign"
                                    options={campaigns}
                                    clearOnEscape
                                    onBlur={handleBlurAutocomplete("campaignName")}
                                    onChange={handleChangeSingleAutocomplete("campaignName")}
                                    fullWidth
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            name={"campaignName"}
                                            label={"Campagne"}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-4" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>

                        {fetching ? <CircularProgress className="inline-block-important" /> : ""}
                        {accountCreated ? <span>
                            <CopyToClipboardField
                                value={URL}
                                id="url"
                                label="URL à donner au candidat"
                            /> </span> : ""}

                    </div>


                    <div className="p-4">
                        <div className="col-md-2">
                            <button
                                onClick={handleSave}
                            >
                                Créer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
};

export default AdminTempAccountCreateView;
