import { api } from "..";
import { IGetTagsList, ITag, ITagWithKind } from "./types";

const tagExtendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTagList: build.mutation<IGetTagsList, string>({
            query: (arg) => ({
                url: `/tags/${arg}`,
                method: "GET"
            })
        }),
        postTagCreate: build.mutation<ITag, ITagWithKind>({
            query: (args) => ({
                url: `/tags/${args.tag_kind}`,
                method: 'POST',
                body: args.tag
            })
        }),
        getTagById: build.mutation<ITag, string>({
            query: (args) => ({
                url: `/tags/${args}`,
                method: 'GET'
            })
        }),
        deleteTagById: build.mutation<void, string>({
            query: (arg) => ({
                url: `/tags/${arg}`,
                method: 'DELETE',
                validateStatus: (status) => status === 204
            })
        })
    }),
    overrideExisting: false
});

const {
    getTagList: {
        useMutation: useMutationGetTagList
    },
    postTagCreate: {
        useMutation: useMutationPostTagCreate
    },
    getTagById: {
        useMutation: useMutationGetTagById
    },
    deleteTagById: {
        useMutation: useMutationDeleteTagById
    }
} = tagExtendedApi.endpoints;

export { tagExtendedApi };
export {
    useMutationDeleteTagById,
    useMutationPostTagCreate,
    useMutationGetTagList,
    useMutationGetTagById
};
