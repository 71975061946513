import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateDocumentTileForCampaign, updateDocumentTitleToDefault } from '../../../../infrastructure/logic/document';
import { useGetCampaignFromRoute } from '../../../../infrastructure/services/campaign';
import { ICampaignSummary } from '../../../../infrastructure/services/campaign/types';
import { useMutationGetScoreboardResultsById } from '../../../../infrastructure/services/scoring';
import { IScoreboardResults } from '../../../../infrastructure/services/scoring/types';
import { selectCurrentUser } from '../../../../infrastructure/services/users/slice';
import DJCerror from '../../../components/error';

import Render from './render';

interface ICampaignsLeaderboardViewProps {
    slug: string;
};

const CampaignsLeaderboardView = (props: ICampaignsLeaderboardViewProps) => {
    const { slug } = props;

    const history = useHistory();

    const currentUser = useSelector(selectCurrentUser);
    const [isReadyToRender, setReadyToRender] = useState<boolean>(false);
    const [globalResults, setGlobalResults] = useState<IScoreboardResults | null>(null);

    const [fetchScoreboardById] = useMutationGetScoreboardResultsById();

    const {
        data: campaign,
        isUninitialized,
        isLoading,
        isError,
        error
    } = useGetCampaignFromRoute(slug);

    const redirectTo = useCallback((url: string) => {
        history.push(url);
    }, [history]);

    const retreiveScoreboardInformations = useCallback(async (scoreboardId: string) => {
        const scoreboardResults = await fetchScoreboardById(scoreboardId);

        if (scoreboardResults.data) {
            setGlobalResults(scoreboardResults.data);
        }
    }, [fetchScoreboardById]);

    const init = useCallback(async (camp: ICampaignSummary) => {
        if (!camp.associated_scoreboard) {
            redirectTo(`/campaigns/${slug}`);
            return;
        }

        await retreiveScoreboardInformations(camp.associated_scoreboard);

        setReadyToRender(true);
    }, [retreiveScoreboardInformations, redirectTo, slug]);

    useEffect(() => {
        if (campaign) {
            updateDocumentTileForCampaign(campaign);
            init(campaign);
        }

        return () => {
            updateDocumentTitleToDefault();
        };
    }, [campaign, init]);

    if (isError && error) {
        const err = (error as any);
        let errorMsg = "Internal Error";

        if (err.data && err.data.detail) {
            errorMsg = err.data.detail.message;
        }

        return (
            <DJCerror
                status={err.status}
                errorMessage={errorMsg}
            />
        )
    }

    if (isUninitialized || isLoading || !isReadyToRender) {
        return null;
    }

    if (!campaign) {
        return (
            <DJCerror
                status={404}
                errorMessage="Service Temporarily Unavailable"
            />
        )
    }

    return (
        <Render
            campaign={campaign}
            scoreboard={globalResults}
            currentUser={currentUser}
        />
    );
};

export default CampaignsLeaderboardView;
